import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { Link, useParams } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import FloatingBannerHeight from 'components/promotion/FloatingBannerHeight'

import MallError from './Error'
import styles from './Intro.module.scss'

import 'react-loading-skeleton/dist/skeleton.css'

export default function MallIntro() {
  const targetDate = '20240830' //d-day 날짜 설정

  const { mallSeq } = useParams()
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [isLoading, setLoarding] = useState(true)
  const [isError, setError] = useState(false)
  const [mallData, setMallData] = useState(null)
  const [isExpired, setExpired] = useState(false)

  const mallInfoGet = async (code) => {
    try {
      await api
        .get(`/v2/mall/` + code)
        .then((response) => {
          if (response.data.meta.code === -1) {
            setError(true)
          } else {
            const data = response.data.data
            setMallData(data)
          }
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
          setError(true)
        })
    } catch (e) {
      console.log(e)
      setError(true)
    } finally {
      setLoarding(false)
    }
  }

  useEffect(() => {
    mallInfoGet(mallSeq.toLowerCase())
  }, [mallSeq])

  const getFormattedDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')

    return `${year}${month}${day}`
  }

  useEffect(() => {
    const currentDate = getFormattedDate()
    setExpired(currentDate > targetDate)
  }, [targetDate])

  return (
    <>
      {isLoading && isLoading ? (
        <>
          <section className={styles.introTemplete}>
            <div className={styles.inner}>
              <Skeleton height={'100vh'} />
            </div>
          </section>
        </>
      ) : isError && isError ? (
        <MallError comment={`연결할 수 없는 페이지 입니다`} />
      ) : (
        <>
          {!!mallData ? (
            deviceCheck ? (
              <section className={styles.imgWrap}>
                <img src={mallData.bannerImageMO} alt="" />
              </section>
            ) : (
              <section
                className={styles.mallBanner}
                style={{
                  backgroundImage: `url('${mallData.bannerImagePC}')`
                }}
              ></section>
            )
          ) : null}
          {!!mallData && mallData.videoCode && mallData.videoCode !== '' ? (
            <section className={styles.introVideo}>
              <dl>
                <dt>
                  미리 들어보는 성공비결<strong>164만 야나두 회원이 경험한 놀라운 변화!</strong>
                </dt>
                <dd>
                  <div className={styles.mediaBox}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={mallData.videoCode}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </dd>
              </dl>
            </section>
          ) : null}
          <div className={styles.introContentImg}>
            {!!mallData && mallData.contentImagePC ? (
              <img src={deviceCheck ? mallData.contentImageMO : mallData.contentImagePC} alt="" />
            ) : null}
          </div>

          <FloatingBannerHeight>
            <div className={styles.introBottomBar} id="fixedBanner">
              {mallSeq !== 'lllcard' || (mallSeq === 'lllcard' && isExpired) ? (
                <p>
                  <strong>특별 할인가로</strong> <strong>만나볼 수 있어요!</strong>
                </p>
              ) : (
                <div className={styles.ddayBoxWrap}>
                  <p className={styles.txt}>{deviceCheck ? '마감까지' : '사용 마감까지'}</p>
                  <DdayCounter targetDate={targetDate} />
                </div>
              )}
              <Link to={`/mall/${mallSeq}/list`}>
                {mallSeq !== 'lllcard'
                  ? mallData && mallData.buttonName
                    ? mallData.buttonName
                    : '전용혜택관 입장하기'
                  : deviceCheck
                  ? '평생교육바우처 상품'
                  : '평생교육바우처 상품 바로보기'}
              </Link>
            </div>
          </FloatingBannerHeight>
        </>
      )}
    </>
  )
}

function DdayCounter({ targetDate }) {
  const parseDate = (dateString) => {
    const year = dateString.substring(0, 4)
    const month = dateString.substring(4, 6) - 1
    const day = dateString.substring(6, 8)
    return new Date(year, month, day)
  }

  const calculateDaysLeft = (date) => {
    const target = parseDate(date)
    const today = new Date()
    const difference = target - today
    return Math.ceil(difference / (1000 * 60 * 60 * 24))
  }

  const [daysLeft, setDaysLeft] = useState(calculateDaysLeft(targetDate))

  useEffect(() => {
    const timer = setInterval(() => {
      setDaysLeft(calculateDaysLeft(targetDate))
    }, 1000 * 60 * 60 * 24)

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate])

  const formatDaysLeft = (days) => {
    const daysString = days < 10 ? `0${days}` : `${days}`
    return daysString.split('')
  }

  if (daysLeft < 0) {
    return null
  }

  return (
    <div className={styles.ddayBox}>
      <span>D</span>
      <span className={styles.dash}>-</span>
      {formatDaysLeft(daysLeft).map((char, index) => (
        <span key={index}>{char}</span>
      ))}
    </div>
  )
}
