import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useGetCouponPossibleDownload from 'hooks/useGetCouponPossibleDownload'
import ModalPortal from 'ModalPortal'

import api from 'common/api'
import * as config from 'common/config'
import { setCookie, getCookie } from 'common/Cookie'
import ModalCouponDownload from 'components/modal/ModalCouponDownload'
import ModalCourse from 'components/modal/ModalCourse'
import useModalLogin from 'store/useModalLogin'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

import InstallmentsButton from './InstallmentsButton'
import PriceButton from './PriceButton'
import styles from '../StoreDetail.module.scss'

export default function TopInfoSection({
  classData,
  userInfo,
  isSticky,
  isEntrancedCheck,
  isEntrancedUser
}) {
  const navigate = useNavigate()
  const [isShowCourseModal, setIsShowCourseModal] = useState(false)
  const [isShowCouponModal, setIsShowCouponModal] = useState(false)

  const { setIsModalLogin } = useModalLogin()
  const { couponDownloadData, getCouponDownload } = useGetCouponPossibleDownload()

  // 로그인시 useGetCouponPossibleDownload 훅에서 쿠폰 리스트 가져옴
  // 비로그인시 classData에서 쿠폰 리스트 가져옴
  const couponList = userInfo ? couponDownloadData : classData.downloadCouponInfos

  const handleOpenModalLogin = useCallback((e) => {
    setCookie('orderClickCheck', 'Y')
    setIsModalLogin(true)
  }, [])

  const handleOpenModalCourse = useCallback((e) => {
    setIsShowCourseModal(true)
  }, [])

  const handleCloseModalCourse = useCallback((e) => {
    setIsShowCourseModal(false)
  }, [])

  const showErrorMessage = (response) => {
    if (response.data.meta.message) {
      alert(response.data.meta.message)
    } else {
      alert(config.MESSAGE['common-error'])
    }
  }

  const dataChkPriceInfo = () => {
    try {
      if (window['checkURL'] !== window.location.href && classData.packageSeq !== undefined) {
        window['dataChkPriceInfo'](
          classData.title,
          !!document.getElementById('productPrice')
            ? Number(
                document.getElementById('productPrice').innerText.replace(',', '').replace(',', '')
              )
            : 0,
          classData.packageCategoryGroupName + ', ' + classData.packageCategoryName,
          classData.packageSeq
        )
        window['checkURL'] = window.location.href
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleClickOrderStart = () => {
    if (!userInfo) {
      handleOpenModalLogin()
    } else if (classData.requireEntranceYn === 'Y') {
      handleOpenModalCourse()
    } else {
      onOrderStart()
    }
  }
  const onOrderStart = (productSeq) => {
    const packageSeq = classData.packageSeq
    const goodsParam =
      typeof productSeq === `string` && productSeq.length > 0
        ? `?presentProductSeq=${productSeq}`
        : ``

    // 주문번호 재셋팅을 위한 productParam 값 셋팅.
    window.sessionStorage.setItem(
      'productParam',
      JSON.stringify({ param: `${packageSeq}${goodsParam}`, orderReset: false })
    )

    api
      .post(`/payment/v2/order/${packageSeq}${goodsParam}`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          navigate(`/mypage/order/cart/detail/${response.data.data}`)
        } else {
          showErrorMessage(response)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDownloadCoupon = (seq) => {
    getCouponDownload(classData?.existDownloadCoupon, seq)
  }

  const handleOpenCouponModal = useCallback((e) => {
    // 로그인 여부 체크
    if (!userInfo) {
      alert('로그인이 필요합니다.')
      window.location.href = '/login'
    } else {
      setIsShowCouponModal(true)
    }
  }, [])

  const handleCloseCouponModal = () => {
    setIsShowCouponModal(false)
  }

  useEffect(() => {
    dataChkPriceInfo()
    if (userInfo) getCouponDownload(classData.existDownloadCoupon, classData.packageSeq)
    return () => {
      document.body.classList.remove('scroll-fixed')
    }
  }, [])

  useEffect(() => {
    if (isEntrancedCheck) {
      // 렌더링 후 orderClickCheck, orderLoginSuccess 쿠키값 체크, 로그인 여부 값을 체크합니다.
      // 1. getCookie('orderClickCheck') === 'Y' : 비로그인 상태에서 수강신청버튼을 클릭한 경우를 체크하기 위한 목적.
      // 2. getCookie('orderLoginSuccess') === 'Y' : 로그인에 성공했으며, reloadType 인 경우를 체크하기 위한 목적. (Login.jsx)
      // 3. userInfo : 유저 정보 유무를 체크하기 위한 목적.
      // 1~3 모두 true 라면 .btn-cart 버튼을 클릭 시켜 결제 화면 or 입학 팝업이 노출되도록 처리하고, 쿠키값과 state 값을 초기화 합니다.

      if (
        getCookie('orderClickCheck') === 'Y' &&
        getCookie('orderLoginSuccess') === 'Y' &&
        userInfo
      ) {
        if (document.querySelector('.btn-cart')) {
          setCookie('orderLoginSuccess', 'N')
          setCookie('orderClickCheck', 'N')
          document.querySelector('.btn-cart').click()
        }
      }
    }
  }, [isEntrancedCheck])

  return (
    <>
      <div className={styles.classInfoSection}>
        <div className={styles.classTitle}>
          <div className={styles.category}>
            {classData.packageCategoryGroupName}
            &nbsp;&middot;&nbsp;
            {classData.packageCategoryName}
          </div>
          <div className={styles.title}>{classData.title}</div>

          <div className={styles.titleDescription}>
            <i className={styles.iconStar}></i>
            <span>
              {classData.reviewSatisfaction.toFixed(1)}(
              {classData.reviewCount >= 100 ? '100+' : classData.reviewCount})
            </span>
            <span className={styles.teacher}>{classData.teacherInfo}</span>
          </div>
        </div>

        {/* 다운로드할 쿠폰 있을 경우 */}
        {couponList.length > 0 &&
          couponList.map((coupon) => (
            <button
              key={coupon?.couponSeq}
              className={styles.couponButton}
              onClick={!userInfo ? handleOpenModalLogin : handleOpenCouponModal}
            >
              <i></i>
              {coupon?.couponName}
            </button>
          ))}

        {/* 시작하기 버튼 */}
        <PriceButton
          isEntrancedUser={isEntrancedUser}
          classData={classData}
          onOpenModalLogin={handleOpenModalLogin}
          onOpenModalCourse={handleOpenModalCourse}
          onOrderStart={handleClickOrderStart}
        />
        {/* 무이자 버튼 */}
        <InstallmentsButton />
      </div>

      {/* 하단 가격 고정버튼 */}
      {isSticky && (
        <div className={styles.floatingPrice}>
          <InstallmentsButton />
          <PriceButton
            isEntrancedUser={isEntrancedUser}
            classData={classData}
            onOpenModalLogin={handleOpenModalLogin}
            onOpenModalCourse={handleOpenModalCourse}
            onOrderStart={handleClickOrderStart}
          />
        </div>
      )}
      {/* 입학 모달  */}
      {isShowCourseModal ? (
        <ModalPortal>
          <ModalCourse
            btnClose
            goodsDirectOrder={handleClickOrderStart}
            handleCloseModal={handleCloseModalCourse}
            showErrorMessage={showErrorMessage}
          />
        </ModalPortal>
      ) : null}
      {/* 쿠폰 다운로드 모달 */}
      {isShowCouponModal && couponDownloadData && (
        <ModalCouponDownload
          btnClose
          handleCloseModal={handleCloseCouponModal}
          couponDownloadData={couponDownloadData}
          getCouponDownloadData={handleDownloadCoupon}
          priceInfoSeq={classData.packageSeq}
        />
      )}
    </>
  )
}
