import React from 'react'
import '../../assets/styles/modal.scss'

export default function ModalInstallments({ title, btnClose, handleClose }) {
  return (
    <>
      <div className="modal-wrap ">
        <div className="dimmed" onClick={handleClose}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleClose}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            <div className="installments">
              <p>
                * 결제 수단 별 결제 창에서 <br />
                할부 개월 선택 시 무이자 할부 행사 안내가 있는 경우에만
                <br />
                무이자 할부 혜택이 제공됩니다.
              </p>
              <p>* 결제 시점에 따라 무이자 혜택 조건이 변동될 수 있습니다.</p>
              <p>* 법인, 체크, 기프트카드는 무이자 혜택이 제외됩니다.</p>
              <p>
                * BC카드의 경우 BC마크가 없는 경우 <br />
                무이자 할부 혜택이 제공되지 않습니다.
              </p>
              <div className="card">
                <strong>최대 12개월 무이자 할부 카드(5만 원 이상 결제 시)</strong>
                <br /> 외환카드, 롯데카드, 현대카드, 현대카드, KB국민카드,
                <br /> BC카드, 삼성카드, 신한카드, 하나SK카드, NH카드
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
