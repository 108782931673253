import React, { useState, useRef, useEffect } from 'react'

import styles from './ModalMallAuth.module.scss'
const img_mall_college =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_mall_college.png'

export default function ModalMallAuth({
  mallType,
  handleCloseModal,
  mallInfoComplete,
  packageSeq
}) {
  const [isBtnActive, setBtnActive] = useState(false)
  const collegeNameRef = useRef()
  const campusNameRef = useRef()
  const validationCheck = () => {
    const collegeName = collegeNameRef.current.value
    const campusName = campusNameRef.current.value
    collegeName.trim().length > 0 && campusName.trim().length > 0
      ? setBtnActive(true)
      : setBtnActive(false)
  }
  const mallAuthComplete = () => {
    const collegeName = collegeNameRef.current.value
    const campusName = campusNameRef.current.value
    if (collegeName.trim().length > 0 && campusName.trim().length > 0) {
      window.localStorage.setItem('mallCategory', collegeName)
      window.localStorage.setItem('mallDetail', campusName)
      window.localStorage.setItem('packageSeq', packageSeq)
      mallInfoComplete()
    } else {
      alert('대학교 명을 입력해주세요.')
    }
  }

  useEffect(() => {
    if (mallType === '') mallInfoComplete()
  }, [mallType, mallInfoComplete])

  return (
    <div className={styles.modalWrap}>
      <div onClick={handleCloseModal}></div>
      <div className={styles.modalInner}>
        <button onClick={handleCloseModal} className={styles.icoClose}>
          <span>취소</span>
        </button>
        {mallType && mallType === 'college' ? (
          <div className={styles.collegeBox}>
            <dl>
              <dt>
                <strong>
                  수강 신청 및 결제를 위해
                  <br />
                  아래 정보를 입력해주세요
                </strong>
                입력하신 정보는 야나두 이용자 식별 및<br />
                회원관리 서비스 제공에 사용됩니다
                <img src={img_mall_college} alt="" />
              </dt>
              <dd>
                <input
                  type="text"
                  placeholder="대학교 명을 입력해주세요."
                  name="collegeName"
                  ref={collegeNameRef}
                  onChange={validationCheck}
                  maxLength={20}
                />
              </dd>
              <dd>
                <input
                  type="text"
                  placeholder="캠퍼스 명을 입력해주세요.(없을경우 '없음'입력)"
                  name="campusName"
                  ref={campusNameRef}
                  onChange={validationCheck}
                  maxLength={20}
                />
              </dd>
            </dl>
            <button
              onClick={() => mallAuthComplete()}
              className={`${styles.btnComplete} ${isBtnActive ? styles.active : ''}`}
            >
              <span>결제하기</span>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
