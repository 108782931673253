import React from 'react'
import { useLocation, useParams } from 'react-router'

import useGetFaqFilter from 'pages/service/hooks/useGetFaqFilter'

import CustomerFaqList from './CustomerFaqList'
import CustomerFooter from './CustomerFooter'
import CustomerTitleBar from './CustomerTitleBar'
import NotFindResult from './NotFindResult'
import s from './WebviewCustomer.module.scss'

export default function CustomerSearchResult() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const keyword = queryParams.get('key')
  const { currentSeq } = useParams()
  const { faqList } = useGetFaqFilter(keyword)

  return (
    <article className={s.customerWrap}>
      <CustomerTitleBar title={Number(currentSeq) === 1 ? '고객센터' : '자주 묻는 질문'} />
      <div className={s.customerInner} style={{ gap: 0 }}>
        <section className={s.sectionIntro}>
          <hgroup className={s.resultTitle}>
            <h3>
              <em>{keyword}</em>에 대한 검색결과
            </h3>
            <span className={s.searchCount}>총 {faqList.length}개</span>
          </hgroup>
        </section>
        {faqList.length < 1 ? (
          <>
            <NotFindResult />
          </>
        ) : (
          <CustomerFaqList faqList={faqList} />
        )}
      </div>
      {faqList.length >= 1 && <CustomerFooter />}
    </article>
  )
}
