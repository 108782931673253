import { API_HOST } from 'common/config'

const userAgent = () => {
  // userAgent OS
  let userAgent = navigator.userAgent.toLowerCase()
  let returnUserAgent
  if (userAgent.search('android') > -1) {
    returnUserAgent = 'aos'
  } else if (
    userAgent.search('iphone') > -1 ||
    userAgent.search('ipod') > -1 ||
    userAgent.search('ipad') > -1 ||
    userAgent.search('macintosh') > -1
  ) {
    returnUserAgent = 'ios'
  }
  return returnUserAgent
}

const isEmpty = (val) => {
  return (
    val === undefined ||
    val === null ||
    val === '' ||
    (val !== null && typeof val === 'object' && !Object.keys(val).length)
  )
}

const isNotEmpty = (val) => {
  return !isEmpty(val)
}

const callIOS = (message, service) => {
  if (service === 'srr') {
    window.webkit.messageHandlers.yndSrriOSScriptHandler.postMessage(message)
  } else {
    window.webkit.messageHandlers.yndiOSScriptHandler.postMessage(message)
  }
}

const callNative = (iOSFunction, androidFunction, defaultFunction) => {
  let device = userAgent()

  try {
    if (device === 'ios') {
      iOSFunction()
    } else if (device === 'aos') {
      androidFunction()
    } else if (isNotEmpty(defaultFunction)) {
      defaultFunction()
    } else {
      return
    }
  } catch (e) {
    if (isNotEmpty(defaultFunction)) {
      defaultFunction()
    }
  }
}

const closeWebView = (service) => {
  callNative(
    function () {
      callIOS({ action: 'closeWebView' }, service)
    },
    function () {
      window.Android.closeWebView()
    }
  )
}

const hideTitleBar = (service) => {
  callNative(
    function () {
      callIOS({ action: 'hideTitleBar' }, service)
    },
    function () {
      window.Android.hideTitleBar()
    }
  )
}

const showTitleBar = (service) => {
  callNative(
    function () {
      callIOS({ action: 'showTitleBar' }, service)
    },
    function () {
      window.Android.showTitleBar()
    }
  )
}

const loadURL = (path) => {
  callNative(
    function () {
      callIOS({ action: 'loadURL', url: `${API_HOST}${path}` })
    },
    function () {
      window.Android.loadURL(`${API_HOST}${path}`)
    }
  )
}

const setTitle = (title) => {
  if (isEmpty(title)) {
    return
  }

  callNative(
    function () {
      callIOS({ action: 'setTitle', title: title })
    },
    function () {
      window.Android.setTitle(title)
    }
  )
}

function trackScreenViewWithGA(screenName, screenClass) {
  callNative(
    () => callIOS({ action: 'sendGAScreenView', name: screenName, screenClass: screenClass }),
    () => {
      if (window.Android && window.Android.sendGAScreenView) {
        window.Android.sendGAScreenView(screenName, screenClass)
      } else {
        console.warn('Android method sendGAScreenView is not available.')
      }
    }
  )
}

export const NATIVE_FUNC = {
  closeWebView,
  hideTitleBar,
  showTitleBar,
  loadURL,
  setTitle,
  trackScreenViewWithGA
}
