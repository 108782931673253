import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import { getCookie } from 'common/Cookie'

import ModalJoin from './ModalJoin'
import ModalSocialLogin from './ModalSocialLogin'
import '../../../src/assets/styles/modal.scss'
import '../../../src/assets/styles/modalJoin.scss'

export default function ModalMemberType({
  title,
  btnClose,
  accessToken,
  encryptToken,
  snsType,
  handleCloseModal
}) {
  //snsType: 0:일반, 1:네이버, 2:페이스북, 3:카카오, 4:애플
  const navigate = useNavigate()

  const [memberType, setMemberType] = useState('')
  const [modalopenJoin, setModalopenJoin] = useState(false)
  const [modalopenLogin, setModalopenLogin] = useState(false)
  const [joinPath, setJoinPath] = useState(null)

  const handleOpenModalSocialLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenLogin(true)
  }, [])

  const handleCloseModalSocialLogin = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenLogin(false)
  }, [])

  const handleOpenModalJoin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenJoin(true)
  }, [])

  const handleCloseModalJoin = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenJoin(false)
    // historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
    if (getCookie('historyUrl') !== '') {
      if (window.location.href !== getCookie('historyUrl'))
        window.location.href = getCookie('historyUrl')
    } else {
      navigate('/')
    }
  }, [])

  const handleMemberCheck = (e) => {
    setMemberType(e.target.value)
  }

  useEffect(() => {
    setJoinPath(getCookie('joinPathCode'))
  }, [])

  return (
    <>
      <div className="modal-wrap modal-form join">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <h3>
              <i className="blind">{title}</i>
            </h3>
          </div>
          <div className="modal-content type-fixed-button social">
            <div className="title">
              <p>
                <strong>회원가입</strong>
                야나두 이용이 처음이신 신규 회원은 추가정보를 입력해 주세요.
                <br />
                기존 야나두 아이디가 있으신 경우, 통합을 진행해 주세요.
              </p>
            </div>
            <ul className="social-join-check">
              <li>
                <label htmlFor="existingMember" className="">
                  <p>
                    <strong>기존 회원</strong>
                    기존 야나두 회원 아이디가 있습니다.
                  </p>
                  <input
                    type="radio"
                    id="existingMember"
                    name="type"
                    value="existing"
                    onChange={handleMemberCheck}
                  />
                  <span className="chk"></span>
                </label>
              </li>
              <li>
                <label htmlFor="newMember" className="">
                  <p>
                    <strong>신규 회원</strong>
                    처음 야나두 사이트를 이용합니다.
                  </p>
                  <input
                    type="radio"
                    id="newMember"
                    name="type"
                    value="new"
                    onChange={handleMemberCheck}
                  />
                  <span className="chk"></span>
                </label>
              </li>
            </ul>
            <div className="btn-flex-form">
              <button
                type="button"
                className={'btn' + (memberType ? ' active' : ' disabled')}
                onClick={
                  memberType === 'existing' ? handleOpenModalSocialLogin : handleOpenModalJoin
                }
              >
                <span>다음</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalopenJoin ? (
        <ModalPortal>
          <ModalJoin
            title={'회원가입'}
            btnClose
            type={'SOCIAL'} // DEFAULT: 기존 회원가입 , SOCIAL: 소셜 회원가입, PAYMENT: 수기결제
            snsType={snsType}
            accessToken={accessToken}
            encryptToken={encryptToken}
            handleCloseModal={handleCloseModalJoin}
            whereJoinType={joinPath}
          />
        </ModalPortal>
      ) : null}
      {modalopenLogin ? (
        <ModalPortal>
          <ModalSocialLogin
            title={'소셜 로그인'}
            btnClose
            snsType={snsType}
            accessToken={accessToken}
            encryptToken={encryptToken}
            handleCloseModal={handleCloseModalSocialLogin}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
