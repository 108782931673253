import { useState, useEffect } from 'react'

import api from 'common/api'

import '../../assets/styles/modal.scss'
import styles from './ModalGoods.module.scss'

const ModalGoods = ({ btnClose, handleCloseModal, goodsDirectOrder, showErrorMessage }) => {
  const [inputValue, setInputValue] = useState({
    goodsType: ''
  })
  const [goodsItem, setGoodsItem] = useState([])

  const getGoodsItem = () => {
    api
      .get(`/v2/store/entrance-present`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          setGoodsItem(response.data.data)
        } else {
          showErrorMessage(response)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleInputValue = (e) => {
    let { name, value } = e.target

    if (value) {
      setInputValue({ ...inputValue, [name]: value })
    } else {
      setInputValue({ ...inputValue, [name]: '' })
    }
  }

  useEffect(() => {
    getGoodsItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="modal-wrap">
      <div className="dimmed" onClick={handleCloseModal}></div>
      <div className={styles.modalInner}>
        {btnClose && (
          <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
            <i className="blind">닫기</i>
          </button>
        )}
        <div className={`${styles.modalContent} modal-content type-fixed-button`}>
          <div className={styles.yndUnivBox}>
            <h4>
              <strong>
                성공지원 입학템
                <br />
                웰컴 키트를 선택해 주세요.
              </strong>
            </h4>
            <p>
              Yanadoo University의
              <br />
              평생 입학 회원이 된 것을 환영합니다!
            </p>
          </div>
          <div className={styles.goodsItemBox}>
            <ul>
              {goodsItem.map((item, index) => (
                <li key={item.productSeq}>
                  <label htmlFor={`goodsChk_${item.productSeq}`}>
                    <div>
                      <div
                        className={styles.goodsThumb}
                        style={{
                          backgroundImage:
                            index === 0
                              ? `url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_goods_item_518.png)`
                              : `url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_goods_item_517.png)`
                        }}
                      />
                      <input
                        type="radio"
                        id={`goodsChk_${item.productSeq}`}
                        name="goodsType"
                        value={item.productSeq}
                        onChange={handleInputValue}
                        checked={inputValue.goodsType === String(item.productSeq) ? true : false}
                      />
                      <span className="chk"></span>
                      <strong>{item.name.substr(10)}</strong>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
            <p>
              <em>웰컴 키트 선택 후 변경 불가</em>
            </p>
          </div>
          <div className={`${styles.btnFlexForm} btn-flex-form`}>
            <button
              className={`btn ${!inputValue.goodsType ? styles.default : styles.active}`}
              onClick={() => goodsDirectOrder(inputValue.goodsType)}
              disabled={inputValue.goodsType ? false : true}
            >
              <span>수강 신청하기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalGoods
