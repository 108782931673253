import { useEffect, useRef } from 'react'

import styles from '../LevelTestAdvancedExam.module.scss'

export default function BubbleMessage({ isShow, questionIndex, onChangeShowBubble }) {
  const timerRef = useRef(null)

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      onChangeShowBubble(true)
    }, 30000)

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [onChangeShowBubble, questionIndex])

  return isShow ? (
    <div className={styles.bubbleMessage}>
      {questionIndex < 16 ? '얼마 안 남았어요!' : '끝까지 파이팅! 😊'}
    </div>
  ) : null
}
