import React, { useEffect, useState } from 'react'

import {
  calcCorrectPercent,
  calcTimePercent,
  formatTime
} from 'pages/levelTestAdvanced/useLevelTestAdvanced'

import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import { evalTextValues } from '../config'
import styles from '../LevelTestAdvancedResult.module.scss'

export default function Average() {
  const { level, score, avgScore, time, avgTime, correctCount, avgCorrectCount, questionCount } =
    useLevelTestAdvancedResultStore()
  const [evalText, setEvalText] = useState({})

  // 사용자 레벨별 평가 문구 가져오기
  useEffect(() => {
    const evalValue = level ? evalTextValues.find((value) => value[level]) : null
    setEvalText(evalValue ? Object.values(evalValue)[0] : {})
  }, [level])

  return (
    <section>
      <hgroup className={styles.hgroup}>
        <h3>{evalText.title}</h3>
        <h6>{evalText.subTitle}</h6>
      </hgroup>

      <ul className={styles.average}>
        <li>
          <div className={styles.title}>
            <p>나의 점수</p>
            <p>{score}점</p>
          </div>

          <div className={styles.track}>
            <div className={styles.fill} style={{ width: `${score}%` }}></div>
          </div>
          <p className={styles.param}>평균 {avgScore}점</p>
        </li>

        <li>
          <div className={styles.title}>
            <p>소요시간</p>
            <p>{formatTime(time)}</p>
          </div>

          <div className={styles.track}>
            <div className={styles.fill} style={{ width: `${calcTimePercent(time, 60)}%` }}></div>
          </div>
          <p className={styles.param}>평균 {formatTime(avgTime)}</p>
        </li>

        <li>
          <div className={styles.title}>
            <p>정답 수</p>
            <p>
              {correctCount}
              <span>/20</span>
            </p>
          </div>

          <div className={styles.track}>
            <div
              className={styles.fill}
              style={{ width: `${calcCorrectPercent(correctCount, questionCount)}%` }}
            ></div>
          </div>
          <p className={styles.param}>평균 {avgCorrectCount}문제</p>
        </li>
      </ul>
    </section>
  )
}
