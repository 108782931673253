import { memo, useCallback, useState, useEffect } from 'react'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import ModalApplyCoupon from 'components/modal/ModalApplyCoupon'

import styles from './Coupon.module.scss'

const Coupon = memo(
  ({
    flag,
    type, // list : 마이페이지 쿠폰 목록 , down : 쿠폰 다운로드 팝업, input : 중복 쿠폰 선택 팝업
    couponData,
    selectedCoupon,
    handleSelectedCoupon,
    couponPriceLimit,
    getCouponDownloadData,
    priceInfoSeq,
    isUrlButton
  }) => {
    const [modalopen, setModalopen] = useState(false)
    const [couponPackage, setCouponPackage] = useState(null)
    const [couponName, setCouponName] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [couponUrl, setCouponUrl] = useState('')
    const addComma = (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const onChangeDash = (date) => {
      return date.replace(/-/g, '.')
    }

    const handleOpenModal = useCallback((e, couponPackage, name, url) => {
      e.stopPropagation()
      e.preventDefault()

      document.body.classList.add('modal-open')
      setModalopen(true)
      setCouponPackage(couponPackage)
      setCouponName(name)
      setCouponUrl(url)
    }, [])

    const handleCloseModal = useCallback((e) => {
      document.body.classList.remove('modal-open')
      setModalopen(false)
    }, [])

    // 개별 쿠폰 다운로드 API
    const downLoadCoupon = (couponCode) => {
      const data = {
        couponCode: couponCode
      }
      api
        .post(`/v2/coupon/download?couponCode=${data.couponCode}`)
        .then((response) => {
          if (response.data.meta.code !== 200) {
            alert(response.data.meta.message)
            setIsLoading(!isLoading)
          } else {
            alert('쿠폰 다운로드가 완료되었습니다!')
            window.location.reload()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    useEffect(() => {
      if (isLoading) {
        priceInfoSeq ? getCouponDownloadData(priceInfoSeq) : getCouponDownloadData()
        setIsLoading(!isLoading)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
      <>
        <li>
          {type === 'input' ? (
            <label
              className={`${styles.couponLabel} ${
                //!overlapDisabled && 중복 사용 가능 쿠폰 && 선택된 쿠폰 배열 리스트에 중복 사용 가능 쿠폰이 있을경우
                (!flag.overlapDisabled &&
                  couponData.overlapUseYn !== 'Y' &&
                  selectedCoupon.some((coupon) => coupon.overlapUseYn === 'Y')) ||
                flag.overlapDisabled ||
                // shareDisabled && 중복 사용 가능 쿠폰 && 수익 배분 쿠폰
                (flag.shareDisabled &&
                  couponData.overlapUseYn === 'Y' &&
                  couponData.couponKindCode === 'SHARE')
                  ? styles.isDisabled
                  : ''
              }`}
              htmlFor={couponData.couponSeq}
            >
              <input
                type="checkbox"
                name={couponData.overlapUseYn}
                id={couponData.couponSeq}
                value={couponData.couponKindCode}
                onChange={handleSelectedCoupon}
                checked={selectedCoupon.some((coupon) =>
                  coupon.number === String(couponData.couponSeq) ? true : false
                )}
                disabled={
                  // 중복 사용 가능 && 쿠폰 할인 금액 > 최대 할인 금액
                  couponData.discountPrice > couponPriceLimit && couponData.overlapUseYn === 'Y'
                }
              />
              <div className={`${styles.couponItem} ${type === 'input' ? styles.isDown : ''}`}>
                <div className={styles.couponBox}>
                  <div>
                    <strong
                      className={
                        selectedCoupon.some(
                          (coupon) => coupon.number === String(couponData.couponSeq)
                        )
                          ? styles.isChecked
                          : ''
                      }
                    >
                      {couponData.discountType === 'PERCENT'
                        ? couponData.discountPercent + `% 할인`
                        : addComma(Number(couponData.discountPrice)) + '원 할인'}
                    </strong>
                    <p>{couponData.couponName}</p>
                    {couponData.minimumPriceYn === 'Y' && (
                      <p>
                        {addComma(Number(couponData.minimumPrice))}원 이상 구매 시
                        {couponData.maximumDiscountPriceYn === 'Y' &&
                          '/ 최대 ' + couponData.maximumDiscountPrice + '원 할인'}
                      </p>
                    )}
                    {couponData.couponPublishEndStatus === 'EXPIRED' ||
                    couponData.couponPublishEndStatus === 'USED' ? (
                      <span
                        className={
                          couponData.couponPublishEndStatus === 'USED'
                            ? styles.isUsed
                            : styles.isExpired
                        }
                      >
                        {couponData.couponPublishEndStatus === 'EXPIRED'
                          ? '기간 만료'
                          : couponData.couponPublishEndStatus === 'USED'
                          ? '사용 완료'
                          : ''}
                      </span>
                    ) : (
                      <>
                        {couponData.restrictedPackageYn === 'N' ? (
                          <span>전체 상품 적용</span>
                        ) : (
                          <span
                            onClick={
                              couponData.restrictedPackageYn === 'Y'
                                ? (e) => {
                                    handleOpenModal(
                                      e,
                                      couponData.couponToPackageInfoDTOList,
                                      couponData.couponName
                                    )
                                  }
                                : undefined
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            적용 상품
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <strong className={couponData.overlapUseYn !== 'Y' ? styles.isDisabled : ''}>
                      {couponData.overlapUseYn === 'Y'
                        ? '중복 사용 가능'
                        : couponData.overlapUseYn === 'S'
                        ? '단독 사용'
                        : '중복 사용 불가'}
                    </strong>
                    <span>
                      {couponData.expiredDate &&
                        onChangeDash(couponData.expiredDate.substring(0, 10)) + ' 까지'}
                    </span>
                  </div>
                </div>
              </div>
            </label>
          ) : (
            <div
              className={`
                ${styles.couponItem}
                ${
                  type === 'down' || type === 'input'
                    ? styles.isDown
                    : couponData.couponPublishEndStatus === 'EXPIRED' ||
                      couponData.couponPublishEndStatus === 'USED'
                    ? styles.isDisabled
                    : ''
                }
              `}
              onClick={
                type === 'list' &&
                couponData.restrictedPackageYn === 'Y' &&
                !couponData.couponPublishEndStatus
                  ? (e) => {
                      handleOpenModal(
                        e,
                        couponData.couponToPackageInfoDTOList,
                        couponData.couponName,
                        couponData.couponUrl
                      )
                    }
                  : undefined
              }
            >
              <div
                className={`${styles.couponBox} ${
                  type === 'list' && couponData.restrictedPackageYn === 'N' ? styles.isDisabled : ''
                }`}
                style={{ cursor: type === 'down' && 'default' }}
              >
                <div>
                  <strong>
                    {couponData.discountType === 'PERCENT'
                      ? couponData.discountPercent + `% 할인`
                      : addComma(Number(couponData.discountPrice)) + '원 할인'}
                  </strong>
                  <p>{couponData.couponName}</p>
                  {couponData.minimumPriceYn === 'Y' && (
                    <p>
                      {addComma(Number(couponData.minimumPrice))}원 이상 구매 시
                      {couponData.maximumDiscountPriceYn === 'Y' &&
                        '/ 최대 ' + couponData.maximumDiscountPrice + '원 할인'}
                    </p>
                  )}
                  {type === 'down' ? (
                    <>
                      {couponData.restrictedPackageYn === 'N' ? (
                        <span>전체 상품 적용</span>
                      ) : (
                        <span
                          onClick={
                            couponData.restrictedPackageYn === 'Y'
                              ? (e) => {
                                  handleOpenModal(
                                    e,
                                    couponData.couponToPackageInfoDTOList,
                                    couponData.couponName,
                                    couponData.couponUrl
                                  )
                                }
                              : undefined
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          적용 상품
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {couponData.couponPublishEndStatus === 'EXPIRED' ||
                      couponData.couponPublishEndStatus === 'USED' ? (
                        <span
                          className={
                            couponData.couponPublishEndStatus === 'USED'
                              ? styles.isUsed
                              : styles.isExpired
                          }
                        >
                          {couponData.couponPublishEndStatus === 'EXPIRED'
                            ? '기간 만료'
                            : couponData.couponPublishEndStatus === 'USED'
                            ? '사용 완료'
                            : ''}
                        </span>
                      ) : (
                        <span>
                          {couponData.restrictedPackageYn === 'N' ? '전체 상품 적용' : '적용 상품'}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <strong className={couponData.overlapUseYn !== 'Y' ? styles.isDisabled : ''}>
                    {couponData.overlapUseYn === 'Y'
                      ? '중복 사용 가능'
                      : couponData.overlapUseYn === 'S'
                      ? '단독 사용'
                      : '중복 사용 불가'}
                  </strong>
                  {type === 'list' ? (
                    <span>
                      {couponData.expiredDate &&
                        onChangeDash(couponData.expiredDate.substring(0, 10)) + ' 까지'}
                    </span>
                  ) : (
                    <>
                      <span>
                        {couponData.expiredDay &&
                          '다운로드 후 ' + couponData.expiredDay + '일이내 사용'}
                        {couponData.expiredDate &&
                          onChangeDash(couponData.expiredDate.substring(0, 10)) + ' 까지'}
                      </span>
                      {couponData.getCouponYn && couponData.getCouponYn ? (
                        <button
                          type="button"
                          className={`${styles.couponDownButton} ${styles.isDisabled}`}
                        >
                          발급완료
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={styles.couponDownButton}
                          onClick={() => {
                            downLoadCoupon(couponData.couponCode)
                          }}
                        >
                          쿠폰받기
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </li>

        {modalopen ? (
          <ModalPortal>
            <ModalApplyCoupon
              btnClose
              title={'쿠폰 적용 상품'}
              couponName={couponName}
              couponPackage={couponPackage}
              handleCloseModal={handleCloseModal}
              couponUrl={couponUrl}
              isUrlButton={isUrlButton}
            />
          </ModalPortal>
        ) : null}
      </>
    )
  }
)

export default Coupon
