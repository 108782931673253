import React, { useState } from 'react'

import ModalPortal from 'ModalPortal'

import FeedbackRating from './components/FeedbackRating'
import FeedbackTags from './components/FeedbackTags'
import FeedbackTextArea from './components/FeedbackTextArea'

import s from './ModalFeedback.module.scss'

export default function ModalFeedback({ postSurvey, onClose }) {
  const [rating, setRating] = useState(5)
  const [selectedTags, setSelectedTags] = useState([])
  const [textareaLength, setTextareaLength] = useState(0)
  const [textareaValue, setTextareaValue] = useState('')

  const handleClickRating = (index) => {
    setRating(index + 1)
  }

  const handleChangeTags = (tag, checked) => {
    setSelectedTags((prev) => (checked ? [...prev, tag] : prev.filter((t) => t !== tag)))
  }

  const handleResetTags = () => {
    setSelectedTags([])
  }

  const handleCheckTextLength = (e) => {
    const textValue = e.target.value
    const textLength = e.target.value.length

    if (textLength <= 100) {
      setTextareaValue(textValue)
      setTextareaLength(textLength)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const dataBody = {
      star: rating,
      tag: selectedTags.join(', '),
      message: textareaValue
    }
    postSurvey(dataBody)
  }

  return (
    <ModalPortal>
      <div className={s.modalWrap}>
        <div className={s.dimmed} onClick={onClose}></div>
        <div className={s.ainadooFeedback}>
          <div className={s.titleBox}>
            <h3>의견 작성</h3>
            <button type="button" className={s.btnClose} onClick={onClose}>
              닫기
            </button>
          </div>
          <div className={s.feedbackBox}>
            <FeedbackRating rating={rating} handleClickRating={handleClickRating} />
            <FeedbackTags
              rating={rating}
              selectedTags={selectedTags}
              handleChangeTags={handleChangeTags}
              handleResetTags={handleResetTags}
            />
            <FeedbackTextArea
              value={textareaValue}
              length={textareaLength}
              handleCheckTextLength={handleCheckTextLength}
            />
          </div>
          <button
            type="submit"
            className={s.btnSubmit}
            onClick={handleSubmit}
            disabled={selectedTags.length === 0}
          >
            의견 보내기
          </button>
        </div>
      </div>
    </ModalPortal>
  )
}
