import React, { useCallback, useEffect } from 'react'

import useSpeechToText from 'hooks/useSpeechToText'

import s from './ChatFooterAudio.module.scss'
import RecorderButton from './RecorderButton'
import RecorderTooltip from './RecorderTooltip'

export default function ChatFooterAudio({ addToFeed, isLoading, character = {} }) {
  const { errorMessage, transcript, isListening, handleRecognition, isActive, runTimeout } =
    useSpeechToText()

  const handleErrorMessage = useCallback(
    (message) => {
      addToFeed({ type: 'Error', msg: message, mode: false })
    },
    [addToFeed]
  )

  const handleStart = useCallback(() => {
    handleRecognition.start()
  }, [handleRecognition])

  const handleAbort = useCallback(() => {
    handleRecognition.abort()
  }, [handleRecognition])

  const handleReStart = useCallback(() => {
    handleRecognition.restart()
  }, [handleRecognition])

  const onSendMessage = (message) => {
    const trimMessage = message.trim()
    if (!!trimMessage) {
      addToFeed({ type: 'User', msg: trimMessage })
      handleAbort()
    } else {
      handleErrorMessage('죄송합니다. 이해하지 못했어요. 다시 한번 말씀해 주시겠어요?')
    }
  }

  useEffect(() => {
    if (errorMessage) {
      handleErrorMessage(errorMessage)
    }
  }, [errorMessage])

  return (
    <section className={s.footerMicGroup}>
      {!isActive && (
        <RecorderTooltip
          isListening={isListening}
          isLoading={isLoading}
          name={character?.info?.firstname}
          msg={transcript}
          runTimeout={runTimeout}
        />
      )}
      <RecorderButton
        isListening={isListening}
        isLoading={isLoading}
        isActive={isActive}
        onClickAbort={handleAbort}
        onClickStart={handleStart}
        onClickReStart={handleReStart}
        onClickSendMessage={() => onSendMessage(transcript)}
      />
    </section>
  )
}
