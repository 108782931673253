import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import AuthService from 'services/authService'

export default function PublicRouter({ children }) {
  const location = useLocation()
  const loginStatus = AuthService.isLoggedIn()

  return !loginStatus ? (
    children
  ) : (
    <Navigate
      to="/"
      state={{
        from: location.pathname,
        authCheck: true,
        query: location.search
      }}
    />
  )
}
