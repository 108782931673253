import Loading from 'components/common/Loading'
import NotFound from 'components/common/NotFound'

import ContentsSection from './components/ContentsSection'
import TopImageSection from './components/TopImageSection'
import TopInfoSection from './components/TopInfoSection'

import useStoreDetail from './hooks/useStoreDetail'
import styles from './StoreDetail.module.scss'

export default function StoreProduct() {
  const {
    isEntrancedUser,
    isEntrancedCheck,
    userInfo,
    classData,
    isLoading,
    isSticky,
    onScrollToTop,
    onChangeIsSticky
  } = useStoreDetail()

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingBox}>
          <Loading />
        </div>
      ) : (
        <>
          {!classData ? (
            <NotFound />
          ) : (
            <div className={styles.container}>
              <TopImageSection
                packageImages={classData.packageDetailImages}
                hashTags={classData.hashTags}
              />
              <TopInfoSection
                isSticky={isSticky}
                classData={classData}
                userInfo={userInfo}
                isEntrancedUser={isEntrancedUser}
                isEntrancedCheck={isEntrancedCheck}
              />
              <ContentsSection
                isSticky={isSticky}
                classData={classData}
                userInfo={userInfo}
                onChangeIsSticky={onChangeIsSticky}
                isLoading={false}
              />
            </div>
          )}

          {isSticky && (
            <div className={styles.scrollToTop}>
              <button onClick={onScrollToTop}>top</button>
            </div>
          )}
        </>
      )}
    </>
  )
}
