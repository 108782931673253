import styles from './LevelTestModalMarketing.module.scss'

export function LevelTestModalMarketing({ onMarketingSubmit }) {
  return (
    <div className={styles.modal}>
      <hgroup>
        <h3>
          야나두의 혜택 정보를 <br />
          놓치고 계시나요?
        </h3>
        <p>
          SMS 광고 및 마케팅 이용동의로 <br />
          야나두의 다양한 혜택을 받아보세요!
        </p>
      </hgroup>

      <div className={styles.btns}>
        <button type="button" onClick={() => onMarketingSubmit(false)}>
          지금은 아니에요
        </button>
        <button type="button" onClick={() => onMarketingSubmit(true)}>
          동의하고 혜택 받기
        </button>
      </div>
    </div>
  )
}
