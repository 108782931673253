import { Link } from 'react-router-dom'

import styles from './ClassItem.module.scss'

export default function ClassItem({ title, listData }) {
  if (!listData || listData.length <= 0) return <></>
  return (
    <>
      <h3 className={styles.storeTitle}>
        <strong>{title}</strong>
      </h3>
      <ul className="class-items">
        {listData &&
          listData.map((item, idx) => {
            return (
              <li key={idx}>
                <Link
                  to={{
                    pathname: `/store/detail/${item.packageSeq}`
                  }}
                >
                  <div className={styles.thumbBox}>
                    <picture className={styles.picture}>
                      <img src={item.thumbnailPath} alt={item.packageName} />
                    </picture>
                    <div
                      className={`${styles.saleStatusBox} ${
                        item?.saleStatus === 'BEFORE' ? styles.before : ''
                      }`}
                    >
                      {item.saleStatus === 'BEFORE' ? (
                        <div>
                          <span>판매 예정</span>
                        </div>
                      ) : item.saleStatus === 'END' ? (
                        <div>
                          <span>Sold out</span>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className={styles.categoryBox}>
                    <div>
                      <span className="category">{item.packageCategory}</span>
                      &nbsp;&middot;&nbsp;
                      <span className="leader">{item.teacherName}</span>
                    </div>
                    <p className="ellipsis line-clamp-2">
                      <strong>{item.packageName}</strong>
                    </p>
                  </div>
                </Link>
              </li>
            )
          })}
      </ul>
    </>
  )
}
