import { useState, useRef } from 'react'
import DaumPostCode from 'react-daum-postcode'
import '../../assets/styles/modal.scss'

const ModalDelivery = ({
  putOrderDelivery,
  orderDelivery,
  btnClose,
  handleCloseModal,
  voucherIco
}) => {
  const nameRef = useRef()
  const numberRef = useRef()
  const addressRef = useRef()

  const regex = /^[0-9]{0,11}$/

  const [inputValue, setInputValue] = useState({
    cellphone: '',
    deliveryAddr1: '',
    deliveryAddr2: '',
    deliveryPhone: '',
    deliveryRequest: '',
    deliverySeq: '',
    deliveryUsername: '',
    orderNo: '',
    userid: '',
    username: '',
    zipCode: ''
  })

  // 우편번호 API
  const handlePostCode = () => {
    new window.daum.Postcode({
      onComplete: (data) => {
        let fullAddress = data.address
        let extraAddress = ''

        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname
          }
          if (data.buildingName !== '') {
            extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
          }
          fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
        }

        setInputValue({
          ...inputValue,
          deliveryAddr1: fullAddress,
          zipCode: data.zonecode // 우편번호
        })
      }
    }).open()
  }

  const handleInputValue = (e) => {
    let { name, value } = e.target
    if (e.target.name === 'deliveryPhone') {
      // 핸드폰번호 체크
      if (regex.test(e.target.value)) {
        setInputValue({ ...inputValue, [name]: value })
      }
    } else {
      setInputValue({ ...inputValue, [name]: value })
    }
  }

  const handleSubmitBtn = () => {
    if (!inputValue.deliveryUsername || inputValue.deliveryUsername.trim().length === 0) {
      alert('이름을 입력해주세요.')
      nameRef.current.focus()
      return false
    }

    if (!inputValue.deliveryPhone || inputValue.deliveryPhone.trim().length === 0) {
      alert('휴대폰 번호를 입력해주세요.')
      numberRef.current.focus()
      return false
    }

    if (!inputValue.deliveryAddr1) {
      alert('주소를 입력해주세요.')
      return false
    }

    if (!inputValue.deliveryAddr2 || inputValue.deliveryAddr2.trim().length === 0) {
      alert('상세주소를 입력해주세요.')
      addressRef.current.focus()
      return false
    }

    putOrderDelivery(
      inputValue.deliveryAddr1,
      inputValue.deliveryAddr2,
      inputValue.deliveryUsername,
      inputValue.deliveryPhone,
      inputValue.deliveryRequest,
      inputValue.zipCode,
      orderDelivery.deliverySeq
    )
  }

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>배송지 수거지 확인/변경</strong>
          </div>
          <div className="modal-content">
            {voucherIco && (
              <div className="modal-ico-col">
                <span className="ico-check"></span>
                <div className="txt">
                  <strong>수강권이 등록되었습니다.</strong>
                  <p>상품 배송을 위해 아래 배송지 정보를 확인해주세요.</p>
                </div>
              </div>
            )}
            {orderDelivery && (
              <div className="modal-delivery-info">
                <dl>
                  <dt>배송/수거지 정보</dt>
                  <dd>
                    <p>{orderDelivery.deliveryUsername}</p>
                    <p>{orderDelivery.deliveryPhone}</p>
                    <p>
                      {orderDelivery.zipCode} {orderDelivery.deliveryAddr1}
                      <br />
                      {orderDelivery.deliveryAddr2}
                    </p>
                  </dd>
                </dl>
                {orderDelivery.deliveryRequest && (
                  <dl>
                    <dt>배송 요청사항</dt>
                    <dd>{orderDelivery.deliveryRequest}</dd>
                  </dl>
                )}
              </div>
            )}
            <div className="modal-form-col">
              <strong>배송지 수거지 변경</strong>
              <dl>
                <dt className="ico-red-dot">이름</dt>
                <dd>
                  <input
                    ref={nameRef}
                    name="deliveryUsername"
                    type="text"
                    placeholder="이름을 입력해주세요."
                    value={inputValue.deliveryUsername}
                    onChange={handleInputValue}
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">휴대폰 번호</dt>
                <dd>
                  <input
                    name="deliveryPhone"
                    onChange={handleInputValue}
                    ref={numberRef}
                    type="text"
                    value={inputValue.deliveryPhone}
                    placeholder="휴대폰 번호를 입력해주세요."
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">주소</dt>
                <dd className="flex-form">
                  <input
                    readOnly
                    className={'txt ellipsis' + (inputValue.deliveryAddr1 ? ' active' : '')}
                    value={
                      inputValue.deliveryAddr1 ? inputValue.deliveryAddr1 : '주소를 입력해주세요.'
                    }
                  ></input>
                  <button className="btn active" onClick={handlePostCode}>
                    <span>우편번호</span>
                  </button>
                </dd>
                <dd>
                  <input
                    name="deliveryAddr2"
                    ref={addressRef}
                    type="text"
                    placeholder="상세 주소를 입력해주세요."
                    onChange={handleInputValue}
                    value={inputValue.deliveryAddr2 ? inputValue.deliveryAddr2 : ''}
                  />
                </dd>
              </dl>
              <dl>
                <dt>배송 요청사항</dt>
                <dd>
                  <input
                    name="deliveryRequest"
                    type="text"
                    maxLength="30"
                    placeholder="배송 요청사항을 입력해주세요."
                    onChange={handleInputValue}
                    value={inputValue.deliveryRequest}
                  />
                </dd>
              </dl>
              <div className="btn-flex-form">
                <button className="btn stroke" onClick={handleCloseModal}>
                  <span>취소</span>
                </button>
                <button className="btn active" onClick={handleSubmitBtn}>
                  <span>변경</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DaumPostCode className="post-code" onComplete={handlePostCode} autoClose animation />
    </>
  )
}

export default ModalDelivery
