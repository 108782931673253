import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'
import Lnb from 'components/Lnb'

import styles from './Device.module.scss'

import 'assets/styles/help.scss'

export default function Device() {
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState([])
  const [deviceList, setDeviceList] = useState([])
  const [itemChk, setItemChk] = useState([])
  const [resetChk, setResetChk] = useState([])

  // 유저정보 가져오기
  const getUserInfo = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
        setUserInfo(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleCheckItem = (checked, seq, idx) => {
    if (checked) {
      setItemChk([...itemChk, idx])
      setResetChk([...resetChk, seq])
    } else {
      setItemChk(itemChk.filter((e) => e !== idx))
      setResetChk(resetChk.filter((e) => e !== seq))
    }
  }

  const getDeviceList = () => {
    api
      .get(`/v1/deviceManagement`)
      .then((response) => {
        setDeviceList(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const putDevice = () => {
    const data =
      resetChk &&
      resetChk.map((item) => {
        return {
          seq: item
        }
      })

    console.log(data)
    api
      .put(`/v1/deviceManagement/application/reset`, data)
      .then((response) => {
        alert('기기 초기화 신청 접수 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        console.log('잠시 후 다시 시도해 주세요.')
      })
  }

  useEffect(() => {
    getUserInfo()
    getDeviceList()
  }, [])

  return (
    <div className="help-col device">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader
            title="학습 기기 관리"
            onClick={() => (document.referrer === '' ? navigate('/mypage/1') : navigate(-1))}
          />
          <Lnb />
          <section className="content">
            <h3>학습 기기 관리</h3>
            <section className={styles.deviceSection}>
              <p className={styles.deviceTitle}>
                <span>
                  {userInfo && userInfo.nickName}({userInfo.userId})
                </span>
                님의 계정에 아래 기기
                <span>{deviceList.filter((e) => e !== undefined).length}건</span>이
                등록되어있습니다.
              </p>
              <div className={styles.deviceBox}>
                <ul className={styles.deviceList}>
                  {Array.from(Array(5), (e, idx) => {
                    return (
                      <li key={idx}>
                        <p>기기가 등록되지 않았습니다.</p>
                      </li>
                    )
                  })}
                </ul>
                <ul className={styles.deviceList}>
                  {deviceList &&
                    deviceList.map((item, idx) => {
                      return (
                        <li
                          key={idx}
                          className={
                            styles.active +
                            ' ' +
                            (item.mobileYn === '1' || item.mobileYn === '2' || item.mobileYn === '3'
                              ? styles.phone
                              : styles.desktop)
                          }
                        >
                          <label
                            htmlFor={'check' + idx}
                            className={item.reqYn === 1 ? styles.progress : ''}
                          >
                            <input
                              type="checkbox"
                              id={'check' + idx}
                              checked={itemChk.includes(idx) ? true : false}
                              onChange={(e) => handleCheckItem(e.target.checked, item.seq, idx)}
                              disabled={item.reqYn === 1 ? 'disabled' : ''}
                            />
                            <span></span>
                            <dl>
                              <dt>
                                <strong>{item.deviceName}</strong>
                              </dt>
                              <dd>
                                <span>{item.registDate.substring(0, 10)}</span>
                              </dd>
                            </dl>
                          </label>
                        </li>
                      )
                    })}
                </ul>
              </div>
              <div className={styles.deviceNoticeBox}>
                <p>기기관리 유의사항</p>
                <ul>
                  <li>
                    야나두 강의는 한 ID당 기기 5대(PC, Mobile, App, Pad)까지 수강이 가능합니다.
                  </li>
                  <li>
                    기기 초기화 신청 시 사유 문의를 위해 고객센터(1600-0563)연락 받으실 수 있습니다.
                  </li>
                  <li>
                    초기화 요청 시, 선택한 기기에 "처리중”이라는 표시를 확인 해주시기 바랍니다.
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className={styles.btnReset + ' ' + (itemChk.length > 0 ? styles.active : '')}
                onClick={putDevice}
              >
                <span>초기화 신청</span>
              </button>
            </section>
          </section>
        </div>
      </div>
    </div>
  )
}
