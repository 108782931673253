import React, { useState, useEffect, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useParams, useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'
import AlertModal from 'components/modal/AlertModal'
import ModalLearningInquiry from 'components/modal/ModalLearningInquiry'

import styles from './DetailLearning.module.scss'
import Lnb from '../../../components/Lnb'

export default function DetailLearning() {
  const navigate = useNavigate()
  const { questionSeq } = useParams()
  const [learningData, setLearningData] = useState([])
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [modalopenInquiry, setModalopenInquiry] = useState(false)

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return isDesktop ? children : null
  }

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleOpenModalInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenInquiry(true)
  }, [])

  const handleCloseModalInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenInquiry(false)
  }, [])

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const getLearningData = () => {
    api
      .get(`/v2/lecture-question/${questionSeq}`)
      .then((response) => {
        setLearningData(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  const deleteLearningData = () => {
    api
      .delete(`/v2/lecture-question/${questionSeq}`)
      .then((response) => {
        alert('삭제가 완료되었습니다.')
        navigate('/mypage/qna/learning')
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getLearningData()

    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader
              title="학습 문의"
              onClick={() =>
                document.referrer === '' ? navigate('/mypage/qna/learning') : navigate(-1)
              }
            />
            <Lnb />
            <section className={styles.listDetailView}>
              <div className={styles.titleCol}>
                <strong>학습문의</strong>
              </div>
              <div className={`${styles.detailTitleArea} ${styles.board}`}>
                <div className={styles.infoArea}>
                  <div className={styles.class}>
                    <strong className={styles.subTit}>{learningData.classTitle}</strong>
                    <p className={`${styles.mainTit} ellipsis`}>{learningData.courseTitle}</p>
                  </div>
                  <div className={styles.replyModify}>
                    <div className={styles.reply}>
                      <strong
                        className={
                          styles.check +
                          (learningData.replyStatus === 'Y' ? ' ' + styles.completed : '')
                        }
                      >
                        {learningData.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                      </strong>
                      <span className={styles.date}>{formatDate(learningData.registDate)}</span>
                    </div>
                    <div className={styles.modify}>
                      <Desktop>
                        {learningData.replyStatus === 'N' && (
                          <div className={`${styles.btnGroup} btn-group`}>
                            <button
                              className={`${styles.btnModify} btn-modify`}
                              onClick={handleOpenModalInquiry}
                            >
                              <span>수정</span>
                            </button>
                            <button
                              className={`${styles.btnDelete} btn-delete`}
                              onClick={handleOpenModalAlert}
                            >
                              <span>삭제</span>
                            </button>
                          </div>
                        )}
                      </Desktop>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.detailContent}>
                <div className={styles.detailQuestion}>
                  <p>{learningData.contents}</p>
                  {learningData.questionFilePath && (
                    <img src={learningData.questionFilePath} alt="" />
                  )}
                </div>
                {learningData.replyStatus === 'Y' ? (
                  <div className={styles.detailAnswer}>
                    <div className={styles.thumbArea}>
                      <span className={styles.thumb}>
                        <i className="blind">썸네일</i>
                        {learningData.answer.answerThumbnailPath && (
                          <img src={learningData.answer.answerThumbnailPath} alt="" />
                        )}
                      </span>
                      <div className={`${styles.desc} desc`}>
                        <strong>{learningData.answer.answerUserName}</strong>
                        <p>{formatDate(learningData.answer.answerDate)}</p>
                      </div>
                    </div>
                    <pre
                      className="answer-txt"
                      dangerouslySetInnerHTML={{
                        __html: learningData.answer.answerContent
                      }}
                    />
                    {learningData.answer.answerFilePath && (
                      <img src={learningData.answer.answerFilePath} alt="" />
                    )}
                  </div>
                ) : null}
              </div>

              <div className="list-btn-col">
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/mypage/qna/learning'
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Desktop>
        {modalopenAlert ? (
          <ModalPortal>
            <AlertModal
              // btnClose
              btnGroup
              msg={'작성하신 문의를 삭제하시겠습니까? 삭제시 문의내역은 복구할 수 없습니다.'}
              handleCloseModal={handleCloseModalAlert}
              handleConfirmClose={deleteLearningData}
            />
          </ModalPortal>
        ) : null}
        {modalopenInquiry ? (
          <ModalPortal>
            <ModalLearningInquiry
              btnClose
              learningData={learningData}
              activeCourseData={learningData}
              handleCloseModal={handleCloseModalInquiry}
            />
          </ModalPortal>
        ) : null}
      </Desktop>
    </>
  )
}
