import React from 'react'

import styles from './UserInfo.module.scss'

const UserInfo = ({ userName, cellPhone }) => {
  return (
    <div className={styles.userInfoBox}>
      <div className="inner-col">
        <h1>주문자 정보</h1>
        <div className={styles.infoBox}>
          <em>구매내역을 알림톡(SMS)으로 안내해 드립니다.</em>
        </div>
        <div className={styles.userBox}>
          <dl>
            <dt>이름</dt>
            <dd>
              <input type="text" name="defaultName" value={userName} readOnly />
            </dd>
          </dl>
          <dl>
            <dt>휴대폰 번호</dt>
            <dd>
              <input type="text" name="defaultCellPhone" value={cellPhone} readOnly />
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}
export default UserInfo
