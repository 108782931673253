import React from 'react'

import styles from './Error.module.scss'

const MallError = ({ comment, fontColor = '#444' }) => {
  return (
    <>
      <section className={styles.errorUI} style={{ color: fontColor }}>
        <p>
          <strong>ERROR</strong>
          {comment}
        </p>
      </section>
    </>
  )
}

export default MallError
