import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import styles from './ItemStore.module.scss'
import SortSelectBox from './SortSelectBox'
import NoContent from '../../NoContent'
import useInfiniteScroll from '../hooks/useInfiniteScroll'
const IcoNoClass = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_class.png'

const MINIMUM_PRICE = 50_000
const MINIMUM_MONTH = 1

export default function Test({
  title,
  storeData,
  storeType,
  detailLink,
  moreBtnUseYn,
  setSort,
  sort,
  isEmpty,
  mallCode
}) {
  const triggerPointRef = useRef(null) // 목록을 로드하는 트리거 포인트 Node Ref
  const { currentData, isLoading, isReachedLimit } = useInfiniteScroll(
    storeData,
    triggerPointRef,
    12
  )

  return (
    <>
      <h3 className={styles.storeTitle}>
        <strong>{title && title === '전체' ? '전체보기' : title}</strong>
        {storeType !== 'mall' ? <SortSelectBox setSort={setSort} sort={sort} /> : null}
        {moreBtnUseYn === 'Y' && <Link to={detailLink}>더 보러 가기</Link>}
      </h3>
      <ul className="class-items">
        {isEmpty ? (
          <NoContent icoImg={IcoNoClass} titleMsg={'등록된 클래스가 없습니다.'} />
        ) : (
          <>
            {storeType !== 'mall' ? (
              <>
                {storeData &&
                  currentData.map((item, idx) => (
                    <li key={idx}>
                      <Link
                        to={{
                          pathname: `/store/detail/${item.packageSeq}`
                        }}
                      >
                        <div className={styles.thumbBox}>
                          <picture className={styles.picture}>
                            <img src={item.thumbnailPath} alt={item.packageName} loading="lazy" />
                          </picture>
                          <div
                            className={`${styles.saleStatusBox} ${
                              item?.saleStatus === 'BEFORE' ? styles.before : ''
                            }`}
                          >
                            {item.saleStatus === 'BEFORE' ? (
                              <div>
                                <span>판매 예정</span>
                              </div>
                            ) : item.saleStatus === 'END' ? (
                              <div>
                                <span>Sold out</span>
                              </div>
                            ) : null}
                          </div>
                          {/* 클래스가 포함 되어 있을 경우 클래스 수 표시 */}
                          {item.classCount > 0 && (
                            <span className={styles.classNumText}>
                              {item.classCount} 클래스 포함
                            </span>
                          )}
                          {item.saleTypeCode === 'EARLY_BIRD' && <span>얼리버드</span>}
                        </div>

                        <div className={styles.categoryBox}>
                          <div>
                            <span className="category">{item.packageCategory}</span>
                            &nbsp;&middot;&nbsp;
                            <span className="leader">{item.teacherName}</span>
                          </div>
                          <p className="ellipsis line-clamp-2">
                            <strong>{item.packageName}</strong>
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
              </>
            ) : (
              //대학몰일때
              <>
                {storeData &&
                  currentData.map((item, idx) => (
                    <li key={idx}>
                      <Link
                        to={{
                          pathname: `/mall/${mallCode}/detail/${item.packageSeq}`
                        }}
                      >
                        <div className={styles.thumbBox}>
                          <picture className={styles.picture}>
                            <img src={item.thumbnailPath} alt={item.packageName} loading="lazy" />
                          </picture>
                          <div
                            className={`${styles.saleStatusBox} ${
                              item?.saleStatus === 'BEFORE' ? styles.before : ''
                            }`}
                          >
                            {item.saleStatus === 'BEFORE' ? (
                              <div>
                                <span>판매 예정</span>
                              </div>
                            ) : item.saleStatus === 'END' ? (
                              <div>
                                <span>Sold out</span>
                              </div>
                            ) : null}
                          </div>
                          {/* 클래스가 포함 되어 있을 경우 클래스 수 표시 */}
                          {item.classCount > 0 && (
                            <span className={styles.classNumText}>
                              {item.classCount} 클래스 포함
                            </span>
                          )}
                          {item.saleTypeCode === 'EARLY_BIRD' && (
                            <span className={styles.earlyText}>얼리버드</span>
                          )}
                        </div>
                        <div className={styles.categoryBox}>
                          <div>
                            <span className="category">{item.packageCategory}</span>
                            &nbsp;&middot;&nbsp;
                            <span className="leader">{item.teacherName}</span>
                          </div>
                          <p className="ellipsis line-clamp-2">
                            <strong>{item.packageName}</strong>
                          </p>
                        </div>
                        <div className={styles.priceBox}>
                          {/* 얼리버드면 얼리버드가격 노출 */}
                          {item.saleTypeCode === 'EARLY_BIRD' ? (
                            <>
                              <p>
                                <em className={styles.discount}>
                                  {addComma(item.exceptOptionSalePrice)}
                                </em>
                                {/* 얼리버드 할인율로 바꿔야됨 */}
                                {/* <mark>
                              {item.exceptOptionEarlyBirdDiscountPercent} %
                            </mark> */}
                              </p>
                              <p>
                                <strong>{addComma(item.exceptOptionMembershipSalePrice)}</strong>
                              </p>
                            </>
                          ) : (
                            // 얼리버드가 아닐때
                            <>
                              <p>
                                <em className={styles.mallDiscount}>
                                  {addComma(item.originalPrice)}
                                </em>
                                <em className={styles.mallCount}>
                                  {addComma(item.exceptOptionSalePrice)}
                                </em>
                              </p>
                              <p>
                                <span className={`${styles.discountPercent} discount-percent`}>
                                  {item.discountPercent}%
                                </span>
                                {item.exceptOptionSalePrice !==
                                item.exceptOptionMembershipSalePrice ? (
                                  <>
                                    {item.exceptOptionMembershipSalePrice && (
                                      <>
                                        {item.exceptOptionMembershipSalePrice <= MINIMUM_PRICE ||
                                        item.learningMonth <= MINIMUM_MONTH ? (
                                          <strong className={`${styles.noMonth} no-month`}>
                                            {addComma(Number(item.exceptOptionMembershipSalePrice))}
                                          </strong>
                                        ) : (
                                          <>
                                            <strong>
                                              {addComma(
                                                Math.round(item.monthlyMembershipSalePrice)
                                              )}
                                            </strong>
                                            <span>{item.learningMonth}</span>
                                          </>
                                        )}
                                        <mark>입학회원</mark>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* 일반판매가와 멤버쉽할인가가 동일하면 일반판매가만 노출 */}
                                    {item.exceptOptionMembershipSalePrice && (
                                      <>
                                        {item.exceptOptionMembershipSalePrice <= MINIMUM_PRICE ||
                                        item.learningMonth <= MINIMUM_MONTH ? (
                                          <strong className="no-month">
                                            {addComma(Number(item.exceptOptionSalePrice))}
                                          </strong>
                                        ) : (
                                          <>
                                            <strong>
                                              {addComma(Math.round(item.monthlySalePrice))}
                                            </strong>
                                            <span>{item.learningMonth}</span>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                {/* 멤버쉽 할인가가 아예 없는 경우에는 일반판매가 노출 */}
                                {!item.exceptOptionMembershipSalePrice && (
                                  <>
                                    {item.exceptOptionMembershipSalePrice && (
                                      <>
                                        {item.exceptOptionMembershipSalePrice <= MINIMUM_PRICE ||
                                        item.learningMonth <= MINIMUM_MONTH ? (
                                          <strong className="no-month">
                                            {addComma(Number(item.exceptOptionSalePrice))}
                                          </strong>
                                        ) : (
                                          <>
                                            <strong>
                                              {addComma(Math.round(item.monthlySalePrice))}
                                            </strong>
                                            <span>{item.learningMonth}</span>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </p>
                            </>
                          )}
                        </div>
                      </Link>
                    </li>
                  ))}
              </>
            )}
          </>
        )}
      </ul>

      {!isReachedLimit && (
        <div
          className={styles.loader}
          ref={triggerPointRef}
          style={{ visibility: `${isLoading ? 'visible' : 'hidden'}` }}
        />
      )}
    </>
  )
}

const addComma = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
