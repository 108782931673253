import React from 'react'

import ChatFeedList from './ChatFeedList'
import ChatTopQna from './ChatTopQna'
import ChatTopSrr from './ChatTopSrr'
import s from './styles/Chat.module.scss'

export default function ChatFeedContainer({ isAiResponse, userSeq, pageType }) {
  return (
    <div className={s.chatFeed}>
      {pageType === 'srr' ? <ChatTopSrr /> : <ChatTopQna />}
      <ChatFeedList isAiResponse={isAiResponse} userSeq={userSeq} pageType={pageType} />
    </div>
  )
}
