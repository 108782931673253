import React, { useState, useEffect } from 'react'

import ModalBack from 'pages/aiNadoo/components/ModalBack'
import ModalCaution from 'pages/aiNadoo/components/ModalCaution'
import { closeWebView } from 'webview/bridge'

import { getCookie, setCookie } from 'common/Cookie'
import ModalFeedback from 'components/modal/modalFeedback'

import s from './styles/TitleBar.module.scss'
import ToastAlert from './ToastAlert'
import { AINADOO_CONFIG } from '../config'
import usePostSurvey from '../hooks/usePostSurvey'

const CAUTION_DATA = [
  {
    title: '자주 묻는 질문을 참고하여 답변해줘요.',
    content: '개인정보 확인이 필요하거나 오류 문의는 카카오톡 상담 혹은 1:1 문의로 질문해 주세요.'
  },
  {
    title: '작성하신 내용을 활용할 수 있어요.',
    content:
      '홍보, 이벤트 등으로 활용 및 서비스 개선을 위해 모든 데이터를 저장하고 있어요. 필요 시 대화 내용을 확인할 수 있습니다.'
  },
  {
    title: '개인정보를 입력하지 마세요.',
    content: '개인정보를 포함한 민감한 정보는 절대로 노출하거나 공유하지 마세요.'
  },
  {
    title: '답변 내용을 신뢰하지 마세요.',
    content:
      '최근 정보가 업데이트 되거나 정책이 변경되었다면 바로 반영되지 않아 답변이 부정확할 수 있어요.'
  },
  { content: 'AI 나두 서비스는 회사의 사정에 따라 사전고지 없이 변경 또는 종료될 수 있어요.' }
]

const IconStar = ({ onClick }) => {
  return (
    <button className={s.btnSurvey} onClick={onClick}>
      {AINADOO_CONFIG.ICON.star}
    </button>
  )
}

export default function AinadooTitleBar({ title, payload }) {
  const [caution, setCaution] = useState(false) // 초기에는 모달을 보이지 않음
  const [back, setBack] = useState(false)
  const [survey, setSurvey] = useState(false)
  const { postSurvey, surveySuccess } = usePostSurvey(payload)

  useEffect(() => {
    window.pressedBackButton = function () {
      pressedBackButton()
    }

    // 컴포넌트가 마운트 될 때 쿠키를 확인하고 모달을 표시할지 결정
    if (getCookie('CautionModal') !== 'N') {
      setCaution(true) // 쿠키가 없거나 'N'이 아니면 모달을 표시
    }

    return () => {
      delete window.pressedBackButton
    }
  }, [])

  const pressedBackButton = () => {
    setBack(!back)
  }

  const handleOpenCaution = () => {
    setCaution(true) // 버튼 클릭 시 쿠키와 관계없이 모달을 열기
  }

  const handleCloseCaution = () => {
    setCookie('CautionModal', 'N', 1) // 모달 닫을 때 쿠키 설정, 1일 동안 유지
    setCaution(false) // 모달을 닫음
  }

  const handleSurveyModal = () => {
    setSurvey(!survey)
  }

  useEffect(() => {
    if (surveySuccess) {
      setSurvey(false)
    }
  }, [surveySuccess])

  return (
    <>
      <hgroup className={s.titleBar}>
        <button className={s.btnBack} onClick={pressedBackButton}>
          webview out
        </button>
        <h1 className={s.title}>{title}</h1>
        <IconStar onClick={handleSurveyModal} />
        <button className={s.btnInfo} onClick={handleOpenCaution}>
          Info
        </button>
      </hgroup>
      {caution && (
        <ModalCaution onClose={handleCloseCaution} notice={CAUTION_DATA} title={'AI 나두'} />
      )}
      {back && <ModalBack onConfirm={() => closeWebView()} onClose={pressedBackButton} />}
      {survey && <ModalFeedback postSurvey={postSurvey} onClose={handleSurveyModal} />}
      {surveySuccess && <ToastAlert message="의견이 제출되었어요" />}
    </>
  )
}
