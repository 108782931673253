import React from 'react'

import { AINADOO_CONFIG } from '../config'
import s from './styles/ToastAlert.module.scss'

export default function ToastAlert({ message }) {
  return (
    <div className={s.toastAlert}>
      <div className={s.message}>
        <span>{message}</span>
        {AINADOO_CONFIG.ICON.check}
      </div>
    </div>
  )
}
