export const feedbackTagsValues = {
  above_three_star: [
    '빠른 답변 속도',
    '궁금증 해결',
    '24시간 문의',
    '쉽고 간단한 이용',
    '친절한 응대'
  ],
  below_three_star: [
    '느린 답변 속도',
    '답변 미해결',
    '오류 발생',
    '복잡하고 이용이 어려움',
    '가독성 떨어짐'
  ]
}
