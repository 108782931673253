import React from 'react'
import { useMediaQuery } from 'react-responsive'

import styles from './PaymentType.module.scss'

const PaymentType = ({ paymentTypeList, returnFn, childRef }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const paymentItem = (code) => {
    switch (code) {
      case 'CARD':
        return '카드결제'
      case 'REMITTANCE':
        return '무통장입금'
      case 'ACCOUNT':
        return '계좌이체'
      case 'NPAY':
        return <em className={styles.naver}>네이버페이</em>
      case 'TOSS':
        return <em className={styles.toss}>토스</em>
      case 'KAKAO':
        return <em className={styles.kakaopay}>카카오페이</em>
      case 'ONLYKAKAOPAY':
        return <em className={styles.kakaopay}>카카오페이</em>
      default:
    }
  }

  return (
    <>
      <div className="inner-col" ref={childRef}>
        <div className={styles.paymentTypeBox}>
          <h1>결제 수단</h1>
          <div
            className={styles.paymentTypeList}
            listLength={paymentTypeList.length}
            style={{
              '--list-width': paymentTypeList.length > 5 ? '33.3%' : 'auto',
              '--border-top': paymentTypeList.length > 5 ? '0' : '0.1rem solid #cfcfda',
              '--flex-basis': paymentTypeList.length > 5 ? 'auto' : 'none',
              '--border-left': paymentTypeList.length > 5 ? '0.1rem solid #cfcfda' : '0'
            }}
          >
            <ul>
              {paymentTypeList.map((item, idx) => (
                <li
                  key={idx}
                  className={`${paymentTypeList.length === 1 ? styles.itemOne : ''} ${
                    item === `REMITTANCE` && isMobile ? styles.mobileNone : ''
                  }`}
                >
                  <label>
                    <input
                      type="radio"
                      name="paymentType"
                      onChange={() => {
                        returnFn(item)
                      }}
                    />
                    <strong>{paymentItem(item)}</strong>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
export default PaymentType
