import React from 'react'

import styles from './TotalPriceInfo.module.scss'

const TotalPriceInfo = ({
  priceViewType,
  totalOrderPrice,
  deliveryFee,
  couponDiscountSum,
  totalDiscountPrice,
  totalPrice
}) => {
  return (
    <div className="inner-col">
      <div className={styles.priceInfoBox}>
        <h1>결제 정보</h1>
        {priceViewType ? (
          <div className={styles.priceBox}>
            <dl>
              <dt>총 주문금액</dt>
              <dd>
                <strong>{totalOrderPrice}</strong>
              </dd>
            </dl>
            <dl className={styles.plus}>
              <dt>배송비</dt>
              <dd>
                <strong>{deliveryFee}</strong>
              </dd>
            </dl>
            {couponDiscountSum && couponDiscountSum !== '0' ? (
              <dl className={styles.minus}>
                <dt>쿠폰 할인 금액</dt>
                <dd>
                  <strong>{couponDiscountSum}</strong>
                </dd>
              </dl>
            ) : null}
            <dl className={styles.result}>
              <dt>
                <strong>최종 결제 금액</strong>
              </dt>
              <dd>{totalPrice}</dd>
            </dl>
          </div>
        ) : (
          <div className={styles.priceBox}>
            <dl>
              <dt>총 주문금액</dt>
              <dd>
                <strong>{totalOrderPrice}</strong>
              </dd>
            </dl>
            <dl className={styles.plus}>
              <dt>배송비</dt>
              <dd>
                <strong>{deliveryFee}</strong>
              </dd>
            </dl>
            <dl className={styles.minus}>
              <dt>총 할인 받은 금액</dt>
              <dd>
                <strong>{totalDiscountPrice}</strong>
              </dd>
            </dl>
            <dl className={styles.result}>
              <dt>
                <strong>최종 결제 금액</strong>
              </dt>
              <dd>{totalPrice}</dd>
            </dl>
          </div>
        )}
      </div>
    </div>
  )
}

export default TotalPriceInfo
