import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import FaqSearchForm from 'pages/service/components/FaqSearchForm'
import { openKakao, loadURL, showTitleBar, webviewHistorySave } from 'webview/bridge'

import { YANADOO_HOST } from 'common/config'

import CustomerFooter from './CustomerFooter'
import CustomerTitleBar from './CustomerTitleBar'
import FaqBestList from './FaqBestList'
import s from './WebviewCustomer.module.scss'
import { WEBVIEW_SERVICE } from '../config'

export default function Customer() {
  const navigate = useNavigate()
  const [terminate, setTerminate] = useState(false)

  const handleNavigate = (url) => {
    navigate(url)
  }

  const handleLoadUrl = (path) => {
    setTerminate(true)
    showTitleBar()
    loadURL(`${path}?historyBack=${YANADOO_HOST}/webview/service/center`)
  }

  useEffect(() => {
    webviewHistorySave()
  }, [])

  return (
    <article className={s.customerWrap}>
      {!terminate && <CustomerTitleBar type="close" title="고객센터" />}
      <div className={s.customerInner}>
        <section className={s.sectionIntro}>
          <h3 className={s.sectionTitle}>무엇을 도와드릴까요?</h3>
          <FaqSearchForm styleName={s.faqSearchForm} isPlatform />
          <FaqBestList />
          <button className={s.faqTextLink} onClick={() => handleNavigate('/webview/service/faq')}>
            <span>자주 묻는 질문 전체보기</span>
            {WEBVIEW_SERVICE.svg_arrow_r}
          </button>
        </section>
        <section className={s.sectionButtons}>
          <button
            className={s.buttonNav}
            onClick={() => handleNavigate('/webview/service/faq/chat?service=YANADOO')}
          >
            <dl>
              <dt>AI 상담</dt>
              <dd>24시간 언제든지 상담 가능해요.</dd>
            </dl>
            <span className={s.arrowR}>{WEBVIEW_SERVICE.svg_arrow_r}</span>
          </button>
          <button className={s.buttonNav} onClick={() => openKakao()}>
            <dl>
              <dt>카카오톡 상담</dt>
              <dd>간편하게 카카오톡으로 문의하세요.</dd>
            </dl>
            <span className={s.arrowR}>{WEBVIEW_SERVICE.svg_arrow_r}</span>
          </button>
          <button
            className={s.buttonNav}
            onClick={() => handleLoadUrl('/webview/mypage/qna/inquiryWrite')}
          >
            <dl>
              <dt>1:1 문의</dt>
              <dd>질문을 남겨주시면 꼼꼼히 확인할게요.</dd>
            </dl>
            <span className={s.arrowR}>{WEBVIEW_SERVICE.svg_arrow_r}</span>
          </button>
        </section>
        <section className={s.sectionLinks}>
          {WEBVIEW_SERVICE.menu_list.map((item, index) => (
            <button className={s.buttonNav} key={index} onClick={() => handleLoadUrl(item.link)}>
              <strong>{item.title}</strong>
              <span className={s.arrowR}>{WEBVIEW_SERVICE.svg_arrow_r}</span>
            </button>
          ))}
        </section>
      </div>
      <CustomerFooter />
    </article>
  )
}
