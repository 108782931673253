import React from 'react'

import styles from './MedalSection.module.scss'
import SectionTitle from './SectionTitle'
const ImgFirstMedal =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_first_medal.png'
const ImgGoldMedal =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_gold_medal.png'
const ImgStarMedal =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_star_medal.png'
const ImgTrophy = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_trophy.png'

function MedalItem({ title, desc, img }) {
  return (
    <li>
      <img src={img} alt="" />
      <strong>{title}</strong>
      <p>{desc}</p>
    </li>
  )
}

export default function MedalSection() {
  return (
    <section className={styles.container}>
      <div className={styles.innerCol}>
        <div className={styles.flexBox}>
          <div>
            <SectionTitle
              title={'많은 사람들이\n야나두를 찾는\n이유를 아시나요?'}
              desc={'아무도 흉내 낼 수 없는 수년간의\n 노하우로 야나두가 만들면 1등입니다.'}
            />
          </div>
          <div>
            <ul>
              <MedalItem
                title={'서비스 만족도 1위'}
                desc={'2017년- 온라인 영어\n 5개 부문'}
                img={ImgStarMedal}
              />
              <MedalItem
                title={'매출 1위'}
                desc={'2018 위메프 티몬\n홈쇼핑 온라인 영어 부문'}
                img={ImgTrophy}
              />
            </ul>
            <ul>
              <MedalItem
                title={'올해의 브랜드 대상'}
                desc={'2022년 올해의 영어 회화 부문\n(4년 연속)'}
                img={ImgGoldMedal}
              />
              <MedalItem
                title={'검색 1위'}
                desc={'2018-2020년\n온라인 영어 부문'}
                img={ImgFirstMedal}
              />
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
