import { YANADOO_CDN_HOST } from 'common/config'

export const LTA_HOST_CDN = `${YANADOO_CDN_HOST}/levelTestAdvanced/2024/`

/**
 * 문제 출제 페이지와 해설 페이지에서 사용될 1-20번까지의 문제 객체입니다.
 *
 * @property {string} type 문제 유형 (blank, basic, completeSentence, conversation, listening, video)
 * @property {string} answerType 해설 페이지에 노출될 문항별 스타일이 상이하여 유형으로 구분 (type1, type2, type3, type4, type5)
 * @property {string} buttonType 일반 문제와 문장완성형 문제(complete)의 버튼 유형을 구분
 * @property {string} category 문제의 카테고리 (vocabulary, grammar, nuance, speaking, listening)
 * @property {string} question 문제의 질문
 *
 * text1-text3은 질문에 대한 보조 설명 텍스트입니다.
 * 출제/해설 페이지에서 공통으로 사용되며 수정 시 각각의 페이지에서 결과를 확인해 주셔야 합니다. (* 문제 출제 페이지 우선으로 작성)
 * @property {(string|JSX.Element)} text1
 * @property {(string|JSX.Element)} text2
 * @property {(string|JSX.Element)} text3
 *
 * @property {Object} buttonGroup 선택지 및 정답을 포함하는 버튼 그룹 객체
 * @property {string} buttonGroup.answer 문제의 정답
 * @property {string[]} buttonGroup.list 문제의 선택지 목록
 * @property {number} buttonGroup.score 문제에 할당된 점수
 * @property {boolean} listBlock 해설 페이지에서 선택지를 블록 단위로 나타낼지 여부를 결정
 * @property {string} src 오디오 또는 비디오 파일의 경로 (listening 또는 video 문제 유형에서 사용)
 * @property {string} comment 문제에 대한 해설 내용
 */
export const questionValues = {
  1: {
    type: 'blank',
    answerType: 'type1',
    category: 'vocabulary',
    question: '빈칸에 알맞은 단어를 선택해 보세요',
    text1: 'Can I _________ this on?',
    text2: '저 이거 입어봐도 되나요?',
    buttonGroup: {
      answer: 'try',
      list: ['wear', 'try'],
      score: 2
    },
    comment:
      '‘(치수를 재기 위해) 입어보다’라고 할 때는 try on을 활용합니다. 따라서, 입어봐도 되는지 물어볼 때는 “Can I try this on?”이라고 합니다.'
  },
  2: {
    type: 'blank',
    answerType: 'type1',
    question: '빈칸에 알맞은 단어를 선택해 보세요',
    category: 'vocabulary',
    text1: 'I’ll do the _________ .',
    text2: '설거지 나머지는 내가 할게.',
    buttonGroup: {
      answer: 'rest',
      list: ['last', 'rest', 'remaining', 'end'],
      score: 3
    },
    comment:
      'rest는 명사로 ‘나머지’라는 뜻이 있습니다. 따라서, 나머지는 내가 하겠다고 말할 때는 “I’ll do the rest.”라고 합니다.'
  },
  3: {
    type: 'basic',
    answerType: 'type2',
    category: 'vocabulary',
    question: `밑줄 친 우리말에\n공통으로 들어가는 단어를 선택해 보세요`,
    text1: (
      <>
        나 립스틱 좀&nbsp;<u>바르자.</u>
      </>
    ),
    text2: (
      <>
        추우면 코트 <u>입어.</u>
      </>
    ),
    buttonGroup: {
      answer: 'put on',
      list: ['apply', 'wear', 'put on', 'correct'],
      score: 3
    },
    comment:
      '동사 put on은 ‘몸에 걸치다’라는 뜻으로 무언가를 완전히 입은 상태가 아닌, 장착하는 순간적인 동작을 의미할 때 활용합니다. 따라서, 립스틱을 바른 상태가 아니라 바르려는 동작을 의미하기 때문에  “Let me put on my lipstick.”, 코트를 완전히 입은 상태가 아니라 걸치는 동작을 의미하기 때문에 “Put on your coat.”라고 해야 합니다.'
  },
  4: {
    type: 'blank',
    answerType: 'type1',
    category: 'vocabulary',
    question: '빈칸에 들어가는 단어를 선택해 보세요',
    text1: `I _________ my\nex-boyfriend yesterday.`,
    text2: '어제 마트 갔다가 우연히 전 남친을 만났지 뭐야.',
    buttonGroup: {
      answer: 'ran into',
      list: ['met', 'faced', 'ran into', 'come across'],
      score: 8
    },
    comment:
      '동사 run into는 ‘~와 우연히 만나다, ~와 충돌하다’  라는 뜻입니다. 따라서, 전남친을 약속해서 만난 게 아니라 우연히 맞닥뜨린 것이기 때문에 “I ran into my ex-boyfriend yesterday.”라고 해야 합니다.'
  },
  5: {
    type: 'completeSentence',
    answerType: 'type3',
    buttonType: 'complete',
    category: 'grammar',
    question: '다음 우리말에 대한 영어 문장을 완성해 보세요',
    text2: '그녀는 무슨 일 해?',
    text3: '(does / What / she / do)',
    buttonGroup: {
      answer: 'What does she do?',
      list: ['does', 'What', 'she', 'do?'],
      score: 2
    },
    comment:
      '일반동사로 질문을 할 때 조동사 do가 필요한데  주어가 3인칭 단수면 does를 활용합니다. 따라서, 직업을 물을 때는 “What does she do?”라고 합니다.'
  },
  6: {
    type: 'blank',
    answerType: 'type1',
    category: 'grammar',
    question: '밑줄 친 우리말에 대한 영어 문장을 완성해 보세요',
    text1: `I _________ wait.`,
    text2: (
      <>
        엄청난 맛집이라고? 웨이팅 2시간이어도
        <br />
        <u>나는 기다리겠어.</u>
      </>
    ),
    buttonGroup: {
      answer: 'would',
      list: ['would', 'could', 'should', 'must'],
      score: 1
    },
    comment:
      'would는 ‘~라면 ~하겠어‘라는 뜻으로 무언가를 할 의지나 의향을 나타날 때 활용합니다. 따라서, 실제 상황이 닥쳤을 때 기다릴 의향이 있다고 말할 때는 “I would wait.”이라고 합니다'
  },
  7: {
    type: 'basic',
    answerType: 'type2',
    category: 'grammar',
    question: '다음 우리말에 대한 영어 문장을 선택해 보세요',
    text1: '이메일에 그렇게 쓰여 있어.',
    buttonGroup: {
      answer: 'The email says it.',
      list: ['It says in the email.', 'The email says it.'],
      score: 3
    },
    listBlock: true,
    comment:
      '원어민들은 사람과 사물의 구분을 두지 않고 동등하게 보기 때문에 사물도 사람처럼 ‘어떠한 행동을 한다’라고 표현합니다. 따라서, 이메일이 마치 그 내용을 말해주는 것처럼 “The email says it.” 이라고 해야 합니다.'
  },
  8: {
    type: 'blank',
    answerType: 'type1',
    category: 'grammar',
    question: '밑줄 친 우리말에 대한 영어 문장을 선택해 보세요',
    text1: `I met an _________ yesterday.`,
    text2: (
      <>
        나 어제 <u>나의 오랜 친구</u> 를 만났어.
      </>
    ),
    buttonGroup: {
      answer: 'old friend of mine',
      list: ['old friend of mine', 'my old friend'],
      score: 5
    },
    listBlock: true,
    comment:
      '영어에서는 a, many, my, this와 같은 한정사를 사용하여 명사의 범위를 한정하는데, 한정사는 연속적으로 사용할 수 없습니다. 따라서, an my old friend와 같이 두 개의 한정사가 연달아 올 수 없습니다. 대신, of mine을 my의 대용으로 표현하여 “I met an old friend of mine.”이라고 해야 합니다.'
  },
  9: {
    type: 'conversation',
    answerType: 'type5',
    category: 'grammar',
    question: '밑줄 친 우리말에 대한 영어 문장을 선택해 보세요',
    text1: `나 이거 써야 하는데\n작동을 안하네... 어떻게 하지?`,
    text2: <u>내가 (내일까지) 고쳐 놓을게.</u>,
    buttonGroup: {
      answer: 'I’ll have fixed it.',
      list: ['I will fix it.', 'I’ll have fixed it.', 'I have fixed it.', 'I’d fix it.'],
      score: 6
    },
    listBlock: true,
    comment:
      '‘(미래의 특정 시점 이전에) 완료해 놓겠다’고 할 때는 미래 완료 시제 will have pp를 활용합니다. 따라서, 내일까지 고쳐 놓겠다고 말할 때는 “I’ll have fixed it.”이라고 합니다.'
  },
  10: {
    type: 'conversation',
    answerType: 'type5',
    category: 'nuance',
    question: '밑줄 친 영어 문장의 뉘앙스를 선택해 보세요',
    text1: `Mary가 하는 말 앞뒤가 안맞아!\n나 너무 화가 나.`,
    text2: <u>I can tell.</u>,
    buttonGroup: {
      answer: '그래 보여.',
      list: ['대신 말해 줄게.', '그래 보여.'],
      score: 4
    },
    comment:
      '‘동사 tell은 ‘말하다’ 외에도 ‘알아보다’라는 뜻이 있습니다. 따라서, 어떤 상태나 상황을 파악하거나 알아차렸을 때 ‘알아보겠다, 그래 보인다’는 뉘앙스로 “I can tell.”이라고 합니다.'
  },
  11: {
    type: 'conversation',
    answerType: 'type5',
    category: 'nuance',
    question: '밑줄 친 우리말에 대한 영어 문장을 선택해 보세요',
    text1: `야 John이랑 Jolie 사귀는\n사이였어!`,
    text2: (
      <>
        아 진짜?&nbsp;<u>No Wonder!</u>
      </>
    ),
    buttonGroup: {
      answer: '어쩐지 그런 거 같더라!', //
      list: ['둘이 왜 만나지?', '의외네..', '어쩐지 그런 거 같더라!', '안 궁금해.'],
      score: 4
    },
    listBlock: true,
    comment:
      'wonder는 명사로 ‘경이로움, 놀라움’ 이라는 뜻이 있습니다. 따라서, 주어진 상황이나 결과가 예상 가능하거나 설명이 필요하지 않을 때 ‘놀랄 게 없다, 당연하다’는 뉘앙스로 “No wonder.”이라고 합니다.'
  },
  12: {
    type: 'conversation',
    answerType: 'type5',
    category: 'nuance',
    question: '밑줄 친 우리말에 대한 영어 문장을 선택해 보세요',
    text1: `What should we have for\nlunch? How about pizza?`,
    text2: <u>음...별로.</u>,
    buttonGroup: {
      answer: 'Not today.', //
      list: ['Not today.', 'Not really.'],
      score: 3
    },
    comment:
      '피자에 대한 선호도를 묻는 것이 아니라, 오늘 점심 메뉴로 피자를 제안하는 상황입니다. 이에 대한 대답으로 “Not today.”가 좀 더 직접적으로 ‘오늘은 별로 피자가 땡기지 않는다, 피자를 원하지 않는다’는 뉘앙스를 전달합니다.'
  },
  13: {
    type: 'conversation',
    answerType: 'type5',
    category: 'nuance',
    question: '밑줄 친 우리말에 대한 영어 문장을 선택해 보세요',
    text1: `미안, 내가 급하게 일이 생겨서...`,
    text2: <u>괜찮아.</u>,
    buttonGroup: {
      answer: 'No worries.', //
      list: ['Don’t worry.', 'No worries.'],
      score: 5
    },
    comment:
      '엄청난 고민과 걱정을 호소하는 것이 아니라, 부탁한 일에 대해 미안해하는 상황입니다. 이에 대한 대답으로 좀 더 가볍고 친근한 뉘앙스의 “No worries.”가 상대방의 걱정이나 불편함을 덜어주어, 문제를 크게 생각하지 않아도 된다는 메시지를 전달합니다.'
  },
  14: {
    type: 'listening',
    answerType: 'type2',
    category: 'listening',
    question: '다음 문장을 듣고 그의 이름을 선택해 보세요',
    src: `${LTA_HOST_CDN}audio-question-14.mp3`,
    text1: 'He isn’t Mr. Smith. He is Mr. Brown.',
    buttonGroup: {
      answer: 'Mr. Brown',
      list: ['Mr. Smith', 'Mr. Brown'],
      score: 3
    },
    comment:
      '원어민이 “He isn’t Mr. Smith. He is Mr. Brown. (그는 스미스 씨가 아니라, 브라운 씨에요)”라고 합니다. 따라서, “He”는 “Mr. Brown”입니다.'
  },
  15: {
    type: 'video',
    answerType: 'type2',
    category: 'listening',
    question: `다음 영상을 보고,\n조엘이 아침으로 먹은 걸 선택해 보세요`,
    src: `${LTA_HOST_CDN}video-question-15.mp4#t=0.1`,
    text1:
      'I start my day with a glass of water and head to the gym to get a personal training session. At 7:30, I have a peanut butter toast with a cup of coffee for breakfast. I arrive at my office in Seattle at 9 a.m.',
    buttonGroup: {
      answer: '커피 한 잔이랑 피넛버터 토스트',
      list: [
        '커피 한 잔이랑 피넛버터 샌드위치',
        '커피 한 잔이랑 피넛버터 토스트',
        '물 한 잔이랑 피넛버터 토스트'
      ],
      score: 8
    },
    listBlock: true,
    comment:
      '조엘이 “I have peanut butter toast with a cup of coffee for breakfast. (아침으로 커피 한 잔과 땅콩버터 토스트를 먹어)”라고 합니다. 따라서, 조엘이 아침으로 먹는 것은 커피 한 잔이랑 피넛버터 토스트입니다.'
  },
  16: {
    type: 'video',
    answerType: 'type2',
    category: 'listening',
    question: `다음 영상을 보고,\n애슐리가 다이어트 중에 먹지 않은 음식을 선택해 보세요.`,
    src: `${LTA_HOST_CDN}video-question-16.mp4#t=0.1`,
    text1:
      'I drank lots of water instead of soft drinks. I ate grilled or roasted food instead of fried food, and chose brown rice and bread over white rice and bread. I also swapped desserts for fresh fruits.',
    buttonGroup: {
      answer: 'ripe fruit',
      list: ['brown rice and bread', 'ripe fruit', 'roasted food'],
      score: 10
    },
    listBlock: true,
    comment:
      '애슐리가 “I ate grilled or roasted food instead of fried food, and chose brown rice and bread over white rice and bread. (튀긴 음식 대신에 그릴에 굽거나 오븐에 구운 음식을 먹었고, 흰밥이나 빵 대신에 현미밥이나 통밀빵을 먹었어)”라고 합니다. 따라서, 애슐리가 먹지 않은 음식은 ripe fruit(익은 과일)입니다.'
  },
  17: {
    type: 'conversation',
    answerType: 'type5',
    category: 'speaking',
    question: '속마음을 알맞은 문장으로 표현해 보세요',
    text1: `I’m full. Do you want to go\nfor a walk?`,
    text2: '(아 귀찮은데.. 거절해야겠다)',
    buttonGroup: {
      answer: 'I’m good.',
      list: ['I’m good.', 'No thanks.', 'I don’t want to.'],
      score: 3
    },
    listBlock: true,
    comment:
      '상대방의 제안에 대한 거절을 덜 강조하면서 나의 의사를 단순하고 명확하게 전달하고 싶을 때 “I‘m good.”라고 합니다.'
  },
  18: {
    type: 'conversation',
    answerType: 'type5',
    category: 'speaking',
    question: '속마음을 알맞은 문장으로 표현해 보세요',
    text1: `You’re coming to the meeting,\nright?`,
    text2: `(어쩌지..\n오늘은 좀 어려울 것 같은데...)`,
    buttonGroup: {
      answer: 'I can’t make it today',
      list: ['I can’t get there today', 'I can’t make it today'],
      score: 5
    },
    listBlock: true,
    comment:
      '단순히 해당 장소에 도달하지 못한다는 게 아니라, 가기 위해 노력은 했으나 불가피한 사정으로 인해 가지 못한다고 아쉬움을 나타내고 싶을 때 “I can’t make it.”라고 합니다.'
  },
  19: {
    type: 'video',
    answerType: 'type2',
    category: 'speaking',
    question: '다음 영상을 보고, 상황에 맞게 리액션을 해주세요.',
    src: `${LTA_HOST_CDN}video-question-19.mp4#t=0.1`,
    text1: (
      <>
        <span>A :</span>
        <span>Hey Sean, I’m sorry! I’m running late.</span>
      </>
    ),
    text2: (
      <>
        <span>B :</span>
        <span>What happened?</span>
      </>
    ),
    text3: (
      <>
        <span>C :</span>
        <span>The bus didn’t come on time.</span>
      </>
    ),
    buttonGroup: {
      answer: 'Take your time.',
      list: ['Slow down.', 'Take your time.', 'Come slowly.'],
      score: 7
    },
    listBlock: true,
    comment:
      '상대방이 늦을 수밖에 없는 상황에 놓였을 때는 급하게 움직일 필요 없이 천천히 오라고 “Take your time.”라고 해주면 좋습니다.'
  },
  20: {
    buttonType: 'complete',
    type: 'video',
    answerType: 'type5',
    category: 'speaking',
    question: `다음 영상을 보고,\n속마음을 알맞은 문장으로 표현해 보세요`,
    src: `${LTA_HOST_CDN}video-question-20.mp4#t=0.1`,
    text1: `we've talked over the deal with…the…agency.`,
    text2: `(목소리가 계속 끊기네).`,
    text3: '( voice / breaking up. / Your / is )',
    buttonGroup: {
      answer: 'Your voice is breaking up.',
      list: ['voice', 'breaking up.', 'Your', 'is'],
      score: 15
    },
    comment:
      '전화 통화나 온라인 미팅 중에 상대방의 목소리가 끊겨서 잘 들리지 않는다고 하고 싶을 때 “Your voice is breaking up.” 이라고 합니다. 여기서 break up은 소리 신호의 불안정함을 의미합니다.'
  }
}
