import React from 'react'

import styles from './AdminAnswer.module.scss'
import ThumbNail from './ThumbNail'

export default function AdminAnswer({ data, backgroundColor, deviceCheck, type }) {
  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  return (
    <>
      {data && (
        <div
          className={`${styles.adminAnswerBox} ${type ? styles.hasType : ''}`}
          style={{ backgroundColor: backgroundColor }}
        >
          <div>
            <ThumbNail
              backgroundColor={'#fff'}
              backgroundImage={`${data.answerUser.profileImageUrl}`}
              size={deviceCheck ? '8.2rem' : '5.4rem'}
            />
            <p>
              <strong>{data.answerUser.nickName}</strong>
              <span>{formatDate(data.registDate)}</span>
            </p>
          </div>
          <pre
            dangerouslySetInnerHTML={{
              __html: data.contents
            }}
          />
          {data?.attachment?.url && (
            <img src={data.attachment.url} alt="학습관리자에서 등록한 이미지" />
          )}
        </div>
      )}
    </>
  )
}
