import ResponsiveImage from 'components/ResponsiveImage'

import { HOST_CDN } from '../config'
import s from '../EnglishHome.module.css'

export default function MotivationSection() {
  return (
    <section className={s.motivation}>
      <div className={s.imgContainer}>
        <ResponsiveImage
          pc={`${HOST_CDN}img_motivation_241127.png`}
          mo={`${HOST_CDN}img_m_motivation_241127.png`}
          alt={'동기부여 장학금으로 완강률 3배 상승'}
          maxWidth={1024}
        />
      </div>
    </section>
  )
}
