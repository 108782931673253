import { createContext } from 'react'

import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'

import { questionValues } from 'pages/levelTestAdvanced/config'

import QItem from './QuestionItem'
import useQuestion from './useQuestion'

export const QuestionContext = createContext()

// 기본 유형
export const Basic = () => {
  const question = useQuestion()
  const { text1, text2 } = question

  return (
    <>
      <QItem.Typography.H2>{text1}</QItem.Typography.H2>
      {text2 && (
        <>
          <QItem.TypographyGap />
          <QItem.Typography variant="h2">{text2}</QItem.Typography>
        </>
      )}
    </>
  )
}
//A,B 대화 유형
export const Blank = () => {
  const question = useQuestion()
  const { text1, text2 } = question

  return (
    <>
      <QItem.Typography.H1>{text1}</QItem.Typography.H1>
      <QItem.TypographyGap />
      <QItem.Typography>{text2}</QItem.Typography>
    </>
  )
}
// 문장완성 유형
export const CompleteSentance = () => {
  const question = useQuestion()
  const { text2 } = question

  return <QItem.Typography.H2>{text2}</QItem.Typography.H2>
}

// A,B 대화 유형
export const Conversation = () => {
  const question = useQuestion()
  const { text1, text2 } = question

  return (
    <>
      <QItem.CircleIndexTypography index="A">{text1}</QItem.CircleIndexTypography>
      <QItem.TypographyGap />
      <QItem.CircleIndexTypography index="B">{text2}</QItem.CircleIndexTypography>
    </>
  )
}

// 듣기 유형
export const Listening = () => {
  const question = useQuestion()
  return <QItem.Audio key={question?.src} src={question?.src} />
}

// 비디오 유형
export const Video = () => {
  const question = useQuestion()
  return <QItem.Video key={question?.src} src={question?.src} />
}

function Question({ children }) {
  const questionIndex = useLevelTestAdvancedExamStore((state) => state.questionIndex)
  const question = questionValues[questionIndex]

  return (
    <QuestionContext.Provider value={{ questionIndex, ...question }}>
      <QItem.Index questionIndex={questionIndex} />
      <QItem.QuestionText question={question?.question} />
      {children}
      <QItem.QuestionButton />
    </QuestionContext.Provider>
  )
}

Question.Basic = Basic
Question.Blank = Blank
Question.CompleteSentance = CompleteSentance
Question.Conversation = Conversation
Question.Listening = Listening
Question.Video = Video

export default Question
