import React from 'react'

import styles from './LikedCount.module.scss'

export default function LikedCount({ count, type }) {
  const countReturn = (num) => {
    num = Number(num)
    return num / 10000 >= 1
      ? Math.floor(num / 10000) +
          '.' +
          (Math.floor(num / 1000) - Math.floor(num / 10000) * 10) +
          '만'
      : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return (
    <span className={styles.countItem}>
      {type} {countReturn(count)}개
    </span>
  )
}
