import React from 'react'

import styles from './BoardBanner.module.scss'

export default function BoardBanner({ title, contents, bgFullbox, bgInnerBox }) {
  return (
    <section className={styles.bannerFullBox} style={{ background: bgFullbox }}>
      <div className={styles.smInner} style={{ background: bgInnerBox }}>
        <h1>{title}</h1>
        <pre>{contents}</pre>
      </div>
    </section>
  )
}
