import { useRef, useState } from 'react'

import useValidation from 'hooks/useValidation'

import api from 'common/api'
import { API_HOST } from 'common/config'
import { getUtm } from 'components/promotion/utile/utile'

import { dbCollectionFormValues, DEVICE_OPTION } from '../config'

export default function useDbCollectionForm(promotion) {
  const { hasDeviceOption, content, hasGoalOption, hasAgeOption, noCounselTime, category } =
    dbCollectionFormValues[promotion]
  const { validateForm, onKeyUpKoreanInput, validateTelNumber } = useValidation()

  const contentInit = () => {
    if (hasDeviceOption) {
      return `기기 옵션: ${DEVICE_OPTION[0]}`
    }

    return content ? `기기 옵션: ${content}` : ''
  }

  const formDataInit = {
    category,
    content: contentInit(),
    counselTime: '',
    email: '',
    etc: '',
    phone: '',
    privacyAgreeYn: false,
    smsAgreeYn: false,
    userAge: '',
    userName: '',
    utm: getUtm() || ''
  }
  const [formData, setFormData] = useState(formDataInit)
  const [modalControl, setModalControl] = useState({
    privacy: false,
    question: false,
    success: false,
    duplicate: false
  })
  const [allChecked, setAllChecked] = useState(false)

  const formRef = useRef(null)

  const handleModalToggle = (key) => {
    setModalControl((prev) => ({ ...prev, [key]: !modalControl[key] }))
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prev) => ({ ...prev, [id]: value }))
  }

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target
    setFormData((prev) => {
      const newFormData = { ...prev, [id]: checked }

      const allChecked = newFormData.privacyAgreeYn && newFormData.smsAgreeYn
      setAllChecked(allChecked)

      return newFormData
    })
  }

  const handleAllCheckboxChange = (e) => {
    const { checked } = e.target
    setAllChecked(checked)
    setFormData({
      ...formData,
      privacyAgreeYn: checked,
      smsAgreeYn: checked
    })
  }

  const koreanNameCheck = () => {
    const userName = onKeyUpKoreanInput(formData.userName, 'FORM_USERNAME_VALIDATION')
    setFormData((prev) => ({ ...prev, userName }))
  }

  const checkValidation = () => {
    return (
      validateForm(formRef, 'userName', 'FORM_USERNAME_REQUIRED') &&
      validateForm(formRef, 'phone', 'FORM_PHONE_REQUIRED') &&
      validateTelNumber(formData.phone, 'FORM_PHONE_VALIDATION') &&
      (!noCounselTime ? validateForm(formRef, 'counselTime', 'FORM_COUNSEL_REQUIRED') : true) && //상담 가능 시간 선택 있을경우
      (hasAgeOption ? validateForm(formRef, 'userAge', 'FORM_AGE_REQUIRED') : true) && // 연령대 선택 있을경우
      (hasGoalOption ? validateForm(formRef, 'goal', 'FORM_GOAL_REQUIRED') : true) && // 학습목표 선택 있을경우
      validateForm(formRef, 'privacyAgreeYn', 'FORM_PRIVACY_REQUIRED', true)
    )
  }

  const handleOpenQuestionModal = (closeCounselModalFunc) => {
    if (!checkValidation()) return

    const select = formRef.current.querySelector('#counselTime')

    handleModalToggle('question')
    if (!noCounselTime) {
      select.selectedIndex = 0
    }
    closeCounselModalFunc && closeCounselModalFunc()
  }

  const handleSubmit = async (gtmProductName, closeCounselModalFunc) => {
    if (!checkValidation()) return

    try {
      const reqBody = {
        ...formData,
        content: hasGoalOption ? `학습목표 : ${formData?.goal}` : contentInit(),
        privacyAgreeYn: formData.privacyAgreeYn ? 'Y' : 'N',
        smsAgreeYn: formData.smsAgreeYn ? 'Y' : 'N'
      }

      const res = await api.post(`${API_HOST}/v2/inquiry/consultation/tel`, reqBody)
      const isDuplication = !res.data.data

      isDuplication ? handleModalToggle('duplicate') : handleModalToggle('success')
      !isDuplication && window.dataLayer.push({ event: 'lead', product_name: gtmProductName })
    } catch (error) {
      console.error(error)
    } finally {
      // 폼 초기화 및 모달 닫기
      resetFormData()
      closeCounselModalFunc && closeCounselModalFunc()
    }
  }

  const resetFormData = () => {
    const counselTimeSelect = formRef.current.querySelector('#counselTime')
    const goalSelect = formRef.current.querySelector('#goal')
    const ageSelect = formRef.current.querySelector('#userAge')

    setFormData(formDataInit)
    setAllChecked(false)
    // 상담 가능 시간 선택 option 있을 경우 상담 가능 시간 선택 초기화
    if (!noCounselTime) {
      counselTimeSelect.selectedIndex = 0
    }
    // 학습목표 선택 option 있을 경우 학습목표 선택 초기화
    if (hasGoalOption) {
      goalSelect.selectedIndex = 0
    }
    // 연령대 선택 option 있을 경우 연령대 선택 초기화
    if (hasAgeOption) {
      ageSelect.selectedIndex = 0
    }
  }

  return {
    formRef,
    formData,
    modalControl,
    allChecked,
    formDataInit,
    setFormData,
    setAllChecked,
    handleInputChange,
    handleCheckboxChange,
    handleModalToggle,
    koreanNameCheck,
    handleOpenQuestionModal,
    handleAllCheckboxChange,
    handleSubmit
  }
}
