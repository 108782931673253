import React, { useState, useCallback } from 'react'

import useSubscription from 'hooks/useSubscription'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import 'assets/styles/modal.scss'
import 'assets/styles/modalJoin.scss'
import api from 'common/api'
import * as config from 'common/config'
import { getCookie } from 'common/Cookie'

import AlertModal from './AlertModal'
import Toast from './Toast'
import Login from '../Login'

export default function ModalSocialLogin({
  title,
  btnClose,
  snsType,
  accessToken,
  encryptToken,
  handleCloseModal
}) {
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [loginMessage, setLoginMessage] = useState(null)
  const [inputValue, setInputValue] = useState({
    id: '',
    password: ''
  })

  const signType = sessionStorage.getItem('signType')
  const { subScriptionModal, postSubscription } = useSubscription()

  const isPlatform = getCookie('historyUrl').indexOf('/webview') > -1
  console.log('🚀  isPlatform:', isPlatform)
  console.log('🚀  historyUrl:', getCookie('historyUrl'))
  console.log('🚀  historyUrl.indexOf("/webview"):', getCookie('historyUrl').indexOf('/webview'))

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleToastStatus = useCallback((status) => {
    setOpenToast(status)
  }, [])

  const handleGetUserInfo = (id, pwd) => {
    setInputValue({
      ...inputValue,
      id: id,
      password: pwd
    })
  }

  // 소셜 토큰 암호화 x
  const handleSignUpSocial = (id, pwd) => {
    let formData = new FormData()

    formData.append('userId', id)
    formData.append('password', pwd)
    formData.append('snsType', snsType)
    formData.append('snsToken', accessToken)

    api
      .put(config.AUTH_API_HOST + '/auth/v2/sign-up/social', formData)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          setLoginMessage(response.data.meta.message)
          handleOpenModalAlert()

          return false
        }

        setOpenToast(true)
        handleLoginSocial()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 소셜 로그인 -  소셜 토큰 암호화 o
  const handleLoginSocial = async () => {
    try {
      const response = await api.post(
        `${config.AUTH_API_HOST}/auth/v2/sign-in/social/get-token?deviceType=0&snsToken=${encryptToken}&snsType=${snsType}`
      )

      if (response.data.meta.code !== 200) {
        if (response.data.meta.message) {
          alert(response.data.meta.message)
        } else {
          alert(config.MESSAGE['common-error'])
        }
        return
      }

      if (signType === 'uPlus') {
        const data = { userSeq: response.data.data.user.userSeq }

        // subscription post
        await postSubscription(data)

        AuthService.setUserInfo(response.data.data)
        return
      }

      AuthService.setUserInfo(response.data.data)

      // 소셜 계정과 야나두 계정 아이디 통합 후 historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
      if (getCookie('historyUrl') !== '') {
        if (window.location.href !== getCookie('historyUrl'))
          window.location.href = getCookie('historyUrl')
      } else {
        window.location.href = '/'
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <div className="modal-wrap modal-login">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <i className="blind">{title}</i>
          </div>
          <div className="modal-content type-fixed-button social">
            <Login
              handleGetUserInfo={handleGetUserInfo}
              handleSignUpSocial={handleSignUpSocial}
              isPlatform={isPlatform}
            />
            <Toast
              msg={'아이디 통합이 완료되었습니다.'}
              openToast={openToast}
              handleToastStatus={handleToastStatus}
            />
          </div>
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            btnConfirm
            msg={loginMessage && loginMessage}
            handleCloseModal={handleCloseModalAlert}
          />
        </ModalPortal>
      ) : null}
      {subScriptionModal}
    </>
  )
}
