import { useEffect, useState } from 'react'

import api from 'common/api'

export default function Notice() {
  const [isShow, setIsShow] = useState(false)
  const [noticeData, setNoticeData] = useState(null)

  const handleNotiveShow = () => {
    setIsShow(!isShow)
  }

  const getNotice = () => {
    api
      .get('/v2/product-detail-notice')
      .then((response) => {
        setNoticeData(response.data.data.contents)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getNotice()
  }, [])

  return (
    <>
      <div className={'product-notice-box' + (isShow ? ' active' : '')}>
        <div className="inner">
          <button type="button" className="btn" onClick={handleNotiveShow}>
            <span>유의사항을 확인해주세요.</span>
          </button>
          <div className="notice-content">
            <div dangerouslySetInnerHTML={{ __html: noticeData }}></div>
          </div>
        </div>
      </div>
    </>
  )
}
