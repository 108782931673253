import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import api from 'common/api'
//style
import '../assets/styles/banner.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination, Autoplay])

const Banner = ({
  userName,
  bannerTitle,
  margin,
  loop,
  autoPlay,
  pagination,
  slideView,
  type,
  data
}) => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const deviceCheck = useMediaQuery({ maxWidth: 1024 })
  const swiperMargin = margin ? margin : null
  const swiperLoop = loop ? true : false

  // 링크형 이벤트 조회수
  const getLinkTypeData = (eventSeq) => {
    api
      .get('/v2/event/' + eventSeq)
      .then((response) => {
        console.log('count')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 렌더링 유틸리티 함수
  const renderSlideItem = (bannerSlide, index) => {
    const commonProps = {
      key: index,
      style: { backgroundColor: bannerSlide.bgColor }
    }

    if (bannerSlide.eventType === 'LINK') {
      return (
        <a
          {...commonProps}
          href={bannerSlide.bannerLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => getLinkTypeData(bannerSlide.eventSeq)}
        >
          <img src={bannerSlide.packageBannerImageFilePath} alt={bannerSlide.eventName} />
        </a>
      )
    } else if (bannerSlide.eventType) {
      return (
        <Link {...commonProps} to={`/event/detail/${bannerSlide.eventSeq}`} className="slide-item">
          <img src={bannerSlide.packageBannerImageFilePath} alt={bannerSlide.eventName} />
        </Link>
      )
    } else {
      return (
        <Link {...commonProps} to={bannerSlide.link} className="slide-item">
          <img
            src={deviceCheck ? bannerSlide.imagePath2 : bannerSlide.imagePath1}
            alt={bannerSlide.imageInfo}
          />
        </Link>
      )
    }
  }

  return (
    <div className={`banner-col ${type}`}>
      {bannerTitle && (
        <strong className="banner-tit">
          {userName}
          {bannerTitle}
        </strong>
      )}
      <div className="banner-slide">
        {data && data.length > 1 ? (
          <Swiper
            className="swiper-container"
            spaceBetween={swiperMargin}
            slidesPerView={slideView}
            loop={swiperLoop}
            pagination={{ clickable: pagination }}
            autoplay={
              autoPlay
                ? {
                    delay: 3000,
                    disableOnInteraction: false
                  }
                : ''
            }
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current

                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
          >
            {data.map((bannerSlide, index) => (
              <SwiperSlide key={index} className="swiper-slide">
                {renderSlideItem(bannerSlide, index)}
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          data && data.map((bannerSlide, index) => renderSlideItem(bannerSlide, index))
        )}
      </div>
    </div>
  )
}

export default Banner
