import { useState } from 'react'
import { Link } from 'react-router-dom'

import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'

import { YAFIT_ALLINONE_LINK } from 'common/config'

import styles from './ServiceSection.module.scss'
import { serviceBannerListValues } from '../config'
const BgEnglishMO =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_m_service_english.png'
const BgUniversityMO =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_m_service_university.png'
const BgYafitMO =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_m_service_yafit_v3.png'
const BgEnglishPC =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_pc_service_english.png'
const BgUniversityPC =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_pc_service_university.png'
const BgYafitPC =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_pc_service_yafit_v3.png'

SwiperCore.use([Autoplay])

export default function ServiceSection({ deviceCheck }) {
  const [activeCard, setActiveCard] = useState([])

  const handleOnClick = (card) => {
    if (deviceCheck) {
      if (activeCard.includes(card)) {
        setActiveCard(activeCard.filter((e) => e !== card))
      } else {
        setActiveCard([...activeCard, card])
      }
    }
  }
  return (
    <section className={styles.introduceSection}>
      <div className={styles.innerCol}>
        <ul className={styles.serviceList}>
          <li
            className={`${styles.serviceListItem} ${styles.typeEnglish} ${
              deviceCheck ? styles.mobile : styles.devicePC
            } ${activeCard.includes('English') ? styles.active : ''}`}
          >
            <picture>
              <img src={deviceCheck ? BgEnglishMO : BgEnglishPC} alt="" />
            </picture>
            <div>
              <span>야나두 영어</span>
              <p>
                하루 10분으로 쉽게
                <br />
                영어를 마스터하는 신기한 방법
                <Link to="/promotion-landing/bestAwards"></Link>
              </p>
              <em className={styles.icoPlusEm} onClick={() => handleOnClick('English')} />
              <div className={styles.toggleBox}>
                <p>
                  누구에게나 어려운 영어를 가장 쉽게 배우는 방법을
                  <br />
                  야나두의 집요 연구소가 개발했습니다.
                </p>
                <p>
                  같은 10분이어도 야나두의 10분은 1시간의 학습 효과를 자랑합니다.
                  <br />
                  영어 잠재력이 넘치는 당신에게 딱 맞는 10분 학습법을 선택하세요!
                </p>
              </div>
            </div>
          </li>
          <li
            className={`${styles.serviceListItem} ${styles.typeYafit} ${
              deviceCheck ? styles.mobile : styles.devicePC
            } ${activeCard.includes('Yafit') ? styles.active : ''}`}
          >
            <picture>
              <img src={deviceCheck ? BgYafitMO : BgYafitPC} alt="" />
            </picture>
            <div>
              <span>야나두 피트니스</span>
              <p>
                10명 중 8명은
                <br />
                꾸준히 운동 중<a href={YAFIT_ALLINONE_LINK}> </a>
              </p>
              <em className={styles.icoPlusEm} onClick={() => handleOnClick('Yafit')} />
              <div className={styles.toggleBox}>
                <p>
                  수만 명의 라이더들과 전세계 랜드마크를
                  <br />
                  함께 달리며 미션마다 쏟아지는
                  <br />
                  마일리지를 쌓아보세요.
                  <br />
                  현금같은 마일리지는 최고의 동기부여가 되죠!
                </p>
                <p>
                  실감 나는 메타버스 세상에서
                  <br />
                  언제, 어디서든 함께 달리는 동료들과
                  <br />
                  여행하듯 즐겁게 운동해 보세요.
                </p>
              </div>
            </div>
          </li>
          <li
            className={`${styles.serviceListItem} ${styles.typeUniversity} ${
              deviceCheck ? styles.mobile : styles.devicePC
            } ${activeCard.includes('University') ? styles.active : ''}`}
          >
            <picture>
              <img src={deviceCheck ? BgUniversityMO : BgUniversityPC} alt="" />
            </picture>
            <div>
              <span>야나두 클래스</span>
              <p>
                쉽게 시작하는
                <br />
                자기계발
                <Link to="/store/list"></Link>
              </p>
              <em className={styles.icoPlusEm} onClick={() => handleOnClick('University')} />
              <div className={styles.toggleBox}>
                <p>
                  새로운 지식과 경험이 필요한 시기에 야나두가 제안하는 강의와 클래스로 실력을
                  업그레이드 해보세요. 삶의 새로운 도전이 펼쳐집니다.
                </p>
              </div>
            </div>
          </li>
        </ul>

        <div className={styles.slideBox}>
          <Swiper
            direction={'vertical'}
            slidesPerView={1}
            loop={serviceBannerListValues.length > 1 ? true : false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
          >
            {serviceBannerListValues?.map((list) => {
              return (
                <SwiperSlide key={list.id}>
                  <div
                    className={`${styles.goodsBox}`}
                    style={{
                      '--badgeTextColor': list.badgeTextColor,
                      '--badgeBgColor': list.badgeBgColor,
                      '--bgColor': list.bgColor,
                      '--textColor': list.textColor
                    }}
                  >
                    {list.link.includes('http') ? (
                      <a href={list.link} target="_blank" rel="noreferrer">
                        {!list.badge ? null : <span>{list.badge}</span>}
                        <p>{list.bannerText}</p>
                      </a>
                    ) : (
                      <Link to={list.link}>
                        {!list.badge ? null : <span>{list.badge}</span>}
                        <p>{list.bannerText}</p>
                      </Link>
                    )}
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
