import React, { useState, useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'
import MobileHeader from 'components/common/MobileHeader'

import TabCoupon from './TabCoupon'
import ModalPortal from '../../ModalPortal'
import Lnb from '../Lnb'
import ModalDelivery from '../modal/ModalDelivery'
import ModalVoucherRegister from '../modal/ModalVoucherRegister'
import NoContent from '../NoContent'
import Pagination from '../Pagination'
const IcoNoVoucher = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_coupon.png'

export default function ItemVoucher() {
  const navigate = useNavigate()
  const [pageIdx, setPageIdx] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const [modalopen, setModalopen] = useState(false)
  const [modalopenDelivery, setModalopenDelivery] = useState(false)
  const [voucherData, setVoucherData] = useState([])

  const onChangeDash = (date) => {
    return date.replace(/-/g, '.')
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    return isDesktop ? children : null
  }

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  // const handleOpenModalDelivery = useCallback((e) => {
  //   document.body.classList.add('modal-open')
  //   setModalopenDelivery(true)
  // }, [])

  const handleCloseModalDelivery = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenDelivery(false)
  }, [])

  // 등록된 수강권 리스트 조회 API
  const getVoucherData = () => {
    api
      .get(`/v2/voucher-publish/list?index=${pageIdx}&size=8`)
      .then((response) => {
        setVoucherData(response.data.data.content)
        setTotalPages(response.data.data.totalPages)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getVoucherData()
  }, [pageIdx])

  return (
    <>
      <div className="coupon-col voucher">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="쿠폰 수강권" onClick={() => navigate('/mypage/1')} />
            <Lnb />
            <section className="coupon-lists-col">
              <TabCoupon activeTab={'VOUCHER'} />
              <div className="tab-content active">
                <div className="btn-flex-form btn-coupon">
                  {/* <button className="btn">
                    <span>MY 클래스 바로가기</span>
                  </button> */}
                  <button className="btn positive" onClick={handleOpenModal}>
                    <span>수강권 등록</span>
                  </button>
                </div>
                <div className="toggle-col">
                  <strong>등록 내역</strong>
                  <button className="btn positive" onClick={handleOpenModal}>
                    <span>수강권 등록</span>
                  </button>
                </div>
                <Desktop>
                  {voucherData.length > 0 ? (
                    <table className="table-form">
                      <colgroup>
                        <col width="25%" />
                        <col width="" />
                        {/* <col width="20%" /> */}
                        <col width="20%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>수강권 번호</th>
                          <th>수강권 명</th>
                          {/* <th>배송지 입력</th> */}
                          <th>등록일</th>
                        </tr>
                      </thead>
                      <tbody>
                        {voucherData.map((voucher, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <p>{voucher.voucherPublishCode}</p>
                              </td>
                              <td>
                                <strong className="ellipsis tal">{voucher.voucherName}</strong>
                              </td>
                              {/* <td>
                                <button
                                  className="btn"
                                  onClick={handleOpenModalDelivery}
                                >
                                  <span>배송지 입력</span>
                                </button>
                              </td> */}
                              <td>
                                <p>{onChangeDash(voucher.voucherUseDate)}</p>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <table className="table-form none-data">
                      <colgroup>
                        <col width="25%" />
                        <col width="" />
                        <col width="20%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>수강권 번호</th>
                          <th>수강권 명</th>
                          <th>등록일</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="3">
                            <NoContent
                              icoImg={IcoNoVoucher}
                              titleMsg={'등록된 수강권이 없습니다.'}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </Desktop>
                <Mobile>
                  {voucherData.length > 0 ? (
                    <div className="table-form-mobile">
                      <ul className="table-lists">
                        {voucherData.map((voucher, index) => {
                          return (
                            <li key={index}>
                              <a href="#;">
                                <span className="">{voucher.voucherPublishCode}</span>
                                <strong className="ellipsis line-clamp-2">
                                  {voucher.voucherName}
                                </strong>
                                <div className="info tar">
                                  <span className="date">
                                    {onChangeDash(voucher.voucherUseDate)}
                                  </span>
                                  {/* <button
                                    className="btn"
                                    onClick={handleOpenModalDelivery}
                                  >
                                    <span>배송지 입력</span>
                                  </button> */}
                                </div>
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  ) : (
                    <div className="table-form-mobile">
                      <ul className="table-lists">
                        <li>
                          <NoContent icoImg={IcoNoVoucher} titleMsg={'등록된 수강권이 없습니다.'} />
                        </li>
                      </ul>
                    </div>
                  )}
                </Mobile>
                {voucherData.length > 0 && (
                  <Pagination
                    type={'IDX'}
                    handleCurrentIdx={handleCurrentIdx}
                    totalPages={totalPages}
                    limitPage={5}
                    maxNum={5}
                    minNum={0}
                    pathName={'/mypage/voucher'}
                    pageIdx={pageIdx}
                  />
                )}

                <div className="coupon-notice-col">
                  <strong>유의사항</strong>
                  <ul className="coupon-notice-lists">
                    <li>
                      수강권은 학습 시작일이 지정된 클래스를 제외하고는 모두 등록과 동시에 학습이
                      가능합니다.
                    </li>
                    <li>정해진 수강기간까지만 사용 가능하며, 기간연장은 불가능 합니다.</li>
                    <li>한번 등록된 수강권은 취소 및 재사용이 불가능합니다.</li>
                    <li>해당 수강권은 등록 유효기간 내 등록 시 사용이 가능합니다.</li>
                    <li>등록 유효기간이 지난 수강권이 자동으로 소멸되며, 재발급되지 않습니다.</li>
                  </ul>
                </div>
                {modalopen ? (
                  <ModalPortal>
                    <ModalVoucherRegister btnClose handleCloseModal={handleCloseModal} />
                  </ModalPortal>
                ) : null}
                {modalopenDelivery ? (
                  <ModalPortal>
                    <ModalDelivery
                      title={'배송지 입력'}
                      btnClose
                      handleCloseModal={handleCloseModalDelivery}
                      voucherIco={true}
                    />
                  </ModalPortal>
                ) : null}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
