import MallOutlet from 'pages/mall'
import MallIntro from 'pages/mall/Intro'
import MallList from 'pages/mall/List'
import MallProduct from 'pages/mall/MallProduct'
import DetailProductPreview from 'pages/product/DetailProductPreview'
const MallRoutes = [
  {
    path: 'mall/*',
    element: <MallOutlet />,
    children: [
      { path: ':mallSeq', element: <MallIntro /> },
      { path: ':mallSeq/list', element: <MallList /> },
      { path: 'detail/:packageSeq', element: <MallProduct /> },
      { path: ':mallSeq/detail/:packageSeq', element: <MallProduct /> },
      { path: 'detail-preview/:packageSeq', element: <DetailProductPreview /> },
      { path: ':mallSeq/detail-preview/:packageSeq', element: <DetailProductPreview /> }
    ]
  }
]

export default MallRoutes
