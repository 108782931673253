import React from 'react'
import { Link } from 'react-router-dom'

export default function TabMain({ activeTab }) {
  return (
    <section className="list-title-col header-line">
      <div className="inner-col">
        <div className="title-area">
          <p>이벤트</p>
          <ul>
            <li className={activeTab === 'PROGRESS' ? 'active' : null}>
              <Link to={'/event/list/1'} className="tab-item">
                진행중 이벤트
              </Link>
            </li>
            <li className={activeTab === 'END' ? 'active' : null}>
              <Link to={'/event/end/1'} className="tab-item">
                종료된 이벤트
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
