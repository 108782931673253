import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'
import MobileHeader from 'components/common/MobileHeader'

import NoContentButtons from './components/NoContentButtons'

import styles from './Home.module.scss'
import TabMain from './TabMain'
import Loading from '../../components/common/Loading'
import Lnb from '../../components/Lnb'
import MyClassList from '../../components/MyClassList'
import NoContent from '../../components/NoContent'

import '../../assets/styles/myClass.scss'
const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_class.png'

export default function MyClass() {
  const [isLoading, setLoading] = useState(true)
  const [isToggle, setIstoggle] = useState(false)
  const [myClassData, setMyClassData] = useState([])
  const [oldClassCount, setOldClassCount] = useState(0)
  const navigate = useNavigate()

  const handleToggleBtn = () => {
    setIstoggle(!isToggle)
  }

  // 3.0 + 2.0 클래스
  const getMyclasssListCLS = () => {
    setLoading(true)
    api
      .get('/v2/myClass/web')
      .then((response) => {
        setMyClassData(response.data.data.classes)
        setOldClassCount(response.data.data.oldClassCount)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getMyclasssListCLS()
  }, [])

  return (
    <div className="myclass-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader title="MY 클래스" onClick={() => navigate('/mypage/1')} />
          <Lnb />
          <section className="content">
            {/* 상단 탭 영역 컴포넌트로 분리 - 전달값으로 active 처리 (MYCLASS / MYHISTORY) */}
            <TabMain activeTab={'MYCLASS'} />
            <section className="myclass-content-box">
              <div className="tab-content active">
                {isLoading && isLoading ? (
                  <div className={styles.loadingBox}>
                    <Loading />
                  </div>
                ) : oldClassCount > 0 || myClassData.length > 0 ? (
                  <>
                    {/*  수강중인 클래스가 1개 이상일 경우 */}
                    <div className="app-download">
                      <p>
                        <strong>야나두 학습 앱에서 수강하실 수 있습니다.</strong>
                        <span>
                          야나두 앱을 다운로드 받고
                          <br />
                          앱에서만 제공하는 다양한 학습 서비스를 이용해보세요.
                        </span>
                      </p>
                      <img
                        src="https://english.yanadoocdn.com/upload/yanadoo/pc/myClass/2102/img_app_code2.png"
                        alt="앱 다운로드 QR"
                      />
                      <div className="download-btn-area">
                        <button
                          type="button"
                          className="btn-aos"
                          onClick={() =>
                            window.open(
                              'https://play.google.com/store/apps/details?id=kr.co.yanadoo.app2'
                            )
                          }
                        >
                          <i className="blind">AOS 다운로드</i>
                        </button>

                        <button
                          type="button"
                          className="btn-ios"
                          onClick={() =>
                            window.open('https://itunes.apple.com/kr/app/id1539582420')
                          }
                        >
                          <i className="blind">IOS 다운로드</i>
                        </button>
                      </div>
                    </div>
                    {/* 3.0 + 2.0 클래스 = versionCode로 디테일 페이지 분기처리 */}
                    <div className="class-content">
                      <div className={'class-box myclass' + (isToggle ? ' active' : '')}>
                        <div className="inner">
                          <p>
                            <strong>PC 수강 지원</strong>
                            야나두 학습 앱 전용 상품 이용자의 학습 편의 향상을 위해 PC에서 동영상
                            강의 수강을 지원하고 있습니다.
                          </p>
                          <button type="button" className="btn-toggle" onClick={handleToggleBtn}>
                            <span>자세히 보기</span>
                          </button>
                        </div>
                        {/* 수강중인 클래스 리스트 컴포넌트 */}
                        <MyClassList
                          myClassData={myClassData}
                          tabType={'DEFAULT'}
                          dataType={'ALL'}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={`class-empty myclass ${styles.empty}`}>
                    <NoContent icoImg={icoImg} titleMsg={'수강중인 클래스가 없습니다.'}>
                      <NoContentButtons />
                    </NoContent>
                  </div>
                )}
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  )
}
