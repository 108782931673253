import React from 'react'
import { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'

import api from 'common/api'

import styles from './index.module.scss'
import StoreClassGroupList from '../../../components/Store/List/ClassGroupList'
import StoreBanner from '../components/StoreBanner'
const StoreList = () => {
  const [isLoading, setLoading] = useState(true)
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [banner, setBanner] = useState([])
  const [list, setList] = useState([])
  const getBannerData = () => {
    api
      .get(`/v2/store/banner`)
      .then((response) => {
        setBanner(response.data.data)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getListData = () => {
    api
      .get(`/v2/store/curation`)
      .then((response) => {
        setList(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getBannerData()
    getListData()
  }, [])

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingBox}>
          <Skeleton height={isMobile ? '78rem' : '48rem'} />
          <ul>
            {new Array(8).fill('').map((item, idx) => (
              <li key={idx}>
                <Skeleton height={isMobile ? '25rem' : '18rem'} />
                <Skeleton width={'18rem'} height={isMobile ? '3rem' : '2rem'} />
                <Skeleton width={'14rem'} height={isMobile ? '3rem' : '2rem'} />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          {' '}
          {banner.map((el, idx) => (
            <div key={idx}>
              {el.bannerType === 'CLASS_HOME_TOP' ? (
                <StoreBanner
                  key="classTop"
                  loop
                  autoPlay
                  heightPC={480}
                  bannerData={el.bannerList}
                />
              ) : null}
            </div>
          ))}
          {list.map((item, idx) => (
            <div key={idx}>
              {item.fixYn === 'Y' ? (
                <StoreClassGroupList
                  title={item.storeCurationName}
                  storeListData={item.packageList}
                  detailLink={item.moreBtnLink}
                  moreBtnUseYn={item.moreBtnUseYn}
                />
              ) : null}
            </div>
          ))}
          {banner.map((el, idx) => (
            <div key={idx}>
              {el.bannerType === 'CLASS_HOME_MIDDLE' ? (
                <StoreBanner
                  key="classMiddle"
                  loop
                  autoPlay
                  heightPC={320}
                  bannerData={el.bannerList}
                />
              ) : null}
            </div>
          ))}
          {list.map((item, idx) => (
            <div key={idx}>
              {item.fixYn === 'N' ? (
                <StoreClassGroupList
                  title={item.storeCurationName}
                  storeListData={item.packageList}
                  detailLink={item.moreBtnLink}
                  moreBtnUseYn={item.moreBtnUseYn}
                />
              ) : null}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default StoreList
