import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import api from 'common/api'

import ButtonDelivery from './ButtonDelivery'
import ButtonOrderCancel from './ButtonOrderCancel'
import ButtonReceipt from './ButtonReceipt'
import ButtonReview from './ButtonReview'
import Lnb from '../../components/Lnb'
import ModalMyInquiry from '../../components/modal/ModalMyInquiry'
import ModalPortal from '../../ModalPortal'

const NOTE_PAY = 'NOTE_PAY'

export default function ItemOrder() {
  const { currentSeq } = useParams()

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const [modalopenMyInquiry, setModalopenMyInquiry] = useState(false)

  const [orderDetailData, setOrderDetailData] = useState([])
  const [paymentData, setPaymentData] = useState([])

  // 영수증 출력
  const openReceipt = (receiptUrl) => {
    if (receiptUrl === '') {
      alert('영수증이 제공되지 않습니다. 고객센터에 문의해주세요.')
    } else {
      window.open(receiptUrl)
    }
  }

  const handleOpenModalMyInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenMyInquiry(true)
  }, [])

  const handleCloseModalMyInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenMyInquiry(false)
  }, [])

  // 주문리스트 API 호출
  const getOrderDetail = () => {
    api
      .get(`/v2/order/detail?orderNo=${currentSeq}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
        } else {
          setOrderDetailData(response.data.data)
          setPaymentData(response.data.data.orderDetailVOList[0])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getOrderDetail()

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div className="order-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <Lnb />
            <section className="order-list-col">
              <h3>주문 상세</h3>
              <div className="order-cancel-col">
                <div className="order-tit">
                  <div className="date">
                    <span className="order-date">
                      {orderDetailData.orderDetailVOList &&
                        orderDetailData.orderDetailVOList[0].orderDate.substring(0, 10)}
                    </span>
                  </div>
                  <span className="light">주문번호 {currentSeq}</span>
                </div>
                {/* 결제수단이 무통장입금일 경우 입금 기한 노출 */}
                {paymentData &&
                paymentData.paymentTypeCode === 'REMITTANCE' &&
                paymentData.paymentStatusCode === 'PAY_CONFIRM' ? (
                  <div className="order-account-info" key={paymentData.productSeq}>
                    <dl>
                      <dt>입금계좌</dt>
                      <dd>{paymentData.vactNum}</dd>
                    </dl>
                    <dl>
                      <dt>입금금액</dt>
                      <dd>{addComma(paymentData.depositPrice)}원</dd>
                    </dl>
                    <dl>
                      <dt>입금기한</dt>
                      <dd>{paymentData.depositDate}</dd>
                    </dl>
                    <p className="noti">* 기한 내 미입금 시 주문이 자동취소됩니다.</p>
                  </div>
                ) : null}
                {orderDetailData.orderDetailVOList &&
                  orderDetailData.orderDetailVOList
                    .filter((VOList) => !VOList.additionalPackage)
                    .map((data, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <>
                            <div className="order-item">
                              <div className="order-desc">
                                <span
                                  // to={{
                                  //   pathname:
                                  //     data.packageTypeCode === 'ENTRANCE'
                                  //       ? `/store/entrance`
                                  //       : `/store/detail/${data.packageSeq}`,
                                  // }}
                                  className="thumb"
                                  style={{
                                    backgroundImage: `url(${data.thumbnailPath})`
                                  }}
                                >
                                  {data.deliveryStatus &&
                                    data.deliveryStatus !== 'TERM_EXPIRATION' &&
                                    data.deliveryStatusName &&
                                    data.paymentStatusName !== '결제취소' && (
                                      <span
                                        className={
                                          'delivery-state-mobile' +
                                          (data.deliveryStatusName === '결제완료' ||
                                          data.deliveryStatusName === '배송대기' ||
                                          data.deliveryStatusName === '배송요청' ||
                                          data.deliveryStatusName === '배송완료' ||
                                          data.deliveryStatusName === '배송접수'
                                            ? ' positive'
                                            : '')
                                        }
                                      >
                                        {data.deliveryStatusName}
                                      </span>
                                    )}
                                </span>
                                <div className="desc">
                                  {/* 결제상태 */}
                                  <span
                                    className={
                                      'order-state-mobile' +
                                      (data.deliveryStatus === 'ORDER_CANCEL' ||
                                      data.deliveryStatus === 'CANCEL_SUCCESS' ||
                                      data.paymentStatusName === '결제취소'
                                        ? ' cancel'
                                        : '')
                                    }
                                  >
                                    {data.paymentStatusName}
                                  </span>
                                  {/* 상품 이름 */}
                                  <strong className="tit ellipsis">{data.orderName}</strong>
                                  <span className="quantity">
                                    수량 : {data.cnt}개 {data.additionalPackage && '추가 교재'}
                                  </span>
                                  {data.paymentWayCode !== NOTE_PAY && (
                                    <div className="price">
                                      <span className="payment-price">
                                        {addComma(data.originalPrice)}원
                                      </span>
                                      <span className="discount-price">
                                        <strong>{addComma(data.paymentPrice)}</strong>원
                                      </span>
                                    </div>
                                  )}
                                  {/* 상품 옵션 수량이 있을 때 노출되어야 함 */}
                                  {data.detailProductVOList.length > 0 && (
                                    <div className="goods-info mobile">
                                      {data.detailProductVOList
                                        .filter((product) => product.requiredYn === 'Y')
                                        .map((productItem) => {
                                          return (
                                            <li key={productItem.orderDetailSeq.toString()}>
                                              {productItem.productName}
                                            </li>
                                          )
                                        })}
                                      {data.detailProductVOList
                                        .filter((product) => product.requiredYn === 'N')
                                        .map((productItem) => {
                                          return (
                                            <li
                                              className="option"
                                              key={productItem.orderDetailSeq.toString()}
                                            >
                                              {productItem.productName}
                                              <span className="option-quantity">
                                                수량 : {productItem.cnt}개
                                              </span>
                                            </li>
                                          )
                                        })}
                                      {data.deliveryStatus === 'TERM_EXPIRATION' && (
                                        <p className="goods-notice">
                                          마인드셋 미작성으로 웰컴키트
                                          <br />
                                          지급 기간이 종료되어 혜택을
                                          <br />
                                          받으실 수 없습니다.
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="state">
                                  {/* 결제상태 */}
                                  <span
                                    className={
                                      'order-state' +
                                      (data.deliveryStatus === 'ORDER_CANCEL' ||
                                      data.deliveryStatus === 'CANCEL_SUCCESS' ||
                                      data.paymentStatusName === '결제취소'
                                        ? ' cancel'
                                        : '')
                                    }
                                  >
                                    {data.paymentStatusName}
                                  </span>
                                  {/* 배송상태는 현물일 때에만 노출
                                배송상태 아이콘 : 배송접수거나 배송준비중일 때엔 남색
                                그 외엔 초록색
                              */}
                                  {data.deliveryStatus &&
                                    data.deliveryStatus !== 'TERM_EXPIRATION' &&
                                    data.deliveryStatusName &&
                                    data.paymentStatusName !== '결제취소' && (
                                      <span
                                        className={
                                          'delivery-state' +
                                          (data.deliveryStatusName === '결제완료' ||
                                          data.deliveryStatusName === '배송대기' ||
                                          data.deliveryStatusName === '배송요청' ||
                                          data.deliveryStatusName === '배송완료' ||
                                          data.deliveryStatusName === '배송접수'
                                            ? ' positive'
                                            : '')
                                        }
                                      >
                                        {data.deliveryStatusName}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="btn-flex-form">
                                {/* 결제상태(paymentStatusCode), 배송상태(deliveryStatus)에 따라 분기처리
                            주문 취소 : 
                              - 현물 : 배송접수, 입금확인중 일 때 
                            결제 취소 : 
                              - 현물 : 배송접수, 결제완료 일 때
                              - 디지털 : 결제 완료일로 부터 7일 이내(결제일 미포함) && 디지털 강의 신청 기록X (무료 지급 강의 불포함)
                            배송지 변경 : 
                              - 현물 : 배송접수, 결제완료 일 때
                            배송조회 :
                              - 현물 : 배송중, 결제완료 일 때
                            후기작성 : 
                              - 현물 : 배송완료, 결제완료 일 때
                              - 디지털 : 결제완료 일 때
                            */}
                                {data.paymentStatusCode === 'PAY_CONFIRM' ? (
                                  <ButtonOrderCancel
                                    orderNo={data.orderNo}
                                    orderDate={data.orderDate}
                                  />
                                ) : null}
                                {data.deliveryStatusName &&
                                data.deliveryStatusName === '배송접수' ? (
                                  <>
                                    {/* <Link
                                    to={{
                                      pathname: `/mypage/order/cancel/${data.seq}`,
                                    }}
                                    className="button stroke"
                                  >
                                    <span>결제취소</span>
                                  </Link> */}
                                    <ButtonDelivery orderNo={data.orderNo} />
                                  </>
                                ) : null}
                                {data.deliveryStatusName === '배송중' ||
                                data.deliveryStatusName === '송장정보업로드' ? (
                                  <>
                                    <button
                                      className="button stroke"
                                      onClick={() =>
                                        window.open(
                                          `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${data.invoiceNum}`,
                                          '_blank'
                                        )
                                      }
                                    >
                                      <span>배송조회</span>
                                    </button>
                                    <ButtonReceipt
                                      invoiceNum={data.invoiceNum}
                                      orderNum={data.orderNo}
                                    />
                                  </>
                                ) : null}
                                {data.paymentStatusCode === 'PAY_SUCCESS' ? (
                                  <>
                                    {data.deliveryStatusName &&
                                    data.deliveryStatusName === '배송완료' ? (
                                      <>
                                        <button
                                          className="button stroke"
                                          onClick={() =>
                                            window.open(
                                              `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${data.invoiceNum}`,
                                              '_blank'
                                            )
                                          }
                                        >
                                          <span>배송조회</span>
                                        </button>
                                        <ButtonReview
                                          productLists={data.detailProductVOList}
                                          packageSeq={data.packageSeq}
                                          thumbnailPath={data.thumbnailPath}
                                        />
                                      </>
                                    ) : null}
                                    {data.packageTypeCode === 'APP' ||
                                    data.packageTypeCode === 'REAL' ||
                                    data.packageTypeCode === 'COMBINATION' ? (
                                      <ButtonReview
                                        productLists={data.detailProductVOList}
                                        packageSeq={data.packageSeq}
                                        thumbnailPath={data.thumbnailPath}
                                      />
                                    ) : null}
                                  </>
                                ) : null}
                                {data.paymentStatusCode === 'ORDER_CANCEL' && (
                                  <span className="slash"></span>
                                )}
                              </div>
                            </div>

                            {/* 상품 구성, 옵션(detailProductVOList) 수량이 있을 때 노출되어야 함 */}
                            {data.detailProductVOList.length > 0 && (
                              <div className="goods-info">
                                {/* {data.detailProductVOList.map((product, idx) => {
                              return (
                                <li
                                  className={
                                    product.requiredYn === 'N' ? 'option' : ''
                                  }
                                  key={product.orderDetailSeq.toString()}
                                >
                                  {product.productName}
                                  {product.requiredYn === 'N' && (
                                    <span className="option-quantity">
                                      수량 : {product.cnt}개
                                    </span>
                                  )}
                                </li>
                              );
                            })} */}
                                {data.detailProductVOList
                                  .filter((product) => product.requiredYn === 'Y')
                                  .map((productItem) => {
                                    return (
                                      <li
                                        key={productItem.orderDetailSeq.toString()}
                                        className={data.additionalPackage ? 'addition' : ''}
                                      >
                                        {productItem.productName}
                                      </li>
                                    )
                                  })}
                                {data.detailProductVOList
                                  .filter((product) => product.requiredYn === 'N')
                                  .map((productItem) => {
                                    return (
                                      <li
                                        className="option"
                                        key={productItem.orderDetailSeq.toString()}
                                      >
                                        {productItem.productName}
                                        <span className="option-quantity">
                                          수량 : {productItem.cnt}
                                        </span>
                                      </li>
                                    )
                                  })}
                                {data.deliveryStatus === 'TERM_EXPIRATION' && (
                                  <p className="goods-notice">
                                    마인드셋 미작성으로
                                    <br />
                                    웰컴키트 지급 기간이 종료되어
                                    <br />
                                    혜택을 받으실 수 없습니다.
                                  </p>
                                )}
                              </div>
                            )}
                          </>
                          {orderDetailData.orderDetailVOList
                            .filter(
                              (VOList) =>
                                VOList.additionalPackage &&
                                VOList.additionalPackageParentsPackageSeq === data.packageSeq
                            )
                            .map((item, idx) => {
                              return (
                                <React.Fragment key={idx}>
                                  <div className="order-item">
                                    <div className="order-desc">
                                      <span
                                        className="thumb"
                                        style={{
                                          backgroundImage: `url(${item.thumbnailPath})`
                                        }}
                                      >
                                        {item.deliveryStatus &&
                                          item.deliveryStatus !== 'TERM_EXPIRATION' &&
                                          item.deliveryStatusName &&
                                          item.paymentStatusName !== '결제취소' && (
                                            <span
                                              className={
                                                'delivery-state-mobile' +
                                                (item.deliveryStatusName === '결제완료' ||
                                                item.deliveryStatusName === '배송대기' ||
                                                item.deliveryStatusName === '배송요청' ||
                                                item.deliveryStatusName === '배송완료' ||
                                                item.deliveryStatusName === '배송접수'
                                                  ? ' positive'
                                                  : '')
                                              }
                                            >
                                              {item.deliveryStatusName}
                                            </span>
                                          )}
                                      </span>
                                      <div className="desc">
                                        {/* 결제상태 */}
                                        <span
                                          className={
                                            'order-state-mobile' +
                                            (item.deliveryStatus === 'ORDER_CANCEL' ||
                                            item.deliveryStatus === 'CANCEL_SUCCESS' ||
                                            item.paymentStatusName === '결제취소'
                                              ? ' cancel'
                                              : '')
                                          }
                                        >
                                          {item.paymentStatusName}
                                        </span>
                                        {/* 상품 이름 */}
                                        <strong className="tit ellipsis">{item.orderName}</strong>
                                        {item.paymentWayCode !== NOTE_PAY && (
                                          <div className="price">
                                            <span className="discount-price">
                                              <strong>{addComma(item.paymentPrice)}</strong>원
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="state">
                                        {/* 결제상태 */}
                                        <span
                                          className={
                                            'order-state' +
                                            (item.deliveryStatus === 'ORDER_CANCEL' ||
                                            item.deliveryStatus === 'CANCEL_SUCCESS' ||
                                            item.paymentStatusName === '결제취소'
                                              ? ' cancel'
                                              : '')
                                          }
                                        >
                                          {item.paymentStatusName}
                                        </span>
                                        {item.deliveryStatus &&
                                          item.deliveryStatus !== 'TERM_EXPIRATION' &&
                                          item.deliveryStatusName &&
                                          item.paymentStatusName !== '결제취소' && (
                                            <span
                                              className={
                                                'delivery-state' +
                                                (item.deliveryStatusName === '결제완료' ||
                                                item.deliveryStatusName === '배송대기' ||
                                                item.deliveryStatusName === '배송요청' ||
                                                item.deliveryStatusName === '배송완료' ||
                                                item.deliveryStatusName === '배송접수'
                                                  ? ' positive'
                                                  : '')
                                              }
                                            >
                                              {item.deliveryStatusName}
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                    <div className="btn-flex-form">
                                      {item.paymentStatusCode === 'PAY_CONFIRM' ? (
                                        <ButtonOrderCancel
                                          orderNo={item.orderNo}
                                          orderDate={item.orderDate}
                                        />
                                      ) : null}
                                      {item.deliveryStatusName &&
                                      item.deliveryStatusName === '배송접수' ? (
                                        <>
                                          <ButtonDelivery orderNo={item.orderNo} />
                                        </>
                                      ) : null}
                                      {item.deliveryStatusName === '배송중' ||
                                      item.deliveryStatusName === '송장정보업로드' ? (
                                        <>
                                          <button
                                            className="button stroke"
                                            onClick={() =>
                                              window.open(
                                                `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${item.invoiceNum}`,
                                                '_blank'
                                              )
                                            }
                                          >
                                            <span>배송조회</span>
                                          </button>
                                          <ButtonReceipt
                                            invoiceNum={item.invoiceNum}
                                            orderNum={item.orderNo}
                                          />
                                        </>
                                      ) : null}
                                      {item.paymentStatusCode === 'PAY_SUCCESS' ? (
                                        <>
                                          {item.deliveryStatusName &&
                                          item.deliveryStatusName === '배송완료' ? (
                                            <>
                                              <button
                                                className="button stroke"
                                                onClick={() =>
                                                  window.open(
                                                    `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${item.invoiceNum}`,
                                                    '_blank'
                                                  )
                                                }
                                              >
                                                <span>배송조회</span>
                                              </button>
                                              <ButtonReview
                                                productLists={item.detailProductVOList}
                                                packageSeq={item.packageSeq}
                                                thumbnailPath={item.thumbnailPath}
                                              />
                                            </>
                                          ) : null}
                                          {item.packageTypeCode === 'APP' ||
                                          item.packageTypeCode === 'REAL' ||
                                          item.packageTypeCode === 'COMBINATION' ? (
                                            <ButtonReview
                                              productLists={item.detailProductVOList}
                                              packageSeq={item.packageSeq}
                                              thumbnailPath={item.thumbnailPath}
                                            />
                                          ) : null}
                                        </>
                                      ) : null}
                                      {item.paymentStatusCode === 'ORDER_CANCEL' && (
                                        <span className="slash"></span>
                                      )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              )
                            })}
                        </React.Fragment>
                      )
                    })}
              </div>
              {orderDetailData.deliveryInfo && (
                <>
                  <h3 className="tit-mobile bdt">
                    {orderDetailData.deliveryInfo.deliveryUsername ? '주문자 정보' : '주문 정보'}
                  </h3>

                  <div className="order-cancel-col">
                    <div className="cancel-info delivery">
                      <dl>
                        <dt>주문자 정보</dt>
                        <dd>
                          {orderDetailData.deliveryInfo.username}{' '}
                          {orderDetailData.deliveryInfo.cellphone}
                        </dd>
                      </dl>
                      {/* 배송지 정보가 있을경우 */}
                      {orderDetailData.deliveryInfo.deliveryUsername && (
                        <dl className="requests-mobile">
                          <dt>배송지 정보</dt>
                          <dd>
                            {orderDetailData.deliveryInfo.deliveryUsername}{' '}
                            {orderDetailData.deliveryInfo.deliveryPhone}
                            <br />
                            {orderDetailData.deliveryInfo.zipCode &&
                              '(' + orderDetailData.deliveryInfo.zipCode + ')'}{' '}
                            {orderDetailData.deliveryInfo.deliveryAddr1}{' '}
                            {orderDetailData.deliveryInfo.deliveryAddr2}
                          </dd>
                        </dl>
                      )}
                      {/* 배송 요청사항이 있을 경우 */}
                      {orderDetailData.deliveryInfo.deliveryRequest && (
                        <dl className="requests-mobile">
                          <dt>배송 요청사항</dt>
                          <dd>{orderDetailData.deliveryInfo.deliveryRequest}</dd>
                        </dl>
                      )}
                    </div>
                  </div>
                  {orderDetailData.orderDetailVOList &&
                    orderDetailData.orderDetailVOList[0].appVoucherNum && (
                      <>
                        <h3 className="tit-mobile">앱이용권 정보</h3>
                        <div className="order-cancel-col">
                          <div className="cancel-info delivery">
                            <dl>
                              <dt>이용권 번호</dt>
                              <dd>{orderDetailData.orderDetailVOList[0].appVoucherNum}</dd>
                            </dl>
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}

              <h3 className="tit-mobile bdt">
                결제 정보
                <div className="btn-group">
                  {orderDetailData.receiptList &&
                    orderDetailData.receiptList
                      .filter((receipt) => receipt.payType === '2')
                      .map((receiptItem) => {
                        return (
                          <button
                            className="button stroke"
                            key={receiptItem.receipt}
                            onClick={() => {
                              openReceipt(receiptItem.receipt)
                            }}
                          >
                            <span>영수증 출력</span>
                          </button>
                        )
                      })}
                </div>
              </h3>
              <div className="order-cancel-col payment">
                <div className="payment-info">
                  <div className="cancel-info">
                    <dl>
                      <dt>결제방법</dt>
                      <dd>
                        <span
                          className={
                            'payment-method' +
                            (paymentData.paymentTypeCode === 'REMITTANCE' && ' no-bankbook')
                          }
                        >
                          {paymentData && paymentData.paymentTypeName}
                        </span>
                        <br />
                        <p className="date">{paymentData && paymentData.paymentDate}</p>
                      </dd>
                    </dl>
                    <dl>
                      <dt>상품 총 금액</dt>
                      <dd>
                        {paymentData.totalPaymentPrice && addComma(paymentData.totalPaymentPrice)}원
                      </dd>
                    </dl>
                    {paymentData && paymentData.deliveryPrice > 0 && (
                      <dl>
                        <dt>배송비</dt>
                        <dd>+ {paymentData && paymentData.deliveryPrice}원</dd>
                      </dl>
                    )}
                    {/* <dl className="discount-detail">
                      <dt>멤버십 할인</dt>
                      <dd>(-) 1,000원</dd>
                    </dl>
                    <dl className="discount-detail">
                      <dt>상품 쿠폰</dt>
                      <dd>(-) 10,000원</dd>
                    </dl> */}
                  </div>
                  <div className="cancel-price">
                    <span>총 결제 금액</span>
                    <strong>
                      {paymentData.totalPaymentPrice && addComma(paymentData.totalPaymentPrice)}원
                    </strong>
                  </div>
                </div>
              </div>
              <div className="btn-flex-form">
                <Link
                  to={{
                    pathname: '/mypage/order/list'
                  }}
                  className="btn positive"
                >
                  <span>주문/배송 목록</span>
                </Link>
                <button className="btn positive" onClick={handleOpenModalMyInquiry}>
                  <span>1:1 문의</span>
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
      {modalopenMyInquiry ? (
        <ModalPortal>
          <ModalMyInquiry btnClose handleCloseModal={handleCloseModalMyInquiry} />
        </ModalPortal>
      ) : null}
    </>
  )
}
