import React from 'react'
import { Link } from 'react-router-dom'

import Lnb from '../../../components/Lnb'
import '../../../assets/styles/main.scss'

export default function DetailQna(props) {
  const qnaItem = props.location?.state.qnaItem

  return (
    <div className="qna-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <Lnb />
          <section className="list-detail-col">
            <div className="title-col">
              <strong>학습문의</strong>
            </div>
            <div className="detail-title-area">
              <div className="tit-flex-form">
                <strong className="sub-tit">{qnaItem?.className}</strong>
                <div className="info">
                  <strong className={'check' + (qnaItem?.answerCheck === 'Y' ? ' completed' : '')}>
                    {qnaItem?.answerCheck === 'Y' ? '답변완료' : '답변대기'}
                  </strong>
                  <span className="date">{qnaItem?.date}</span>
                </div>
              </div>
              <p className="main-tit">{qnaItem?.className}</p>
              {qnaItem?.answerCheck === 'N' && (
                <div className="btn-group">
                  <button className="btn-modify">
                    <span>수정</span>
                  </button>
                  <button className="btn-delete">
                    <span>삭제</span>
                  </button>
                </div>
              )}
            </div>
            <div className="detail-content">
              <div className="detail-question">
                <p>{qnaItem?.qnaContent}</p>
                {qnaItem?.image && <img src={qnaItem?.image} alt="" />}
              </div>
              {qnaItem?.answerCheck === 'Y' ? (
                <div className="detail-answer">
                  <div className="thumb-area">
                    <span className="thumb">
                      <i className="blind">썸네일</i>
                    </span>
                    <div className="desc">
                      <strong>학습관리자</strong>
                      <p>{qnaItem?.answerDate}</p>
                    </div>
                  </div>
                  <p className="answer-txt">{qnaItem?.answerContent}</p>
                  {qnaItem?.image && <img src={qnaItem?.image} alt="" />}
                </div>
              ) : null}
            </div>

            <div className="list-btn-col">
              <div className="inner-col">
                <Link
                  to={{
                    pathname: '/mypage/qna'
                  }}
                  className="btn btn-go-list"
                >
                  목록보기
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
