import React from 'react'

import { AINADOO_CONFIG } from '../config'
import s from './styles/ChatTopSrr.module.scss'

export default function ChatTopSrr() {
  return (
    <div className={s.chatTopSrr}>
      <img
        className={s.srrImg}
        src={`${AINADOO_CONFIG.IMG_HOST}img_ainadoo_top_srr.png`}
        alt={'스르르 학습지'}
      />
      <dl className={s.titleGroup}>
        <dt className={s.title}>학습 도우미 AI 스르르</dt>
        <dd className={s.description}>
          학습 도우미 AI 스르르와의 대화가 시작되었습니다. <br />
          스르르와 관련한 내용들을 질문해 보세요!
        </dd>
      </dl>
      <div className={s.tips}>
        <img
          className={s.tipImg}
          src={`${AINADOO_CONFIG.IMG_HOST}ico_talk.gif`}
          alt="AI에게 이렇게 질문해 보세요."
        />
        <div className={s.tipTxt}>
          AI 스르르에게 이렇게 질문 해보세요! <br />
          <span>CAN에 대해 설명해줘!</span>
        </div>
      </div>
    </div>
  )
}
