import { YANADOO_CDN_HOST } from 'common/config'

export const keyVisualBannerValues = {
  1: {
    pc: `${YANADOO_CDN_HOST}/promotion/etc/img_ba-banner1.png`,
    mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_ba-banner1-m.png`,
    background: '#100732'
  },
  2: {
    pc: `${YANADOO_CDN_HOST}/promotion/etc/img_ba-banner2.png`,
    mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_ba-banner2-m.png`,
    background: '#140302'
  }
}
