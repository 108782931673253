import React, { useState, useEffect, useCallback } from 'react'

import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import '../../assets/styles/modal.scss'
import '../../assets/styles/_modalMindsetIntro.scss'
import 'swiper/swiper.scss'
import api from 'common/api'
import * as config from 'common/config'

import AlertModal from './AlertModal'
import ModalMindsetResult from './ModalMindsetResult'
import ModalPortal from '../../ModalPortal'

SwiperCore.use([Autoplay])

export default function ModalMindsetInput({ title, btnClose, handleCloseModal }) {
  const [modalopen1, setModalopen1] = useState(false)
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const handleOpenModalMindsetResult = useCallback((e) => {
    MindsetValidation()

    if (e.currentTarget.classList.value.indexOf('disabled') !== -1) {
      alert('목표를 입력해 주세요')
    } else {
      document.body.classList.add('modal-open')
      setModalopen1(true)
    }
  }, [])

  const [btnToggle, setBtnToggle] = useState(false)
  const handleListToggle = () => {
    setBtnToggle(!btnToggle)
  }

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseAlertModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const mindsetBestList = [
    '제 2의 커리어 만들기',
    '이 구역의 최고 일잘러',
    '영어로 비즈니스 하기',
    '자막없이 미드보기',
    '리즈시절 몸매 되찾기'
  ]

  const MindsetValidation = () => {
    const inputText = document.getElementById('mindsetInput')
    const btnSave = document.querySelector('.btn-mindset')
    const btnMindsetItem = document.querySelectorAll('.btn-mindset-item')
    const btnInputReset = document.querySelector('.btn-input-reset')

    for (let i = 0; btnMindsetItem.length > i; i++) {
      if (inputText.value === btnMindsetItem[i].innerText && inputText.value !== '') {
        btnMindsetItem[i].classList.add('active')
      } else {
        btnMindsetItem[i].classList.remove('active')
      }
    }

    if (inputText.value.length > 0) {
      btnSave.classList.remove('disabled')
      btnSave.classList.add('active')
      btnInputReset.classList.add('active')
    } else {
      btnSave.classList.add('disabled')
      btnSave.classList.remove('active')
      btnInputReset.classList.remove('active')
    }
  }

  const MindsetInputReset = () => {
    document.getElementById('mindsetInput').value = ''
    MindsetValidation()
  }

  const MindsetInputAuto = (e) => {
    const inputText = document.getElementById('mindsetInput')
    const target = e.currentTarget
    const btnTogglePromise = document.getElementById('btnTogglePromise')

    inputText.value = target.innerText
    MindsetValidation()

    if (btnTogglePromise.classList.value.indexOf('active') !== -1) {
      setTimeout(function () {
        setBtnToggle(!btnToggle)
      }, 200)
    }
  }

  // 초기 랜더링 시 현재 입학 상태 여부 체크(비정상 접근 시 예외처리)
  const getMindsetState = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
        let result = response.data.data.entrance

        if (result.entranceStatus !== 'ENTRANCE') {
          // 입학 상태가 아님
          alert('입학 상태가 아닙니다.')
          handleCloseModal()
        } else if (
          result.entranceStatus === 'ENTRANCE' &&
          !result.isPledgePopTarget &&
          !result.isWriteEntrancePledge
        ) {
          // 입학 상태이면서, APP 팝업 발생 조건이 아니고, 서약서 미작성
          alert('서약서 작성 가능 상태가 아닙니다.')
          handleCloseModal()
        } else if (
          result.entranceStatus === 'ENTRANCE' &&
          !result.isPledgePopTarget &&
          result.isWriteEntrancePledge
        ) {
          // 입학 상태이면서, APP 팝업 발생 조건이 아니고, 서약서 작성을 한경우
          setModalopen1(true)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  let delayCheck = false

  const handleConfirmClose = () => {
    if (delayCheck) {
      alert('잠시후에 다시 시도해주세요')
    } else {
      delayCheck = true
      api
        .post(config.AUTH_API_HOST + '/auth/v2/user/pledge/pop-close')
        .then((response) => {
          if (response.data.meta.code === 200) {
            handleCloseAlertModal()
            handleCloseModal()
            delayCheck = false
          } else {
            alert(response.data.meta.message)
            delayCheck = false
          }
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
          delayCheck = false
        })
    }
  }

  useEffect(() => {
    getMindsetState()
  }, [])

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleOpenModalAlert}></div>
        <div className="modal-inner" style={{ height: '100%' }}>
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleOpenModalAlert}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content" style={{ height: '100%' }}>
            <div className="mindset-input">
              <div className="mindset-inner">
                <p>
                  성장 마인드셋을 갖춘
                  <br />
                  나는 오늘부터
                </p>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="예) 한달안에 토익 만점"
                    name="mindsetInput"
                    maxLength="20"
                    onChange={MindsetValidation}
                    id="mindsetInput"
                  />
                  <button type="button" className="btn-input-reset" onClick={MindsetInputReset}>
                    지우기
                  </button>
                </div>
                {mindsetBestList && mindsetBestList.length > 0 ? (
                  <>
                    <dl>
                      <dt>다른 사람들의 목표는?</dt>
                      <dd>
                        <div className="best-list">
                          {mindsetBestList.length > 1 ? (
                            <Swiper
                              className="swiper-container"
                              slidesPerView={1}
                              direction="vertical"
                              loop={true}
                              autoplay={{
                                delay: 3000,
                                disableOnInteraction: false
                              }}
                            >
                              {mindsetBestList.map((item, idx) => {
                                return (
                                  <SwiperSlide key={idx} className="swiper-slide">
                                    {item}
                                  </SwiperSlide>
                                )
                              })}
                            </Swiper>
                          ) : (
                            <>
                              {mindsetBestList.map((item, idx) => (
                                <div key={idx} className="swiper-slide">
                                  {item}
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                        <button
                          type="button"
                          onClick={handleListToggle}
                          id="btnTogglePromise"
                          className={btnToggle ? 'active' : ''}
                        ></button>
                      </dd>
                    </dl>
                  </>
                ) : (
                  ''
                )}
                <div className="promise-box">
                  <ul className={btnToggle ? 'active' : ''}>
                    {mindsetBestList.map((item, idx) => (
                      <li key={idx}>
                        <button
                          type="button"
                          className="btn-mindset-item"
                          onClick={MindsetInputAuto}
                        >
                          {item}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <p>
                    을(를) 목표로
                    <br />
                    도전은 즐겁게 받아들이고
                    <br />
                    좋은 습관을 형성해가며,
                    <br />
                    중도포기 없이 끈기있게
                    <br />
                    앞으로 나아갈 것을
                    <br />나 자신과 약속합니다.
                  </p>
                </div>
                <button
                  type="button"
                  className="btn btn-mindset disabled"
                  onClick={handleOpenModalMindsetResult}
                >
                  저장
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalopen1 ? (
        <ModalPortal>
          <ModalMindsetResult
            btnClose
            title={'서약서 결과'}
            resultText={document.getElementById('mindsetInput').value}
            handleCloseModal={handleCloseModal}
          />
        </ModalPortal>
      ) : null}
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            btnGroupTxtClose={'취소'}
            btnGroupTxtConfirm={'다음에 하기'}
            msg={"다음에 서약하기\n'마이페이지 > 서약서 작성하기'에서 이어서 진행하실 수 있습니다."}
            handleCloseModal={handleCloseAlertModal}
            handleConfirmClose={handleConfirmClose}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
