import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import MobileHeader from 'components/common/MobileHeader'

import api from '../../common/api'

import '../../assets/styles/notice.scss'

export default function DetailRefund({ match }) {
  const navigate = useNavigate()
  const { hash } = useLocation()

  const [refundData, setRefundData] = useState([])

  // 야나두 영어 & 야핏사이클 탭 전환
  const [tabCurrent, setTabCurrent] = useState(() => (hash === '#yafitcycle' ? 1 : 0))
  const handleChangeTab = (idx) => {
    setTabCurrent(idx)
  }

  // 야나두 영어 & 야핏사이클 환불 정책 API 요청
  const getRefundData = async () => {
    try {
      // 야나두 영어
      const yanadooResponse = await api.get('v2/terms?brandCode=YANADOO&termsType=REFUND')
      // 야핏사이클
      const yafitResponse = await api.get('v2/terms?brandCode=YAFIT_CYCLE&termsType=REFUND')
      setRefundData(() => [
        { title: '야나두', data: yanadooResponse.data.data.contents }, //
        { title: '야핏사이클', data: yafitResponse.data.data.contents }
      ])
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getRefundData()
  }, [])

  return (
    <>
      <div className="refund-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader
              title="환불 규정 안내"
              onClick={() =>
                document.referrer === '' ? navigate('/service/center') : navigate(-1)
              }
            />
            <section className="list-detail-col">
              <nav className="tabs">
                {refundData.map((item, idx) => (
                  <button
                    key={uuid()}
                    className={tabCurrent === idx ? 'active' : ''}
                    onClick={() => {
                      handleChangeTab(idx)
                    }}
                  >
                    {item.title}
                  </button>
                ))}
              </nav>

              <div className="title-col">
                <strong>환불 규정 안내</strong>
              </div>
              <div className="detail-title-area">
                <div className="tit-flex-form">
                  <strong className="sub-tit black" style={{ marginTop: '0' }}>
                    {refundData.length !== 0 && refundData[tabCurrent].title} 환불 정책
                  </strong>
                  <div className="info">
                    <span className="date" />
                  </div>
                </div>
              </div>

              <div className="detail-content" style={{ borderBottom: 'none', color: '#000' }}>
                <div className="detail-question">
                  {refundData.length !== 0 && (
                    <div
                      className="refund-content"
                      dangerouslySetInnerHTML={{ __html: refundData[tabCurrent].data }}
                    ></div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
