import React from 'react'
import { Link } from 'react-router-dom'

export default function TabMain({ activeTab }) {
  return (
    <section>
      <ul className="tab-menu-col">
        <li className={activeTab === 'TERMS' ? 'active' : null}>
          <Link to="/service/termsdetail" className="tab-menu">
            이용약관
          </Link>
        </li>
        <li className={activeTab === 'POLICY' ? 'active' : null}>
          <Link to="/service/policydetail" className="tab-menu">
            개인정보처리방침
          </Link>
        </li>
      </ul>
    </section>
  )
}
