import React, { useState, useRef, useEffect } from 'react'

import api from 'common/api'

export default function ModalGoodsInquiry({
  btnClose,
  classData,
  questionList,
  putQuestion,
  questionSeq,
  handleCloseModal
}) {
  const inquiryRef = useRef()
  const [textareaLength, setTextareaLength] = useState()
  const [textareaValue, setTextareaValue] = useState()

  const handleInquiryValue = (e) => {
    setTextareaValue(e.target.value)
    setTextareaLength(e.target.value.length)
  }

  const postInquiry = () => {
    if (textareaLength < 11) {
      alert('최소 10자 이상 입력해주세요.')
      inquiryRef.current.focus()

      return false
    }
    putQuestion(questionSeq, textareaValue)
  }

  // 내가 작성한 상품 문의가 있다면 데이터 업데이트
  const getMyInquiry = () => {
    api
      .get('/v2/package-question/' + questionSeq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents)
          setTextareaLength(response.data.data.contents.length)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const questionValue =
    questionList &&
    questionList
      .filter((question) => question.questionSeq === questionSeq)
      .map((question) => question.contents)

  useEffect(() => {
    if (questionValue) {
      setTextareaValue(questionValue)
      setTextareaLength(questionValue[0].length)
    }
  }, [classData])

  useEffect(() => {
    getMyInquiry()
  }, [])
  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <span className="blind">닫기</span>
            </button>
          )}
          <div className="modal-header">
            <strong>상품 문의</strong>
          </div>
          <div className="modal-content">
            <div className="class-info-box">
              <p>
                {classData.title ? classData.title : classData.packageTitle}
                <strong>
                  {classData.packageCategoryGroupName}
                  &middot;
                  {classData.packageCategoryName}
                </strong>
              </p>
            </div>
            <div className="modal-form-col write-form">
              <form action="">
                <dl className="inquiry-area">
                  <dt>문의내용</dt>
                  <dd>
                    <textarea
                      placeholder="※ 현재 문의량이 많아 답변이 지연되고 있습니다. 순차적으로 답변 드리고 있으며, 빠른 답변이 될 수 있도록 하겠습니다. 불편을 드려 죄송합니다."
                      maxLength="1000"
                      ref={inquiryRef}
                      value={textareaValue}
                      onChange={handleInquiryValue}
                    ></textarea>
                    <p>
                      <span>{textareaLength}</span>/1000
                    </p>
                  </dd>
                </dl>
              </form>
              <div className="notice">
                <strong>문의 작성 안내</strong>
                <ul>
                  <li>
                    약관 및 법률에 위배(비속어, 허위사실 등), 해석이 불가능하거나 문의 외 다른
                    목적의 글을 작성한 경우 관리자에 의해 사전 동의 없이 미게시 될 수 있습니다.
                  </li>
                  <li>작성하신 내용은 홍보, 이벤트 등 다양한 방법으로 활용될 수 있습니다.</li>
                </ul>
                <div className="btn-flex-form">
                  <button type="button" className="btn" onClick={handleCloseModal}>
                    <span>취소</span>
                  </button>
                  <button type="button" className="btn active" onClick={postInquiry}>
                    <span>확인</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
