import React from 'react'
import Lottie from 'react-lottie-player'

import cx from 'classnames'

import s from './ChatFooterAudio.module.scss'
import { CHAT_COMPONENTS_CONFIG } from './config'
import ResponseLoading from './ResponseLoading.json'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function RecorderButton({
  isListening,
  isLoading,
  isActive,
  onClickAbort,
  onClickStart,
  onClickReStart,
  onClickSendMessage
}) {
  return (
    <>
      {isListening ? (
        <div className={s.controlButtons}>
          <button className={s.buttonCancel} onClick={onClickAbort}>
            취소
          </button>
          <button className={s.buttonMic} onClick={onClickSendMessage} disabled={isLoading}>
            <div>{CHAT_COMPONENTS_CONFIG.svg.send}</div>
          </button>
          <button className={s.buttonRestart} onClick={onClickReStart}>
            <span>재시작</span>
          </button>
        </div>
      ) : isLoading ? (
        <div className={cx(s.buttonMic, s.buttonLoading)}>
          <div className={s.loading}>
            <Lottie {...defaultOptions} animationData={ResponseLoading} />
          </div>
        </div>
      ) : (
        <button
          className={s.buttonMic}
          onClick={onClickStart}
          disabled={isActive ? isActive : isLoading}
        >
          <div>
            {isActive ? CHAT_COMPONENTS_CONFIG.svg.disabled : CHAT_COMPONENTS_CONFIG.svg.mic}
          </div>
        </button>
      )}
    </>
  )
}
