import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import AuthService from 'services/authService'

import Loading from 'components/common/Loading'

const Logout = () => {
  const location = useLocation()
  const currentPath = location.search.split('returnUrl=')[1]
  const LogoutAction = () => {
    AuthService.signOut(window.location.replace(decodeURIComponent(currentPath)))
  }
  useEffect(() => {
    LogoutAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section style={{ position: 'relative', height: '60rem' }}>
      <Loading />
    </section>
  )
}

export default Logout
