import React, { useState } from 'react'

import api from 'common/api'
import * as config from 'common/config'

import Password from '../Password'
import '../../assets/styles/modal.scss'

export default function ModalPassword({ btnClose, handleCloseModal }) {
  const [pwdComplete, setPwdComplete] = useState(false)
  const [newPassword, setNewPassword] = useState(null)
  const [currentPassword, setCurrentPassword] = useState(null)
  const [pwdCheckResult, setPwdCheckResult] = useState(null)

  const handleNewPwdComplete = (status, newPwd, currentPwd) => {
    setPwdComplete(status)
    setNewPassword(newPwd)
    setCurrentPassword(currentPwd)
  }

  const postEditPassword = () => {
    const data = {
      currentPassword: currentPassword,
      newPassword: newPassword
    }

    api
      .put(config.AUTH_API_HOST + '/auth/v2/user/verify-and-edit-password', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          setPwdCheckResult(false)
        } else {
          setPwdCheckResult(true)
          alert('비밀번호 변경이 완료되었습니다.')
          window.location.reload()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <span className="blind">닫기</span>
            </button>
          )}
          <div className="modal-header">
            <strong>비밀번호 변경</strong>
          </div>
          <div className="modal-content">
            <div className="modal-form-col password">
              <form name="passwordFrm" action="#">
                <Password
                  currentPwd
                  handleNewPwdComplete={handleNewPwdComplete}
                  pwdCheckResult={pwdCheckResult}
                />
                <div className="btn-flex-form">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleCloseModal}
                    style={{ borderColor: '#CFCFDA' }}
                  >
                    <span>취소</span>
                  </button>
                  <button
                    type="button"
                    className="btn active"
                    disabled={pwdComplete ? false : true}
                    onClick={postEditPassword}
                  >
                    <span>확인</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
