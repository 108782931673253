import { useMediaQuery } from 'react-responsive'

import ItemStore from './ItemStore'

const StoreGroupClassGroupList = ({ title, storeListData, detailLink, moreBtnUseYn, styles }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  return (
    <section className="class-list-col" style={styles}>
      <div className="inner-col">
        <ItemStore
          title={title}
          storeData={isMobile ? storeListData.slice(0, 4) : storeListData}
          detailLink={detailLink}
          moreBtnUseYn={moreBtnUseYn}
        />
      </div>
    </section>
  )
}

export default StoreGroupClassGroupList
