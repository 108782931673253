import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'

import styles from './Product.module.scss'
import TabQna from './TabQna'
import Lnb from '../../../components/Lnb'
import NoContent from '../../../components/NoContent'
import Pagination from '../../../components/Pagination'
const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_qna.png'

export default function Product() {
  const navigate = useNavigate()
  const [pageIdx, setPageIdx] = useState(1)
  const [productData, setProductData] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [dataKeyword, setDataKeyword] = useState({
    period: 3,
    reply: 'ALL'
  })

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  const handlePeriodCheck = (e) => {
    const targetValue = e.target.value
    setPageIdx(1)
    setDataKeyword({ ...dataKeyword, period: targetValue })

    getInquiryData(targetValue, dataKeyword.reply)
  }

  const handleReplyStatusCheck = (e) => {
    const targetValue = e.target.value
    setPageIdx(1)
    setDataKeyword({ ...dataKeyword, reply: targetValue })

    getInquiryData(dataKeyword.period, targetValue)
  }

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const getInquiryData = (period, replyStatus) => {
    ;(replyStatus === 'ALL' && period === 'ALL'
      ? api.get(`/v2/package-question/user/list?index=${pageIdx}&size=10`)
      : period === 'ALL' && replyStatus !== 'ALL'
      ? api.get(
          `/v2/package-question/user/list?index=${pageIdx}&size=10&keys=replyStatus&types=equal&values=${replyStatus}`
        )
      : period !== 'ALL' && replyStatus === 'ALL'
      ? api.get(
          `/v2/package-question/user/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}`
        )
      : api.get(
          `/v2/package-question/user/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}&keys=replyStatus&types=equal&values=${replyStatus}`
        )
    )
      .then((response) => {
        setProductData(response.data.data.content)
        setTotalPages(response.data.data.totalPages)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getInquiryData(dataKeyword.period, dataKeyword.reply)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIdx])

  return (
    <div className="qna-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader title="상품 문의" onClick={() => navigate('/mypage/1')} />
          <Lnb />
          <section className={styles.qnaListSection}>
            <TabQna activeTab={'PRODUCT'} />
            <div>
              <div className={styles.searchBox}>
                <ul className={styles.searchTxtList}>
                  <li className={styles.searchTxtItem}>
                    답변이 등록된 후에는 문의를 수정 또는 삭제하실 수 없습니다.
                  </li>
                  <li className={styles.searchTxtItem}>
                    상품 문의는 해당 상품 상세 페이지에서 등록 가능합니다.
                  </li>
                </ul>
                <div className={styles.searchSelectBox}>
                  <select
                    name="inquiryPeriod"
                    id="inquiryPeriod"
                    value={dataKeyword.period}
                    onChange={handlePeriodCheck}
                  >
                    <option value="ALL">전체</option>
                    <option value="3">3개월</option>
                    <option value="6">6개월</option>
                    <option value="12">12개월</option>
                  </select>
                  <select
                    name="replyStatus"
                    id="replyStatus"
                    value={dataKeyword.reply}
                    onChange={handleReplyStatusCheck}
                  >
                    <option value="ALL">전체</option>
                    <option value="Y">답변 완료</option>
                    <option value="N">답변 대기</option>
                  </select>
                </div>
              </div>
              <Desktop>
                <table
                  className={styles.tableBox}
                  style={{ borderBottom: productData.length > 0 ? '0.1rem solid #b7b7c8' : 'none' }}
                >
                  <colgroup>
                    <col width="24%" />
                    <col width="" />
                    <col width="20%" />
                    <col width="12%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>상품명</th>
                      <th>문의 내용</th>
                      <th>작성일</th>
                      <th>답변여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productData.length > 0 ? (
                      productData.map((productItem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className="ellipsis">{productItem.packageTitle}</p>
                            </td>
                            <td>
                              <Link to={`/mypage/qna/product/detail/${productItem.questionSeq}`}>
                                <strong className="ellipsis">{productItem.contents}</strong>
                              </Link>
                            </td>
                            <td>
                              <p>{formatDate(productItem.registDate)}</p>
                            </td>
                            <td>
                              <p
                                className={
                                  styles.check +
                                  (productItem.replyStatus === 'Y' ? ' ' + styles.completed : '')
                                }
                              >
                                {productItem.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                              </p>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">
                          <NoContent icoImg={icoImg} titleMsg={'등록된 문의가 없습니다.'} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Desktop>
              <Mobile>
                <div className="table-form-mobile">
                  {productData.length > 0 ? (
                    <ul className="table-lists">
                      {productData.map((productItem, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/mypage/qna/product/detail/${productItem.questionSeq}`}>
                              <span className="ellipsis">{productItem.packageTitle}</span>
                              <strong className="ellipsis line-clamp-2">
                                {productItem.contents}
                              </strong>
                              <div className="info">
                                <span
                                  className={
                                    'check' + (productItem.replyStatus === 'Y' ? ' completed' : '')
                                  }
                                >
                                  {productItem.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                                </span>
                                <span className="date">
                                  {productItem.registDate.substring(0, 10)}
                                </span>
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <NoContent icoImg={icoImg} titleMsg={'등록된 문의가 없습니다.'} />
                  )}
                </div>
              </Mobile>

              {productData.length > 0 && (
                <Pagination
                  type={'IDX'}
                  handleCurrentIdx={handleCurrentIdx}
                  totalPages={totalPages}
                  limitPage={5}
                  maxNum={5}
                  minNum={0}
                  pageIdx={pageIdx}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
