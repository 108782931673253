import { useState } from 'react'

import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'
import useLevelTestAdvancedStore from 'store/levelTestAdvanced/useLevelTestAdvancedStore'

import { questionValues } from 'pages/levelTestAdvanced/config'

import PartIntro from './PartIntro'
import Question from './Question'
import ProgressBar from '../ProgressBar'
import QuestionNavigation from '../QuestionNavigation'

export default function QuestionSection() {
  const [partIndex, setPartIndex] = useState(1)
  const [isIntro, setIsIntro] = useState(true)

  const {
    questionIndex,
    startTime,
    onChangeStartTime,
    onChangeLeadTime,
    onChangeCurrentSection,
    onChangeQuestionIndex
  } = useLevelTestAdvancedExamStore()
  const onChangeIsInitial = useLevelTestAdvancedStore((state) => state.onChangeIsInitial)

  const handleClickStart = () => {
    setIsIntro(false)
    if (questionIndex === 1) {
      onChangeStartTime(new Date())
      onChangeIsInitial(false)
    } else {
      onChangeQuestionIndex(questionIndex + 1)
    }
  }

  const handleClickPrev = () => {
    onChangeQuestionIndex(questionIndex - 1)
  }

  const handleChangeLeadTime = () => {
    const diff = new Date().getTime() - new Date(startTime).getTime()
    const leadTime = Math.trunc(diff / 1000)
    onChangeLeadTime(leadTime)
  }

  const handleClickNext = () => {
    switch (questionIndex) {
      case 9:
      case 13: {
        // 문제완료 > 파트 인트로
        setIsIntro(true)
        setPartIndex((prev) => prev + 1)
        break
      }
      case 20: // 테스트 문제 완료 > 자가진단
        onChangeCurrentSection('selfCheck')
        handleChangeLeadTime()
        break
      default: //테스트 다음 문제로 이동
        onChangeQuestionIndex(questionIndex + 1)
        break
    }
  }

  return isIntro ? (
    <PartIntro partIndex={partIndex} onClickStart={handleClickStart} />
  ) : (
    <>
      <ProgressBar />
      <Question>
        {
          {
            basic: <Question.Basic />, // 기본형 유형
            blank: <Question.Blank />, //영어 빈칸에 들어갈 단어 선택 유형
            completeSentence: <Question.CompleteSentance />, // A,B 대화 유형
            conversation: <Question.Conversation />, // 문장완성 유형
            listening: <Question.Listening />, // 듣기 유형
            video: <Question.Video /> // 비디오 유형
          }[questionValues[questionIndex]?.type]
        }
      </Question>
      <QuestionNavigation onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
    </>
  )
}
