import styles from './YndButton.module.scss'

export default function YndButton({ size, name, btnTheme, style, onClick }) {
  return (
    <button
      className={`${styles.customButton} ${styles[size]} ${btnTheme === 'dark' ? styles.dark : ''}`}
      style={style}
      onClick={onClick}
    >
      {name}
    </button>
  )
}
