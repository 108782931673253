import { useState, useCallback, useRef } from 'react'

import api from 'common/api'

import AlertModal from './AlertModal'
import ModalPortal from '../../ModalPortal'

import '../../assets/styles/modal.scss'
import '../../assets/styles/coupon.scss'

const ModalVoucherRegister = ({ btnClose, handleCloseModal }) => {
  const voucherRef = useRef()
  const [warning, setWarning] = useState(false)
  const [warningText, setWarningText] = useState('')
  const [btn, setBtn] = useState(false)
  const [voucherPublishCode, setVoucherPublishCode] = useState('')
  const [modalopen, setModalopen] = useState(false)

  const handleOpenModal2 = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
    window.location.reload()
  }, [])

  const handleValidVoucher = (e) => {
    let value = e.target.value
    let regCheck = /^[A-Za-z0-9+]{1,21}$/

    setVoucherPublishCode(value)

    if (regCheck.test(value) && value.length > 0) {
      setBtn(true)
      setWarning(false)
    } else {
      setBtn(false)
      setWarningText('유효하지 않은 코드입니다.')
      setWarning(true)
    }
  }

  const handleVoucherCheck = (e) => {
    e.preventDefault()

    const data = {
      voucherPublishCode: voucherPublishCode
    }

    api
      .post(`/v2/voucher-publish?voucherPublishCode=${data.voucherPublishCode}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
        } else {
          // alert('수강권 등록이 완료되었습니다!');
          setVoucherPublishCode('')
          handleOpenModal2()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>수강권 등록</strong>
          </div>
          <div className="modal-content type-fixed-button register">
            <div className="modal-center-box">
              <div className="modal-center-inner">
                <div className="register-box" style={{ marginTop: '10rem' }}>
                  <input
                    type="text"
                    className="coupon-code"
                    maxLength="20"
                    placeholder="수강권 코드를 입력해주세요."
                    ref={voucherRef}
                    onChange={handleValidVoucher}
                    value={voucherPublishCode}
                  />
                  <span className={'warning' + (warning === true ? ' active' : '')}>
                    {warningText}
                  </span>
                </div>
                <div className="coupon-notice">
                  <strong>수강권 등록 안내</strong>
                  <ul>
                    <li>수강권 등록과 동시에 수강이 가능합니다.</li>
                    <li>정해진 수강기간까지만 사용 가능하며, 기간연장은 불가능합니다.</li>
                    <li>한번 등록된 수강권은 취소 및 재사용이 불가능합니다.</li>
                    <li>수강권은 등록 유효기간 내 등록 시 사용이 가능합니다.</li>
                    <li>등록 유효기간이 지난 수강권은 자동으로 소멸되며, 재발급 되지 않습니다.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="btn-flex-form">
              <button
                className={'btn' + (btn === true ? ' active' : ' disabled')}
                onClick={handleVoucherCheck}
              >
                <span>수강권 등록하기</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalopen ? (
        <ModalPortal>
          <AlertModal
            btnGroupLink
            btnGroupLinkTxt={'지금 확인하기'}
            titleMsg={'수강권이 등록되었습니다.'}
            msg={'MY클래스에서 등록된 클래스를 확인하실 수 있습니다.'}
            handleCloseModal={handleCloseModal2}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}

export default ModalVoucherRegister
