import React from 'react'
import { Helmet } from 'react-helmet-async'

const MetaInfo = ({ props }) => {
  const baseMetaInfo = {
    title: '하루 10분 야나두',
    description: '매일 하루 10분 습관의 힘으로 당신의 인생을 멋지게 바꿔보세요',
    imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/common/img_og_yanadoo.png',
    site_name: '하루 10분 야나두'
  }

  const metaCheck = (key) => {
    return props && !!props[key] ? props[key] : baseMetaInfo[key]
  }
  return (
    <>
      <Helmet>
        <title>{metaCheck('title')}</title>
        <meta name="description" content={metaCheck('description')} />\
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaCheck('title')} />
        <meta property="og:site_name" content={metaCheck('site_name')} />
        <meta property="og:description" content={metaCheck('description')} />
        <meta property="og:image" content={metaCheck('imgsrc')} />
        <meta name="twitter:title" content={metaCheck('title')} />
        <meta name="twitter:description" content={metaCheck('description')} />
        <meta name="twitter:image" content={metaCheck('imgsrc')} />
      </Helmet>
    </>
  )
}

export default MetaInfo
