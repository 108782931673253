import React from 'react'
import Lottie from 'react-lottie-player'

import cx from 'classnames'

import s from './ChatFooterAudio.module.scss'
import { CHAT_COMPONENTS_CONFIG } from './config'
import LottieData from './VoiceLoaded.json'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const getAfeterfix = (name) => {
  let afterfix = ''
  switch (name) {
    case 'Sam':
      afterfix = '이'
      break
    case 'Rachael':
      afterfix = '이'
      break
    case 'Tom':
      afterfix = '이'
      break
    default:
      afterfix = '가'
      break
  }

  return afterfix
}

export default function RecorderTooltip({ isListening, isLoading, name = '', msg, runTimeout }) {
  return (
    <>
      <div className={cx(s.tooltip, { [s.hide]: isListening || isLoading })}>
        {CHAT_COMPONENTS_CONFIG.svg.tooltip.recorder}
      </div>
      {isListening && (
        <div className={s.listeningTooltip}>
          <div className={s.tooltipInner}>
            <div className={s.loading}>
              <Lottie {...defaultOptions} animationData={LottieData} />
            </div>
            <span className={cx(s.text, { [s.nowrap]: runTimeout })}>
              {msg ? msg : runTimeout ? '재시작 또는 취소 버튼을 눌러주세요.' : '듣고 있어요.'}
            </span>
          </div>
        </div>
      )}
      {isLoading && (
        <div className={s.listeningTooltip}>
          <span className={cx(s.text, s.nowrap)}>
            {name && name !== '' ? `${name}${getAfeterfix(name)}` : 'AI가'} 답변을 작성하고 있어요.
          </span>
        </div>
      )}
    </>
  )
}
