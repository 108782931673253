import { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'

import ButtonAudio from 'pages/aiTalk/components/ButtonAudio'
import TranslateBtn from 'pages/aiTalk/components/TranslateBtn'
import LottieData from 'pages/aiTalk/lottieData.json'
import useChatStateStore from 'pages/aiTalk/store/useChatStateStore'

import s from './Chat.module.css'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function TypingMessage({
  text,
  aiInfo,
  userSeq,
  mode,
  controls,
  handleProfileModal
}) {
  const { images, info, key } = aiInfo
  const { setChatState } = useChatStateStore()

  // translate
  const [translateText, setTranslateText] = useState(null)
  const [transLoading, setTransLoading] = useState(false)
  const [translated, setTranslated] = useState(false)

  useEffect(() => {
    setChatState(true)
  }, [])

  return (
    <div className={s.aiMessage}>
      <div className={s.msgThumb} onClick={handleProfileModal}>
        <img src={images.circle} alt={info.fullname} />
      </div>
      <dl>
        <dt className={s.msgName}>{info.firstname}</dt>
        <dd className={s.message}>
          {text}
          {controls && (
            <div className={s.btnGroup}>
              <ButtonAudio
                text={text}
                promptType={key}
                userSeq={userSeq}
                autoplay={mode === 'audio'}
              />
              <TranslateBtn
                type={'AI'}
                message={text}
                translated={translated}
                translateText={translateText}
                setTransLoading={setTransLoading}
                setTranslated={setTranslated}
                setTranslateText={setTranslateText}
              />
            </div>
          )}
        </dd>
        {transLoading && !translateText && (
          <dd className={s.translateText}>
            <div className={s.lottieBox}>
              <Lottie {...defaultOptions} animationData={LottieData} />
            </div>
          </dd>
        )}
        {translateText && translated && <dd className={s.translateText}>{translateText}</dd>}
      </dl>
    </div>
  )
}
