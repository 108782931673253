import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import { v4 as uuid } from 'uuid'

import LevelTestCounsel from 'components/promotion/dbCollectionForm/components/LevelTestCounsel'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import Button from './common/Button'
import useSetCategoryGrade from '../hooks/useSetCategoryGrade'
import useSetCategoryStats from '../hooks/useSetCategoryStats'
import styles from '../LevelTestAdvancedResult.module.scss'

export default function Grading({ userName, onSubmitConsultation }) {
  const navigate = useNavigate()
  const { test, correctCount } = useLevelTestAdvancedResultStore()
  const { categoryStats } = useSetCategoryStats(test)
  const { grade } = useSetCategoryGrade(categoryStats)

  // 상담 신청 팝업
  const gradeRef = useRef(null)
  const [isFirstIntersecting, setIsFirstIntersecting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleScroll = useCallback((entries) => {
    if (entries[0].isIntersecting) {
      setIsModalOpen(true)
      setIsFirstIntersecting(true)
    }
  }, [])

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  useLayoutEffect(() => {
    if (!gradeRef.current && isFirstIntersecting) return

    const observer = new IntersectionObserver(handleScroll, { threshold: 1 })

    if (!isFirstIntersecting) observer.observe(gradeRef.current)
    else observer.unobserve(gradeRef.current)

    return () => {
      observer.disconnect()
    }
  }, [isFirstIntersecting, handleScroll])

  return (
    <>
      <section className={styles.grading} ref={gradeRef}>
        <hgroup className={styles.hgroup}>
          <h6>20문제 중</h6>
          <h3>{correctCount}문제를 맞췄어요</h3>
        </hgroup>

        <ul>
          {grade.map((item, idx) => (
            <li key={uuid()}>
              <p>Part {idx + 1}</p>
              <h5>{item.category}</h5>
              <h3>
                {item.correct}
                <span>/{item.amount}</span>
              </h3>

              <figure>
                <img src={item.src} alt={item.badge} />
              </figure>
            </li>
          ))}
        </ul>

        <Button
          text="정답 상세보기"
          onClick={() => {
            navigate('/levelTestAdvanced/answer')
          }}
        />
      </section>

      {isModalOpen && (
        <ModalWrap>
          <LevelTestCounsel
            type="popup"
            userName={userName}
            onSubmitConsultation={onSubmitConsultation}
            onClose={handleModalClose}
          />
        </ModalWrap>
      )}
    </>
  )
}
