import React, { useState, useEffect } from 'react'

import api from 'common/api'

import styles from './HomeReviewSection.module.scss'
import SectionTitle from './SectionTitle'
import { setDefaultItemCount, BtnMoreAllList } from '../../../components/BtnMoreAllList'

export default function HomeReviewSection() {
  const [isLoading, setIsLoading] = useState(true)
  const [reviewInfo, setReviewInfo] = useState([])
  const [addReviewList, setAddReviewList] = useState([])
  const [categoryData, setCategoryData] = useState({
    category: 'ALL'
  })
  const [reviewData, setReviewData] = useState({
    list: [],
    totalCount: 0
  })

  const handleAddReviewList = (data) => {
    setAddReviewList(data)
  }

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const getReviewInfo = () => {
    api
      .get(`v2/review/real-time/info`)
      .then((response) => {
        setReviewInfo(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getReviewList = (category) => {
    api
      .get(`v2/review/real-time/list?categoryCode=${category}`)
      .then((response) => {
        let responseData = response.data.data

        setReviewData({
          ...reviewData,
          list: responseData,
          totalCount: responseData.length
        })
        setDefaultItemCount(3, responseData.length)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleChangeCategory = (e) => {
    const targetValue = e.target.value
    setIsLoading(true)
    setCategoryData({ ...categoryData, category: targetValue })
    getReviewList(targetValue)
  }

  const ReviewTemp = ({ reviewInfo, addReviewList }) => {
    return (
      <section className={styles.reviewCol}>
        <h4>
          <span>다양한 후기를 확인해 보세요</span>
          <select
            name="categorySct"
            id="categorySct"
            value={categoryData.category}
            onChange={handleChangeCategory}
          >
            {reviewInfo &&
              reviewInfo.map((item) => (
                <option value={item.categoryCode} key={item.categoryCode}>
                  {item.categoryName}
                </option>
              ))}
          </select>
        </h4>
        <ul>
          {addReviewList?.length > 0 &&
            addReviewList
              .filter((list) => list?.satisfaction === 5)
              .map((item, idx) => (
                <li key={idx}>
                  <ReviewItems item={item} />
                </li>
              ))}
        </ul>
      </section>
    )
  }

  const ReviewItems = ({ item }) => {
    const [reviewDetail, setReviewDetail] = useState(false)

    return (
      <>
        <div>
          <p>
            <strong>{item.title}</strong>
          </p>
          <div className={styles.starPoint}>
            <span style={{ width: `${item.satisfaction && item.satisfaction * 20}%` }}>
              star point
            </span>
          </div>
        </div>
        <div className={reviewDetail ? styles.active : ''}>
          <p>{item.content}</p>
        </div>
        <div>
          <em>{item.userName}</em>
          <button type="button" onClick={() => setReviewDetail(!reviewDetail)}>
            {item.content.length >= 100 && <span>{reviewDetail ? '접기' : '더보기'}</span>}
          </button>
        </div>
      </>
    )
  }

  const SatisfactionTemp = ({ level, per }) => {
    return (
      <div className={styles.satisfactionGraph}>
        <div className={styles.starPoint}>
          <span style={{ width: `${level ? level * 20 : 0}%` }}>star level</span>
        </div>
        <div>
          <span style={{ width: `${per ? per : 0}%` }}>star point</span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    reviewInfo && getReviewInfo()
    reviewData && getReviewList(categoryData.category)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={styles.homeReviewCol}>
      <div className={styles.innerCol}>
        <SectionTitle
          title={'수강생들의 진심을 담은\n실제 후기 입니다.'}
          desc={`${String(reviewInfo.totalCount).substring(
            0,
            2
          )}만 명의 실제 수강생들의 후기가\n '할 수 있다'는 자신감을 쌓아 드립니다.`}
        />
        <div className={styles.gradeCol}>
          <dl>
            <dt>수강생 실제 후기</dt>
            <dd>
              <strong>{reviewInfo.totalCount && addComma(parseInt(reviewInfo.totalCount))}</strong>
            </dd>
          </dl>
          <dl>
            <dt>만족도</dt>
            <dd>
              <strong>
                {reviewInfo.satisfactionAverage && parseFloat(reviewInfo.satisfactionAverage)}
              </strong>
            </dd>
          </dl>
          <dl>
            <dt>만족도 비율</dt>
            <dd>
              <SatisfactionTemp level={5} per={reviewInfo.fiveSatisfactionPercent} />
              <SatisfactionTemp level={4} per={reviewInfo.fourSatisfactionPercent} />
              <SatisfactionTemp level={3} per={reviewInfo.threeSatisfactionPercent} />
              <SatisfactionTemp level={2} per={reviewInfo.twoSatisfactionPercent} />
              <SatisfactionTemp level={1} per={reviewInfo.oneSatisfactionPercent} />
            </dd>
          </dl>
        </div>
        <ReviewTemp
          reviewInfo={reviewInfo.realTimeReviewCategoryItems}
          addReviewList={addReviewList}
        />
        <BtnMoreAllList
          reviewData={reviewData}
          isLoading={isLoading}
          handleAddReviewList={handleAddReviewList}
        />
      </div>
    </section>
  )
}
