import '../../assets/styles/modal.scss'

import styles from './ModalEntSale.module.scss'

const ModalEntSale = ({ btnClose, data, MINIMUM_PRICE, MINIMUM_MONTH, handleCloseModal }) => {
  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return (
    <div className="modal-wrap share-modal">
      <div className="dimmed" onClick={handleCloseModal}></div>
      <div className="modal-inner">
        {btnClose && (
          <button type="button" className={styles.btnModalClose} onClick={handleCloseModal}>
            <span>닫기</span>
          </button>
        )}
        <div className={styles.saleInfoBox}>
          <div className={styles.modalHeader}>
            <strong>입학회원 할인 안내</strong>
          </div>
          <div className={styles.modalContent}>
            <dl>
              <dt>정가</dt>
              <dd>
                <span>{addComma(Number(data.exceptOptionSalePrice))}</span>
              </dd>
            </dl>
            <dl>
              <dt>입학회원 할인 금액</dt>
              <dd>
                <span>
                  &ndash;{' '}
                  {addComma(
                    Number(data.exceptOptionSalePrice - data.exceptOptionMembershipSalePrice)
                  )}
                </span>
              </dd>
            </dl>
            <dl>
              <dt>최종 금액</dt>
              <dd>
                {data && data.exceptOptionSalePrice !== data.exceptOptionMembershipSalePrice ? (
                  <span>{addComma(Number(data.exceptOptionMembershipSalePrice))}</span>
                ) : (
                  <span>{addComma(Number(data.exceptOptionSalePrice))}</span>
                )}
              </dd>
            </dl>
            {data.exceptOptionMembershipSalePrice >= MINIMUM_PRICE &&
              data.installmentMonth >= MINIMUM_MONTH && (
                <dl>
                  <dt>{data.installmentMonth}개월 할부</dt>
                  <dd>
                    {data.exceptOptionSalePrice !== data.exceptOptionMembershipSalePrice ? (
                      <>
                        <mark>{data.exceptOptionDiscountPercent}&#37;</mark>
                        <strong>{addComma(Number(data.monthlyMembershipSalePrice))}</strong>
                      </>
                    ) : (
                      <strong>{addComma(Number(data.monthlySalePrice))}</strong>
                    )}
                  </dd>
                </dl>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalEntSale
