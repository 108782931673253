import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import api from 'common/api'
import { API_HOST } from 'common/config'
import { ONLY_NUMBER_REGEXR } from 'common/regExr'
import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'
import useLevelTestAdvancedStore from 'store/levelTestAdvanced/useLevelTestAdvancedStore'

export default function useSetDataToStore() {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const navigate = useNavigate()

  const { search } = useLocation()
  const seq = search.replace(ONLY_NUMBER_REGEXR, '')

  const storeData = useLevelTestAdvancedStore()
  const { setData, setStoreData, isResultStoreSet } = useLevelTestAdvancedResultStore()

  useEffect(() => {
    // 문제 풀이 스토어에 값이 없을 경우 심화레벨테스트 랜딩페이지로 이동
    if (storeData.isInitial || !seq) {
      window.alert('잘못된 접근입니다.')
      navigate('/levelTestAdvanced/landing')
      return
    }

    // 결과 스토어에 값이 이미 할당된 경우 ex) 정답 해설 페이지에서 뒤로가기
    if (isResultStoreSet) {
      setIsLoading(false)
      return
    }

    // 첫 결과 페이지 랜딩 시 API요청, 문제 풀이 스토어 데이터와 함께 결과 스토어에 할당
    const fetchData = async () => {
      try {
        // throw Error('Error Occurred!')

        const data = await api
          .get(`${API_HOST}/v2/level-test/deepen/${seq}`)
          .then((res) => res.data.data)
        setData(data)
        setStoreData(storeData)
        // console.log('data : ', data)
        // console.log('storeData : ', storeData)
      } catch (error) {
        console.error(error)
        setIsError(true)
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 700)
      }
    }

    fetchData()
  }, [seq, setData, setStoreData, storeData, navigate, isResultStoreSet])

  return { isLoading, isError }
}
