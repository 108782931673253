import { useState, useRef, useEffect } from 'react'

import api from 'common/api'

import '../assets/styles/certified.scss'

export default function Certified({
  type,
  time,
  timerStart,
  handleCertifiedComplete,
  reset,
  checkCertUrl,
  handleToastStatus
}) {
  // 1. type: EXCLUSIVE 인경우 API url 분기처리
  // 2. time: 인증번호 시간
  // 3. timerStart: 인증번호&재전송 클릭시마다 상태값+1 업데이트한값 전달
  // * 상태값이 변경될때마다 타이머 시작 함수 호출
  // 4. handleCertifiedComplete: 인증 완료시 true를 부모에게 전달
  // 5. reset: 전달한 상태값이 변경될때마다 타이머 초기화 시켜줌
  // 6. checkCertUrl: 인증 번호 체크 API url이 각각 다르기 때문에 전달받아 사용
  // 7. handleToastStatus: 인증 완료 후 토스트 팝업 여는 함수
  // 부모 컴포넌트에 필요한 상태값 및 함수: certifiedComplete, certifiedCodeShow, timerStart, handleCertifiedComplete(), checkCertUrl
  // * ModalJoin.jsx, ModalDormantAccounts.jsx 참고

  const certifiedCodeRef = useRef()
  const certifiedCodeTime = useRef(time)
  const defaultMin = parseInt(certifiedCodeTime.current / 60)
  const defaultSec = certifiedCodeTime.current % 60

  const [timerShow, setTimerShow] = useState(false)
  const [timerMin, setTimerMin] = useState(null)
  const [timerSec, setTimerSec] = useState(null)
  const [codeStatus, setCodeStatus] = useState(false)
  const [codeValue, setCodeValue] = useState('')

  // 인증번호 클릭시 타이머 시작
  const handleStartTimer = () => {
    setCodeStatus(false)
    setCodeValue('')
    setTimerShow(true)
    certifiedCodeTime.current = time
    setTimerMin(defaultMin < 10 ? '0' + defaultMin : defaultMin)
    setTimerSec(defaultSec < 10 ? '0' + defaultSec : defaultSec)
  }

  // 인증번호 유효성 체크
  const handleCertifiedValue = (e) => {
    let codeRegExp = /^[0-9]*$/

    if (!codeRegExp.test(e.target.value)) {
      certifiedCodeRef.current.focus()

      return false
    }

    if (!e.target.value) {
      setCodeValue('')
      setCodeStatus(false)

      return false
    }

    setCodeValue(e.target.value)

    return true
  }

  const checkCertNumber = () => {
    ;(type === 'EXCLUSIVE'
      ? api.post(checkCertUrl + '?certNumber=' + codeValue)
      : api.post(checkCertUrl + '&certNumber=' + codeValue)
    )
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        type === 'EXCLUSIVE' ? alert('인증이 완료되었습니다.') : handleToastStatus(true)

        setCodeStatus(true)
        handleCertifiedComplete(true)
        setTimerShow(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 인증번호 타이머
  useEffect(() => {
    if (timerShow) {
      const isTimer = setInterval(() => {
        if (!codeStatus.certifiedCode && certifiedCodeTime.current >= 0) {
          let min = parseInt(certifiedCodeTime.current / 60)
          let sec = certifiedCodeTime.current % 60

          setTimerMin(min < 10 ? '0' + min : min)
          setTimerSec(sec < 10 ? '0' + sec : sec)
          certifiedCodeTime.current -= 1
        } else {
          setTimerShow(false)
          clearInterval(isTimer)
        }
      }, 1000)

      return () => clearInterval(isTimer)
    }
  }, [timerShow, timerSec, reset])

  // props로 전달받은 상태값이 변경될때마다 타이머 초기화
  useEffect(() => {
    certifiedCodeTime.current = time
    setTimerMin(defaultMin < 10 ? '0' + defaultMin : defaultMin)
    setTimerSec(defaultSec < 10 ? '0' + defaultSec : defaultSec)
    setCodeValue('')
    setCodeStatus(false)
    setTimerShow(false)
  }, [reset])

  // 재전송 버튼 클릭시 타이머 재시작
  useEffect(() => {
    if (timerStart > 0) {
      handleStartTimer()
    }
  }, [timerStart])

  return (
    <>
      <div className={'certified-code-form' + (codeStatus ? ' disabled' : '')}>
        <span className="sub-title">인증번호</span>
        <input
          type="text"
          placeholder="인증번호 입력"
          name="certifiedCode"
          ref={certifiedCodeRef}
          value={codeValue}
          onChange={handleCertifiedValue}
          readOnly={codeStatus ? true : false}
        />
        {timerShow && (
          <em className="timer">
            {timerMin} : {timerSec}
          </em>
        )}
        <button
          type="button"
          className={
            'btn btn-certified-code' + (codeValue.length > 0 && timerShow ? ' active' : ' disabled')
          }
          disabled={codeValue.length > 0 && timerShow ? false : true}
          onClick={checkCertNumber}
        >
          <span>인증</span>
        </button>
      </div>
    </>
  )
}
