import { useLayoutEffect } from 'react'

import { StaggerMotion } from 'components/promotion/utile/utile'

/**
 * IntersectionObserver와 함께 타겟 엘리먼트의 순차적 모션 효과를 처리하는 커스텀 훅
 * @param {React.RefObject} ref - 타겟 엘리먼트에 대한 ref
 * @param {number} delayTime - 모션 효과의 지연 시간 (ms)
 * @param {string} activeClassName - 모션 활성 시 추가되는 클래스 명
 * @param {string} [rootMargin=''] - 콜백을 트리거하기 위한 뷰포트 여백
 * @param {number} [threshold=0] - 콜백을 트리거하기 위해 루트 요소 내에 표시되어야 하는 대상 요소의 백분율을 나타내는 0과 1 사이의 숫자
 * @returns {void}
 */
export default function useStaggerMotion(
  ref,
  delayTime,
  activeClassName,
  rootMargin = '',
  threshold = 0,
  isOnce = false
) {
  const staggerInstance = new StaggerMotion(ref, delayTime, activeClassName, isOnce)
  useLayoutEffect(() => {
    /**
     * IntersectionObserver에서 모션을 토글하는 콜백 함수
     * @param {IntersectionObserverEntry[]} els - IntersectionObserver의 엔트리의 배열
     */
    const toggleStagger = (els) => {
      els.forEach((el) => {
        if (el.isIntersecting) {
          staggerInstance.staggerOn(ref)
        } else {
          staggerInstance.staggerOff(ref)
        }
      })
    }

    // IntersectionObserver를 생성
    const observer = new IntersectionObserver(toggleStagger, { rootMargin, threshold })

    // 타겟을 관찰
    observer.observe(ref.current)

    // 옵저버를 해제하고 모션을 비활성화
    return () => {
      observer.disconnect()
      staggerInstance.staggerOff(ref)
    }
  }, [staggerInstance, ref, rootMargin, threshold])
}
