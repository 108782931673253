import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import { convertHtmlCode } from 'utils/convertHtmlCode'

import api from 'common/api'
import MobileHeader from 'components/common/MobileHeader'
import NoContent from 'components/NoContent'
import Pagination from 'components/Pagination'

import 'assets/styles/notice.scss'

export default function Notice() {
  const navigate = useNavigate()
  const { currentSeq } = useParams()
  const [noticeData, setNoticeData] = useState([])
  const [totalPages, setTotalPages] = useState()

  const getNoticeData = useCallback(() => {
    api
      .get(`/v2/notice/list?index=` + currentSeq + `&size=10`)
      .then((response) => {
        const dataArr = response.data.data.content.map((item) => ({
          ...item,
          title: convertHtmlCode(item.title)
        }))
        setNoticeData(dataArr)
        setTotalPages(response.data.data.totalPages)
        convertHtmlCode(response.data.data.content[2].title)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [currentSeq])

  const onChangeDash = (date) => {
    return date.replace(/-/g, '.')
  }

  useEffect(() => {
    getNoticeData()
  }, [currentSeq, getNoticeData])

  return (
    <div className="notice-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader
            title="공지사항"
            onClick={() => (document.referrer === '' ? navigate('/mypage/1') : navigate(-1))}
          />
          <section className="notice-table-col">
            <h3>공지사항</h3>
            <table className="table-form">
              <colgroup>
                <col width="10%" />
                <col width="" />
                <col width="20%" />
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>제목</th>
                  <th>등록일</th>
                </tr>
              </thead>
              <tbody>
                {noticeData.length > 0 ? (
                  noticeData.map((noticeItem, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <p className="ellipsis">
                            {noticeItem.fixYn === 'Y' ? '공지' : noticeItem.serviceNoticeSeq}
                          </p>
                        </td>
                        <td className="tal">
                          <Link
                            to={{
                              pathname: `/service/notice/detail/${noticeItem.serviceNoticeSeq}`
                            }}
                          >
                            <strong
                              style={{ display: 'inline-block' }}
                              className={
                                'ellipsis' + (noticeItem.newNoticeYn === 'Y' ? ' ico-new' : '')
                              }
                            >
                              {noticeItem.title}
                            </strong>
                          </Link>
                        </td>
                        <td>
                          <p>{onChangeDash(noticeItem.registDate.substring(0, 10))}</p>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan="3">
                      <NoContent titleMsg={'등록된 공지사항이 없습니다.'} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="table-form-mobile">
              {noticeData.length > 0 ? (
                <ul className="table-lists">
                  {noticeData.map((noticeItem, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={{
                            pathname: `/service/notice/detail/${noticeItem.serviceNoticeSeq}`
                          }}
                          className={'ico-arrow'}
                        >
                          <strong
                            style={{ display: 'inline-block' }}
                            className={
                              'ellipsis' + (noticeItem.newNoticeYn === 'Y' ? ' ico-new' : '')
                            }
                          >
                            {noticeItem.title}
                          </strong>
                          <div className="info">
                            <span className="date">{noticeItem.registDate.substring(0, 10)}</span>
                          </div>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <NoContent titleMsg={'등록된 문의가 없습니다.'} />
              )}
            </div>
            <Pagination
              type={'SEQ'}
              totalPages={totalPages}
              limitPage={10}
              maxNum={10}
              minNum={0}
              pathName={'/service/notice'}
              currentSeq={currentSeq}
            />
          </section>
        </div>
      </div>
    </div>
  )
}
