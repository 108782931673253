import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import cx from 'classnames'

import FaqList from './FaqList'
import Pagination from './Pagination'
import useGetFaqCategory from '../hooks/useGetFaqCategory'
import useGetFaqList from '../hooks/useGetFaqList'
import s from '../Service.module.scss'

export default function TabFaq() {
  const [cate, setCate] = useState('BEST')
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState({ yanadoo: true, yafit: false })
  const groupCode = tab.yanadoo ? 'YANADOO_FAQ_GROUP' : 'YAFIT_FAQ_GROUP'
  const { category } = useGetFaqCategory(groupCode)
  const { faqList, totalPage } = useGetFaqList({ cate, group: groupCode, page, size: 10 })
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const handleTab = (key) => {
    setTab((prevTab) => ({
      yanadoo: key === 'yanadoo' ? !prevTab.yanadoo : false,
      yafit: key === 'yafit' ? !prevTab.yafit : false
    }))
    setCate('BEST')
  }

  const handleCategory = (code) => {
    setCate(code)
    setPage(1)
  }

  const onPageChange = (current) => {
    setPage(current)
  }
  return (
    <>
      {!isMobile && <h3>자주 묻는 질문 FAQ</h3>}
      <div className={s.csTabNav}>
        <button
          type="button"
          className={cx(s.csTabBtn, { [s.csTabActive]: tab.yanadoo })}
          onClick={() => handleTab('yanadoo')}
          disabled={tab.yanadoo}
        >
          야나두
        </button>
        <button
          type="button"
          className={cx(s.csTabBtn, { [s.csTabActive]: tab.yafit })}
          onClick={() => handleTab('yafit')}
          disabled={tab.yafit}
        >
          야핏 사이클
        </button>
      </div>
      <div className={s.csCategory}>
        {category.map((item, index) => (
          <button
            key={index}
            type="button"
            className={cx(s.csCateBtn, { [s.csCateActive]: item.code === cate })}
            onClick={() => handleCategory(item.code)}
          >
            <span>{item.value}</span>
          </button>
        ))}
      </div>
      <div className={s.csTabContent}>
        <FaqList list={faqList} />
        <Pagination currentPage={page} totalPages={totalPage} onPageChange={onPageChange} />
      </div>
    </>
  )
}
