import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import ModalWinnerAddress from 'components/modal/ModalWinnerAddress'
import useModalLogin from 'store/useModalLogin'

import DetailTitle from './DetailTitle'
import TabMain from './TabMain'

export default function ItemWinner() {
  const { currentSeq } = useParams()

  const [btnToggle, setBtnToggle] = useState(false)
  const [eventData, setEventData] = useState(null)
  const [winnerContent, setEventContent] = useState(null)
  const [myResultData, setMyResultData] = useState()
  const [memberYn, setMemberYn] = useState(false)
  const [winnerModalOpen, setWinnerModalOpen] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  const handleOpenWinnerModal = () => {
    if (!memberYn) {
      handleOpenModalLogin()

      return false
    }

    document.body.classList.add('modal-open')
    setWinnerModalOpen(true)
  }

  const handleCloseWinnerModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setWinnerModalOpen(false)
  }, [])

  const handleOpenModalLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }, [])

  const handleListToggle = () => {
    setBtnToggle(!btnToggle)
  }

  const getWinnerData = () => {
    api
      .get(`/v2/event/winner-manage/${currentSeq}`)
      .then((response) => {
        setEventData(response.data.data)
        setEventContent(response.data.data.winnerContent)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  const getMyResultData = () => {
    api
      .get(`/v2/event/winner-manage/${currentSeq}/winner/check`)
      .then((response) => {
        setMyResultData(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getWinnerData()
    if (AuthService.getUserInfo()) {
      setMemberYn(true)
      getMyResultData()
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <TabMain activeTab={'END'} />
      {eventData && <DetailTitle eventData={eventData} winner />}
      <section className="event-participate-col">
        <div className="inner-col">
          <div className="event-area">
            {winnerContent && <pre dangerouslySetInnerHTML={{ __html: winnerContent }}></pre>}
          </div>
          <div className="winner-result-area">
            {eventData && eventData.winnerUploadYn === 'Y' ? (
              <>
                <button type="button" className="btn btn-my-result" onClick={handleOpenWinnerModal}>
                  <span>나의 당첨 결과 확인</span>
                </button>
                <div className="winner-list">
                  <button
                    type="button"
                    className={'btn btn-all-result' + (btnToggle ? ' active' : '')}
                    onClick={handleListToggle}
                  >
                    <span>당첨자 전체보기</span>
                  </button>
                  <div className={btnToggle ? 'active' : ''}>
                    <em>이름과 핸드폰번호 뒷자리로 당첨 여부를 확인하세요.</em>
                    <ul>
                      {eventData &&
                        eventData.winners.map((list, idx) => (
                          <li key={idx}>
                            {list.userName}
                            <span>{list.cellPhone}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>
      <section className="list-btn-col">
        <div className="inner-col">
          <Link to="/event/end/1" className="btn btn-go-list">
            목록보기
          </Link>
        </div>
      </section>
      {eventData && winnerModalOpen ? (
        <ModalPortal>
          <ModalWinnerAddress
            btnClose
            handleCloseModal={handleCloseWinnerModal}
            myResultData={myResultData} // 당첨 여부
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
