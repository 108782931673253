import ModalPortal from 'ModalPortal'

import ModalNotice from 'components/modal/ModalNotice'
import { COUNSEL_TIME } from 'components/promotion/dbCollectionForm/config'
import ModalWrap from 'components/promotion/modal/ModalWrap'

import styles from './LevelTestCounsel.module.scss'
import { LevelTestModalMarketing } from './LevelTestModalMarketing'
import useDbCollectionFormLevelTest from '../hooks/useDbCollectionFormLevelTest'

export default function LevelTestCounsel({ type, userName, onSubmitConsultation, onClose }) {
  const {
    formData, // 상담 폼 데이터 (상담시간/개인정보 & 마케팅 동의)
    isOpenModalNotice, // 이용 약관 모달
    handleToggleModalNotice,
    isOpenModalMarketing, // 마케팅 동의 체크 유도 모달
    handleToggleModalMarketing,
    isAllChecked, // 전체 동의 체크 유무
    handleAllCheckboxChange,
    handleInputChange, // 상담시간 선택 핸들러
    handleCheckboxChange, // 개인정보 수집 및 이용 동의 체크 핸들러
    selectRef, // 상담시간 select Ref
    handleSubmit, // 상담 신청 버튼 클릭 핸들러 (유효성 체크)
    postConsultationData // 상담 데이터 전송 핸들러 (마케팅 모달에서 사용)
  } = useDbCollectionFormLevelTest()

  const submitCallback = () => {
    if (type === 'advanced') {
      onSubmitConsultation(formData.privacyAgreeYn, formData.smsAgreeYn, formData.counselTime)
    } else {
      onSubmitConsultation(
        false,
        formData.privacyAgreeYn,
        formData.smsAgreeYn,
        formData.counselTime
      )
    }
  }

  const handleMarketingSubmit = (smsAgreeYn) => {
    postConsultationData(() => {
      if (type === 'advanced') {
        onSubmitConsultation(true, smsAgreeYn, formData.counselTime)
      } else {
        onSubmitConsultation(false, true, smsAgreeYn, formData.counselTime)
      }
    })
  }

  return (
    <>
      <div
        className={`
          ${styles.counsel}
          ${type === 'advanced' ? styles.advanced : ''}
          ${type === 'popup' ? styles.popup : ''}`}
      >
        {type !== 'popup' && (
          <hgroup>
            <h3>{userName}님을 위한 맞춤 상품 추천!</h3>
            <p>야나두 전문 상담사가 함께 할게요.</p>
          </hgroup>
        )}

        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(submitCallback)
          }}
        >
          {type === 'popup' && (
            <>
              <hgroup>
                <h3>
                  내 레벨테스트 결과에 <br />
                  만족하시나요?
                </h3>
                <p>{userName}님을 위한 맞춤 상품 추천!</p>
              </hgroup>

              <button type="button" className={styles.close} onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 4L20 20M20 4L4 20"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </>
          )}

          <div className={styles.img}>
            <img
              src="https://english.yanadoocdn.com/upload/yanadoo/new/levelTest/img_result-coupon_v2.svg"
              alt="할인혜택 최대 100,000원"
            />
          </div>
          <button type="submit" className={styles.btn}>
            빠른 무료 상담 신청
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3.10789 22L1 19.8691L8.78422 12L1 4.13088L3.10789 2L13 12L3.10789 22Z"
                fill="black"
              />
            </svg>
          </button>

          <p className={styles.desc}>
            레벨 테스트 완료 기념! <br />
            <strong>전화상담 후 구매 시 시크릿 최대 10만원 할인 혜택!</strong>
          </p>

          <div className={styles.select}>
            <label htmlFor="counselTime">상담가능시간</label>
            <select
              id="counselTime"
              onChange={handleInputChange}
              ref={selectRef}
              value={formData.counselTime}
            >
              {COUNSEL_TIME.map((item, index) => (
                <option
                  key={`${item + index}`}
                  disabled={index === 0}
                  hidden={index === 0}
                  value={index === 0 ? '' : item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
          <ul className={styles.agree}>
            <li>
              <label>
                <input
                  type="checkbox"
                  id="allAgree"
                  checked={isAllChecked}
                  onChange={handleAllCheckboxChange}
                />
                <em></em>
                전체 동의
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  id="privacyAgreeYn"
                  checked={formData.privacyAgreeYn}
                  onChange={handleCheckboxChange}
                />
                <em></em>
                개인정보 수집 · 이용 동의 [필수]
                <button type="button" onClick={handleToggleModalNotice}>
                  약관보기
                </button>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  id="smsAgreeYn"
                  checked={formData.smsAgreeYn}
                  onChange={handleCheckboxChange}
                />
                <em></em>
                SMS 광고 및 마케팅 이용 동의 [선택]
              </label>
            </li>
          </ul>
        </form>
      </div>

      {isOpenModalNotice && (
        <ModalPortal>
          <ModalNotice
            title={'개인정보 수집 및 이용 동의 약관'}
            btnClose
            handleCloseModal={handleToggleModalNotice}
          />
        </ModalPortal>
      )}

      {isOpenModalMarketing && (
        <ModalWrap onClose={handleToggleModalMarketing}>
          <LevelTestModalMarketing onMarketingSubmit={handleMarketingSubmit} />
        </ModalWrap>
      )}
    </>
  )
}
