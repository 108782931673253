import React, { useState } from 'react'

import Terms from 'pages/service/Terms'

import ModalWrap from 'components/promotion/modal/ModalWrap'

import s from './ModalTerms.module.css'

const TERMS_TAB = [
  {
    name: '야나두',
    brandCode: 'YANADOO',
    termsType: 'SERVICE'
  },
  {
    name: '야핏 사이클',
    brandCode: 'YAFIT_CYCLE',
    termsType: 'SERVICE'
  }
]

export default function ModalTerms({ close }) {
  const [brand, setBrand] = useState('YANADOO')
  const [type, setType] = useState('SERVICE')

  const handleBrand = (brandCode, termsType) => {
    setBrand(brandCode)
    setType(termsType)
  }

  return (
    <ModalWrap btn onClose={() => close('policy')}>
      <div className={s.terms}>
        <ul className={s.tabMenu}>
          {TERMS_TAB.map((item, index) => {
            return (
              <li
                key={index}
                className={item.brandCode === brand ? s.active : undefined}
                onClick={() => handleBrand(item.brandCode, item.termsType)}
              >
                <span>{item.name}</span>
              </li>
            )
          })}
        </ul>
        <div className={s.scroll}>
          <Terms brand={brand} type={type} />
        </div>
      </div>
    </ModalWrap>
  )
}
