import React from 'react'

import { moveToSection } from 'utils/moveToSection'

import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import styles from '../LevelTestAdvancedResult.module.scss'
import LineChart from './charts/LineChart'
import Button from './common/Button'

export default function LevelChart() {
  const { name, location } = useLevelTestAdvancedResultStore()

  return (
    <section className={styles.lineChart}>
      <hgroup className={styles.hgroup}>
        <h6>{name}님의 영어 실력은</h6>
        <h3>상위 {location}%</h3>
      </hgroup>
      <LineChart />
      <Button
        text="레벨UP 추천 학습보기"
        onClick={() => {
          moveToSection('recommendCourse')
        }}
      />
    </section>
  )
}
