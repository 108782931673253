import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import styles from './SortSelectBox.module.scss'

export default function SortSelectBox({ setSort, sort }) {
  const [list, setList] = useState([])

  const sortEng = (list) => {
    switch (list) {
      case '최신순':
        return 'RECENT'
      default:
        return 'POPULAR'
    }
  }
  const sortOnClick = (e, sortList) => {
    setSort(sortEng(sortList))
  }
  useEffect(() => {
    setList(['인기순', '최신순'])
  }, [])
  const location = useLocation()

  // 현재 URL 경로를 가져온다
  const currentPath = location.pathname

  // 전체보기 페이지('/store/list')에서는 컴포넌트를 표시하지 않는다
  if (currentPath === '/store/list') {
    return null
  }
  return (
    <div className={styles.selectBox}>
      {list.map((item) => (
        <button
          key={item}
          onClick={(e) => sortOnClick(e, item)}
          className={sort === sortEng(item) ? styles.active : ''}
        >
          {item}
        </button>
      ))}
    </div>
  )
}
