import { useEffect, useRef, useState } from 'react'
import { isAndroid } from 'react-device-detect'

import { requestPermission } from 'webview/bridge'

import useIsAudioState from 'store/useIsAudioStore'

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const isRecognitionSupported = !!SpeechRecognition

export default function useSpeechToText() {
  const recognitionRef = useRef(null)
  const isRestartFlag = useRef(null)

  const [errorMessage, setErrorMessage] = useState('')
  const [isListening, setIsListening] = useState(false)
  const [transcript, setTranscript] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [runTimeout, setRunTimeout] = useState(false)

  const { setIsAudioState } = useIsAudioState()

  useEffect(() => {
    if (!isRecognitionSupported) {
      console.warn('이 브라우저는 음성 인식을 지원하지 않습니다.')
      setErrorMessage('이 브라우저는 음성 인식을 지원하지 않습니다.')
    } else {
      const initSpeechRecognition = ({ lang, continuous, interimResults }) => {
        recognitionRef.current = new SpeechRecognition()
        recognitionRef.current.lang = lang || 'en-US'
        recognitionRef.current.continuous = continuous || false // 연속 모드 설정
        recognitionRef.current.interimResults = interimResults || false // 중간 결과도 사용

        console.log('ready : 음성인식 준비')
        isRestartFlag.current = false

        // stt: 인식된 음성을 텍스트로 변환
        let finalTranscript = ''
        if (isAndroid) {
          recognitionRef.current.onresult = (event) => {
            let newInterimTranscript = ''
            let resultTranscript = ''

            for (let i = event.resultIndex; i < event.results.length; i++) {
              const text = event.results[i][0].transcript
              if (event.results[i].isFinal) {
                resultTranscript += text + ' '
              } else {
                newInterimTranscript += text
              }
            }

            setTranscript(resultTranscript + newInterimTranscript)
          }
        } else {
          recognitionRef.current.onresult = (event) => {
            let newInterimTranscript = ''

            for (let i = event.resultIndex; i < event.results.length; i++) {
              const text = event.results[i][0].transcript
              if (event.results[i].isFinal) {
                finalTranscript += text + ' '
              } else {
                newInterimTranscript += text
              }
            }

            setTranscript(finalTranscript + newInterimTranscript)
          }
        }
        // error
        recognitionRef.current.onerror = (event) => {
          if (isAndroid) {
            setRunTimeout(false)
          } else {
            finalTranscript = ''
          }
          recognitionOnError(event.error)
        }
        // end
        recognitionRef.current.onend = () => {
          if (isAndroid) {
            setRunTimeout(false)
          } else {
            finalTranscript = ''
          }
          recognitionOnEnd()
        }
      }

      initSpeechRecognition({ continuous: true, interimResults: true })
    }

    return () => {
      setErrorMessage('')
      if (recognitionRef.current) {
        recognitionRef.current.onend = null
        recognitionRef.current.onerror = null
        recognitionRef.current.stop()
      }
    }
  }, [])

  const recognitionOnError = (error) => {
    setTranscript('')
    recognitionRef.current.stop()
    switch (error) {
      case 'service-not-allowed':
      case 'not-allowed':
        requestPermission(error === 'service-not-allowed' ? 'speech' : 'mic')
        setErrorMessage(
          error === 'service-not-allowed'
            ? '음성인식 권한이 없습니다. 권한 설정 후 다시 한번 말씀해 주시겠어요?'
            : '마이크 권한이 없으면 음성모드 이용이 어려워요.😥 아래의 방법으로 재시도 부탁드릴게요.\n\n1. 공통: AI 원어민톡을 종료 후 재접속 > 음성모드 > 마이크 버튼 선택 > 마이크 접근 알림이 뜨면 "허용" 버튼 선택\n\n2. 아이폰: 설정 > 개인정보 보호 및 권한 > 마이크 > 내가 접속한 App의 마이크 권한 "ON" (safari인 경우: 설정 > safari 검색 > 마이크 > “허용”)\n\n3. 안드로이드폰: 설정 > 애플리케이션 > 내가 접속한 App > 권한 > 마이크 > “앱 사용 중에만 허용” or “항상 확인”\n\n4. PC 크롬 브라우저: 주소창 URL 영역 좌측의 설정 아이콘 또는 마이크 아이콘 선택 > 마이크 "ON"\n\n✅ 모든 설정 후 반드시 "새로고침" 또는 "AI 원어민톡 재접속" 하셔야 음성모드를 이용하실 수 있어요.'
        )
        setIsActive(true)
        isRestartFlag.current = false
        break
      default:
        console.error('[handleOnError] 음성인식 오류 : ', error)
        break
    }
  }

  const recognitionOnEnd = () => {
    console.log('=====> onEnd')
    if (isRestartFlag.current) {
      console.log('=====> onEnd/isRestartFlag')
      try {
        setTranscript('')
        isRestartFlag.current = false
        recognitionRef.current.start()
      } catch (error) {
        console.error('음성 인식 재시작 오류:', error)
      }
    } else {
      if (isAndroid) {
        console.log('=====> onEnd/isAndroid')
        setRunTimeout(true)
      } else {
        setIsListening(false)
      }
    }
  }

  const recognitionStart = () => {
    setRunTimeout(false)
    setIsListening(true)
  }
  const recognitionAbort = () => {
    setIsListening(false)
    isRestartFlag.current = false

    if (recognitionRef.current) {
      recognitionRef.current.stop()
    }
  }
  const recognitionReStart = () => {
    console.log('=====> reStart')
    if (recognitionRef.current) {
      setIsListening(true)
      isRestartFlag.current = true
      if (runTimeout) {
        console.log('=====> reStart/runTimeout/start')
        recognitionRef.current.start()
        isRestartFlag.current = false
        setRunTimeout(false)
        setTranscript('')
      } else {
        console.log('=====> reStart/stop')
        recognitionRef.current.stop()
      }
    } else {
      console.error('음성 인식 객체 초기화 실패')
      setIsListening(false)
    }
  }

  const clearErrorMessage = () => {
    setErrorMessage('')
  }

  useEffect(() => {
    setTranscript('')
    if (isListening) {
      try {
        recognitionRef.current?.start()
        setIsAudioState(false)
      } catch (error) {
        console.error('음성 인식 시작 오류:', error)
        setIsListening(false)
      }
    } else {
      recognitionRef.current?.stop()
      setIsAudioState(true)
    }
  }, [isListening])

  useEffect(() => {
    if (isActive) {
      setIsListening(false)
    }
  }, [isActive])

  return {
    errorMessage,
    transcript,
    isListening,
    handleRecognition: {
      start: recognitionStart,
      abort: recognitionAbort,
      restart: recognitionReStart
    },
    clearErrorMessage,
    isActive,
    runTimeout
  }
}
