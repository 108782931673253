import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'

import styles from './Review.module.scss'
import TabQna from './TabQna'
import Lnb from '../../../components/Lnb'
import NoContent from '../../../components/NoContent'
import Pagination from '../../../components/Pagination'
const icoCheck_36x21 =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_36x21.png'
const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_qna.png'

export default function Review() {
  const navigate = useNavigate()
  const [pageIdx, setPageIdx] = useState(1)
  const [reviewData, setReviewData] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [dataKeyword, setDataKeyword] = useState({
    period: 3
  })

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  const handlePeriodCheck = (e) => {
    const targetValue = e.target.value
    setPageIdx(1)
    setDataKeyword({ ...dataKeyword, period: targetValue })

    getReviewData(targetValue)
  }

  const getReviewData = (period) => {
    ;(period === 'ALL'
      ? api.get(`/v2/review/user/list?index=${pageIdx}&size=10`)
      : api.get(
          `/v2/review/user/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}`
        )
    )
      .then((response) => {
        setReviewData(response.data.data.content)
        setTotalPages(response.data.data.totalPages)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getReviewData(dataKeyword.period)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIdx])

  return (
    <div className="qna-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader title="후기" onClick={() => navigate('/mypage/1')} />
          <Lnb />
          <section className={styles.qnaListSection}>
            <TabQna activeTab={'REVIEW'} />
            <div>
              <div className={styles.searchBox}>
                <ul className={styles.searchTxtList}>
                  <li className={styles.searchTxtItem}>
                    답변이 등록된 후에는 수정 또는 삭제하실 수 없습니다.
                  </li>
                </ul>
                <div className={styles.searchSelectBox}>
                  <select
                    name="reviewPeriod"
                    id="reviewPeriod"
                    value={dataKeyword.period}
                    onChange={handlePeriodCheck}
                    style={{
                      backgroundImage: `url(${icoCheck_36x21})`
                    }}
                  >
                    <option value="ALL">전체</option>
                    <option value="3">3개월</option>
                    <option value="6">6개월</option>
                    <option value="12">12개월</option>
                  </select>
                </div>
              </div>
              <Desktop>
                <table
                  className={styles.tableBox}
                  style={{ borderBottom: reviewData.length > 0 ? '0.1rem solid #b7b7c8' : 'none' }}
                >
                  <colgroup>
                    <col width="12%" />
                    <col width="20%" />
                    <col width="" />
                    <col width="20%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>분류</th>
                      <th>상품/클래스명</th>
                      <th>내용</th>
                      <th>별점/작성일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewData.length > 0 ? (
                      reviewData.map((reviewItem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className="ellipsis">{reviewItem.reviewTypeName}</p>
                            </td>
                            <td>
                              <p className="ellipsis">{reviewItem.title}</p>
                            </td>
                            <td>
                              <Link
                                to={`/mypage/qna/review/detail/${reviewItem.learningReviewSeq}`}
                              >
                                <strong
                                  className={
                                    'ellipsis ' + (reviewItem.attachments ? styles.icoPhoto : '')
                                  }
                                >
                                  {reviewItem.contents}
                                </strong>
                              </Link>
                            </td>
                            <td>
                              <div className="review-info">
                                <strong className="star-point">
                                  <span
                                    style={{
                                      width: reviewItem.satisfaction * 20 + '%'
                                    }}
                                  />
                                </strong>
                                <p>{formatDate(reviewItem.registDate)}</p>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">
                          <NoContent icoImg={icoImg} titleMsg={'등록된 후기가 없습니다.'} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Desktop>
              <Mobile>
                <div className="table-form-mobile">
                  {reviewData.length > 0 ? (
                    <ul className="table-lists">
                      {reviewData.map((reviewItem, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/mypage/qna/review/detail/${reviewItem.learningReviewSeq}`}>
                              <span className="ellipsis">{reviewItem.title}</span>
                              <strong className="ellipsis line-clamp-2">
                                {reviewItem.contents}
                              </strong>
                              <strong className="star-point">
                                <span
                                  style={{
                                    width: reviewItem.satisfaction * 20 + '%'
                                  }}
                                />
                              </strong>
                              <div className="info">
                                <span
                                  className={
                                    'check' + (reviewItem.answerYn === 'Y' ? ' completed' : '')
                                  }
                                >
                                  {reviewItem.answerYn === 'Y' ? '답변완료' : '답변대기'}
                                </span>
                                <span className="date">{formatDate(reviewItem.registDate)}</span>
                                {reviewItem.attachments && <span className={styles.photoSpan} />}
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <NoContent icoImg={icoImg} titleMsg={'등록된 후기가 없습니다.'} />
                  )}
                </div>
              </Mobile>
              {reviewData.length > 0 && (
                <Pagination
                  type={'IDX'}
                  handleCurrentIdx={handleCurrentIdx}
                  totalPages={totalPages}
                  limitPage={5}
                  maxNum={5}
                  minNum={0}
                  pageIdx={pageIdx}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
