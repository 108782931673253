import Lottie from 'react-lottie-player'

import LottieChatData from 'pages/aiTalk/lottieAlwaysChat.json'
import LottieVoiceData from 'pages/aiTalk/lottieAlwaysVoice.json'

import s from '../aiTalk.module.css'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet'
  }
}

export default function SectionAlways() {
  return (
    <section className={s.always}>
      <div className={s.inner}>
        <hgroup className={s.titleGroup}>
          <h3>
            채팅/음성 모드로
            <br />
            언제 어디서나 AI 친구와 함께!
          </h3>
          <p>
            내가 원하는 장소, 상황에 맞게 AI 친구와 대화해 보세요.
            <br />
            일상 속에서 영어가 더 가깝게 느껴집니다.
          </p>
        </hgroup>
        <div className={s.gifContainer}>
          <ul>
            <li>
              <div className={s.gifBox}>
                <Lottie {...defaultOptions} animationData={LottieChatData} />
              </div>
              <p className={s.gifBoxDescription}>
                지하철이라 누가 들을까 걱정된다면?
                <br />
                <b>채팅 모드</b>로 편하게 톡으로 얘기해요.
              </p>
            </li>
            <li>
              <div className={s.gifBox}>
                <Lottie {...defaultOptions} animationData={LottieVoiceData} />
              </div>
              <p className={s.gifBoxDescription}>
                집에 편하게 있을 때는?
                <br />
                <b>음성 모드(Beta)</b>로 통화하듯 대화할 수 있어요.
              </p>
              <span className={s.gifBoxSubDescription}>
                * 음성모드는 Beta 형태로 제공되며, {'\n'}서비스 기간이나 제공 방식 등은 사전 고지
                없이 변경될 수 있습니다.
                <br />* iOS 15 버전 미만의 디바이스에서는 음성모드의 이용이 제한됩니다.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
