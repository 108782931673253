import styles from './ItemStore.module.scss'

export default function ItemStore({ storeData, title, subTitle }) {
  return (
    <>
      <h3 className={styles.storeTitle}>
        {title && title}
        <span>{subTitle && subTitle}</span>
      </h3>
      <ul className={styles.productList}>
        {storeData &&
          storeData.map((item, itemIndex2) => {
            return (
              <li key={itemIndex2}>
                <a href={item.productUrl} target={`_${item.linkTarget.toLowerCase()}`}>
                  <span className={styles[item.iconType.toLowerCase()]} />
                  <img src={item.thumbnailPath} alt={item.categoryName} />
                  <div className={styles.productText}>
                    <strong>{item.productName}</strong>
                    <p>
                      {item.text1} <br />
                      {item.text2}
                    </p>
                  </div>
                </a>
              </li>
            )
          })}
      </ul>
    </>
  )
}
