import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetStoreList(sort, category) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isEmpty, setIsEmpty] = useState(false)

  const getStoreList = async (sort, category) => {
    setIsLoading(true)

    try {
      const result = await api
        .get(`/v2/store/sale/package/list?sortOrder=${sort}${category}`)
        .then((response) => response.data.data)

      setData(result)
      setIsEmpty(result.length === 0)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!sort || !category) return
    getStoreList(sort, category)
  }, [sort, category])

  return { data, isLoading, isEmpty }
}
