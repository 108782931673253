import React from 'react'
import { Link } from 'react-router-dom'

export default function NoContent({
  icoImg,
  titleMsg,
  descMsg,
  linkUrl,
  titleUrl,
  style,
  children
}) {
  return (
    <div className="no-content" style={style}>
      {icoImg && <span className="ico" style={{ backgroundImage: `url(${icoImg})` }}></span>}
      <>
        <p className="title">{titleMsg}</p>
        {descMsg && <p className="desc">{descMsg}</p>}
        {linkUrl && <Link to={linkUrl}>{titleUrl}</Link>}
      </>
      {children && children}
    </div>
  )
}
