import React from 'react'
import { Link } from 'react-router-dom'

import styles from './BottomMenuSection.module.scss'
export default function BottomMenuSection() {
  return (
    <section className={styles.groupSection}>
      <div className={styles.innerCol}>
        <h3>
          No.1 성장 그룹
          <br />
          야나두와 함께
        </h3>
        <ul className={styles.menuList}>
          <li className={styles.menuListItem}>
            <Link to="/EnglishHome">10분 영어배우기 &gt;</Link>
          </li>
          <li className={styles.menuListItem}>
            <Link to="https://yafit.yanadoo.co.kr/yanadoo/promotion/yafitAllInOne.html">
              가볍게 다이어트 &gt;
            </Link>
          </li>
          <li className={styles.menuListItem}>
            <Link to="/store/list">야나두 클래스 &gt;</Link>
          </li>
        </ul>
      </div>
    </section>
  )
}
