import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useSubscription from 'hooks/useSubscription'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import { setCookie, getCookie } from 'common/Cookie'

import ModalMemberType from '../modal/ModalMemberType'
import NoContent from '../NoContent'

export default function AppleAuth() {
  const navigate = useNavigate()
  const getToken = new URL(window.location.href).searchParams.get('id_token')
  const error = new URL(window.location.href).searchParams.get('error')
  const [accessToken, setAccessToken] = useState(null)
  const [encryptToken, setEncryptToken] = useState(null)
  const [modalopenSocialType, setModalopenSocialType] = useState(false)

  const signType = sessionStorage.getItem('signType')
  const { subScriptionModal, postSubscription } = useSubscription()

  const handleOpenModalMemberType = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenSocialType(true)
  }, [])

  const handleCloseModalMemberType = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenSocialType(false)
    // historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
    if (getCookie('historyUrl') !== '') {
      setCookie('orderLoginSuccess', 'Y')
      if (window.location.href !== getCookie('historyUrl'))
        window.location.href = getCookie('historyUrl')
    } else {
      navigate('/')
    }
  }, [])

  // jwt decode
  function parseJwt(token) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  const getEncrypUserId = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/sign-in/encrypt-sns-token?snsToken=' + accessToken)
      .then((response) => {
        setEncryptToken(response.data.data.encryptSnsToken)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 소셜 토큰 암호화 o
  const handleLoginApple = async () => {
    try {
      const response = await api.post(
        `${config.AUTH_API_HOST}/auth/v2/sign-in/social/get-token?deviceType=0&snsToken=${encryptToken}&snsType=4`
      )

      if (response.data.meta.code !== 200) {
        handleOpenModalMemberType()
        return false
      }

      if (signType === 'uPlus') {
        const data = { userSeq: response.data.data.user.userSeq }

        // subscription post
        await postSubscription(data)

        AuthService.setUserInfo(response.data.data)
        return
      }

      AuthService.setUserInfo(response.data.data)

      AuthService.biginUserInfo()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (getToken) {
      let tokenDecode = parseJwt(getToken)
      setAccessToken(tokenDecode.sub)
    }
  }, [getToken])

  useEffect(() => {
    if (accessToken) {
      getEncrypUserId()
    }
  }, [accessToken])

  useEffect(() => {
    if (encryptToken) {
      handleLoginApple()
    }
  }, [encryptToken])

  useEffect(() => {
    if (error) {
      navigate('/login')
    }
  }, [])

  return (
    <>
      <div className="social-login apple">
        <NoContent titleMsg={''} />
      </div>
      {modalopenSocialType ? (
        <ModalPortal>
          <ModalMemberType
            title={'회원가입'}
            btnClose
            handleCloseModal={handleCloseModalMemberType}
            snsType={4}
            accessToken={accessToken}
            encryptToken={encryptToken}
          />
        </ModalPortal>
      ) : null}

      {subScriptionModal}
    </>
  )
}
