import * as config from 'common/config'
import { setCookie } from 'common/Cookie'

const KAKAO_JS_KEY = '7b1f9e59c99d803abada86f803981062'
const KAKAO_REDIRECT_URI = `${config.YANADOO_HOST}/oauth/kakao`

const NAVER_CLIENT_ID = 'nKdWPxG8veBDPYX6P5gU'
const NAVER_REDIRECT_URI = `${config.YANADOO_HOST}/oauth/naver`

const FACEBOOK_CLIENT_ID = '3477650222259953'
const FACEBOOK_REDIRECT_URI = `${config.YANADOO_HOST}/oauth/facebook`

const APPLE_CLIENT_ID = 'kr.co.yanadoo.login'
const APPLE_REDIRECT_URI = `${config.AUTH_API_HOST}/views/apple/sign-in/redirect`

// 자바스크립트 SDK 호출 함수
const injectScript = (url, callback) => {
  const s = document.createElement('script')
  s.src = url
  s.type = 'text/javascript'
  s.addEventListener('load', callback)
  document.head.appendChild(s)
}

const setAppleInit = () => {
  window.AppleID.auth.init({
    clientId: APPLE_CLIENT_ID,
    redirectURI: APPLE_REDIRECT_URI,
    response_type: 'code id_token',
    state: ''
  })
}

const setAppleInitIframe = () => {
  window.AppleID.auth.init({
    clientId: APPLE_CLIENT_ID,
    redirectURI: APPLE_REDIRECT_URI,
    response_type: 'code id_token',
    state: '',
    usePopup: true
  })
}

const handleAppleLogin = (joinPath) => {
  setCookie('joinPathCode', joinPath, 1)
  window.AppleID.auth.signIn()
}

// 카카오
const handleKakaoLogin = (joinPath, iframe) => {
  const scope = ''
  setCookie('joinPathCode', joinPath, 1)

  window.Kakao.Auth.login({
    scope,
    success: function (response) {
      window.Kakao.Auth.setAccessToken(response.access_token)

      let accessToken = window.Kakao.Auth.getAccessToken()

      window.Kakao.API.request({
        url: '/v2/user/me',
        success: function (response) {
          if (iframe) {
            window.location.href = `${KAKAO_REDIRECT_URI}?id=${response.id}&token=${accessToken}&isFrame=iframe`
          } else {
            window.location.href = `${KAKAO_REDIRECT_URI}?id=${response.id}&token=${accessToken}`
          }
        },
        fail: function (error) {
          console.log(error)
        }
      })
    },
    fail: function (error) {
      console.log(error)
    }
  })
}

// 네이버
const handleNaverLogin = (joinPath) => {
  setCookie('joinPathCode', joinPath, 1)

  const naverLogin = new window.naver.LoginWithNaverId({
    clientId: NAVER_CLIENT_ID,
    callbackUrl: NAVER_REDIRECT_URI,
    isPopup: false,
    loginButton: { color: 'green', type: 1, height: 30 },
    callbackHandle: true
  })
  naverLogin.init()
}

const handleNaverLoginIframe = (joinPath) => {
  setCookie('joinPathCode', joinPath, 1)

  const naverLogin = new window.naver.LoginWithNaverId({
    clientId: NAVER_CLIENT_ID,
    callbackUrl: NAVER_REDIRECT_URI,
    isPopup: true,
    loginButton: { color: 'green', type: 1, height: 30 },
    callbackHandle: true
  })
  naverLogin.init()
}

// 페이스북
const handleFacebookLogin = (joinPath) => {
  setCookie('joinPathCode', joinPath, 1)
  // 로그인 정보 GET
  window.FB.login((res) => {
    // 사용자 정보 GET

    if (res.authResponse) {
      window.FB.api(`/${res.authResponse.userID}/`, 'GET', { fields: 'id,name,email' }, (res2) => {
        window.location.href = FACEBOOK_REDIRECT_URI + '?id=' + res.authResponse.userID
      })
    }
  })
}

function setFBAsyncInit() {
  window.FB.init({
    version: 'v5.0',
    appId: FACEBOOK_CLIENT_ID,
    xfbml: true,
    cookie: true
  })
}

const OAuth = {
  KAKAO_REDIRECT_URI,
  KAKAO_JS_KEY,
  FACEBOOK_CLIENT_ID,
  FACEBOOK_REDIRECT_URI,
  injectScript,
  setAppleInit,
  setAppleInitIframe,
  handleAppleLogin,
  handleKakaoLogin,
  handleNaverLogin,
  handleNaverLoginIframe,
  handleFacebookLogin,
  setFBAsyncInit
}

export default OAuth
