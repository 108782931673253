import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetClassCategory(cate) {
  const [category, setCategory] = useState([])

  function getCategoryList(categoryPath) {
    api
      .get(`/v2/store/category/${categoryPath}`)
      .then((response) => {
        const res = response.data.data
        setCategory(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCategoryList(cate === 'ENGLISH' ? 'FOREIGN' : cate)
  }, [cate])

  return category
}
