import React, { useState, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'

import styles from './ReviewThumbs.module.scss'
import ModalReviewDetail from '../../../components/modal/ModalReviewDetail'
import ModalPortal from '../../../ModalPortal'

export default function ReviewThumbs({ item, comment }) {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [modalopen, setModalopen] = useState(false)

  const [images, setImages] = useState(null)

  const handleOpenModal = useCallback(
    (images) => {
      document.body.classList.add('modal-open')
      setImages(images)
      setModalopen(!modalopen)
    },
    [modalopen]
  )

  const handleCloseModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  return (
    <>
      {item.attachments?.length > 0 ? (
        <button
          className={styles.viewBtn}
          type="button"
          onClick={() => {
            handleOpenModal(item.attachments)
          }}
          style={{
            '--padding': !comment ? '1.5rem 0 0 0' : '2.3rem 3.5rem 3.5rem',
            '--padding-mobile': !comment ? '3rem 0 0 0' : '4rem'
          }}
        >
          {item.attachments.map((files, idx) => {
            return (
              <span
                key={idx}
                className={styles.itemThumbSpan}
                style={{
                  background: `url(${
                    files.thumbnailPath ? files.thumbnailPath : files.url
                  }) no-repeat center / cover`
                }}
              ></span>
            )
          })}
        </button>
      ) : (
        <button
          className={styles.viewBtn}
          type="button"
          onClick={() => {
            handleOpenModal(item)
          }}
          style={{
            '--padding': !comment ? '1.5rem 0 0 0' : '2.3rem 3.5rem 3.5rem',
            '--padding-mobile': !comment ? '3rem 0 0 0' : '4rem'
          }}
        >
          <span
            className={styles.itemThumbSpan}
            style={{ background: `url(${item.url}) no-repeat center / cover` }}
          />
        </button>
      )}
      {modalopen ? (
        <ModalPortal>
          <ModalReviewDetail
            deviceCheck={deviceCheck}
            handleCloseModal={handleCloseModal}
            images={images}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
