import { useCallback, useState } from 'react'

import api from 'common/api'
import useChatFeedListStore from 'store/useChatFeedListStore'

import { AINADOO_CONFIG } from '../config'

export default function useAiFeeds({ classSeq, lectureSeq, userSeq, assistantId, threadId }) {
  const { API_HOST } = AINADOO_CONFIG
  const { setFeedList, resetFeedList } = useChatFeedListStore()
  const [isAiResponse, setIsAiResponse] = useState(false)

  const addToFeed = useCallback(
    (feed) => {
      if (feed.type === 'USER') setIsAiResponse(true)
      setFeedList({ type: feed.type, message: feed.message })
    },
    [setFeedList]
  )

  const fetchMessage = useCallback(
    async (message, type = 'AI') => {
      const payload = {
        classSeq: Number(classSeq),
        lectureSeq: Number(lectureSeq),
        userSeq: Number(userSeq),
        assistantId,
        threadId,
        message
      }

      try {
        setIsAiResponse(true)
        const response = await api.post(API_HOST.chat, payload)
        const data = response.data
        addToFeed({ type, message: data })
      } catch (e) {
        console.error(`fetch message error (${type}): `, e)
      } finally {
        setIsAiResponse(false)
      }
    },
    [assistantId, classSeq, lectureSeq, threadId, userSeq, addToFeed, API_HOST]
  )

  const postPromptMessage = useCallback(
    async ({ message }) => {
      await fetchMessage(message)
    },
    [fetchMessage]
  )

  const initPromptMessage = useCallback(async () => {
    await fetchMessage('Hi')
  }, [fetchMessage])

  return {
    addToFeed,
    initPromptMessage,
    isAiResponse,
    postPromptMessage,
    resetFeedList
  }
}
