import React, { useState, useEffect, useCallback } from 'react'

export default function DateBirth({
  yearValue,
  monthValue,
  dayValue,
  line,
  mark,
  handleBirthCheck
}) {
  const [yearList, setYearList] = useState([])
  const [monthList, setMonthList] = useState([])
  const [dayList, setDayList] = useState([])

  const getYear = useCallback((e) => {
    const yaer = []
    for (let i = 1900; i <= new Date().getFullYear() - 14; i++) {
      yaer.push(i)
    }
    setYearList(yaer)
  }, [])

  const getMonth = useCallback((e) => {
    const month = []
    for (let i = 1; i < 13; i++) {
      let mm = i > 9 ? i : '0' + i
      month.push(mm)
    }
    setMonthList(month)
  }, [])

  useEffect(() => {
    const day = []
    let baseDate = new Date(yearValue, monthValue, 0).getDate()

    for (let i = 1; i <= baseDate; i++) {
      let dd = i > 9 ? i : '0' + i
      day.push(dd)
    }
    setDayList(day)
  }, [yearValue, monthValue])

  useEffect(() => {
    getYear()
    getMonth()
  }, [])

  return (
    <dl className={line ? 'line' : null}>
      <dt className={mark ? 'mark' : null}>생년월일</dt>
      <dd>
        <div className="birthday-box">
          <select
            id="year-select"
            className="sct year-select"
            name="birthYear"
            value={yearValue}
            onChange={handleBirthCheck}
          >
            <option value="default">년</option>
            {yearList &&
              yearList.map((item, idx) => (
                <option key={idx} value={item}>
                  {item}
                </option>
              ))}
          </select>
          <select
            id="month-select"
            className="sct month-select"
            name="birthMonth"
            value={monthValue}
            onChange={handleBirthCheck}
          >
            <option value="default">월</option>
            {monthList &&
              monthList.map((item, idx) => (
                <option key={idx} value={item}>
                  {item}
                </option>
              ))}
          </select>
          <select
            id="day-select"
            className="sct day-select"
            name="birthDay"
            value={dayValue}
            onChange={handleBirthCheck}
          >
            <option value="default">일</option>
            {dayList &&
              dayList.map((item, idx) => (
                <option key={idx} value={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
      </dd>
    </dl>
  )
}
