import { useCallback } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

// 카테고리 정보(categoryStats)로 진단 결과에 따른 아코디언 목록 생성
export default function useSetAccordionList(categoryStats) {
  const [assessmentList, setAssessmentList] = useState()
  const getAssessmentText = useCallback((categoryStats) => {
    const assessmentArr = Object.entries(assessmentValues)

    for (let value of assessmentArr) {
      const key = value[0]
      const values = value[1]
      const score = categoryStats[key].score
      const title = getAssessmentTitle(key)
      const currentLevel = calcAssessmentLevel(key, score)

      setAssessmentList((prev) => ({ ...prev, [key]: { title, text: values[currentLevel] } }))
    }
  }, [])

  useEffect(() => {
    if (!categoryStats) return
    getAssessmentText(categoryStats)
  }, [categoryStats, getAssessmentText])

  return { assessmentList }
}

const assessmentValues = {
  vocabulary: {
    assessLv1:
      '의사소통이 힘들어요. 암기하고 있는 기초적인 단어들을 위주로 활용하는 편이에요. 기본적인 단어들을 듣고 말하는 연습이 필요해요. 기본 어휘들을 조합하여 단순하고 짧은 메시지를 전달하는 연습부터 시작해 보세요. ', //
    assessLv2:
      '비교적 간결하고 쉬운 단어 위주로 말할 수 있지만, 구사할 수 있는 어휘가 한정적이에요. 특히 내가 잘 모르거나 친숙하지 않는 주제에 대해서 얘기할 경우, 아는 단어가 많지 않아요. 말하기 내용이 풍부해지도록 기본적인 의사소통을 위한 어휘력을 길러 자꾸 말해보려는 연습이 필요해요. 자신에 대해서 말할 수 있는 (예시: 가족, 취미, 좋아하는 것) 단어와 표현들을 먼저 익히고 그와 같이 자주 쓰이는 단어 덩어리들을 함께 암기하는 것이 도움이 될 거예요.',
    assessLv3:
      '내가 잘 알고 있거나 친숙한 주제에 대해서는 오랫동안 얘기할 수 있지만, 단어가 떠오르지 않을 때 이를 적절한 다른 단어나 말로 표현하여 설명하는 것은 아직 어려워요. 친숙한 주제뿐만 아니라 잘 모르지만 알고 싶은, 관심이 있는 주제와 관련된 단어들을 익히는 것이 도움이 돼요. 또한 단어가 떠오르지 않을 때, 최대한 자신이 알고 있는 다른 단어나 표현들을 이용하여 설명하려는 연습(paraphrasing)을 꾸준히 해보세요.',
    assessLv4:
      '내가 잘 알고 있는 주제뿐만 아니라 익숙하지 않은 주제에 대해서 얘기할 수 있어요. 하지만 사용할 수 있는 단어들은 제한적이에요. 상황에 적절한 단어를 활용하고 있는지 잘 모를 때가 많아요. 다양한 어휘에 노출될 수 있도록 다양한 콘텐츠들을 찾아보고 사용해 보는 연습이 필요해요. 적절한 단어나 말로 표현하기 위해 다양한 전략들(바꾸어 말하기, 직역해 보기, 비슷한 단어 사용하기)을 꾸준히 습득하고 연습해 보세요.',
    assessLv5:
      '다양한 주제에 대해서 이야기할 수 있을 만큼 충분한 어휘력을 가지고 있어요. 잘 모르거나 생각나지 않는 단어를 적절하게 다른 단어나 쉬운 문장으로 자연스럽게 표현할 수 있는 편이에요. 어휘의 표면적인 뜻뿐만 아니라 그 속의 함축적인 의미를 파악하고 이를 적절하게 사용한다면 더욱 세련되고 정교하게 표현할 수 있어요. 원어민들이 자주 사용하는 관용적인 표현들을 습득하기 위해 원서나 뉴스 등을 읽고 해독해 보는 것이 도움이 될 거예요.',
    assessLv6:
      '어휘력이 매우 풍부한 편으로 전문적인 토론도 가능할 정도의 어휘력을 가지고 있어요. 원어민들이 자주 사용하는 관용적인 표현들도 거의 능숙하게 사용할 수 있으며 모르거나 생각나지 않는 단어를 적절한 다른 단어나 문장으로 빠르게 대체해서 말할 수 있어요. 언어는 꾸준히 쓰지 않으면 금방 잊어버리게 되고 간단한 단어도 떠오르지 않을 때도 있어요. 지금처럼 계속해서 다양한 어휘와 표현을 문맥과 상황에 맞게 써보면서 일상화하는 것이 중요해요.'
  },
  grammar: {
    assessLv1:
      '의사소통이 힘들어요. 정확한 문장으로 말하는 데 매우 어려움을 겪고 있어요. 영어 기본 어순이나 문법에 대한 이해가 낮은 편이에요. 영어는 한국어와 어순, 구조가 다르므로 영어 문장을 구성하는 기본 구조와 어순을 익히는 것이 가장 중요해요. 이와 함께 다양한 단어들을 학습하고 문장 안에 넣어보는 연습도 같이 해보세요.',
    assessLv2:
      '주로 머릿속에 암기되어 있는 짧은 단어, 구, 문장위주로 말하고 있어요. 문법상 다양한 오류(어순, 시제, 동사 형태 등)가 전체적인 말속에 빈번하게 발생하고 있어요. 말하기를 위한 기초 뼈대(문장구조, 시제, 동사 형태 등) 학습이 꼭 필요해요. 이와 함께 자신이 만든 문장에 있는 다양한 오류들을 체크하여 정리하고, 이를 정확하게 고쳐 다시 말해보는 연습도 필요해요.',
    assessLv3:
      '기본적인 문장 형식과 단순 시제를 만들 수는 있지만 다양한 수식어구를 활용하는데 아직 어려움이 있어요. 문법 상 오류가 아직 빈번하게 발생하며 만들어내는 문장의 뜻과 의도를 이해하기 어려울 때가 있어요. 상황에 맞는 적절한 시제, 관계사, 접속사 등을 사용하여 좀 더 풍부하게 문장을 구체화하는 연습이 필요해요. 자주 만들어 내는 다양한 문법적 오류들을 체크하여 그중 문장의 뜻과 의미에 크게 영향을 주는 부분들을 고쳐서 다시 말해보는 연습을 해보세요.',
    assessLv4:
      '짧고 단순한 문장을 말할 때는 문법적으로 정확하며 가끔 길고 복잡한 수식어를 활용해서 말할 수도 있어요. 하지만 문법 오류들을 빈번히 만들어내어 말의 뜻과 의도를 이해하기 어려울 때가 있어요. 말할 때 자주 틀리는 다양한 문법 오류들을 유형 별로 미리 체크해 보고 이를 숙지해서 다시 말해보는 연습이 도움이 돼요. 말하는 도중 문법 오류가 발생하게 되면 이를 스스로 발견하고 바로 고쳐서 다시 말해보는 연습(self-correction)이 필요해요.',
    assessLv5:
      '짧고 단순한 문장뿐만 아니라 길고 복잡한 문장구조로도 말할 수 있어요. 아직 특정적인 부분에서 문법상 실수가 발생하지만 문장의 전체적인 내용과 뜻을 이해하는데 크게 영향을 미치치는 않아요. 말할 때 특정적으로 자주 발생하는 문법 오류들은 습관처럼 굳어진 것이기 때문에 말할 때마다 발화 속도가 조금 느려지더라도 이 부분들을 의식해서 정확히 말하려는 연습이 필요해요.',
    assessLv6:
      '다양한 문장 구조들을 유연하게 사용할 수 있어요. 가끔 문법적 오류가 발견되기도 하지만 대부분이 정확한 편이며 문장의 뜻을 전달하는 데는 아무런 지장이 없어요. 문법적으로 정확하게 잘 말하고 있어요. 어쩌다 가끔씩 발생하는 문법 오류들은 다시 고쳐 말하거나(repetition) 다른 문장으로 표현하는 등(paraphrasing)의 방법들로 말의 흐름에 따라 자연스럽게 사용하면 좋아요.'
  },
  nuance: {
    assessLv1:
      '의사소통이 힘들어요. 영어 단어, 표현이 갖고 있는 뉘앙스에 대한 이해도가 낮은 편이에요. 영어 단어, 표현의 뜻이 아니라, 그것들이 지닌 뉘앙스를 이해하는 게 중요해요. 기본적인 단어들부터 듣고 말하는 연습이 필요해요. ',
    assessLv2:
      '영어 단어, 표현의 뉘앙스를 잘 몰라 정확하게 의사 전달이 되지 않아요. 상대방이 말하고자 하는 말의 의도나 뜻을 이해하기 힘들 때가 있어요. 원어민들이 해당 단어, 표현을 어떤 상황에 쓰는지 많은 듣기 연습을 통해 확인하고 따라 소리 내보는 것이 중요해요. 모두 듣고 이해하려 하지 말고 문장 속에서 그 단어, 표현의 뉘앙스를 부분적으로 파악해 보는 연습이 도움이 될 거예요. ',
    assessLv3:
      '영어 단어, 표현의 뉘앙스 알고 있어도 어떤 상황에 쓰이는 건지를 잘 몰라 일상에 잘 활용하지 못해요. 평소 일상생활 속에서 나의 의사나 의견을 입 밖으로 말하는 연습을 해야 합니다. 실수를 두려워하지 않고 이 상황 저 상황에 적용해보고 나면 감을 잡을 거예요. ',
    assessLv4:
      '하고 싶은 말을 간단한 문장으로 말하려고 노력하지만 가끔 잘못된 뉘앙스로 오해가 생길 때가 있어요. 원어민들이 해당 표현이나 추임새를 어떤 상황에서 어떤 뉘앙스로 쓰는지를 파악하고 나면 오해가 생기지 않을 거예요. 이를 일상생활에서 적용하여 꾸준히 말해보는 연습이 필요해요.',
    assessLv5:
      '상황에 알맞은 단어나 표현을 생각해 내느라 머뭇거림이 있지만, 조리 있게 상황(뉘앙스)에 맞는 표현을 구사할 수 있어요. 원어민들이 평소 쓰는 영어 단어, 표현을 많이 익히고 이를 일상생활에서 적용하여 꾸준히 말해보는 연습이 필요해요. 뉘앙스를 제대로 파악하고 나면 한결 자연스러운 말의 흐름을 유지할 수 있어요.',
    assessLv6:
      '영어 단어, 표현이 갖고 있는 뉘앙스를 정확하게 파악하고, 상황에 맞게 사용하여 의사전달이 명료한 편이에요. 자신의 의사를 정확히 표현하고자 맥락에 맞는 단어나 표현을 적절히 잘 활용하고 있어요. 다양한 영어 콘텐츠를 통해 실제 원어민들이 일상에서 많이 사용하는 표현을 직접 활용해 보려는 연습을 꾸준히 해보세요.'
  },
  listening: {
    assessLv1:
      '의사소통이 힘들어요. 상대방이 천천히 명확하게 말을 해도 대부분의 단어를 듣지 못해 내용을 이해하는 데 어려움을 겪고 있어요. 영어 알파벳 개별 소리가 어떻게 나는지 익힌 뒤 원어민의 음성을 꾸준히 들어보는 것이 중요해요. 들을 때 모두 듣고 이해하려 하지 말고 문장 속에서 단어 단위로 그 소리와 뜻을 부분적으로 파악해 보는 연습이 도움이 될 거예요.',
    assessLv2:
      '문장 속 자신이 알고 있는 소수의 단어들을 캐치하고 조합하여 대략적인 의미만을 이해할 수 있어요. 여러 단어로 구성된 구(Phrase)나 비구조적인 발화를 들었을 때는 당황하여 자주 놓치는 실수를 반복하며, 내용을 잘못 알아듣곤 해요. 원어민의 음성을 들을 때 문장의 모든 단어와 전체 의미를 파악하려고 하기 보다 말속의 주제, 포인트를 캐치해 보려고 시도해 보세요. 또한 문장 속의 구(Phrases), 함께 쓰이는 단어 덩어리 위주로 듣고 이해하려는 연습도 도움이 될 거예요.',
    assessLv3:
      '몇몇 쉬운 단어들로 말하거나 자신과 관련된 주제나 친숙한 내용을 들었을 때 잘 이해하는 편이에요. 하지만 생소한 주제나 모르는 단어, 구가 들어간 문장은 대략적으로만 이해할 수 있어요. 친숙한 주제뿐만 아니라 잘 모르는 주제도 꾸준히 듣고 어휘력을 늘리는 것이 필요해요. 또한 문장의 속도를 조절하여 천천히, 그리고 점점 빠르게 하여 반복적으로 들어보는 것이 빠른 원어민 음성을 이해하데 도움이 될 거예요.',
    assessLv4:
      '빠르지 않은 속도로 명확한 발음의 음성은 충분히 이해할 수 있어요. 특히 짧고 단순한 발표 내용이나 메시지의 경우 내용의 핵심을 정확히 파악할 수 있어요. 하지만, 속도가 조금만 빨라지거나 길고 비구조적인 말을 듣고 이해하는 것은 아직 어려워해요. 원어민 음성을 듣고 바로바로 따라 말하는 쉐도잉(Shadowing) 연습을 꾸준히 한다면 영어의 다양한 연음과 빠른 속도의 발화를 이해하는 데 도움이 될 거에요. 이와 함께 내가 알고 있는 어휘량를 꾸준히 늘리는 것도 중요해요.',
    assessLv5:
      '일상 대화 뿐만 아니라 친숙한 주제와 관련된 내용을 다룬 연설, 강의도 무리 없이 이해하고 핵심 내용을 파악할 수 있어요. 하지만 아직 실제 원어민들이 생활 속에 쓰는 빠르고 비구조적인 발화는 듣고 이해하기 힘든 편이에요. 영어로 된 영화, 드라마 등을 많이 보고 들으며 꾸준히 Spoken English를 가까이한다면 원어민들이 실제 생활에 쓰는 빠르고 비구조적인 발화 스타일에 적응할 수 있을 뿐만 아니라 말속에 담긴 함축적인 의미까지 자연스럽게 파악할 수 있을 거예요.',
    assessLv6:
      '앞뒤 문장이 구조적으로 명확하게 연결되지 않은 긴 문장들이나 함축적인 의미가 담긴 문장들로 구성된 빠른 속도의 원어민의 발화도 잘 이해하는 편이에요. 이제는 영어 토론, 강의 등 전문적인 내용의 영어 콘텐츠도 많이 접해보며 원어민이 쓰는 다양한 표현과 고급 어휘까지 함께 익혀본다면 영어 듣기 실력을 한층 더 업그레이드하는데 도움이 될 거예요.'
  },
  speaking: {
    assessLv1:
      '의사소통이 힘들어요. 대화를 나눌 때마다 매우 머뭇거려서 대화가 매끄럽게 진행되지 않는 편이에요. 다양한 문장들을 들어보고 기본적 의사소통을 위한 쉬운 일상 영어 단어나 활용도가 높은 구문 패턴 등을 어느 정도 암기하고 말하기 연습하는 것이 도움이 될 거에요.',
    assessLv2:
      '말을 하면서 자주 머뭇거려 대화의 흐름이 자주 끊기고 짧은 대화에도 적절한 표현으로 말하기 힘들어요. 일상 대화 주제에서 자주 쓰이는 간단한 단어, 짧은 문장 등을 암기하고 천천히 말하는 연습을 해보세요. ',
    assessLv3:
      '친숙한 내용이나 일상생활에 대해 이야기 나눌 때는 꽤 유창하게 말하는 편이에요. 하지만 질문에 응답하는데 시간이 조금 걸리는 편이에요. 실수를 두려워하지 않고, 일단 상대방 말에 리액션하며 대화를 이어나가려는 노력이 필요해요. 일상생활에서 자신이 보는 것들이나 생각하는 것들을 영어 문장으로 생각하고 말해보는 연습을 꾸준히 하는 것이 도움이 될 거예요.',
    assessLv4:
      '대화 흐름 파악이 가능하나, 적절한 표현을 구사하기 위해 종종 수정해서 말하는 경향이 있어요. 자신이 익숙한 문장이나 단순한 문장은 유창하게 말할 수 있지만 가끔 어색하고 부자연스러운 표현을 만들어 내기도 해요. 유용하게 쓰이는 영어 어휘, 표현, 패턴들을 많이 익히고 이를 일상 대화에 적용하여 꾸준히 말해보는 연습이 필요해요.',
    assessLv5:
      '상대방의 말을 듣고 적절한 단어나 표현을 생각해내기 위해 가끔 머뭇거리지만 대화를 자연스럽게 유도할 수 있어요. 대화에 적절한 단어나 표현이 떠오르지 않을 때는 비슷한 의미를 가진 다른 표현을 사용하여 대화의 흐름을 유지한다면 더욱 유창하게 말할 수 있을 거예요.',
    assessLv6:
      '상대방과 대화를 나눌 때 자신이 생각하고 표현하고자 하는 것들을 말할 수 있으며, 대화 주제를 유창하게 이어나갈 수 있어요. 유창함을 지속하기 위해 꾸준히 영어 콘텐츠를 접하고 영어로 말하는 습관을 유지해 보세요! 원어민처럼 영어를 조금 더 고급스럽게, 세련되게 말하는 연습을 해보는 것도 좋아요.'
  }
}

const calcAssessmentLevel = (key, score) => {
  if (key === 'vocabulary') {
    if (score >= 15) {
      return 'assessLv6'
    } else if (score >= 11) {
      return 'assessLv5'
    } else if (score >= 8) {
      return 'assessLv4'
    } else if (score >= 5) {
      return 'assessLv3'
    } else if (score >= 2) {
      return 'assessLv2'
    } else {
      return 'assessLv1'
    }
  } else if (key === 'grammar') {
    if (score >= 14) {
      return 'assessLv6'
    } else if (score >= 11) {
      return 'assessLv5'
    } else if (score >= 8) {
      return 'assessLv4'
    } else if (score >= 4) {
      return 'assessLv3'
    } else if (score >= 1) {
      return 'assessLv2'
    } else {
      return 'assessLv1'
    }
  } else if (key === 'nuance') {
    if (score >= 14) {
      return 'assessLv6'
    } else if (score >= 12) {
      return 'assessLv5'
    } else if (score >= 11) {
      return 'assessLv4'
    } else if (score >= 7) {
      return 'assessLv3'
    } else if (score >= 3) {
      return 'assessLv2'
    } else {
      return 'assessLv1'
    }
  } else if (key === 'listening') {
    if (score >= 19) {
      return 'assessLv6'
    } else if (score >= 14) {
      return 'assessLv5'
    } else if (score >= 12) {
      return 'assessLv4'
    } else if (score >= 9) {
      return 'assessLv3'
    } else if (score >= 3) {
      return 'assessLv2'
    } else {
      return 'assessLv1'
    }
  } else if (key === 'speaking') {
    if (score >= 25) {
      return 'assessLv6'
    } else if (score >= 21) {
      return 'assessLv5'
    } else if (score >= 16) {
      return 'assessLv4'
    } else if (score >= 11) {
      return 'assessLv3'
    } else if (score >= 8) {
      return 'assessLv2'
    } else {
      return 'assessLv1'
    }
  }
}

const getAssessmentTitle = (key) => {
  if (key === 'vocabulary') {
    return '어휘'
  } else if (key === 'grammar') {
    return '문법'
  } else if (key === 'nuance') {
    return '뉘앙스'
  } else if (key === 'listening') {
    return '듣기'
  } else if (key === 'speaking') {
    return '말하기'
  }
}
