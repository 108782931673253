import { useEffect, useState } from 'react'

export default function ModalBtn({
  btnClose,
  complete,
  handleCloseModal,
  handleImageUpload,
  handleRandomNum
}) {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    if (loading) {
      handleCloseModal()
    }
    return () => setLoading(false)
  }, [handleImageUpload, handleRandomNum])
  return (
    <>
      <div className="modal-wrap button">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-btn-inner">
          <div className="btn-lists">
            <label className="button default" htmlFor="select-image-file">
              <span>사진 선택</span>
            </label>
            <input
              type="file"
              accept="image/*"
              name="select-image-file"
              id="select-image-file"
              multiple
              onChange={handleImageUpload}
            />
            {/* <button className="button default">
              <span>사진 선택</span>
            </button> */}
            <button className="button default" onClick={handleRandomNum}>
              <span>기본 이미지 사용</span>
            </button>
          </div>
          <button className="button" onClick={handleCloseModal}>
            <span>취소</span>
          </button>
        </div>
      </div>
    </>
  )
}
