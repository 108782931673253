import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'

import CategoryFilter from 'components/Store/Category/CategoryList'
import ClassList from 'components/Store/List/ClassList'
import ItemStore from 'components/Store/List/ItemStore'

import styles from './index.module.scss'
import StoreBanner from '../components/StoreBanner'
import useStoreCategory from '../hooks/useStoreCategory'

const StoreCategory = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const {
    categorySeq,
    cateList,
    handleCategory,
    categoryTitle,
    curationList,
    isLoading,
    storeData,
    setSort,
    sort,
    isEmpty,
    bannerList
  } = useStoreCategory()

  return (
    <>
      <StoreBanner key={categorySeq} loop autoPlay heightPC={360} bannerData={bannerList} />
      <section className="class-list-col">
        <div className="inner-col">
          {cateList.length > 0 && categorySeq !== 'hobby' ? (
            <div className="category">
              <CategoryFilter
                list={cateList}
                handleCategory={handleCategory}
                categoryTitle={categoryTitle}
              />
            </div>
          ) : null}

          {curationList && curationList.length > 0 && (
            <>
              {curationList.map((item, index) => {
                return (
                  <ClassList
                    key={index}
                    title={item.storeCurationName}
                    listData={item.packageList}
                  />
                )
              })}
            </>
          )}

          {isLoading ? (
            <div className={styles.loadingBox}>
              <div className={styles.classTit}>
                <Skeleton
                  width={isMobile ? '20rem' : '11rem'}
                  height={isMobile ? '4rem' : '4rem'}
                />
                <Skeleton
                  width={isMobile ? '17rem' : '12rem'}
                  height={isMobile ? '4rem' : '3rem'}
                />
              </div>
              <div className={styles.loader} />
            </div>
          ) : (
            <ItemStore
              title={categoryTitle}
              storeData={storeData}
              setSort={setSort}
              sort={sort}
              isEmpty={isEmpty}
            />
          )}
        </div>
      </section>
    </>
  )
}

export default StoreCategory
