import React, { useState, useRef, useEffect } from 'react'

import ReactPlayer from 'react-player/lazy'

import Duration from './audio/Duration'
import styles from './AudioPlayer.module.scss'

export default function AudioPlayer({ data, isLoading }) {
  const player = useRef()
  const [playing, setPlaying] = useState(false) // 재생 상태값

  const [audioState, setAudioState] = useState({
    url: '',
    playing: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    loop: false
  })

  // 오디오 재생 구간 조절하는 함수
  const handleSeekChange = (e) => {
    setAudioState({ ...audioState, played: parseFloat(e.target.value) })
  }

  const handleSeekMouseDown = (e) => {
    setAudioState({ ...audioState, seeking: true })
  }

  const handleSeekMouseUp = (e) => {
    setAudioState({ ...audioState, seeking: false })
    player.current.seekTo(parseFloat(e.target.value))
  }

  // 오디오 재생,일시정지 함수
  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  // 오디오 총 재생 시간 구하는 함수
  const handleDuration = (duration) => {
    setAudioState({ ...audioState, duration })
  }

  // 오디오 프로그레스바 표시 함수
  const handleProgress = (state) => {
    const { loaded, loadedSeconds, played, playedSeconds } = state

    if (!audioState.seeking) {
      setAudioState({ ...audioState, loaded, loadedSeconds, played, playedSeconds })
    }
  }

  useEffect(() => {
    if (data) {
      setAudioState({ ...audioState, url: data.url })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      {data && (
        <div className={styles.audioPlayerBox}>
          <ReactPlayer
            ref={player}
            url={audioState.url} // 플레이어 url
            width="0" // 플레이어 크기 (가로)
            height="0" // 플레이어 크기 (세로)
            playing={playing} // 자동 재생 on
            muted={false} // 자동 재생 on
            controls={false} // 플레이어 컨트롤 노출 여부
            light={false} // 플레이어 모드
            pip={false} // pip 모드 설정 여부
            onSeek={(e) => console.log('onSeek', e)}
            onProgress={handleProgress}
            onDuration={handleDuration}
            playsinline={true} // 모바일에서 비디오를 전체 화면으로 노출 여부
          />
          <button
            className={`${styles.audioButton} ${playing ? styles.pause : styles.play}`}
            onClick={handlePlayPause}
          >
            <div className={styles.blind}>{playing ? '재생' : '일시정지'}</div>
          </button>
          <div className={styles.progressSlider}>
            <progress max={1} value={audioState.played} />
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={audioState.played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              readOnly
            />
          </div>
          <Duration seconds={audioState.duration * (1 - audioState.played)} />
        </div>
      )}
    </>
  )
}
