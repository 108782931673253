import React, { useLayoutEffect, useRef } from 'react'

import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  PointElement,
  RadarController,
  RadialLinearScale,
  Title,
  Tooltip
} from 'chart.js'

import styles from '../../LevelTestAdvancedResult.module.scss'

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  RadarController,
  RadialLinearScale
)

export default function RadarChart({ level, categoryStats }) {
  const canvasNode = useRef(null)

  // 점수를 백분율 변환
  const calcPercentage = (currentNum, wholeNum) => {
    return (currentNum / wholeNum) * 100
  }

  useLayoutEffect(() => {
    if (!level || !categoryStats) return

    const data = Object.entries(categoryStats).map((data) => {
      const { score, points } = data[1]
      return calcPercentage(score, points)
    })

    const ctx = canvasNode.current

    const chart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: ['어휘', '문법', '뉘앙스', '듣기', '말하기'],
        datasets: [
          {
            label: '',
            data,
            borderColor: '#FF6255',
            pointStyle: false,
            borderWidth: 0,
            backgroundColor: () => {
              const rgbCodes = {
                lv1: 'rgba(254, 73, 57, .7)',
                lv2: 'rgba(255, 136, 0, .7)',
                lv3: 'rgba(253, 197, 0, .7)',
                lv4: 'rgba(104, 201, 9, .7)',
                lv5: 'rgba(32, 134, 255, .7)',
                lv6: 'rgba(156, 70, 255, .7)'
              }

              return rgbCodes[level]
            }
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        scales: {
          r: {
            min: 0,
            max: 100,
            grid: {
              color: '#B7B7C8'
            },
            ticks: {
              display: false,
              stepSize: 20
            },
            pointLabels: {
              font: {
                size: () => {
                  if (window.innerWidth > 750) {
                    return 16
                  }
                  if (window.innerWidth > 480) {
                    return 24
                  }
                  return 14
                }
              }
            }
          }
        }
      }
    })

    return () => {
      chart.destroy()
    }
  }, [level, categoryStats])

  return (
    <>
      <div className={styles.radarChart}>
        <canvas ref={canvasNode}></canvas>
      </div>
    </>
  )
}
