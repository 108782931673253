import React from 'react'

import styles from './FileDownLoad.module.scss'

export default function FileDownLoad({ data }) {
  const handleAlert = () => {
    alert('파일은 앱에서 다운받아주세요.')
  }

  return (
    <>
      {data && (
        <button className={styles.fileDownLoadLink} type="button" onClick={handleAlert}>
          <strong>{data.name}</strong>
        </button>
      )}
    </>
  )
}
