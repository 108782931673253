import styles from './CategoryList.module.scss'

export default function CategoryFilter({ list, handleCategory, categoryTitle }) {
  return (
    <section className={styles.categoryBox}>
      <ul className={styles.categoryList}>
        {list.map((item, idx) => (
          <li key={idx}>
            <button
              className={categoryTitle === item.categoryName ? styles.active : ''}
              onClick={() => handleCategory(item.categoryCodeList, item.categoryName, idx)}
            >
              {item.categoryName}
            </button>
          </li>
        ))}
      </ul>
    </section>
  )
}
