// import { useState } from 'react'
import { Link } from 'react-router-dom'

import useDateComparison from 'hooks/useDateComparison'

import api from 'common/api'
import * as config from 'common/config'

import ListItem from './ListItem'

/* 이벤트 타입이 link인 경우에는 a태그(새창 열기) / apply인 경우에는 link */
/* 이벤트 타입 link일때 조회수 증가를 위해 링크 클릭시 데이터 호출(getLinkTypeData) */

export default function ListItemDivision({ data }) {
  // const [linkTypeData, setLinkTypeData] = useState()
  const comparison = useDateComparison(data.saleEndDate)

  const getLinkTypeData = (eventSeq) => {
    api
      .get(`/v2/event/${eventSeq}`)
      .then((response) => {
        // setLinkTypeData(response.data.data)
        // TODO : 링크타입 이벤트 조회수 개선 필요
        console.log(response)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  return (
    <>
      {data.eventType === 'LINK' ? (
        <a
          onClick={() => getLinkTypeData(data.eventSeq)}
          href={data.bannerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItem item={data} comparison={comparison} />
        </a>
      ) : (
        <Link
          to={{
            pathname: `/event/detail/${data.eventSeq}`
          }}
          state={{ bannerImage: data.bannerImageFilePath }}
        >
          <ListItem item={data} comparison={comparison} />
        </Link>
      )}
    </>
  )
}
