import React from 'react'

import styles from './Satisfaction.module.scss'
const BgStarOrange =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_star_120x24_orange.png'
const BgStarGray = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_star_120x24g.png'

export default function Satisfaction({ type, width, height, per }) {
  return (
    <div
      className={styles.starPoint}
      style={{
        display: type ? type : 'block',
        width: width ? `${width}rem` : '10rem',
        height: height ? `${height}rem` : '2rem'
      }}
    >
      <div className={styles.starTrack} style={{ backgroundImage: `url(${BgStarGray})` }}></div>
      <div
        className={styles.starFill}
        style={{
          width: per ? `${per * 20}%` : '0%',
          backgroundImage: `url(${BgStarOrange})`
        }}
      ></div>
    </div>
  )
}
