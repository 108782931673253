import { v4 as uuid } from 'uuid'

import { sortOrderValues } from 'pages/searchResult/config'

import styles from '../searchResult.module.css'

export default function SortOrder({ listLength, sortOrder, onChange }) {
  return (
    <div className={styles.filterRow}>
      <span className={styles.count}>총 {listLength}개</span>
      {listLength > 0 && (
        <div className={styles.orderButtons}>
          {sortOrderValues.map(({ value, title }) => (
            <button
              key={uuid()}
              className={value === sortOrder ? styles.activeButton : ''}
              onClick={() => onChange(value)}
            >
              {title}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
