import { useRef, useState, useCallback } from 'react'

import ModalPortal from 'ModalPortal'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import ModalVod from 'components/modal/ModalVod'

import 'swiper/swiper.scss'
import SectionTitle from './SectionTitle'
import { vodListValues } from '../config'
import styles from './VodReviewSection.module.scss'

SwiperCore.use([Pagination, Autoplay])
//data

export default function VodReviewSection() {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const [modalOpen, setModalOpen] = useState(false)
  const [mediaKey, setMediaKey] = useState(null)
  const handleOpenModal = useCallback((url) => {
    document.body.classList.add('modal-open')
    setModalOpen(true)
    setMediaKey(url)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalOpen(false)
  }, [])

  return (
    <>
      <section className={styles.vodSection}>
        <div className={styles.innerCol}>
          <SectionTitle title={'리얼 성장 스토리로\n‘할 수 있는’ 자신감 UP!'} />
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            breakpoints={{
              1024: {
                slidesPerView: 2
              },
              320: {
                slidesPerView: 1.5
              }
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
          >
            {vodListValues?.map((list, idx) => {
              return (
                <SwiperSlide key={list.sortOrder}>
                  <button
                    type="button"
                    onClick={() => {
                      handleOpenModal(list.linkPath)
                    }}
                  >
                    <img src={list.imagePath1} alt="" />
                    <div className={styles.vodInfoBox}>
                      <p>{list.subTitle}</p>
                      <strong>{list.title}</strong>
                    </div>
                  </button>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className={styles.btnWrap}>
            <button
              type="button"
              className={`${styles.btnSlide} ${styles.prev}`}
              ref={navigationPrevRef}
            >
              <em className="blind">PREV</em>
            </button>
            <button
              type="button"
              className={`${styles.btnSlide} ${styles.next}`}
              ref={navigationNextRef}
            >
              <em className="blind">NEXT</em>
            </button>
          </div>
        </div>
        {modalOpen ? (
          <ModalPortal>
            <ModalVod btnClose handleCloseModal={handleCloseModal} iframeUrl={mediaKey} />
          </ModalPortal>
        ) : null}
      </section>
    </>
  )
}
