import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

import styles from './QuestionSection.module.css'
import { partTitleValues, testPartIntroValues } from '../../config'

export default function PartIntro({ partIndex, onClickStart }) {
  const partInfo = testPartIntroValues[partIndex - 1]

  if (!partInfo) return null

  return (
    <div className={styles.introContainer}>
      <p className={styles.introTitle}>{partInfo.title}</p>
      <p className={styles.introDescription}>{partInfo.description}</p>
      <p className={styles.introSubDescription}>{partInfo.subDescription}</p>
      <img
        className={styles.introImage}
        src={`${LTA_HOST_CDN}bg-test-part-intro-${partIndex}.gif`}
        alt="level"
      />
      <button className={styles.introStartButton} onClick={onClickStart}>
        {partTitleValues[partIndex - 1]} 테스트 시작하기
      </button>
      <p className={styles.introRelativeClass}>{partInfo.relativeClass}</p>
    </div>
  )
}
