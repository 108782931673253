import React, { useState, useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'

import styles from './Inquiry.module.scss'
import TabQna from './TabQna'
import ModalPortal from '../../../../src/ModalPortal'
import Lnb from '../../../components/Lnb'
import ModalMyInquiry from '../../../components/modal/ModalMyInquiry'
import NoContent from '../../../components/NoContent'
import Pagination from '../../../components/Pagination'
const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_qna.png'

export default function Inquiry() {
  const navigate = useNavigate()
  const [pageIdx, setPageIdx] = useState(1)
  const [modalopen, setModalopen] = useState(false)
  const [inquiryData, setInquiryData] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [dataKeyword, setDataKeyword] = useState({
    period: 3,
    answer: 'ALL'
  })

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  const handlePeriodCheck = (e) => {
    const targetValue = e.target.value
    setPageIdx(1)
    setDataKeyword({ ...dataKeyword, period: targetValue })

    getInquiryData(targetValue, dataKeyword.answer)
  }

  const handleAnswerStatusCheck = (e) => {
    const targetValue = e.target.value
    setPageIdx(1)
    setDataKeyword({ ...dataKeyword, answer: targetValue })

    getInquiryData(dataKeyword.period, targetValue)
  }

  const getInquiryData = (period, answerStatus) => {
    ;(answerStatus === 'ALL' && period === 'ALL'
      ? api.get(`/v2/inquiry/list?index=${pageIdx}&size=10`)
      : period === 'ALL' && answerStatus !== 'ALL'
      ? api.get(
          `/v2/inquiry/list?index=${pageIdx}&size=10&keys=answerYn&types=equal&values=${answerStatus}`
        )
      : period !== 'ALL' && answerStatus === 'ALL'
      ? api.get(
          `/v2/inquiry/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}`
        )
      : api.get(
          `/v2/inquiry/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}&keys=answerYn&types=equal&values=${answerStatus}`
        )
    )
      .then((response) => {
        setInquiryData(response.data.data.content)
        setTotalPages(response.data.data.totalPages)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getInquiryData(dataKeyword.period, dataKeyword.answer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIdx])

  return (
    <div className="qna-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader title="나의 문의" onClick={() => navigate('/mypage/1')} />
          <Lnb />
          <section className={styles.qnaListSection}>
            <TabQna activeTab={'INQUIRY'} />
            <div>
              <div className={styles.searchBox}>
                <ul className={styles.searchTxtList}>
                  <li className={styles.searchTxtItem}>
                    답변이 등록된 후에는 문의를 수정 또는 삭제하실 수 없습니다.
                  </li>
                </ul>
                <div className={styles.searchSelectBox}>
                  <select
                    name="inquiryPeriod"
                    id="inquiryPeriod"
                    value={dataKeyword.period}
                    onChange={handlePeriodCheck}
                  >
                    <option value="ALL">전체</option>
                    <option value="3">3개월</option>
                    <option value="6">6개월</option>
                    <option value="12">12개월</option>
                  </select>
                  <select
                    name="replyStatus"
                    id="replyStatus"
                    value={dataKeyword.answer}
                    onChange={handleAnswerStatusCheck}
                  >
                    <option value="ALL">전체</option>
                    <option value="Y">답변 완료</option>
                    <option value="N">답변 대기</option>
                  </select>
                </div>
              </div>
              <Desktop>
                <table
                  className={styles.tableBox}
                  style={{ borderBottom: inquiryData.length > 0 ? '0.1rem solid #b7b7c8' : 'none' }}
                >
                  <colgroup>
                    <col width="24%" />
                    <col width="" />
                    <col width="20%" />
                    <col width="12%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>분류</th>
                      <th>문의 내용</th>
                      <th>작성일</th>
                      <th>답변여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inquiryData.length > 0 ? (
                      inquiryData.map((inquiryItem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className="ellipsis">{inquiryItem.categoryName}</p>
                            </td>
                            <td>
                              <Link to={`/mypage/qna/inquiry/detail/${inquiryItem.inquirySeq}`}>
                                <strong className="ellipsis">{inquiryItem.contents}</strong>
                              </Link>
                            </td>
                            <td>
                              <p>{formatDate(inquiryItem.registDate)}</p>
                            </td>
                            <td>
                              <p
                                className={
                                  styles.check +
                                  (inquiryItem.answerYn === 'Y' ? ' ' + styles.completed : '')
                                }
                              >
                                {inquiryItem.answerYn === 'Y' ? '답변완료' : '답변대기'}
                              </p>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">
                          <NoContent
                            icoImg={icoImg}
                            titleMsg={'등록된 문의가 없습니다.'}
                            style={{ paddingBottom: 0 }}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Desktop>
              <Mobile>
                <div className="table-form-mobile">
                  {inquiryData.length > 0 ? (
                    <ul className="table-lists">
                      {inquiryData.map((inquiryItem, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/mypage/qna/inquiry/detail/${inquiryItem.inquirySeq}`}>
                              <span className="ellipsis">{inquiryItem.categoryName}</span>
                              <strong className="ellipsis line-clamp-2">
                                {inquiryItem.contents}
                              </strong>
                              <div className="info">
                                <span
                                  className={
                                    'check' + (inquiryItem.answerYn === 'Y' ? ' completed' : '')
                                  }
                                >
                                  {inquiryItem.answerYn === 'Y' ? '답변완료' : '답변대기'}
                                </span>
                                <span className="date">{formatDate(inquiryItem.registDate)}</span>
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <NoContent icoImg={icoImg} titleMsg={'등록된 문의가 없습니다.'} />
                  )}
                </div>
              </Mobile>

              <div
                className={styles.btnBox}
                style={{ margin: inquiryData.length > 0 ? '5rem auto' : '0 auto 5rem auto' }}
              >
                <button className="btn active" type="button" onClick={handleOpenModal}>
                  문의하기
                </button>
              </div>
              {inquiryData.length > 0 && (
                <Pagination
                  type={'IDX'}
                  handleCurrentIdx={handleCurrentIdx}
                  totalPages={totalPages}
                  limitPage={5}
                  maxNum={5}
                  minNum={0}
                  pageIdx={pageIdx}
                />
              )}
              {modalopen ? (
                <ModalPortal>
                  <ModalMyInquiry btnClose handleCloseModal={handleCloseModal} />
                </ModalPortal>
              ) : null}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
