import React, { useState, useEffect } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { useParams, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import api from 'common/api'
import BoardBanner from 'components/board/BoardBanner'
import BoardListReviewItem from 'components/board/BoardListReviewItem'
import ImgView from 'components/ImgView'

import AdminAnswer from './AdminAnswer'
import CommentReview from './CommentReview'
import styles from './DetailBoardReview.module.scss'
import SkeletonReview from './SkeletonReview'
const BgBannerSrr =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_board_banner_srr.png'
const ImgBannerSrr =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_board_banner_srr_356x356.png'

export default function DetailBoard({ type }) {
  const boardDetailInfo = {
    REVIEW: {
      title: `학습지 후기`,
      contents: `하루 한 장으로 영어가 스르르~\n스르르 학습지의 영어 소통 공간입니다.`,
      bgFullbox: `url(${BgBannerSrr}) repeat-y 50% 50% / 100% auto`,
      bgInnerBox: `url(${ImgBannerSrr}) no-repeat 100% 50% / 17.8rem auto`,
      apiContentUrl: '/v2/community-tab/srr-learning-review/detail?learningReviewSeq=',
      apiCommentUrl: '/v2/community-tab/srr-learning-review/comment/list?learningReviewSeq=',
      apiPrevNextUrl: '/v2/community-tab/community/move-and-post?communityPostSeq='
    }
  }[type]

  const location = useLocation()
  const param = location.search
  const { currentSeq } = useParams()
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [communityInfo, setCommunityInfo] = useState(null)
  const [commentData, setCommentData] = useState(null)

  const [commentRegistTime, setCommentRegistTime] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // 커뮤니티 상세 정보
  const getCommunityInfo = (seq) => {
    api
      .get(`${boardDetailInfo.apiContentUrl}${seq}`)
      .then((response) => {
        setCommunityInfo(response.data.data)
        setIsLoading(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 댓글 조회 API
  const getCommentData = (seq) => {
    api
      .get(`${boardDetailInfo.apiCommentUrl}${seq}&pageIndex=1&pageSize=100`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentData(response.data.data?.comments)
          setCommentRegistTime(response.data.meta.dateTime.replace('-', '/').replace('-', '/'))
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCommunityInfo(currentSeq)
    getCommentData(currentSeq)
    setIsLoading(false)
    document.body.classList.remove('modal-open')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeq])

  return (
    <>
      <BoardBanner
        title={boardDetailInfo.title}
        contents={boardDetailInfo.contents}
        bgFullbox={boardDetailInfo.bgFullbox}
        bgInnerBox={boardDetailInfo.bgInnerBox}
      />

      <div className={styles.detailBoardBox}>
        <div className={styles.cardInner}>
          {!isLoading ? (
            <SkeletonTheme baseColor="#f7f7fc" highlightColor="#eeeeee">
              <SkeletonReview deviceCheck={deviceCheck} />
            </SkeletonTheme>
          ) : (
            <div className={styles.boardListReviewBox}>
              {communityInfo && (
                <>
                  <BoardListReviewItem
                    deviceCheck={deviceCheck}
                    title={communityInfo.classes.name}
                    contents={communityInfo.contents}
                    registDate={communityInfo.registDate}
                    backgroundImage={communityInfo.registUser.profileImageUrl}
                    userName={communityInfo.registUser.nickName}
                    satisfaction={communityInfo.satisfaction}
                  />
                  <ImgView data={communityInfo} isLoading={isLoading} />
                </>
              )}
              {communityInfo && communityInfo?.answer && (
                <AdminAnswer
                  data={communityInfo.answer}
                  deviceCheck={deviceCheck}
                  backgroundColor={'#F7F7FC'}
                  type={type}
                />
              )}
              <CommentReview
                deviceCheck={deviceCheck}
                getCommentData={getCommentData}
                data={commentData}
                commentRegistTime={commentRegistTime}
                isLoading={isLoading}
                communityInfo={communityInfo}
                type={type}
                currentSeq={currentSeq}
              />
            </div>
          )}
          <Link className={styles.styledLink} to={`/community/srr/${type.toLowerCase()}?${param}`}>
            목록보기
          </Link>
        </div>
      </div>
    </>
  )
}
