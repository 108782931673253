import { useCallback, useEffect, useState } from 'react'

import api from 'common/api'

import { AINADOO_CONFIG } from '../config'

export default function usePostSurvey(payload) {
  const { API_HOST } = AINADOO_CONFIG
  const [surveySuccess, setSurveySuccess] = useState(false)

  const postSurvey = useCallback(
    async ({ star, tag, message = '' }) => {
      const dataBody = {
        classSeq: payload.classSeq,
        lectureSeq: payload.lectureSeq,
        userSeq: payload.userSeq,
        star,
        tag,
        message
      }

      try {
        const response = await api.post(API_HOST.survey, dataBody)

        if (response.status === 200) {
          setSurveySuccess(true)
        } else {
          setSurveySuccess(false)
        }
      } catch (e) {
        console.error('🚀 Post Survey Error:', e.message, {
          payload: dataBody,
          apiUrl: API_HOST.survey
        })
      }
    },
    [payload, API_HOST]
  )

  useEffect(() => {
    if (surveySuccess) {
      const timer = setTimeout(() => {
        setSurveySuccess(false)
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [surveySuccess])

  return { postSurvey, surveySuccess }
}
