import React from 'react'
import '../../assets/styles/modal.scss'

import styles from './ModalNotice.module.scss'

export default function ModalNotice({ title, btnClose, handleCloseModal }) {
  return (
    <div className="modal-wrap">
      <div className="dimmed" onClick={handleCloseModal}></div>
      <div className="modal-inner">
        {btnClose && (
          <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
            <i className="blind">닫기</i>
          </button>
        )}
        <div className="modal-header">
          <strong>{title}</strong>
        </div>
        <div className="modal-content">
          <div className={styles.noticeBox}>
            <dl>
              <dt>
                야나두는 귀하의 이벤트 및 문의에 대한 처리 및 회신을 위하여 귀하의 개인정보를 수집
                및 처리하고자 합니다. 내용을 자세히 읽으신 후 동의여부를 결정하여 주시기 바랍니다.
              </dt>
              <dd>
                1. 개인정보 수집 이용목적
                <br />
                - 야나두 교육 상품 안내, 고객문의(상담신청) 처리 및 회신
                <br />- 야나두 피트니스 상품 안내, 고객문의(상담신청) 처리 및 회신
              </dd>
              <dd>
                2. 개인정보 수집 항목
                <br />- 이름, 휴대전화번호
              </dd>
              <dd>
                3. 개인정보 이용기간 및 보유기간
                <br />- 상담 완료 후 파기
              </dd>

              <dd>
                4. 동의를 거부할 권리 및 동의 거부에 따른 불이익(TM 동의 후 철회 방법)
                <br />- 귀하는 개인정보 수집 및 이용동의에 대하여 동의를 거부할 권리가 있으며,
                동의를 거부하는 경우 상담신청이 불가능합니다.
                <br />- TM 동의 후 철회를 원하실 경우 야나두 고객센터(1600-0563)으로 연락주시면
                언제든 철회 가능합니다.
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
