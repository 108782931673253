import { useEffect, useState, useRef } from 'react'

import api from 'common/api'
import Loading from 'components/common/Loading'

import styles from './ModalImageCollectAll.module.scss'

const ModalImageCollectAll = ({
  type,
  title,
  btnClose,
  handleCloseModal,
  handleOpenImageDetailModal,
  apiInfo,
  categoryData,
  parseCategoryCodeList
}) => {
  const imageCountRef = useRef(28)
  const scrollCheckRef = useRef()
  const [cardImages, setCardImages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [lastCheckFlag, setLastCheckFlag] = useState(false)
  const [imageIdx, setImageIdx] = useState(1)

  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0
  }

  const getAttachments = (idx, size, category) => {
    let categoryCode
    if (category) {
      categoryCode = parseCategoryCodeList(categoryData, 'categories')
    }
    ;(type === 'SRR'
      ? api.get(apiInfo.apiAttachmentsUrl + `${idx}&pageSize=${size}`)
      : api.get(apiInfo.apiAttachmentsUrl + `${idx}&pageSize=${size}${categoryCode}`)
    )
      .then((response) => {
        if (response.data.data) {
          setIsLoading(false)
          handleMoreData(response.data.data?.attachments)
        } else {
          setLastCheckFlag(true)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleMoreIndex = () => {
    setImageIdx((prev) => prev + 1)
  }

  const handleMoreData = (data) => {
    const imageData = [...new Set(data.map(JSON.stringify))].map(JSON.parse)
    setCardImages([...cardImages, ...imageData])
  }

  useEffect(() => {
    if (type === 'SRR') {
      getAttachments(imageIdx, imageCountRef.current)
    } else if (type === 'REAL' && categoryData) {
      getAttachments(imageIdx, imageCountRef.current, categoryData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isLoading && !lastCheckFlag) {
      if (type === 'SRR') {
        getAttachments(imageIdx, imageCountRef.current)
      } else if (type === 'REAL' && categoryData) {
        getAttachments(imageIdx, imageCountRef.current, categoryData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIdx, categoryData])

  useEffect(() => {
    if (!isLoading && !lastCheckFlag) {
      //로딩되었을 때만 실행
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleMoreIndex()
        }
      }, options)

      observer.observe(scrollCheckRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className="modal-wrap modal-image">
      <div className="dimmed" onClick={handleCloseModal}></div>
      <div className="modal-inner">
        {btnClose && (
          <button type="button" className={styles.buttonClose} onClick={handleCloseModal}>
            <i className="blind">닫기</i>
          </button>
        )}
        <div className="modal-header">
          <strong>{title}</strong>
        </div>
        <div className="modal-content">
          {!isLoading && cardImages.length > 0 ? (
            <ul className={styles.cardLists}>
              {cardImages
                .filter((item) => item.url !== '')
                .map((cardImage, idx) => {
                  return (
                    <li key={idx} style={{ backgroundImage: `url(${cardImage.url})` }}>
                      {cardImage?.count > 1 && <span>{cardImage?.count}</span>}
                      <button
                        onClick={() => {
                          handleOpenImageDetailModal(cardImage)
                          handleCloseModal()
                        }}
                      >
                        <i className="blind">상세 보기 팝업</i>
                      </button>
                    </li>
                  )
                })}
            </ul>
          ) : (
            <Loading />
          )}
          <div ref={scrollCheckRef}></div>
        </div>
      </div>
    </div>
  )
}

export default ModalImageCollectAll
