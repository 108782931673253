import { useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'

export default function CustomOrder() {
  const { seq } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const makeCustomOrder = () => {
    const query = new URLSearchParams(location.search)
    const URL_CART = '/mypage/order/cart'
    const errorReturnUrl = query.get('errorReturnUrl') || URL_CART

    // 주문번호 재셋팅을 위한 productParam 값 셋팅.
    window.sessionStorage.setItem(
      'productParam',
      JSON.stringify({ param: `custom/${seq}`, orderReset: false })
    )

    api
      .post(`/payment/v2/order/custom/${seq}`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          navigate(`/mypage/order/cart/detail/${response.data.data}`)
        } else {
          if (response.data.meta.message) {
            alert(response.data.meta.message)
          } else {
            alert(config.MESSAGE['common-error'])
          }
          window.location.href = errorReturnUrl
        }
      })
      .catch((e) => {
        console.log(e)
        alert(config.MESSAGE['common-error'])
        window.location.href = errorReturnUrl
      })
  }

  useEffect(() => {
    makeCustomOrder()
  }, [])

  return null
}
