export const scrollMove = (x, y) => {
  window.scrollTo(x, y)
}

// 현재시간과 비교가 필요한 날짜형식을 매개변수로 갖고 있는 함수
// 인자값 yyyy/mm/dd hh:mm:ss
// 1분미만 : 방금전 / 1시간 미만 : n분전 / 24시간 미만 : n시간 전 / 그 외 yyyy/mm/dd 오전or오후 hh:mm 라는 문구로 리턴
export const timeCheck = (nowTime, targetTime) => {
  let gapSec = (new Date(nowTime) - new Date(targetTime)) / 1000
  if (gapSec > 86400) {
    return (
      targetTime.split(' ')[0].replace('-', '.').replace('-', '.') +
      ' ' +
      (Number(targetTime.split(' ')[1].split(':')[0]) >= 12 ? '오후 ' : '오전') +
      (Number(targetTime.split(' ')[1].split(':')[0]) > 12
        ? Number(targetTime.split(' ')[1].split(':')[0]) - 12
        : Number(targetTime.split(' ')[1].split(':')[0])) +
      ':' +
      targetTime.split(' ')[1].split(':')[1]
    )
  } else if (gapSec <= 60) {
    return '방금전'
  } else if (gapSec <= 3600) {
    return Math.floor(gapSec / 60) + '분전'
  } else if (gapSec <= 86400) {
    return Math.floor(gapSec / 3600) + '시간전'
  }
}

export const isEmpty = (val) => {
  return (
    val === undefined ||
    val === null ||
    val === '' ||
    val.trim() === '' ||
    (val !== null && typeof val === 'object' && !Object.keys(val).length)
  )
}

export const addComma = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
