import React from 'react'
import { Link } from 'react-router-dom'

import styles from './BoardListItem.module.scss'

// [props 정보]
// best : 베스트 게시물 여부 (boolean)
// subText : {강의이름} || <span>YYYY.MM.DD</span>
// contents : 게시물 내용 text (string)
// subInfo : <em>답변완료</em> YYYY.MM.DD || <span>조회수 {number}</span><span>좋아요 {number}</span><span>댓글 {number}</span>
// userName : {닉네임}
export default function BoardListItem({
  best,
  link,
  subTextTitle,
  subTextDate,
  contents,
  subInfo,
  userName
}) {
  return (
    <li className={styles.boardItemBox}>
      <Link to={link} className={best ? styles.best : ''}>
        <p>
          <strong>
            {subTextTitle}
            {subTextDate}
          </strong>
          {contents}
        </p>
        <div className={styles.itemInfo}>
          <strong>{subInfo}</strong>
          <span>{userName}</span>
        </div>
      </Link>
    </li>
  )
}
