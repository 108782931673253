import axios from 'axios'
import AuthService from 'services/authService'

import * as config from 'common/config'

const instance = axios.create({
  baseURL: config.API_HOST,
  headers: {
    'Content-Type': 'application/json'
  }
})

const forceSignIn = (error) => {
  AuthService.removeUserInfo()
  // alert(config.MESSAGE['unauthorized']);
  window.location.href = config.SIGN_URL['sign-in']
  // return false;
  return Promise.reject(error)
}

instance.interceptors.request.use(
  (requestConfig) => {
    if (requestConfig.url !== config.SIGN_URL['get-refresh-token']) {
      const token = AuthService.getLocalAccessToken()
      if (token) {
        requestConfig.headers['Authorization'] = 'Bearer ' + token
      }
    }

    return requestConfig
  },
  (error) => {
    return Promise.reject(error)
  }
)
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log(error)
    const orgConfig = error.config

    if (
      orgConfig.url !== config.SIGN_URL['get-token'] &&
      orgConfig.url !== config.SIGN_URL['get-refresh-token'] &&
      orgConfig.url !== config.SIGN_URL['sign-out'] &&
      error.response
    ) {
      if (error.response.status === 403) {
        AuthService.removeUserInfo()
        window.location.reload()
        return
      }

      if (error.response.status === 401 && !orgConfig.isRetry) {
        const originalRequest = orgConfig
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token
              return instance(originalRequest)
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        }

        isRefreshing = true
        originalRequest.isRetry = true

        try {
          const response = await instance.post(config.SIGN_URL['get-refresh-token'], null, {
            headers: {
              Authorization: 'Bearer ' + AuthService.getLocalRefreshToken()
            }
          })

          if (response.status !== 200 || response.data.meta.code !== 200) {
            AuthService.signOut()

            if (response.data.meta.message) {
              alert(response.data.meta.message)
            } else {
              alert(config.MESSAGE['common-error'])
            }
            return Promise.reject(error)
          }

          AuthService.setLocalAccessToken(response.data.data.accessToken)
          AuthService.setCookieAccessToken(response.data.data.accessToken)
          isRefreshing = false
          processQueue(null, response.data.data.accessToken)
          return instance(originalRequest)
        } catch (e) {
          isRefreshing = false
          processQueue(e, null)
          // 토큰 갱신 실패
          forceSignIn(e)
          return
        }
      }
    } else {
      console.error(config.MESSAGE['common-error'])
      // alert(config.MESSAGE["common-error"]);
    }

    return Promise.reject(error)
  }
)

export default instance
