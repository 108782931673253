import React from 'react'
import Lottie from 'react-lottie-player'

import cx from 'classnames'

import useChatFeedListStore from 'store/useChatFeedListStore'

import s from './Chat.module.scss'
import ChatFeedItem from './ChatFeedItem'
import InitMessage from './InitMessage'
import LottieData from '../CircleLoading.json'
import { AI_FAQ_CONFIG } from '../config'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function ChatFeedList({ isAiResponse, addToFeed }) {
  const { feedList } = useChatFeedListStore()

  return (
    <ul className={s.feedList}>
      <InitMessage />
      {feedList.map(({ type, message, subContents }, index) => (
        <li className={cx(s.feedItem, s[type.toLowerCase()])} key={index}>
          <ChatFeedItem
            type={type}
            message={message}
            isAiResponse={isAiResponse}
            subContents={type === 'AI' && subContents ? subContents : []}
            addToFeed={addToFeed}
          />
        </li>
      ))}
      {isAiResponse && (
        <li className={cx(s.feedItem, s.srrLoading)}>
          <div className={s.aiMessage}>
            <div className={s.msgThumb}>
              <img src={AI_FAQ_CONFIG.AI_PROFILE_THUMB} alt="AI 상담원" />
            </div>
            <dl>
              <dt className={s.msgName}>AI 상담원</dt>
              <dd className={s.message}>
                <div className={s.lottieBox}>
                  <Lottie {...defaultOptions} animationData={LottieData} />
                </div>
                답변을 생성 중입니다.
              </dd>
            </dl>
          </div>
        </li>
      )}
    </ul>
  )
}
