import React from 'react'

import cx from 'classnames'
import useGetFaqCategory from 'pages/service/hooks/useGetFaqCategory'

import s from './WebviewCustomer.module.scss'

export default function CustomerFaqCategory({ handleCategory, cate }) {
  const { category } = useGetFaqCategory('YANADOO_FAQ_GROUP')

  return (
    <div className={s.faqCategory}>
      {category.map((item, index) => (
        <button
          className={cx({ [s.active]: cate === item.code })}
          key={index}
          onClick={() => handleCategory(item.code)}
        >
          {item.value}
        </button>
      ))}
    </div>
  )
}
