import React from 'react'
import { Link } from 'react-router-dom'

import styles from './TabBoard.module.scss'

export default function TabBoard({ activeTab }) {
  return (
    <div className={styles.tabMenuBox}>
      <ul className={styles.tabMenuList}>
        <li className={`${styles.tabMenuItem} ${activeTab === 'LIST' ? styles.active : null}`}>
          <Link to="/community/srr/list">스르르 학습지 Talk</Link>
        </li>
        <li className={`${styles.tabMenuItem} ${activeTab === 'REVIEW' ? styles.active : null}`}>
          <Link to="/community/srr/review">학습지 후기</Link>
        </li>
      </ul>
    </div>
  )
}
