import React, { useEffect, useState } from 'react'

import cx from 'classnames'

import { AINADOO_CONFIG } from '../config'
import s from './styles/SrrTips.module.scss'

export default function SrrTips({ tips }) {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  if (!tips || tips.length < 1) return null

  return (
    <div className={s.srrTip}>
      <hgroup className={s.tipHeader}>
        <h4>
          <img
            className={s.tipImg}
            src={`${AINADOO_CONFIG.IMG_HOST}ico_tip.svg`}
            alt="오늘의 강의 요약"
          />
          오늘의 강의 요약
        </h4>
        <button className={cx(s.btnArrow, { [s.open]: open })} onClick={() => setOpen(!open)}>
          toggle button
        </button>
      </hgroup>
      <div className={cx(s.tipListWrap, { [s.open]: open })}>
        <ul className={s.tipList}>
          {tips.map((tip, index) => (
            <li key={index}>{tip}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
