import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import styles from './BoardSkeleton.module.scss'

export default function BoardSkeleton() {
  return (
    <div className={styles.boardBox}>
      <SkeletonTheme baseColor="#f4f4f4" highlightColor="#f8f8f8">
        <div className={styles.boardInfo}>
          <p>
            <Skeleton width={'30%'} />
          </p>
        </div>
        <div className={styles.boardList}>
          <ul>
            {new Array(5).fill('').map((item, idx) => {
              return (
                <li key={idx}>
                  <div className={styles.skeletonBox}>
                    <dl>
                      <dt>
                        <Skeleton width={'18rem'} />
                      </dt>
                      <dd>
                        <Skeleton width={'6rem'} />
                      </dd>
                    </dl>
                    <Skeleton />
                    <dl>
                      <dt>
                        <Skeleton width={'4rem'} inline={true} />
                        <Skeleton width={'4rem'} inline={true} />
                        <Skeleton width={'4rem'} inline={true} />
                      </dt>
                      <dd>
                        <Skeleton width={'8rem'} />
                      </dd>
                    </dl>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </SkeletonTheme>
    </div>
  )
}
