import React, { useState, useRef, useEffect } from 'react'
import Lottie from 'react-lottie-player'

import LottieData from 'pages/aiTalk/lottieSound.json'

import api from 'common/api'
import { YANADOO_AI_HOST } from 'common/config'
import useIsAudioState from 'store/useIsAudioStore'

import s from './ButtonAudio.module.css'
import { AI_CDN_HOST } from '../config'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const ButtonAudio = ({ text, yellow = false, promptType, userSeq, autoplay }) => {
  const [audioBlob, setAudioBlob] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const audioRef = useRef(null)
  const { isAudioState } = useIsAudioState()

  // 오디오 블롭 설정 및 재생 함수
  const handleSetAndPlayAudio = async (text) => {
    if (isPlaying && audioRef.current) {
      audioRef.current.pause() // 현재 재생 중인 오디오를 일시 정지
      audioRef.current.currentTime = 0 // 재생 위치를 처음으로
      setIsPlaying(false)
    }

    if (!audioBlob) {
      try {
        const response = await api.post(
          `${YANADOO_AI_HOST}/audio`,
          {
            message: text,
            promptType: promptType ? promptType : '',
            userSeq: userSeq ? userSeq : ''
          },
          {
            responseType: 'arraybuffer', // 응답 타입을 arraybuffer로 설정
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        const blob = new Blob([response.data], { type: 'audio/mpeg' })
        setAudioBlob(blob)
        setIsFinished(false) // 새로운 오디오 블롭이 설정되면 재생 완료 상태를 초기화
        // 오디오 블롭이 설정된 후 재생
        const audioUrl = URL.createObjectURL(blob)
        audioRef.current.src = audioUrl
        // play() 호출은 Promise를 반환하므로 에러 핸들링
        audioRef.current
          .play()
          .then(() => {
            setIsPlaying(true) // 재생 시작
          })
          .catch((error) => {
            console.error('Error playing audio:', error)
          })
      } catch (error) {
        console.error('Error fetching audio:', error)
      }
    } else {
      // 기존 블롭 데이터로 재생
      const audioUrl = URL.createObjectURL(audioBlob)
      audioRef.current.src = audioUrl
      // play() 호출은 Promise를 반환하므로 에러 핸들링
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true) // 재생 시작
        })
        .catch((error) => {
          console.error('Error playing audio:', error)
        })
    }
  }

  // 오디오 재생 종료 처리 함수
  const handleAudioEnded = () => {
    setIsPlaying(false) // 재생 중 상태 업데이트
    setIsFinished(true) // 재생 완료 상태 업데이트
  }

  useEffect(() => {
    if (autoplay && text) {
      handleSetAndPlayAudio(text)
    }
  }, [autoplay, text])

  // isAudioState 값이 false일 때 오디오를 중단
  useEffect(() => {
    if (!isAudioState && isPlaying && audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
      setIsPlaying(false)
    }
  }, [isAudioState, isPlaying])

  return (
    <div className={s.speakerBox}>
      <button
        className={s.audioBtn}
        onClick={() => handleSetAndPlayAudio(text)}
        disabled={isPlaying}
      >
        {isPlaying ? (
          <div className={s.soundLottie}>
            <Lottie {...defaultOptions} animationData={LottieData} />
          </div>
        ) : (
          <img
            className={s.icoSpeaker}
            src={`${AI_CDN_HOST}${yellow ? 'ico_speaker_y.png' : 'ico_speaker.png'}`}
            alt="speaker"
          />
        )}
      </button>
      {audioBlob && (
        <audio
          ref={audioRef}
          className={s.audioPlayer}
          onEnded={handleAudioEnded} // 오디오 재생이 끝났을 때 호출
          onPlay={() => setIsPlaying(true)} // 재생 시작 시 상태 업데이트
          onPause={() => setIsPlaying(false)} // 일시 정지 시 상태 업데이트
        />
      )}
    </div>
  )
}

export default ButtonAudio
