import React, { useState } from 'react'
import { useLocation } from 'react-router'

import cx from 'classnames'
import FaqAnswer from 'pages/service/components/FaqAnswer'
import useGetFaqContent from 'pages/service/hooks/useGetFaqContent'

import s from './WebviewCustomer.module.scss'

export default function CustomerFaqList({ faqList }) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const faqSeq = Number(queryParams.get('faqSeq'))
  const [selected, setSelected] = useState(faqSeq ? faqSeq : null)
  const { answer } = useGetFaqContent(selected)

  const handleFaqSeq = (faqSeq) => {
    setSelected((prev) => (prev !== faqSeq ? faqSeq : null))
  }

  return (
    <section className={s.customerFaqList}>
      {faqList.map((faq, index) => (
        <dl key={index} className={cx({ [s.open]: selected && selected === faq.faqSeq })}>
          <dt onClick={() => handleFaqSeq(faq.faqSeq)}>
            <div dangerouslySetInnerHTML={{ __html: faq.title }} />
          </dt>
          <dd>
            <FaqAnswer answer={answer} />
          </dd>
        </dl>
      ))}
    </section>
  )
}
