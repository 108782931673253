import React from 'react'

import cx from 'classnames'
import s from 'pages/chatBot/ChatBot.module.scss'

import ChatFeed from './ChatFeed'

export default function ChatContainer({
  location,
  feed,
  isLoading,
  userSeq,
  messageBoxRef,
  mode,
  isSupported = true
}) {
  return (
    <section
      className={cx(s.chatContainer, { [s.audioChatContainer]: mode === 'audio' })}
      ref={messageBoxRef}
    >
      <div className={s.inner}>
        {location && location.state !== null && (
          <ChatFeed
            feed={feed}
            aiInfo={location.state.info}
            isLoading={isLoading}
            userSeq={userSeq}
            mode={mode}
            isSupported={isSupported}
          />
        )}
      </div>
    </section>
  )
}
