import React, { memo, useEffect, useMemo } from 'react'

import { feedbackTagsValues } from '../config'
import s from './styles/FeedbackTags.module.scss'

const Tag = memo(({ tagText, checked, handleChangeTags }) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => handleChangeTags(tagText, e.target.checked)}
      />
      <span>{tagText}</span>
    </label>
  )
})

export default function FeedbackTags({ rating, selectedTags, handleChangeTags, handleResetTags }) {
  const isAboveThreeStar = rating >= 3
  const tags = useMemo(
    () =>
      isAboveThreeStar ? feedbackTagsValues.above_three_star : feedbackTagsValues.below_three_star,
    [isAboveThreeStar]
  )

  useEffect(() => {
    const tagSet = new Set(tags)
    if (selectedTags.some((tag) => !tagSet.has(tag))) {
      handleResetTags()
    }
  }, [tags, selectedTags, handleResetTags])

  return (
    <div className={s.tagsBox}>
      <strong className={s.title}>
        {rating <= 2 ? '어떤 점이 아쉬웠나요?' : '어떤 점이 만족스러웠나요?'} (1개 이상)
      </strong>
      <div className={s.tags}>
        {tags.map((tag) => (
          <Tag
            key={tag}
            tagText={tag}
            checked={selectedTags.includes(tag)}
            handleChangeTags={handleChangeTags}
          />
        ))}
      </div>
    </div>
  )
}
