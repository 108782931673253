import React, { useEffect } from 'react'

import CommentView from 'components/CommentView'
import ImgView from 'components/ImgView'

import styles from './DetailReviewForm.module.scss'

export default function DetailReviewForm({ getReviewData, reviewData, isLoading }) {
  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  return (
    <>
      {reviewData && (
        <div className={styles.detailView}>
          <div className={styles.detailTxtBox}>
            <div className={styles.flexBox}>
              <div className={styles.leftBox}>
                <span
                  className={styles.thumbSpan}
                  style={{
                    backgroundImage: reviewData.profileUrl && `url(${reviewData.profileUrl})`
                  }}
                >
                  <i>썸네일</i>
                </span>
                <div>
                  <strong>{reviewData.nickName ? reviewData.nickName : reviewData.username}</strong>
                  <p>{reviewData.registDate}</p>
                </div>
              </div>
              <div className={styles.rightBox}>
                <div className={styles.starPoint}>
                  <span
                    style={{
                      width: reviewData.satisfaction ? reviewData.satisfaction * 20 + '%' : '0%'
                    }}
                  >
                    star point
                  </span>
                </div>
                {reviewData.title ? (
                  <p>
                    <strong>{reviewData.title}</strong>
                  </p>
                ) : (
                  <p>
                    <strong>장학금 후기</strong>
                  </p>
                )}
              </div>
            </div>
            <div className={styles.contents}>
              <p>{reviewData.contents}</p>
            </div>
          </div>
          <ImgView data={reviewData} isLoading={isLoading} />
          {reviewData.answerYn === 'Y' && reviewData.answer ? (
            <div className={styles.detailAnswer}>
              <div className={styles.detailAnswerContent}>
                <div className={styles.thumbArea}>
                  <span className={styles.thumb}>
                    <i className="blind">썸네일</i>
                    {reviewData.answer.answerThumbnailPath && (
                      <img src={reviewData.answer.answerThumbnailPath} alt="" />
                    )}
                  </span>
                  <div className={styles.desc}>
                    <strong>{reviewData.answer.answerUserName}</strong>
                    <p>{formatDate(reviewData.answer.answerDate)}</p>
                  </div>
                </div>{' '}
                <pre
                  className={styles.answerTxt}
                  dangerouslySetInnerHTML={{
                    __html: reviewData.answer.answerContent
                  }}
                />
                {reviewData.image && <img src={reviewData.image} alt="" />}
              </div>
            </div>
          ) : null}
          <CommentView getReviewData={getReviewData} data={reviewData} isLoading={isLoading} />
        </div>
      )}
    </>
  )
}
