import React from 'react'
import { Link } from 'react-router-dom'

import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'assets/styles/banner.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import ResponsiveImage from 'components/ResponsiveImage'

import styles from './StoreBanner.module.scss'

SwiperCore.use([Pagination, Autoplay])

const StoreBanner = ({ autoPlay, heightPC, bannerData }) => {
  return (
    <>
      {bannerData?.length > 0 && (
        <section className={styles.bannerBox}>
          <Swiper
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={
              autoPlay
                ? {
                    delay: 3000,
                    disableOnInteraction: false
                  }
                : ''
            }
          >
            {bannerData?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link
                    to={item.link}
                    style={{
                      backgroundColor: item.bgColor
                    }}
                    target={item.linkType === 'NEW' ? '_blank' : '_self'}
                  >
                    <ResponsiveImage
                      mo={item.bannerFileMobile}
                      pc={item.bannerFilePc}
                      maxWidth={1024}
                    />
                  </Link>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>
      )}
    </>
  )
}

export default StoreBanner
