import React, { useState, useCallback, useEffect, useRef } from 'react'

import ModalPortal from 'ModalPortal'

import styles from './CardImage.module.scss'
import ModalImageCollectAll from './modal/ModalImageCollectAll'
import ModalImageDetail from './modal/ModalImageDetail'
const icoNoImage = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_image.png'

export default function CardImage({
  type,
  isLoading,
  deviceCheck,
  cardImages,
  cardImagesLength,
  categoryData,
  parseCategoryCodeList
}) {
  const [count, setCount] = useState(9) // 카드 기본 노출 개수
  const defaultCountRef = useRef(cardImagesLength)
  const [seq, setSeq] = useState({
    learningReviewSeq: null,
    scholarshipSeq: null,
    scholarshipRefundHistorySeq: null
  })

  const apiInfo = {
    SRR: {
      apiAttachmentsUrl: `/v2/community-tab/srr-learning-review/attachments?pageIndex=`,
      apiDetailUrl: `/v2/community-tab/srr-learning-review/detail?learningReviewSeq=`,
      apiRollingUrl: '/v2/community-tab/srr-learning-review/rolling/attachment?learningReviewSeq=',
      apiPrevNextUrl: '/v2/community-tab/srr-learning-review/move-and-detail?moveType='
    },
    REAL: {
      apiAttachmentsUrl: `/v2/review/real-time/learning-and-scholarship/attachments?pageIndex=`,
      apiDetailUrl: `/v2/review/detail/`,
      apiDetailScholarshipUrl: `/v1/scholarship/scholarshipReview/withoutUser/`,
      apiRollingUrl: '/v2/review/real-time/learning-and-scholarship/rolling/attachment?reviewType=',
      apiPrevNextUrl: '/v2/review/real-time/learning-and-scholarship/move?moveType='
    }
  }[type]

  const handleSettingSeq = (data) => {
    data.scholarshipSeq
      ? setSeq({
          learningReviewSeq: null,
          scholarshipSeq: data.scholarshipSeq,
          scholarshipRefundHistorySeq: data.scholarshipRefundHistorySeq
        })
      : setSeq({
          learningReviewSeq: data.learningReviewSeq,
          scholarshipSeq: null,
          scholarshipRefundHistorySeq: null
        })
  }

  const [modalOpenCollectAll, setModalOpenCollectAll] = useState(false)
  const [modalOpenImageDetail, setModalOpenImageDetail] = useState(false)

  const handleOpenImageDetailModal = useCallback((data) => {
    document.body.classList.add('modal-open')
    handleSettingSeq(data)
    setModalOpenImageDetail(true)
  }, [])
  const handleCloseImageDetailModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalOpenImageDetail(false)
  }, [])

  const handleOpenImageCollectAllModal = useCallback(() => {
    document.body.classList.add('modal-open')
    setModalOpenCollectAll(true)
  }, [])
  const handleCloseImageCollectAllModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalOpenCollectAll(false)
  }, [])

  useEffect(() => {
    if (!isLoading && cardImagesLength < count) {
      // 카드 이미지의 배열의 길이를 countRef 세팅
      cardImages.length = count
      // cardImages 배열의 empty를 { url: '' }로 세팅
      cardImages.fill({ url: '' }, cardImagesLength)
    }

    setCount(deviceCheck ? 6 : 9)
  }, [isLoading, deviceCheck, cardImagesLength, cardImages, count])

  return (
    <>
      {!isLoading && (
        <div className={styles.cardImageBox}>
          <ul>
            {cardImages
              .filter((_, idx) => idx < count)
              .map((cardImage, idx) => {
                return (
                  <li
                    key={idx}
                    className={cardImage.url === '' ? `${styles.noImage}` : ''}
                    style={
                      cardImage.url === ''
                        ? { backgroundImage: `url(${icoNoImage})` }
                        : { backgroundImage: `url(${cardImage.url})` }
                    }
                  >
                    {cardImage?.count > 1 && <span>{cardImage?.count}</span>}
                    {/* cardImages의 length가 (PC:10개 / MO:7개) 이상이고 마지막 카드 이미지라면 */}
                    {deviceCheck ? (
                      defaultCountRef.current > count && idx + 1 === count ? (
                        <button className={styles.btnMore} onClick={handleOpenImageCollectAllModal}>
                          <p className={styles.blind}>전체 보기 팝업</p>
                        </button>
                      ) : (
                        <button
                          onClick={
                            cardImage?.learningReviewSeq || cardImage?.scholarshipSeq
                              ? () => {
                                  handleOpenImageDetailModal(cardImage)
                                }
                              : undefined
                          }
                        >
                          <p className={styles.blind}>상세 보기 팝업</p>
                        </button>
                      )
                    ) : defaultCountRef.current > count && idx + 1 === count ? (
                      <button className={styles.btnMore} onClick={handleOpenImageCollectAllModal}>
                        <p className={styles.blind}>전체 보기 팝업</p>
                      </button>
                    ) : (
                      <button
                        onClick={
                          cardImage?.learningReviewSeq || cardImage?.scholarshipSeq
                            ? () => {
                                handleOpenImageDetailModal(cardImage)
                              }
                            : undefined
                        }
                      >
                        <p className={styles.blind}>상세 보기 팝업</p>
                      </button>
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      )}
      {/* 전체보기 모달 */}
      {modalOpenCollectAll ? (
        <ModalPortal>
          <ModalImageCollectAll
            type={type}
            btnClose
            title={'전체보기'}
            cardImages={cardImages}
            handleOpenImageDetailModal={handleOpenImageDetailModal}
            handleCloseModal={handleCloseImageCollectAllModal}
            apiInfo={apiInfo}
            categoryData={categoryData}
            parseCategoryCodeList={parseCategoryCodeList}
          />
        </ModalPortal>
      ) : null}
      {/* 상세보기 모달 */}
      {modalOpenImageDetail ? (
        <ModalPortal>
          <ModalImageDetail
            type={type}
            seq={seq}
            btnClose
            deviceCheck={deviceCheck}
            title={'이미지 후기'}
            cardImages={cardImages}
            handleOpenImageCollectAllModal={handleOpenImageCollectAllModal}
            handleCloseModal={handleCloseImageDetailModal}
            apiInfo={apiInfo}
            handleSettingSeq={handleSettingSeq}
            categoryData={categoryData}
            parseCategoryCodeList={parseCategoryCodeList}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
