import React from 'react'
import { Link } from 'react-router-dom'

import ThumbNail from 'pages/community/ThumbNail'

import styles from './BoardListReviewItem.module.scss'

// [props 정보]
// type : IMAGE (이미지 후기 모달)
// link : 이동 될 url
// backgroundImage : 썸네일 이미지 url(string)
// userName : {닉네임}
// registDate : 등록 날짜
// title : 게시물 타이틀
// contents : 게시물 내용 text (string)
export default function BoardListReviewItem({
  type,
  link,
  userName,
  registDate,
  title,
  contents,
  backgroundImage,
  satisfaction,
  deviceCheck
}) {
  return (
    <div className={`${styles.boardItemBox} ${type === 'IMAGE' ? styles.image : ''}`}>
      {link && <Link to={link}></Link>}
      <div className={styles.flexBox}>
        <div className={styles.leftBox}>
          <ThumbNail
            backgroundColor={'#fff'}
            backgroundImage={`${backgroundImage}`}
            size={deviceCheck ? '8.2rem' : '5.4rem'}
          />
          <div>
            <strong>{userName}</strong>
            <p>{registDate}</p>
          </div>
        </div>
        <div className={styles.rightBox}>
          <div className={styles.starPoint}>
            <span style={{ width: `${satisfaction ? satisfaction * 20 : 0}%` }}>star point</span>
          </div>
          <p>{title}</p>
        </div>
      </div>
      {contents && (
        <div>
          <p>{contents}</p>
        </div>
      )}
    </div>
  )
}
