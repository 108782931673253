import React, { useState, useEffect, useRef, useCallback } from 'react'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import CommunityInfo from 'components/CommunityInfo'
import ModalModify from 'components/modal/ModalModify'

import styles from './CommentReview.module.scss'
import CommentReviewItem from './CommentReviewItem'
import { timeCheck, isEmpty } from '../../common/common'

export default function CommentReview({
  getCommentData, // 댓글 조회 API
  deviceCheck,
  data,
  commentRegistTime,
  isLoading,
  communityInfo,
  type,
  currentSeq // 댓글 조회 시퀀스
}) {
  const [userInfo, setUserInfo] = useState(null)
  const commentInputRef = useRef()
  const [modalOpen, setModalOpen] = useState(false)
  const [commentValue, setCommentValue] = useState({
    relationSeq: currentSeq,
    parentSeq: 0, // 부모 댓글 seq
    commentInputValue: '', // 댓글 작성 input value
    writeCommentFlag: false, // 답글달기 작성중입니다 표시 flag
    nickName: '', // 답글달기 작성중입니다 유저 닉네임
    commentSeq: 0, // 수정,삭제 시 댓글 seq
    commentContents: '', // 수정 시 댓글 내용
    replyFlag: false // 댓글 수정, 삭제, 등록 시 대댓글 조회 flag
  })

  const handleOpenModal = useCallback((seq, contents) => {
    document.body.classList.add('modal-open')
    setModalOpen(true)
    setCommentValue({ ...commentValue, commentSeq: seq, commentContents: contents })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalOpen(false)
  }, [])

  // 모달에서 댓글 수정 시 발생함수
  const handleCommentModify = () => {
    handleCloseModal()
    commentInputRef.current.focus()
    setCommentValue({
      ...commentValue,
      commentInputValue: commentValue.commentContents,
      nickName: '댓글 수정중',
      writeCommentFlag: true
    })
  }

  // 유저정보 가져오기
  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(`${config.AUTH_API_HOST}/auth/v2/user`)
        .then((response) => {
          setUserInfo(response.data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  // 댓글달기 클릭 함수
  const handleOpenComment = () => {
    setCommentValue({
      relationSeq: currentSeq,
      parentSeq: 0,
      commentInputValue: '',
      writeCommentFlag: false,
      nickName: '',
      commentSeq: 0,
      commentContents: '',
      replyFlag: false
    })

    commentInputRef.current.focus()
  }

  // 답글달기 클릭 함수
  const handleOpenReply = (parentSeq, nickName) => {
    setCommentValue({
      relationSeq: currentSeq,
      parentSeq: parentSeq,
      commentInputValue: '',
      writeCommentFlag: true,
      nickName: nickName,
      commentSeq: 0,
      commentContents: '',
      replyFlag: false
    })

    commentInputRef.current.focus()
  }

  // 취소버튼 함수
  const handleCancel = () => {
    setCommentValue({
      relationSeq: currentSeq,
      parentSeq: 0,
      commentInputValue: '',
      writeCommentFlag: false,
      nickName: '',
      commentSeq: 0,
      commentContents: '',
      replyFlag: false
    })
  }

  // 댓글 등록
  const postComment = (e) => {
    e.preventDefault()

    // 로그인 여부 체크
    if (!AuthService.getUserInfo()) {
      alert('로그인이 필요합니다.')
      return false
    }

    if (isEmpty(commentValue.commentInputValue)) {
      alert('댓글을 입력해주세요.')
      commentInputRef.current.focus()
      return false
    }

    const data = {
      contents: commentValue.commentInputValue,
      parentSeq: commentValue.parentSeq,
      relationSeq: currentSeq,
      relationType: 'REVIEW'
    }
    api
      .post(`/v2/comment`, data)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentValue({
            relationSeq: currentSeq,
            parentSeq: 0,
            commentInputValue: '',
            writeCommentFlag: false,
            nickName: '',
            commentSeq: 0,
            commentContents: '',
            replyFlag: true
          })
          getCommentData(currentSeq)
        } else {
          alert(response.data.meta.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 댓글 삭제
  const deleteComment = (e, commentseq) => {
    e.preventDefault()

    api
      .delete(`/v2/comment/${commentseq}`)

      .then((response) => {
        if (response.data.meta.code === 200) {
          alert('삭제되었습니다.')
          handleCloseModal()
          setCommentValue({
            relationSeq: currentSeq,
            parentSeq: 0,
            commentInputValue: '',
            writeCommentFlag: false,
            nickName: '',
            commentSeq: 0,
            commentContents: '',
            replyFlag: true
          })
          getCommentData(currentSeq)
        } else {
          alert(response.data.meta.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 댓글 수정
  const putComment = (e) => {
    e.preventDefault()

    if (commentValue.commentContents === commentValue.commentInputValue) {
      alert('내용이 동일합니다. 수정해주세요!')
      return false
    }

    if (isEmpty(commentValue.commentInputValue)) {
      alert('댓글을 입력해주세요.')
      commentInputRef.current.focus()
      return false
    }

    const data = {
      commentSeq: commentValue.commentSeq,
      contents: commentValue.commentInputValue,
      duration: 0
    }

    api
      .put(`/v2/comment`, data)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentValue({
            relationSeq: currentSeq,
            parentSeq: 0,
            commentInputValue: '',
            writeCommentFlag: false,
            nickName: '',
            commentSeq: 0,
            commentContents: '',
            replyFlag: true
          })
          getCommentData(currentSeq)
        } else {
          alert(response.data.meta.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleChangeCommentInput = (e) => {
    const { value } = e.target
    setCommentValue({ ...commentValue, commentInputValue: value })
  }

  useEffect(() => {
    getUserInfo()
  }, [isLoading])

  useEffect(() => {
    setCommentValue({
      ...commentValue,
      replyFlag: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <CommunityInfo
        data={communityInfo}
        type={type}
        isLoading={isLoading}
        handleOpenComment={handleOpenComment}
      />
      <div className={styles.commentReviewBox}>
        {data &&
          data.map((comment, idx) => {
            return (
              <CommentReviewItem
                handleOpenReply={handleOpenReply}
                key={idx}
                backgroundImage={comment.registUser.profileImageUrl}
                nickName={comment.registUser.nickName}
                contents={comment.contents}
                likeCount={comment.likeCount}
                registDate={timeCheck(
                  commentRegistTime,
                  comment.registDate.replace('-', '/').replace('-', '/')
                )}
                existChildComment={comment.existChildComment}
                deviceCheck={deviceCheck}
                isLoading={isLoading}
                data={comment}
                commentRegistTime={commentRegistTime}
                getCommentData={getCommentData}
                userInfo={userInfo}
                handleOpenModal={handleOpenModal}
                replyFlag={commentValue.replyFlag}
                currentSeq={currentSeq}
              ></CommentReviewItem>
            )
          })}
      </div>
      <div className={styles.writeCommentBox}>
        {commentValue.writeCommentFlag && (
          <p>
            <strong className={commentValue.commentContents ? styles.disabled : ''}>
              {commentValue.nickName}
            </strong>
            <button onClick={handleCancel}>취소</button>
          </p>
        )}
        <form>
          <div>
            <input
              className={commentValue.commentInputValue ? styles.active : ''}
              value={commentValue.commentInputValue}
              placeholder={'댓글을 작성해주세요.'}
              onChange={handleChangeCommentInput}
              ref={commentInputRef}
            />
            <button onClick={commentValue.commentSeq ? putComment : postComment}>
              <i className="blind">댓글 등록하기</i>
            </button>
          </div>
        </form>
      </div>
      {modalOpen ? (
        <ModalPortal>
          <ModalModify
            handleCloseModal={handleCloseModal}
            handleCommentModify={handleCommentModify}
            handleCommentDelete={deleteComment}
            modalCommentSeq={commentValue.commentSeq}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
