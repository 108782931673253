import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import 'assets/styles/main.scss'

import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import Lnb from 'components/Lnb'
import MyClassList from 'components/MyClassList'

export default function Home() {
  const [myclassData, setMyclassData] = useState([])
  const [userInfo, setUserInfo] = useState(null)
  const [orderListData, setOrderListData] = useState([])

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // const normalBanner = [
  //   {
  //     imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/banner/combnr_pc_yafit_2306.png',
  //     imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/banner/combnr_m_yafit_2306.png',
  //     imageInfo: '세상의 모든 사이클이 야핏으로 연결된다. 야핏특가 최대 50% 할인',
  //     link: 'https://www.yanadoo.co.kr/event/detail/25?utm_source=event&utm_medium=referral_band_survey&utm_campaign=freind_benefit',
  //     bgColor: '#141720'
  //   }
  // ]

  const getMyclassData = () => {
    api
      .get('/v2/myClass/web/top')
      .then((response) => {
        setMyclassData(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 유저정보 가져오기
  const getUserInfo = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
        setUserInfo(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 주문리스트 API 호출
  const getOrderList = () => {
    api
      .get(`/v2/order/list?pageIndex=1&pageSize=2`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
        } else {
          setOrderListData(response.data.data)
          // console.log('otrderListData');
          // console.log(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (userInfo) {
      getMyclassData()
      getOrderList()
    }
  }, [userInfo])

  useEffect(() => {
    if (AuthService.getUserInfo()) {
      getUserInfo()
    } else {
      alert(config.MESSAGE['unauthorized'])
    }
  }, [])

  return (
    <>
      <div className="mypage-col">
        <div className="inner-col">
          <div className="container mypage-main">
            <Lnb />
            <section className="mypage-main-col">
              <div className="myclass-list-col">
                <div className="title-area">
                  <strong>MY 클래스</strong>
                  <Link to={'/myclass/1'}>전체보기</Link>
                </div>
                <MyClassList myClassData={myclassData} tabType={'DEFAULT'} dataType={'ALL'} />
              </div>
              <div className="myclass-order-col">
                <div className="title-area">
                  <strong>주문배송조회</strong>
                  <Link to={'/mypage/order/list'}>전체보기</Link>
                </div>
                <ul className="myclass-order-lists">
                  {orderListData.length !== 0 ? (
                    orderListData.length > 1 ? (
                      orderListData.map((orderDetailData, idx) => {
                        return (
                          <li key={idx}>
                            <div className="myclass-order-box">
                              <strong
                                className={
                                  'tit' +
                                  (orderDetailData.orderDetailVOList[0].deliveryStatusName ===
                                  '배송중'
                                    ? ' ico-red-dot'
                                    : '')
                                }
                              >
                                {orderDetailData.orderDetailVOList[0].deliveryStatusName
                                  ? orderDetailData.orderDetailVOList[0].deliveryStatusName
                                  : orderDetailData.orderDetailVOList[0].paymentStatusName}
                                {/* {orderListData.orderDetailVOList[0]
                                    .paymentStatusCode === 'PAY_SUCCESS' &&
                                    '결제완료'}
                                  {orderListData.orderDetailVOList[0]
                                    .deliveryStatusName === '송장정보업로드' ||
                                  orderListData.orderDetailVOList[0]
                                    .deliveryStatusName === '배송완료처리'
                                    ? '배송중'
                                    : null} */}
                              </strong>
                              <div className="order-info">
                                <div
                                  className="thumb"
                                  style={{
                                    backgroundImage: `url(${orderDetailData.orderDetailVOList[0].thumbnailPath})`
                                  }}
                                >
                                  <i className="blind">썸네일</i>
                                </div>
                                <div className="desc">
                                  <div className="order-state">
                                    <strong>
                                      {orderDetailData.orderDetailVOList[0].deliveryStatusName
                                        ? orderDetailData.orderDetailVOList[0].deliveryStatusName
                                        : orderDetailData.orderDetailVOList[0].paymentStatusName}
                                    </strong>
                                    <span className="date">
                                      {orderDetailData.orderDetailVOList[0].orderDate.substring(
                                        0,
                                        10
                                      )}
                                    </span>
                                  </div>
                                  <p className="title">
                                    <strong>
                                      <span className="ellipsis">
                                        {orderDetailData.orderDetailVOList[0].orderName}
                                      </span>
                                    </strong>
                                    {orderDetailData.orderDetailVOList.length > 1 && (
                                      <span className="num">
                                        외 {orderDetailData.orderDetailVOList.length - 1}건
                                      </span>
                                    )}
                                  </p>
                                  <span className="price">
                                    {addComma(
                                      orderDetailData.orderDetailVOList[0].totalPaymentPrice
                                    )}
                                    원
                                  </span>
                                </div>
                              </div>
                              <div className="btn-flex-form">
                                {(orderDetailData.orderDetailVOList[0].deliveryStatusName &&
                                  orderDetailData.orderDetailVOList[0].deliveryStatusName ===
                                    '배송중') ||
                                orderDetailData.orderDetailVOList[0].deliveryStatusName ===
                                  '배송완료처리' ? (
                                  <>
                                    <button
                                      className="btn"
                                      onClick={() =>
                                        window.open(
                                          `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${orderListData.orderDetailVOList[0].invoiceNum}`,
                                          '_blank'
                                        )
                                      }
                                    >
                                      <span>배송조회</span>
                                    </button>
                                    <button
                                      className="btn"
                                      onClick={() => alert('상품수령 확인 처리를 하시겠습니까?')}
                                    >
                                      <span>상품수령 확인</span>
                                    </button>
                                  </>
                                ) : null}
                                <Link
                                  to={{
                                    pathname: `/mypage/order/detail/${orderDetailData.orderDetailVOList[0].orderNo}`
                                  }}
                                  className="btn"
                                >
                                  <span>주문 상세</span>
                                </Link>
                              </div>
                            </div>
                          </li>
                        )
                      })
                    ) : (
                      <>
                        <li>
                          <div className="myclass-order-box">
                            <strong
                              className={
                                'tit' +
                                (orderListData[0].orderDetailVOList[0].deliveryStatusName ===
                                '배송중'
                                  ? ' ico-red-dot'
                                  : '')
                              }
                            >
                              {orderListData[0].orderDetailVOList[0].deliveryStatusName
                                ? orderListData[0].orderDetailVOList[0].deliveryStatusName
                                : orderListData[0].orderDetailVOList[0].paymentStatusName}
                            </strong>
                            <div className="order-info">
                              <div
                                className="thumb"
                                style={{
                                  backgroundImage: `url(${orderListData[0].orderDetailVOList[0].thumbnailPath})`
                                }}
                              >
                                <i className="blind">썸네일</i>
                              </div>
                              <div className="desc">
                                <div className="order-state">
                                  <strong>
                                    {orderListData[0].orderDetailVOList[0].deliveryStatusName
                                      ? orderListData[0].orderDetailVOList[0].deliveryStatusName
                                      : orderListData[0].orderDetailVOList[0].paymentStatusName}
                                  </strong>
                                  <span className="date">
                                    {orderListData[0].orderDetailVOList[0].orderDate.substring(
                                      0,
                                      10
                                    )}
                                  </span>
                                </div>
                                <p className="title">
                                  <strong>
                                    <span className="ellipsis">
                                      {orderListData[0].orderDetailVOList[0].orderName}
                                    </span>
                                  </strong>
                                  {orderListData[0].orderDetailVOList[0].length > 1 && (
                                    <span className="num">
                                      외 {orderListData[0].orderDetailVOList[0].length - 1}건
                                    </span>
                                  )}
                                </p>
                                <span className="price">
                                  {addComma(
                                    orderListData[0].orderDetailVOList[0].totalPaymentPrice
                                  )}
                                  원
                                </span>
                              </div>
                            </div>
                            <div className="btn-flex-form">
                              {(orderListData[0].orderDetailVOList[0].deliveryStatusName &&
                                orderListData[0].orderDetailVOList[0].deliveryStatusName ===
                                  '배송중') ||
                              orderListData[0].orderDetailVOList[0].deliveryStatusName ===
                                '배송완료처리' ? (
                                <>
                                  <button
                                    className="btn"
                                    onClick={() =>
                                      window.open(
                                        `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${orderListData[0].invoiceNum}`,
                                        '_blank'
                                      )
                                    }
                                  >
                                    <span>배송조회</span>
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => alert('상품수령 확인 처리를 하시겠습니까?')}
                                  >
                                    <span>상품수령 확인</span>
                                  </button>
                                </>
                              ) : null}
                              <Link
                                to={{
                                  pathname: `/mypage/order/detail/${orderListData[0].orderDetailVOList[0].orderNo}`
                                }}
                                className="btn"
                              >
                                <span>주문 상세</span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="none">주문내역이 없습니다.</li>
                      </>
                    )
                  ) : (
                    <li className="none">주문내역이 없습니다.</li>
                  )}
                </ul>
              </div>
              {/* 
              // TODO : 배너 사용에 대한 히스토리 필요
              <Banner userName={userInfo && userInfo.nickName} bannerTitle={"님을 위한 혜택"} loop autoPlay slideView={1} type={"normal"} data={normalBanner} /> 
              */}
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
