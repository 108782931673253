import { useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams, useLocation } from 'react-router-dom'

import api from 'common/api'

import Banner from '../../components/Banner'
import CurriculumList from '../product/CurriculumList'
import DetailCard from '../product/DetailCard'
import Notice from '../product/Notice'
import PriceInfo from '../product/PriceInfo'
import TeacherInfo from '../product/TeacherInfo'

export default function MallProduct() {
  const { packageSeq, mallSeq } = useParams()
  const { pathname } = useLocation()

  const [classData, setClassData] = useState([])

  const [productPnt, setProductPnt] = useState(false)
  const [curriculumPnt, setCurriculumPnt] = useState(false)

  const productDetailRef = useRef()
  const curriculumPointRef = useRef()
  const reviewPointRef = useRef()
  const questionPointRef = useRef()
  const menuBarRef = useRef()

  const [prodDetail, setProdDetail] = useState(false)

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  const onScroll = (moveTarget) => {
    window.scrollTo(0, document.querySelector(moveTarget).offsetTop - 10)
  }

  const scrollCheck = () => {
    if (questionPointRef.current && questionPointRef.current.getBoundingClientRect().top < 150) {
      setProductPnt(false)
      setCurriculumPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else if (
      curriculumPointRef.current &&
      curriculumPointRef.current.getBoundingClientRect().top < 150
    ) {
      setProductPnt(false)
      setCurriculumPnt(true)
      menuBarRef.current.classList.add('fixed')
    } else if (
      productDetailRef.current &&
      productDetailRef.current.getBoundingClientRect().top < 150
    ) {
      setProductPnt(true)
      setCurriculumPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else if (reviewPointRef.current && reviewPointRef.current.getBoundingClientRect().top < 150) {
      setProductPnt(false)
      setCurriculumPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else {
      menuBarRef.current.classList.remove('fixed')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollCheck)

    return () => {
      window.removeEventListener('scroll', scrollCheck)
      document.body.classList.remove('modal-open')
    }
  }, [])

  const getClassData = (seq) => {
    api
      .get(`/v2/store/sale/package/${seq}/detail`)
      .then((response) => {
        setClassData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (packageSeq) {
      getClassData(packageSeq)
    }
  }, [packageSeq, pathname])

  return (
    <>
      <section className="product-col">
        <div className="inner-col">
          <div className="product-content-col">
            <DetailCard classData={classData} />
            <Mobile>
              <PriceInfo classData={classData} mallCode={mallSeq} />
            </Mobile>
            {/* 해당 상품과 연관된 이벤트가 있는 경우 노출 */}
            {classData.packageEvents && classData.packageEvents.length > 0 && (
              <Banner type={'normal'} data={classData.packageEvents} />
            )}
            <section className="product-list-col" style={{ top: 0 }} ref={menuBarRef}>
              <ul>
                <li className={productPnt === true ? 'active' : ''}>
                  <button type="button" onClick={() => onScroll('.product-detail')}>
                    상품소개
                  </button>
                </li>
                {classData.packageTypeCode === 'REAL' ? null : (
                  <li className={curriculumPnt === true ? 'active' : ''}>
                    <button type="button" onClick={() => onScroll('.curriculum-col')}>
                      커리큘럼
                    </button>
                  </li>
                )}
              </ul>
            </section>
            {/* 구성정보 : 묶음 상품인 경우에만 노출 */}
            {/* <Composition packageSeq={packageSeq} /> */}
            <div
              ref={productDetailRef}
              className={prodDetail === true ? 'product-detail active' : 'product-detail'}
              dangerouslySetInnerHTML={{
                __html: classData.detail
              }}
            ></div>
            <button
              className="btn-detail-more"
              type="button"
              onClick={() => setProdDetail(true)}
              style={{ display: 'none' }}
            >
              <span>상품정보 더보기</span>
            </button>
            {/* {classData.packageTypeCode === 'COMBINATION' && (
            )} */}
            <TeacherInfo classData={classData} />
            {/* 실물상품, 앱 이용권인 경우 비노출 */}
            {classData.packageTypeCode === 'REAL' || classData.packageTypeCode === 'APP' ? null : (
              <>
                <div className="point-curriculum" ref={curriculumPointRef}></div>
                <CurriculumList packageSeq={packageSeq} />
              </>
            )}
            <Notice />
          </div>
          <Desktop>
            <PriceInfo classData={classData} mallCode={mallSeq} />
          </Desktop>
        </div>
      </section>
    </>
  )
}
