import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import api from 'common/api'

import styles from './ButtonList.module.scss'
import { scrollMove } from '../../common/common'

export default function ButtonList({ currentSeq, handleCurrentIdx, type, boardDetailInfo, param }) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [postSeq, setPostSeq] = useState(null)
  // 이전 다음 버튼 API
  const getMove = (seq, moveType) => {
    api
      .get(`${boardDetailInfo.apiPrevNextUrl}${seq}&moveType=${moveType}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          setPostSeq(currentSeq)
          alert(response.data.meta.message)
        } else {
          setPostSeq(
            type !== 'STUDY' ? response.data.data.communityPostSeq : response.data.data.questionSeq
          )
        }
        setIsLoading(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (isLoading && currentSeq !== postSeq) {
      setIsLoading(false)
      navigate(`/community/${type.toLowerCase()}/detail/${postSeq}`)
      handleCurrentIdx(1)
      scrollMove(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, postSeq])

  return (
    <div className={styles.btnListBox}>
      <button
        type="button"
        className={styles.buttonItem}
        onClick={() => {
          getMove(currentSeq, 'PREV')
        }}
      >
        이전
      </button>
      <Link className={styles.linkItem} to={`/community/${type.toLowerCase()}/list?${param}`}>
        목록보기
      </Link>
      <button
        type="button"
        className={styles.buttonItem}
        onClick={() => {
          getMove(currentSeq, 'NEXT')
        }}
      >
        다음
      </button>
    </div>
  )
}
