/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import useHideHeader from 'hooks/useHideHeader'
import ModalPortal from 'ModalPortal'

import { YANADOO_CDN_HOST } from 'common/config'
import { headerValues } from 'components/header/config'
import useHeaderHeightStore from 'store/useHeaderHeightStore'

import styles from './ClassSearch.module.scss'

const IconClose = `${YANADOO_CDN_HOST}/search/icon-close.png`

function RecommendKeywords({ onClick }) {
  return (
    <div className={styles.recommended}>
      <span className={styles.recommendedTitle}>추천 검색어</span>
      <ul className={styles.recommendedList}>
        {headerValues.searchRecommendedList.map((keyword) => (
          <li key={keyword.id} className={styles.recommendedItem}>
            <button className={styles.recommendedButton} onClick={() => onClick(keyword.text)}>
              {keyword.text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default function ClassSearch() {
  const navigation = useNavigate()
  const location = useLocation()
  const { top } = useHideHeader()
  const headerBannerHeight = useHeaderHeightStore((state) => state.headerBannerHeight)

  const dimRef = useRef()

  const [value, setValue] = useState('')
  const [isShow, setIsShow] = useState(false)
  const [isScroll, setIsScroll] = useState(false)

  const handleChangeValue = (e) => {
    setValue(e.target.value)
  }

  const handleClickRecommended = (keyword) => {
    setValue(keyword)
    handleMoveToSearchResult(keyword, 'HASHTAG')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (value.length > 0) {
      handleMoveToSearchResult(value, 'TEXT')
    }
  }

  const handleMoveToSearchResult = (searchText) => {
    navigation(`/search-result?searchText=${searchText}`)
  }

  const handleClose = () => {
    setIsShow(false)
  }

  const handleClickDim = (e) => {
    if (e.target === dimRef.current) handleClose()
  }

  const handleHideGnb = useCallback(() => {
    let currentScrollTop = window.scrollY
    setIsScroll(currentScrollTop > 0)
  }, [setIsScroll])

  useEffect(() => {
    let timer = null
    setInterval(() => {
      document.addEventListener('scroll', handleHideGnb)
    })
    return () => {
      clearInterval(timer)
      document.removeEventListener('scroll', handleHideGnb)
    }
  }, [handleHideGnb])

  useEffect(() => {
    handleClose()
  }, [location])

  return (
    <>
      <button className={styles.btnSearch} onClick={() => setIsShow(!isShow)}>
        <img src={`${YANADOO_CDN_HOST}/search/icon-search.png`} alt="search icon" />
      </button>
      {isShow && (
        <ModalPortal>
          <div
            className={styles.dim}
            style={{ top: (top ? top + 1 : top) + (isScroll ? 0 : headerBannerHeight) }}
            ref={dimRef}
            onClick={handleClickDim}
          >
            <div className={styles.container}>
              <div className={styles.inner}>
                <p className={styles.title}>클래스 검색</p>
                <div className={styles.inputContainer}>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      value={value}
                      onChange={handleChangeValue}
                      className={styles.input}
                      placeholder="배우고 싶은 강의 검색"
                    />
                  </form>
                  <button className={styles.closeButtonM} type="button" onClick={handleClose}>
                    <img src={IconClose} alt="close icon" />
                  </button>
                </div>

                {/* 추천 검색어 */}
                <RecommendKeywords onClick={handleClickRecommended} />

                <button className={styles.closeButton} onClick={handleClose}>
                  닫기
                  <img src={IconClose} alt="close icon" />
                </button>
              </div>
            </div>
          </div>
        </ModalPortal>
      )}
    </>
  )
}
