import React, { useState, useEffect } from 'react'
export default function AppDownloadVoca() {
  const [isDevice, setIsDevie] = useState(false)
  const isIOS = navigator.userAgent.match(/ipad|ipod|iphone|Mac/i)

  const chkDevice = () => {
    if (isIOS) {
      setIsDevie(true)
      window.location.href = 'https://itunes.apple.com/kr/app/id1494842135'
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=kr.co.yanadoo.voca'
    }
  }

  useEffect(() => {
    chkDevice()
  }, [])

  return (
    <div>
      <h4>{isDevice ? 'true' : 'false'}</h4>
    </div>
  )
}
