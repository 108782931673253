import { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import api from 'common/api'
import BoardListReviewItem from 'components/board/BoardListReviewItem'
import Loading from 'components/common/Loading'

import styles from './ModalImageDetail.module.scss'
import ModalReviewDetail from './ModalReviewDetail'

//style
import 'assets/styles/modal.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination, Navigation])

const ModalImageDetail = ({
  type,
  seq,
  deviceCheck,
  title,
  btnClose,
  handleCloseModal,
  handleOpenImageCollectAllModal,
  apiInfo,
  handleSettingSeq,
  categoryData,
  parseCategoryCodeList
}) => {
  const [defaultSeq, setDefaultSeq] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isRollingLoading, setIsRollingLoading] = useState(true)
  const [index, setIndex] = useState(1)

  const [modalopen, setModalopen] = useState(false)
  const [communityInfo, setCommunityInfo] = useState(null)
  const [rollingData, setRollingData] = useState([])

  const [images, setImages] = useState(null)

  const [activeAttachment, setActiveAttachment] = useState({})

  const onClickActive = (attachment, idx) => {
    if (activeAttachment.idx !== idx) {
      setActiveAttachment({ ...attachment, idx: idx })
    }
  }

  // 첨부파일 롤링 이전, 다음 버튼 클릭시
  const onClickRollingMove = (type) => {
    if (isRollingLoading) return
    setIsRollingLoading(true)

    if (type === 'PREV') {
      setIndex(index - 1)
    } else {
      setIndex(index + 1)
    }
  }

  // 첨부파일 롤링 영역 아이템 클릭 시 데이터 변경
  const onClickChange = (data) => {
    setIsLoading(true)
    handleSettingSeq(data)
  }

  // 이미지 후기 상세 정보
  const getCommunityInfo = () => {
    ;(type === 'SRR'
      ? api.get(apiInfo.apiDetailUrl + `${seq.learningReviewSeq}`)
      : seq.scholarshipSeq
      ? api.get(
          apiInfo.apiDetailScholarshipUrl +
            `${seq.scholarshipSeq}/${seq.scholarshipRefundHistorySeq}`
        )
      : api.get(apiInfo.apiDetailUrl + `${seq.learningReviewSeq}`)
    )

      .then((response) => {
        setCommunityInfo(response.data.data)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 모바일에서 이전 글, 다음 글 클릭 시
  const onClickMove = (moveType) => {
    let categoryCode
    if (categoryData) {
      categoryCode = parseCategoryCodeList(categoryData, 'categories')
    }
    ;(type === 'SRR'
      ? api.get(apiInfo.apiPrevNextUrl + `${moveType}&learningReviewSeq=${seq.learningReviewSeq}`)
      : seq.scholarshipSeq
      ? api.get(
          apiInfo.apiPrevNextUrl +
            `${moveType}&reviewType=SCHOLARSHIP&scholarshipSeq=${seq.scholarshipSeq}&scholarshipRefundHistorySeq=${seq.scholarshipRefundHistorySeq}${categoryCode}`
        )
      : api.get(
          apiInfo.apiPrevNextUrl +
            `${moveType}&reviewType=LEARNING&learningReviewSeq=${seq.learningReviewSeq}${categoryCode}`
        )
    )

      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
        } else {
          setIsLoading(true)
          handleSettingSeq(response.data.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 첨부파일 롤링 조회
  const getRollingAttachment = (seq, index) => {
    let categoryCode
    if (categoryData) {
      categoryCode = parseCategoryCodeList(categoryData, 'categories')
    }
    ;(type === 'SRR'
      ? api.get(apiInfo.apiRollingUrl + `${seq.learningReviewSeq}&pageIndex=${index}&pageSize=9`)
      : seq.scholarshipSeq
      ? api.get(
          apiInfo.apiRollingUrl +
            `SCHOLARSHIP&scholarshipRefundHistorySeq=${seq.scholarshipRefundHistorySeq}&scholarshipSeq=${seq.scholarshipSeq}&pageIndex=${index}&pageSize=9${categoryCode}`
        )
      : api.get(
          apiInfo.apiRollingUrl +
            `LEARNING&learningReviewSeq=${seq.learningReviewSeq}&pageIndex=${index}&pageSize=9${categoryCode}`
        )
    )

      .then((response) => {
        setRollingData(response.data.data)
        setIsRollingLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleOpenReviewDetailModal = useCallback((images) => {
    document.body.classList.add('modal-open')
    setImages(images)
    setModalopen(true)
  }, [])

  const handleCloseReviewDetailModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  useEffect(() => {
    setDefaultSeq(seq)
    return () => {
      handleCloseReviewDetailModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getCommunityInfo()
    setIsLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seq])

  useEffect(() => {
    if (Object.keys(defaultSeq).length !== 0) {
      getRollingAttachment(defaultSeq, index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSeq, index, deviceCheck])

  useEffect(() => {
    if (!isLoading) {
      seq.scholarshipSeq
        ? setActiveAttachment({ ...communityInfo?.scholarshipRefundHistoryImageList[0], idx: 0 })
        : setActiveAttachment({ ...communityInfo?.attachments[0], idx: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      <div className="modal-wrap modal-image">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button className={styles.buttonClose} type="button" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <button
              className={styles.buttonAll}
              onClick={() => {
                handleOpenImageCollectAllModal()
                handleCloseModal()
              }}
            >
              <span>전체보기</span>
            </button>
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            {!isLoading ? (
              <>
                <div className={styles.imageDetailBox}>
                  {!deviceCheck &&
                    (seq.scholarshipSeq ? (
                      <button
                        className={styles.thumbButton}
                        onClick={() => {
                          handleOpenReviewDetailModal(
                            communityInfo?.scholarshipRefundHistoryImageList
                          )
                        }}
                        style={{
                          backgroundImage: `url(${activeAttachment.imageUrl})`
                        }}
                      ></button>
                    ) : (
                      <button
                        className={styles.thumbButton}
                        onClick={() => {
                          handleOpenReviewDetailModal(communityInfo?.attachments)
                        }}
                        style={{
                          backgroundImage: `url(${
                            activeAttachment.url || activeAttachment.thumbnailPath
                          })`
                        }}
                      ></button>
                    ))}
                  {deviceCheck && (
                    <>
                      {seq.scholarshipSeq ? (
                        <div className={styles.swiperBox}>
                          <Swiper
                            className="swiper-container"
                            pagination={
                              communityInfo?.scholarshipRefundHistoryImageList.length > 1 && {
                                type: 'fraction'
                              }
                            }
                            centeredSlides={true}
                            slidesPerView="auto"
                            autoHeight={true}
                            loop={true}
                          >
                            {communityInfo?.scholarshipRefundHistoryImageList.length > 0 &&
                              communityInfo?.scholarshipRefundHistoryImageList.map(
                                (attachment, idx) => {
                                  return (
                                    <SwiperSlide
                                      key={idx}
                                      onClick={() => {
                                        handleOpenReviewDetailModal(
                                          communityInfo?.scholarshipRefundHistoryImageList
                                        )
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundImage: `url(${attachment.imageUrl})`
                                        }}
                                      ></span>
                                    </SwiperSlide>
                                  )
                                }
                              )}
                          </Swiper>
                        </div>
                      ) : (
                        <div className={styles.swiperBox}>
                          <Swiper
                            pagination={
                              communityInfo?.attachments.length > 1 && { type: 'fraction' }
                            }
                            centeredSlides={true}
                            slidesPerView="auto"
                            autoHeight={true}
                            loop={true}
                          >
                            {communityInfo?.attachments.length > 0 &&
                              communityInfo?.attachments.map((attachment, idx) => {
                                return (
                                  <SwiperSlide
                                    key={idx}
                                    onClick={() => {
                                      handleOpenReviewDetailModal(communityInfo?.attachments)
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundImage: `url(${
                                          attachment.url || attachment.thumbnailPath
                                        })`
                                      }}
                                    ></span>
                                  </SwiperSlide>
                                )
                              })}
                          </Swiper>
                        </div>
                      )}
                      <div className={styles.prevNextBox}>
                        <button
                          onClick={() => {
                            onClickMove('PREV')
                          }}
                          className={styles.prev}
                        >
                          이전 글
                        </button>
                        <button
                          onClick={() => {
                            onClickMove('NEXT')
                          }}
                          className={styles.next}
                        >
                          다음 글
                        </button>
                      </div>
                    </>
                  )}
                  {seq.scholarshipSeq ? (
                    <div className={styles.detailBox}>
                      <BoardListReviewItem
                        type={'IMAGE'}
                        deviceCheck={deviceCheck}
                        title={'장학금 후기'}
                        registDate={communityInfo?.registDate}
                        backgroundImage={communityInfo?.profileImageUrl}
                        userName={communityInfo?.username}
                        satisfaction={communityInfo?.satisfaction}
                      />
                      {!deviceCheck && (
                        <ul className={styles.imageLists}>
                          {communityInfo?.scholarshipRefundHistoryImageList.length > 0 &&
                            communityInfo?.scholarshipRefundHistoryImageList.map(
                              (attachment, idx) => {
                                return (
                                  <li key={idx}>
                                    <button
                                      className={activeAttachment.idx === idx ? styles.active : ''}
                                      onClick={() => {
                                        onClickActive(attachment, idx)
                                      }}
                                      style={{
                                        backgroundImage: `url(${attachment.imageUrl})`
                                      }}
                                    ></button>
                                  </li>
                                )
                              }
                            )}
                        </ul>
                      )}
                      <p>{communityInfo?.contents}</p>
                      <Link
                        to={
                          `/courseReview/detail/` +
                          seq.scholarshipSeq +
                          '/' +
                          seq.scholarshipRefundHistorySeq
                        }
                      >
                        본문 보기
                      </Link>
                    </div>
                  ) : (
                    <div className={styles.detailBox}>
                      <BoardListReviewItem
                        type={'IMAGE'}
                        deviceCheck={deviceCheck}
                        title={communityInfo?.classes?.name || communityInfo?.title}
                        registDate={communityInfo?.registDate}
                        backgroundImage={
                          communityInfo?.registUser?.profileImageUrl || communityInfo?.thumbnailPath
                        }
                        userName={communityInfo?.registUser?.nickName || communityInfo?.nickName}
                        satisfaction={communityInfo?.satisfaction}
                      />
                      {!deviceCheck && (
                        <ul className={styles.imageLists}>
                          {communityInfo?.attachments.length > 0 &&
                            communityInfo?.attachments.map((attachment, idx) => {
                              return (
                                <li key={idx}>
                                  <button
                                    className={activeAttachment.idx === idx ? styles.active : ''}
                                    onClick={() => {
                                      onClickActive(attachment, idx)
                                    }}
                                    style={{
                                      backgroundImage: `url(${
                                        attachment.url || attachment.thumbnailPath
                                      })`
                                    }}
                                  ></button>
                                </li>
                              )
                            })}
                        </ul>
                      )}
                      <p>{communityInfo?.contents}</p>
                      <Link
                        to={
                          type === 'SRR'
                            ? `/community/review/detail/` + seq.learningReviewSeq
                            : `/courseReview/detail/` + seq.learningReviewSeq + '/0'
                        }
                      >
                        본문 보기
                      </Link>
                    </div>
                  )}
                </div>
                {!deviceCheck && (
                  <div className={styles.rollingImageBox}>
                    <button
                      disabled={rollingData?.isFirstPage}
                      className={styles.btnPrev}
                      onClick={() => {
                        onClickRollingMove('PREV')
                      }}
                    >
                      <i className="blind">이전</i>
                    </button>
                    {!isRollingLoading ? (
                      <ul>
                        {rollingData?.attachments.length > 0 ? (
                          rollingData.attachments.map((attachment, idx) => {
                            return (
                              <li key={idx}>
                                <button
                                  onClick={() => {
                                    onClickChange(attachment)
                                  }}
                                  style={{
                                    backgroundImage: `url(${attachment.url})`
                                  }}
                                  className={
                                    attachment.learningReviewSeq === seq.learningReviewSeq ||
                                    attachment.scholarshipSeq === seq.scholarshipSeq
                                      ? styles.active
                                      : ''
                                  }
                                >
                                  {attachment.count > 1 && <span>{attachment.count}</span>}
                                </button>
                              </li>
                            )
                          })
                        ) : (
                          <></>
                        )}
                      </ul>
                    ) : (
                      <Loading />
                    )}

                    {rollingData && (
                      <button
                        disabled={rollingData?.isLastPage}
                        className={styles.btnNext}
                        onClick={() => {
                          onClickRollingMove('NEXT')
                        }}
                      >
                        <i className="blind">다음</i>
                      </button>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
      {modalopen ? (
        <ModalPortal>
          <ModalReviewDetail
            deviceCheck={deviceCheck}
            handleCloseModal={handleCloseReviewDetailModal}
            images={images}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}

export default ModalImageDetail
