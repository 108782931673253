import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import api from 'common/api'

import styles from './TempTerms.module.scss'

const serviceItems = [
  {
    id: 1,
    brandCode: 'yanadoo',
    name: '야나두',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      }
    ]
  },

  {
    id: 2,
    brandCode: 'YAFIT_CYCLE',
    name: '야핏사이클',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        brandCode: 'YAFIT_CYCLE',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      },
      {
        id: 3,
        termsType: 'OPERATION_POLICY',
        name: '운영 정책'
      }
    ]
  },
  {
    id: 3,
    brandCode: 'yafit_rider',
    name: '야핏 무브',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        brandCode: 'yafit_rider',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      },
      {
        id: 3,
        termsType: 'location_service',
        name: '위치기반 서비스 이용약관'
      }
    ]
  },
  {
    id: 4,
    name: 'B2B 단체수강',
    brandCode: 'B2B',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      }
    ]
  }
]

export default function TempTerms() {
  const contentRef = useRef(null)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isPrivacyOnly = searchParams.get('privacyOnly')

  const { brandSeq, itemSeq } = useParams()
  const [hash, setHash] = useState(location.hash)
  const [termsData, setTermsData] = useState([])
  const [termsDataList, setTermsDataList] = useState([])

  const [currentSeq, setCurrentSeq] = useState('')

  // Hash 리셋
  const resetHash = useCallback(() => {
    setHash(location.hash)
  }, [location])

  // 이용약관 내용 조회
  const getTermsContent = useCallback(async () => {
    try {
      const result = await api
        .get(
          `/v2/terms?brandCode=${brandSeq.toUpperCase()}&seq=${currentSeq}&termsType=${itemSeq.toUpperCase()}`
        )
        .then((response) => response.data.data.contents)

      setTermsData(result)
    } catch (error) {
      console.error(error)
    }
  }, [brandSeq, itemSeq, currentSeq])

  // 이용약관 시행 이력 조회
  const getTermsHistory = useCallback(async () => {
    try {
      const result = await api
        .get(
          `/v2/terms/history-titles?brandCode=${brandSeq.toUpperCase()}&termsType=${itemSeq.toUpperCase()}`
        )
        .then((response) => response.data.data)
      setTermsDataList(result)
    } catch (error) {
      console.error(error)
    }
  }, [brandSeq, itemSeq])

  const handleTermsChange = (e) => {
    const seq = parseInt(e.target.value)
    setCurrentSeq(seq)
  }

  // Data Fetch
  useEffect(() => {
    getTermsContent()
    getTermsHistory()
    resetHash()
  }, [getTermsContent, getTermsHistory, resetHash])

  // Hash로 문서 위치로 이동 로직
  useEffect(() => {
    if (!hash || !contentRef.current) return

    const targetEl = contentRef.current.querySelector(hash)
    window.scrollTo(0, targetEl ? targetEl.offsetTop : 0)
  }, [termsData, hash])

  const FormBox = ({ title, contents }) => {
    return (
      <div className={styles.formBoxTemp}>
        <h3>{title}</h3>
        <div ref={contentRef}>
          {contents && <div dangerouslySetInnerHTML={{ __html: contents }}></div>}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.innerCol}>
        <section className={styles.brandTabsTemp}>
          <ul>
            {serviceItems.map(({ name, brandCode, childTabs }) => {
              const privacyPolicyIndex = childTabs.findIndex(
                (item) => item.termsType === 'privacy_policy'
              )
              const setIndex = isPrivacyOnly === 'y' ? privacyPolicyIndex : 0

              return (
                <li key={uuid()}>
                  <Link
                    to={`/service/${brandCode}/${childTabs[setIndex].termsType}${location.search}`}
                    className={brandSeq === brandCode ? styles.active : ''}
                  >
                    {name}
                  </Link>

                  <section className={styles.serviceTabsTemp}>
                    <ul className={brandCode}>
                      {childTabs.map(({ termsType, name }) => {
                        const isActive = itemSeq === termsType ? styles.active : ''

                        if (isPrivacyOnly === 'y' && termsType !== 'privacy_policy') return null
                        return (
                          <li key={uuid()}>
                            <Link
                              to={`/service/${brandCode}/${termsType}${location.search}`}
                              className={`${brandCode}_${termsType} ${isActive}`}
                              onClick={() => {
                                setCurrentSeq('')
                              }}
                            >
                              {name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </section>
                </li>
              )
            })}
          </ul>
        </section>
        <section className={styles.termsFormTemp}>
          {serviceItems
            .filter((brand) => brand.brandCode === brandSeq) //
            .map((brandItem) =>
              brandItem.childTabs
                .filter((service) => service.termsType === itemSeq) //
                .map((serviceItem) => (
                  <FormBox
                    key={uuid()} //
                    title={`${brandItem.name} ${serviceItem.name}`}
                    contents={termsData}
                  />
                ))
            )}
        </section>
        <div className={styles.termsHistoryTemp}>
          {termsDataList && (
            <select value={currentSeq} onChange={handleTermsChange}>
              {termsDataList.map((termsList) => (
                <option value={termsList.seq} key={uuid()}>
                  {termsList.title}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </>
  )
}
