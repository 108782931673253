import { useState, useCallback, useRef, useEffect } from 'react'

import api from 'common/api'
import * as config from 'common/config'

import AlertModal from './AlertModal'
import Toast from './Toast'
import ModalPortal from '../../ModalPortal'
import Certified from '../Certified'
import CountryCode from '../CountryCode'
import Password from '../Password'

import '../../../src/assets/styles/modal.scss'
import '../../../src/assets/styles/modalJoin.scss'

export default function ModalDormantAccounts({ btnClose, userInfo, handleCloseModal }) {
  const phoneRef = useRef()

  const [validStatus, setValidStatus] = useState({
    phone: false
  })

  const [inputValue, setInputValue] = useState({
    phone: '',
    countryCode: '82'
  })

  const [openToast, setOpenToast] = useState(false)
  const [step1Complete, setStep1Complete] = useState(false)
  const [step2Complete, setStep2Complete] = useState(false)
  const [newPassword, setNewPassword] = useState(null)
  const [pwdComplete, setPwdComplete] = useState(false)
  const [certifiedComplete, setCertifiedComplete] = useState(false)
  const [certifiedCodeShow, setCertifiedCodeShow] = useState(false)
  const [timerStart, setTimerStart] = useState(0)
  const [modalopen2, setModalopen2] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [stepStatus, setStepStatus] = useState(1)

  const handleToastStatus = useCallback((status) => {
    setOpenToast(status)
  }, [])

  const checkCertUrl =
    config.AUTH_API_HOST + '/auth/v2/sign-in/sleep/check-cert-number?userId=' + userInfo.id

  const handleCheckedItem = () => {
    setIsChecked(!isChecked)
  }

  const handleNewPwdComplete = (status, newPwd) => {
    setPwdComplete(status)
    setNewPassword(newPwd)
  }

  const handleCertifiedComplete = (status) => {
    setCertifiedComplete(status)
  }

  // 국가 코드
  const handleCountryCode = (e) => {
    setInputValue({ ...inputValue, countryCode: e.target.value })
  }

  const handlePhoneCheck = (e) => {
    let phoneRegExp = /^[0-9]*$/

    if (!phoneRegExp.test(e.target.value)) {
      phoneRef.current.focus()

      return false
    }

    setInputValue({ ...inputValue, phone: e.target.value })

    if (e.target.value.length < 10) {
      setValidStatus({ ...validStatus, phone: false })

      return false
    }

    setValidStatus({ ...validStatus, phone: true })

    return true
  }

  const handleOpenModal2 = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen2(true)
  }, [])

  // const handleCloseModal2 = useCallback((e) => {
  //   document.body.classList.remove('modal-open')
  //   setModalopen2(false)
  // }, [])

  const getCertNumber = () => {
    let formData = new FormData()

    formData.append('cellphone', inputValue.phone)
    formData.append('countryCode', inputValue.countryCode)
    formData.append('userId', userInfo.id)
    formData.append('password', userInfo.password)

    api
      .post(config.AUTH_API_HOST + '/auth/v2/sign-in/sleep/get-cert-number', formData)
      .then((response) => {
        if (response.data.meta.code === 200) {
          alert('인증번호를 발송하였습니다.')
          setTimerStart((prevNum) => prevNum + 1)
          setCertifiedCodeShow(true)
        } else {
          alert(response.data.meta.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const postEditPassword = () => {
    let formData = new FormData()

    formData.append('newPassword', newPassword)
    formData.append('userId', userInfo.id)
    formData.append('countryCode', inputValue.countryCode)
    formData.append('cellPhone', inputValue.phone)

    api
      .post(config.AUTH_API_HOST + '/auth/v2/sign-in/sleep/change-password', formData)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
        } else {
          handleOpenModal2()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (stepStatus === 1 && validStatus.phone && certifiedComplete && isChecked) {
      setStep1Complete(true)
    } else {
      setStep1Complete(false)
    }
  }, [validStatus, isChecked, certifiedComplete])

  useEffect(() => {
    if (stepStatus === 2 && pwdComplete) {
      setStep2Complete(true)
    } else {
      setStep2Complete(false)
    }
  }, [validStatus, pwdComplete])
  return (
    <>
      <div className="modal-wrap modal-form join">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className={'modal-inner dormant' + (stepStatus === 1 ? ' step-1' : ' step-2')}>
          {btnClose && (
            <button
              type="button"
              className={'btn-modal-close' + (stepStatus === 1 ? ' white' : '')}
              onClick={handleCloseModal}
            >
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            {stepStatus === 1 ? (
              <i className="blind">휴면 회원 해제</i>
            ) : (
              <strong>새 비밀번호 설정</strong>
            )}
          </div>
          <div className="modal-content">
            {stepStatus === 1 ? (
              <div className="title">
                <p>
                  회원님은
                  <br />
                  <strong>
                    현재 <span>휴면상태</span>입니다.
                  </strong>
                </p>
                <p>
                  로그인 한 지 1년 이상이 지나 아이디가
                  <br />
                  휴면상태로 전환되었습니다.
                  <br />
                  핸드폰 인증 후 야나두 사용이 가능합니다.
                </p>
              </div>
            ) : null}
            <form name="dormantAccountsFrm" action="#" className="frm">
              <div className="form-box">
                {stepStatus === 1 ? (
                  <dl className="">
                    <dt>휴대전화</dt>
                    <dd className="phone-box">
                      <CountryCode handleCountryCode={handleCountryCode} />
                      <div
                        className={'certified-code-form' + (certifiedComplete ? ' disabled' : '')}
                      >
                        <input
                          type="text"
                          maxLength="11"
                          placeholder="휴대전화 번호를 입력해주세요."
                          name="phone"
                          ref={phoneRef}
                          value={inputValue.phone}
                          onChange={handlePhoneCheck}
                          readOnly={certifiedComplete ? true : false}
                        />
                        <button
                          type="button"
                          className={
                            'btn btn-certified-code' + (validStatus.phone ? ' active' : ' disabled')
                          }
                          disabled={validStatus.phone ? false : true}
                          onClick={getCertNumber}
                        >
                          {certifiedCodeShow ? <span>재전송</span> : <span>인증번호</span>}
                        </button>
                      </div>
                      <Certified
                        time={300}
                        timerStart={timerStart}
                        handleCertifiedComplete={handleCertifiedComplete}
                        checkCertUrl={checkCertUrl}
                        handleToastStatus={handleToastStatus}
                      />
                      <label htmlFor="sleepAgree" className="checkbox-flex-form lg-chk">
                        <input
                          type="checkbox"
                          id="sleepAgree"
                          checked={isChecked ? true : false}
                          onChange={handleCheckedItem}
                        />
                        <span className="chk"></span>
                        <p>야나두 휴면계정 해제에 동의합니다.</p>
                      </label>
                    </dd>
                  </dl>
                ) : (
                  <Password handleNewPwdComplete={handleNewPwdComplete} />
                )}
              </div>
            </form>
            <div className="btn-flex-form">
              {stepStatus === 1 ? (
                <button
                  type="button"
                  className={'btn btn-dormant next' + (step1Complete ? ' active' : ' disabled')}
                  disabled={step1Complete ? false : true}
                  onClick={() => setStepStatus(2)}
                >
                  다음
                </button>
              ) : (
                <button
                  type="button"
                  className={'btn btn-dormant release' + (step2Complete ? ' active' : ' disabled')}
                  disabled={step2Complete ? false : true}
                  onClick={postEditPassword}
                >
                  휴면 해제
                </button>
              )}
            </div>
          </div>
          <Toast
            msg={'인증이 완료되었습니다.'}
            openToast={openToast}
            handleToastStatus={handleToastStatus}
          />
        </div>
      </div>
      {modalopen2 ? (
        <ModalPortal>
          <AlertModal
            btnConfirm
            msg={'휴면 계정 해제 요청이 정상적으로 처리되었습니다.'}
            handleCloseModal={handleCloseModal}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
