import s from './styles/Chat.module.scss'

export default function MessageUser({ message }) {
  return (
    <>
      <div className={`${s.message} ${s.userMessage}`}>
        <p>{message}</p>
      </div>
    </>
  )
}
