import React from 'react'

import styles from './LikedButton.module.scss'

export default function LikedButton({
  onToggleLike,
  liked // false: 빈하트, liked: 눌린하트, active: 모션하트
}) {
  return (
    <button
      className={`${styles.heartButton} ${
        liked === 'active' ? styles.isActive : liked === 'liked' ? styles.isLiked : ''
      }`}
      onClick={() => {
        onToggleLike()
      }}
    >
      <span>좋아요</span>
    </button>
  )
}
