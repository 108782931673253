import { useMemo } from 'react'
import { useLocation } from 'react-router'

import ResponsiveImage from 'components/ResponsiveImage'

import { keyVisualBannerValues } from './config'

export default function KeyVisualBanner() {
  const location = useLocation()

  const data = useMemo(() => {
    switch (location.pathname) {
      case '/promotion-landing/bestAwardsDBOnly':
      case '/promotion-landing/laptopPackage':
      case '/promotion-landing/macbookPackage':
      case '/promotion-landing/tabPackageAir':
        return keyVisualBannerValues[1]

      case '/promotion-landing/applePackage':
      case '/promotion-landing/applePackageUI':
      case '/promotion-landing/ipadDouble':
      case '/promotion-landing/tabPackage10th':
        return keyVisualBannerValues[2]

      default:
        return null
    }
  }, [location.pathname])

  return data ? (
    <div style={{ textAlign: 'center', background: data?.background }}>
      <ResponsiveImage
        style={{ maxWidth: '1920px', display: 'inline-block' }}
        maxWidth={750}
        pc={data?.pc}
        mo={data?.mobile}
      />
    </div>
  ) : null
}
