import styles from './SectionTitle.module.scss'

export default function SectionTitle({ title, desc }) {
  return (
    <div className={styles.titleCol}>
      <h3>{title}</h3>
      <p>{desc}</p>
    </div>
  )
}
