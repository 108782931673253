import React from 'react'
import { Link } from 'react-router-dom'

import styles from './TabQna.module.scss'

export default function TabQna({ activeTab }) {
  return (
    <div>
      <ul className={styles.tabMenu}>
        <li className={activeTab === 'INQUIRY' ? `${styles.active}` : null}>
          <Link to="/mypage/qna/inquiry">1:1 문의</Link>
        </li>
        <li className={activeTab === 'PRODUCT' ? `${styles.active}` : null}>
          <Link to="/mypage/qna/product">상품 문의</Link>
        </li>
        <li className={activeTab === 'LEARNING' ? `${styles.active}` : null}>
          <Link to="/mypage/qna/learning">학습 문의</Link>
        </li>
        <li className={activeTab === 'REVIEW' ? `${styles.active}` : null}>
          <Link to="/mypage/qna/review">후기</Link>
        </li>
      </ul>
    </div>
  )
}
