import { useEffect, useState } from 'react'

import styles from './ModalModify.module.scss'

export default function ModalModify({
  handleCloseModal,
  handleCommentModify,
  handleCommentDelete,
  modalCommentSeq
}) {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    if (loading) {
      handleCloseModal()
    }
    return () => setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.modalWrap}>
      <div onClick={handleCloseModal}></div>
      <div className={styles.modalInner}>
        <div className={styles.btnBox}>
          <button onClick={handleCommentModify}>
            <span>댓글 수정</span>
          </button>
          <button
            onClick={(e) => {
              handleCommentDelete(e, modalCommentSeq)
            }}
          >
            <span>댓글 삭제</span>
          </button>
        </div>
        <button onClick={handleCloseModal}>
          <span>취소</span>
        </button>
      </div>
    </div>
  )
}
