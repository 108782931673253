import React, { useEffect } from 'react'

import styles from './NumberMotion.module.scss'

export default function NumberMotion() {
  let motionCountUser = 170
  let motionCountMoney = 88
  let motionCountKm = 3073
  let motionCountUserNum = 10

  function countMotion(targetName, count) {
    const target = document.querySelector(targetName)
    const countBox = target.querySelector('.count-motion')

    let checkPoint = true
    let intervalCount
    let countLength = 1

    for (let i = 0; i < String(count).length; i++) {
      countLength *= 10
    }

    clearInterval(intervalCount)

    if (countBox.classList.value.indexOf('motion-fixed') === -1) {
      intervalCount = setInterval(() => {
        if (checkPoint) {
          countBox.innerHTML = Math.floor(Math.random() * countLength)
        } else {
          countBox.innerHTML = count
          countBox.classList.add('motion-fixed')
          clearInterval(intervalCount)
        }
      }, 20)
      setTimeout(() => {
        checkPoint = false
      }, 700)
    }
  }

  useEffect(() => {
    countMotion('.number-motion', motionCountUser)
    countMotion('.number-motion2', motionCountMoney)
    countMotion('.number-motion3', motionCountKm)
    countMotion('.number-motion4', motionCountUserNum)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={styles.numberMotionSection}>
      <div className={styles.innerCol}>
        <ul className={styles.motionItem}>
          <li className="number-motion">
            <strong>
              <em className="count-motion">0</em>
              <span>만</span>
            </strong>
            <p>누적 회원 수</p>
          </li>
          <li className="number-motion2">
            <strong>
              <em className="count-motion">0</em>
              <span>억</span>
            </strong>
            <p>누적 장학금액</p>
          </li>
          <li className="number-motion3">
            <strong>
              <em className="count-motion">0</em>
              <span>만</span>
            </strong>
            <p>총 라이딩 거리 Km</p>
          </li>
          <li className="number-motion4">
            <strong>
              <em className="count-motion">0</em>
              <span>억</span>
            </strong>
            <p>총 소모 칼로리</p>
          </li>
        </ul>
        <p className={styles.basisParagraph}>*위의 데이터는 2024년 10월 기준입니다.</p>
      </div>
    </section>
  )
}
