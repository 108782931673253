import React from 'react'

import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import SectionTitle from './SectionTitle'
import { textRollingListValues } from '../config'
import styles from './TextRollingSection.module.scss'

SwiperCore.use([Pagination, Autoplay])

export default function TextRollingSection() {
  return (
    <section className={styles.container}>
      <SectionTitle
        title={'야나두는 이렇게 다릅니다.'}
        desc={'무엇이 진정한 가치를 지니는지\n 야나두는 끊임없이 연구하고 개선합니다.'}
      />
      <div className={styles.rollingSwiperBox}>
        <Swiper
          pagination={{
            clickable: true
          }}
          centeredSlides={true}
          spaceBetween={80}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          breakpoints={{
            1024: {
              slidesPerView: 3.2
            },
            320: {
              slidesPerView: 1
            }
          }}
        >
          {textRollingListValues?.map((item, idx) => (
            <SwiperSlide key={idx}>
              <strong>{item.title}</strong>
              <p>{item.desc}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
