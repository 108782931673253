import React, { useState } from 'react'

import classNames from 'classnames'
import { v4 as uuid } from 'uuid'

import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

import styles from '../LevelTestAdvancedAnswer.module.scss'

export default function Answer({ idx, questionValue, userResult }) {
  const questionNumber = idx + 1
  const {
    type,
    text1,
    text2,
    text3,
    question,
    buttonGroup,
    listBlock,
    comment,
    buttonType,
    answerType
  } = questionValue

  /** 코멘트 토글 */
  const [commentToggle, setCommentToggle] = useState()
  const handleToggleComment = () => {
    setCommentToggle((prev) => !prev)
  }

  return (
    <article className={styles.article}>
      <h4 className={styles.caption}>Q{questionNumber}.</h4>
      <ul>
        <li>
          <p className={styles.th}>문제</p>
          <div className={styles.td}>
            <p className={styles.param01}>{question}</p>

            {/* 문제 타입별 스타일 상이 */}
            {answerType === 'type1' && (
              <>
                {text1 && <p className={styles.param02}>{text1}</p>}
                {text1 && <p className={styles.param03}>{text2}</p>}
              </>
            )}

            {answerType === 'type2' && (
              <div className={styles[type]}>
                {text1 && <p className={styles.param02}>{text1}</p>}
                {text2 && <p className={styles.param02}>{text2}</p>}
                {text3 && <p className={styles.param02}>{text3}</p>}
              </div>
            )}

            {answerType === 'type3' && (
              <div className={styles[type]}>
                {text2 && <p className={styles.param02}>{text2}</p>}
                {text3 && <p className={styles.param02}>{text3}</p>}
              </div>
            )}

            {answerType === 'type4' && (
              <div className={styles[type]}>
                {text1 && (
                  <p className={styles.param03}>
                    <span>A :</span>
                    <span>{text1}</span>
                  </p>
                )}
                {text2 && (
                  <p className={styles.param02}>
                    <span>B :</span>
                    <span>{text2}</span>
                  </p>
                )}
              </div>
            )}

            {answerType === 'type5' && (
              <div className={styles[type]}>
                {text1 && (
                  <p className={styles.param03}>
                    <span>A :</span>
                    <span>{text1}</span>
                  </p>
                )}
                {text2 && (
                  <p className={styles.param02}>
                    <span>B: </span>
                    <span>
                      {text2} <br /> {text3}
                    </span>
                  </p>
                )}
              </div>
            )}
          </div>
        </li>

        <li>
          <p className={styles.th}>선택</p>

          <div className={styles.td}>
            {/* 문장 완성형 선택지는 사용자 입력 결과에서 가져옴 (type : completeSentence, videoCompleteSentence) */}
            {buttonType === 'complete' ? (
              // 문장 완성 문제일 때
              <div className={styles.complete}>
                <p className={styles.param03}>{userResult.answer.join(' ')}</p>
              </div>
            ) : (
              // 문장 완성 문제가 아닐 때
              <div className={classNames(styles.choice, listBlock ? styles.block : '')}>
                {buttonGroup.list.map((answer) => (
                  <p key={uuid()} className={answer === userResult.answer ? styles.checked : ''}>
                    {answer}
                  </p>
                ))}
              </div>
            )}
          </div>
        </li>

        <li>
          <p className={styles.th}>정답</p>
          <div className={styles.td}>
            <div className={styles.answer}>
              <p className={styles.param03}>{buttonGroup.answer}</p>
              <button
                type="button"
                onClick={handleToggleComment}
                className={commentToggle ? styles.active : ''}
              >
                {commentToggle ? '해설 닫기' : '해설 보기'}
              </button>
            </div>
          </div>
        </li>

        {commentToggle && (
          <li className={styles.commentary}>
            <p className={styles.th}></p>
            <div className={styles.td}>
              <p className={styles.param04}>{comment}</p>
            </div>
          </li>
        )}

        <li>
          <p className={styles.th}>채점</p>
          <div className={styles.td}>
            <figure className={styles.marker}>
              {userResult.isCorrect ? ( //
                <img src={`${LTA_HOST_CDN}ico_mark-o.svg`} alt="정답" />
              ) : (
                <img src={`${LTA_HOST_CDN}ico_mark-x.svg`} alt="오답" />
              )}
            </figure>
          </div>
        </li>
      </ul>
    </article>
  )
}
