import { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import useHeaderHeightStore from 'store/useHeaderHeightStore'

import HeaderBanner from './HeaderBanner'
import styles from './NavCompany.module.scss'

export default function NavCompany({
  setEntry,
  gnbItems,
  currentIdx,
  onClickGnbMenu,
  gaEvent,
  depthCheck,
  currentPath,
  children
}) {
  const [depth2Active, setDepth2Active] = useState(null)
  const [isMoDepth3Chk, setMoDepth3Chk] = useState(false)
  const [gnbMovePoint, setGnbMovePoint] = useState(0)
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const gnbAreaRef = useRef()
  const gnbBoxRef = useRef()
  const [gnbBtnPrev, setGnbBtnPrev] = useState(true)
  const [gnbBtnNext, setGnbBtnNext] = useState(true)
  // GNB 컬러코드와 슬로건 텍스트를 배열로 갖고있는 상태값 입니다. currentIdx 번째 GNB의 정보를 초기값으로 갖습니다.
  const [companyItem, setCompanyItem] = useState([
    gnbItems[currentIdx].backgroundColor,
    gnbItems[currentIdx].description
  ])
  // pc에서 1024 이하의 해상도에서, GNB에서 드래그 기능 구현을 위한 상태값.
  const [isDrag, setIsDrag] = useState(false)
  const [beginX, setBeginX] = useState()
  const setIsShowBanner = useHeaderHeightStore((state) => state.setIsShowBanner)

  // GNB 상단바의 배경 컬러코드와 슬로건 텍스트를 변경하는 함수입니다.
  // GNB 상단바에 onMouseOver 또는 onMouseOut 이벤트가 일어났을때 해당 상태값을 업데이트 합니다.
  const gnbInfoSet = (idx) => {
    setCompanyItem([gnbItems[idx].backgroundColor, gnbItems[idx].description])
  }

  // 3depth 메뉴 리셋
  const depthActiveReset = () => {
    setDepth2Active(null)
    setMoDepth3Chk(false)
  }
  // mobile 사이즈에서 GNB 2depth 메뉴를 클릭 했을때, 3depth 메뉴를 보여주는 함수입니다.
  const moGnbActive = (idx) => {
    setDepth2Active(idx)
    setMoDepth3Chk(true)
    if (idx === depth2Active) {
      depthActiveReset()
    }
  }

  // GNB 컨텐츠 길이와, 보여지는 영역을 계산, GNB 위치 화살표 노출 여부를 판단하는 함수.
  const autoGnbCheck = () => {
    const gnbBoxWidth = gnbBoxRef.current.offsetWidth
    const gnbAreaBox = gnbAreaRef.current.offsetWidth - 236
    if (isMobile || gnbAreaBox >= gnbBoxWidth) {
      setGnbBtnPrev(false)
      setGnbBtnNext(false)
      setGnbMovePoint(0)
    } else {
      gnbMovePoint >= 0 ? setGnbBtnPrev(false) : setGnbBtnPrev(true)
      gnbMovePoint < gnbAreaBox - gnbBoxWidth ? setGnbBtnNext(false) : setGnbBtnNext(true)
    }
  }

  // GNB 이동 버튼 클릭 시 실행되는 함수.
  const gnbMoveEvent = (type) => {
    const moveWidth = 100 // 클릭 시 이동되는 gap.
    const movePoint = gnbMovePoint + moveWidth * (type === 'prev' ? 1 : -1)
    const gnbBoxWidth = gnbBoxRef.current.offsetWidth
    const gnbAreaBox = gnbAreaRef.current.offsetWidth - 236
    if (isMobile || gnbAreaBox >= gnbBoxWidth) {
      setGnbBtnPrev(false)
      setGnbBtnNext(false)
    } else {
      setGnbMovePoint(
        movePoint > 0
          ? 0
          : movePoint > gnbAreaBox - gnbBoxWidth
          ? movePoint
          : gnbAreaBox - gnbBoxWidth
      )
      movePoint >= 0 ? setGnbBtnPrev(false) : setGnbBtnPrev(true)
      movePoint < gnbAreaBox - gnbBoxWidth ? setGnbBtnNext(false) : setGnbBtnNext(true)
    }
  }

  const onDragStart = (e) => {
    e.preventDefault()
    setIsDrag(true)
    setBeginX(e.pageX + gnbBoxRef.current.scrollLeft)
  }

  const onDragEnd = () => {
    setIsDrag(false)
  }

  const onDragMove = (e) => {
    if (isDrag) gnbBoxRef.current.scrollLeft = beginX - e.pageX
  }

  useEffect(() => {
    if (depthCheck !== null && depth2Active === null) {
      setDepth2Active(depthCheck.depth2)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthCheck, currentIdx])

  let navigate = useNavigate()
  const goHome = () => {
    let path = `newPath`
    navigate(path)
    setEntry(true)
  }

  useEffect(() => {
    gnbInfoSet(currentIdx)
    autoGnbCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIdx])

  useEffect(() => {
    window.addEventListener('scroll', depthActiveReset)
    return () => window.removeEventListener('scroll', depthActiveReset)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', autoGnbCheck)
    return () => {
      window.removeEventListener('resize', autoGnbCheck)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    //  헤더 띠 배너있을 경우 show hide
    setIsShowBanner(
      gnbItems[currentIdx].menuName === '야나두 영어' ||
        gnbItems[currentIdx].menuName === '야나두 클래스'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIdx])

  return (
    <>
      <HeaderBanner />
      <section className={styles.companyCol} style={{ backgroundColor: companyItem[0] }}>
        <div className={`${styles.innerCol} ${styles.topInnerCol} ${styles.flex}`}>
          <strong className={styles.slogan}>{companyItem[1]}</strong>
          <ul className={styles.companyListCol}>
            <li className={styles.companyItems}>
              <button onClick={goHome}>HOME</button>
            </li>
            {gnbItems &&
              gnbItems.map((item, idx) => (
                <li
                  key={idx}
                  className={`${styles.companyItems} ${currentIdx === idx ? styles.active : ''}`}
                >
                  <Link
                    to={item.link}
                    onClick={() => onClickGnbMenu(idx, item.menuName)}
                    onMouseOver={() => gnbInfoSet(idx)}
                    onMouseOut={() => gnbInfoSet(currentIdx)}
                    target={item.target}
                  >
                    {item.menuName}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </section>
      <section className={`${styles.navCol} sub-header-wrap`}>
        <div className="sub-header-inner">
          <div className={`${styles.menuListCol} sub-header-nav`} ref={gnbAreaRef}>
            <h2
              className={`
                ${styles.h2}

                ${
                  currentIdx === 0
                    ? styles.logoEng
                    : currentIdx === 1
                    ? styles.logoYu
                    : currentIdx === 2
                    ? styles.logoYafit
                    : null
                }
              `}
            >
              <Link
                to={
                  currentIdx === 0
                    ? gnbItems[currentIdx].link
                    : currentIdx === 1
                    ? gnbItems[currentIdx].link
                    : currentIdx === 2
                    ? gnbItems[currentIdx].link
                    : null
                }
              >
                야나두
              </Link>
            </h2>
            {gnbItems &&
              gnbItems
                .filter((item, idx) => {
                  return idx === currentIdx
                })
                .map((gnb, index) => (
                  // header sub scroll-x navigation
                  <ul
                    key={index}
                    className={`${styles.menuList} ${styles.active} scroll-nav`}
                    style={{ marginLeft: +gnbMovePoint + 'px' }}
                    ref={gnbBoxRef}
                    onMouseDown={onDragStart}
                    onMouseMove={onDragMove}
                    onMouseUp={onDragEnd}
                    onMouseLeave={onDragEnd}
                  >
                    {gnb.childGnbMenus.map((item, index, e) => (
                      <li
                        className={styles.menuItems}
                        key={index}
                        style={{
                          '--bg-color': gnb.backgroundColor ? gnb.backgroundColor : 'transparent'
                        }}
                      >
                        {isMobile && item.childGnbMenus.length > 0 ? (
                          <Link
                            to="#"
                            onClick={() => moGnbActive(index)}
                            className={`${
                              !currentPath.includes('/store/detail') && depth2Active === index
                                ? styles.active
                                : ''
                            } ${depth2Active === index && isMoDepth3Chk ? ' check' : ''}`}
                            borderColor={companyItem[0]}
                          >
                            {item.menuName}
                          </Link>
                        ) : item.link.includes('http') ? (
                          <a
                            href={item.link}
                            target={`_${item.linkTarget.toLowerCase()}`}
                            onClick={() => {
                              setDepth2Active(index)
                              gaEvent(item.gaEventName !== undefined ? item.gaEventName : '')
                            }}
                            className={`${
                              !currentPath.includes('/store/detail') && depth2Active === index
                                ? styles.active
                                : ''
                            } ${depth2Active === index && isMoDepth3Chk ? ' check' : ''}`}
                          >
                            <span
                              className={
                                gnb.gnbMenuSeq === 49
                                  ? styles.eng
                                  : gnb.gnbMenuSeq === 105
                                  ? styles.yu
                                  : gnb.gnbMenuSeq === 71
                                  ? styles.yafit
                                  : ''
                              }
                            >
                              {item.menuName}
                            </span>
                          </a>
                        ) : (
                          <Link
                            to={item.link}
                            target={`_${item.linkTarget.toLowerCase()}`}
                            onClick={() => {
                              setDepth2Active(index)
                              gaEvent(item.gaEventName !== undefined ? item.gaEventName : '')
                            }}
                            className={`${
                              !currentPath.includes('/store/detail') &&
                              currentPath.includes(`${item.link}`)
                                ? styles.active
                                : ''
                            } ${depth2Active === index && isMoDepth3Chk ? ' check' : ''}`}
                          >
                            <span
                              className={
                                gnb.gnbMenuSeq === 49
                                  ? styles.eng
                                  : gnb.gnbMenuSeq === 105
                                  ? styles.yu
                                  : gnb.gnbMenuSeq === 71
                                  ? styles.yafit
                                  : ''
                              }
                            >
                              {item.menuName}
                            </span>
                          </Link>
                        )}
                        {/* free 하드 코딩 20240307 */}
                        {item.childGnbMenus?.length > 0 && (
                          <ul className="depth3">
                            {item.childGnbMenus.map((sub, index, e) => (
                              <li
                                key={`${sub.depth}-${sub.gnbMenuSeq}`}
                                className={
                                  sub.iconType && sub.iconType !== 'NONE'
                                    ? styles[sub.iconType.toLowerCase()]
                                    : ''
                                }
                              >
                                {sub.link.includes('http') ? (
                                  <Link
                                    to={sub.link}
                                    target={`_${sub.linkTarget.toLowerCase()}`}
                                    onClick={() => {
                                      setMoDepth3Chk(false)
                                      gaEvent(sub.gaEventName !== undefined ? sub.gaEventName : '')
                                    }}
                                  >
                                    {sub.menuName}
                                  </Link>
                                ) : (
                                  <NavLink
                                    to={sub.link}
                                    target={`_${sub.linkTarget.toLowerCase()}`}
                                    onClick={() => {
                                      setMoDepth3Chk(false)
                                      gaEvent(sub.gaEventName !== undefined ? sub.gaEventName : '')
                                    }}
                                  >
                                    {sub.menuName}
                                  </NavLink>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                ))}
            <button
              type="button"
              className={styles.btnGnbPrev}
              onClick={() => gnbMoveEvent('prev')}
              style={{ display: gnbBtnPrev ? `block` : `none` }}
            >
              prev
            </button>
            <button
              type="button"
              className={styles.btnGnbNext}
              onClick={() => gnbMoveEvent('next')}
              style={{ display: gnbBtnNext ? `block` : `none` }}
            >
              next
            </button>
          </div>
          {/* side menu */}
          {children}
        </div>
      </section>
      <div
        className={`${styles.moGnbDimed} ${isMoDepth3Chk ? styles.block : ''}`}
        onClick={() => setMoDepth3Chk(false)}
      />
    </>
  )
}
