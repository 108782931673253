import { useRef, useState } from 'react'

import alertMessage from 'common/alertMessage'

export default function useDbCollectionFormLevelTest() {
  // 상담시간, 개인정보 수집 및 마케팅 동의 체크 유무 초기 값
  const initialFormData = {
    counselTime: '',
    privacyAgreeYn: false,
    smsAgreeYn: false
  }
  const [formData, setFormData] = useState(initialFormData)
  const [isOpenModalNotice, setIsOpenModalNotice] = useState(false)
  const [isOpenModalMarketing, setIsOpenModalMarketing] = useState(false)
  const [isAllChecked, setIsAllChecked] = useState(false)

  const selectRef = useRef(null)

  // 이용약관 모달 토글
  const handleToggleModalNotice = () => {
    setIsOpenModalNotice((prev) => !prev)
  }

  // 마케팅 동의 모달 토글
  const handleToggleModalMarketing = () => {
    setIsOpenModalMarketing((prev) => !prev)
  }

  // 개인정보 수집 및 이용 동의 체크 핸들러
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target
    setFormData((prev) => {
      const newFormData = { ...prev, [id]: checked }
      setIsAllChecked(newFormData.privacyAgreeYn && newFormData.smsAgreeYn)
      return newFormData
    })
  }

  // 전체 동의 체크 핸들러
  const handleAllCheckboxChange = (e) => {
    const { checked } = e.target
    setIsAllChecked(checked)
    setFormData((prev) => ({
      ...prev,
      privacyAgreeYn: checked,
      smsAgreeYn: checked
    }))
  }

  // 상담가능시간 선택 핸들러
  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prev) => ({ ...prev, [id]: value }))
  }

  // 상담 데이터 전송 핸들러
  const postConsultationData = (submitCallback) => {
    // 모달 닫기
    setIsOpenModalNotice(false)
    setIsOpenModalMarketing(false)

    // 상담 데이터 전송
    submitCallback()

    // 모달 폼 초기화
    setFormData(initialFormData)
    setIsAllChecked(false)
  }

  // 상담 신청 버튼 클릭 핸들러
  const handleSubmit = (submitCallback) => {
    // 유효성 체크
    if (formData.counselTime === '') {
      alert(alertMessage.FORM_COUNSEL_REQUIRED)
      return
    }

    if (!formData.privacyAgreeYn) {
      alert(alertMessage.FORM_PRIVACY_REQUIRED)
      return
    }

    // 개인정보 수집만 체크된 경우 마케팅 동의 모달 열기
    if (!formData.smsAgreeYn) {
      handleToggleModalMarketing(true)
      return
    }

    // 상담 데이터 전송
    postConsultationData(submitCallback)
  }

  return {
    formData, // 상담 폼 데이터 (상담시간/개인정보 & 마케팅 동의)
    isOpenModalNotice, // 이용 약관 모달
    handleToggleModalNotice,
    isOpenModalMarketing, // 마케팅 동의 체크 유도 모달
    handleToggleModalMarketing,
    isAllChecked, // 전체 동의 체크 유무
    handleAllCheckboxChange,
    handleInputChange, // 상담시간 선택 핸들러
    handleCheckboxChange, // 개인정보 수집 및 이용 동의 체크 핸들러
    selectRef, // 상담시간 select Ref
    handleSubmit, // 상담 신청 버튼 클릭 핸들러 (유효성 체크)
    postConsultationData // 상담 데이터 전송 핸들러 (마케팅 모달에서 전송시 사용)
  }
}
