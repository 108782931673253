import React from 'react'

const ImgAddBook = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_addbook.png'
const AddBookItem = ({
  packageIdx,
  title,
  productSeq,
  bookSeq,
  imgUrl,
  addBookCheck,
  orderListSeq
}) => {
  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const checkedYn = (seq) => {
    let checkYn = false
    orderListSeq.forEach((item) => {
      item.additionalPackageList.forEach((item) => {
        if (item.packageSeq === seq) checkYn = true
      })
    })
    return checkYn
  }
  return (
    <>
      <label>
        <input
          type="checkbox"
          value={Number(productSeq)}
          onChange={(e) => {
            addBookCheck(packageIdx, e.target.checked, bookSeq, productSeq)
          }}
          checked={checkedYn(bookSeq)}
        />
        <span></span>
        <div className="imgBox">
          <img src={imgUrl ? imgUrl : ImgAddBook} alt="" />
        </div>
        <strong>{title}</strong>
        <em>+{addComma(Number(productSeq))}원</em>
      </label>
    </>
  )
}

export default AddBookItem
