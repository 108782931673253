import { useRef } from 'react'
import { Link } from 'react-router-dom'

import useStaggerMotion from 'hooks/useStaggerMotion'

import { YAFIT_ALLINONE_LINK } from 'common/config'

import styles from './BrandValueSection.module.scss'

export default function BrandValueSection() {
  const ref = useRef(null)
  useStaggerMotion(ref, 300, styles.active, '0px', 0.5)

  return (
    <section>
      <div className={styles.innerCol}>
        <div className={styles.descBox}>
          <div className={styles.innerBox} ref={ref}>
            <p className={styles.animationText}>변화의 시작은 매우 쉽습니다.</p>
            <p className={styles.animationText}>
              올바른 선택 하나로 당신도 지금 시작할 수 있습니다.
            </p>
            <p className={styles.animationText}>야나두와 함께 성장하는 나로 시작하세요.</p>
          </div>
        </div>
        <div className={styles.valueBox}>
          <div className={styles.innerBox}>
            <p>
              야나두와 함께 하는
              <span>그 이상의 가치 +</span>
            </p>
            <ul>
              <li>
                <Link to="/promotion-landing/bestAwards">
                  하루 10분 영어
                  <strong>야나두 영어</strong>
                </Link>
              </li>
              <li>
                <a href={YAFIT_ALLINONE_LINK}>
                  수만명이 함께 달리는<strong>야나두 피트니스</strong>
                </a>
              </li>
              <li>
                <Link to="/store/list">
                  새로운 성공 로드맵<strong>야나두 클래스</strong>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
