import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useGetGnbList from 'hooks/useGetGnbList'
import useSubscription from 'hooks/useSubscription'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import { getCookie, setCookie } from 'common/Cookie'
import AlertModal from 'components/modal/AlertModal'

import 'assets/styles/login.scss'

import ModalPortal from '../ModalPortal'
import ModalDormantAccounts from './modal/ModalDormantAccounts'
import ModalFindPwd from './modal/ModalFindPwd'
import ModalNewPwd from './modal/ModalNewPwd'

export default function Login({
  handleCloseModal,
  handleSignUpSocial,
  checked,
  reloadType,
  signType,
  isPlatform
}) {
  const location = useLocation()
  const pramChk = location.search.split('?')
  const pram = pramChk.length > 1 ? pramChk[1].split('&')[pramChk[1].split('&').length - 1] : ''
  const promotionPathName = pram
    ? decodeURIComponent(pramChk[1].split('&')[pramChk[1].split('&').length - 1].split('=')[1])
    : ''

  const navigate = useNavigate()
  const { state } = useLocation()
  const defaultUrl = state && state.from
  const redirectUrl = defaultUrl + (state?.query ? state.query : '')
  const authCheck = state && state.authCheck

  const [isKeyStatus, setIsKeyStatus] = useState(true)
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [modalopenSleep, setModalopenSleep] = useState(false)
  const [modalopenNewPwd, setModalopenNewPwd] = useState(false)
  const [modalopenFindPwd, setModalopenFindPwd] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [loginCode, setLoginCode] = useState(null)
  const [loginMessage, setLoginMessage] = useState(null)
  const [pwdCookie, setPwdCookie] = useState('')

  const [inputValue, setInputValue] = useState({
    id: '',
    password: ''
  })

  const { setRefresh } = useGetGnbList()

  /*
   * uPlus 구독 서비스 query
  TODO: location 사용에 대해서는 고민필요
  */

  const { subScriptionModal, postSubscription } = useSubscription()

  const handleInputValue = (e) => {
    let { name, value } = e.target

    setInputValue({ ...inputValue, [name]: value })
  }

  const handleOpenModalAlert = useCallback((msg, code) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
    setIsKeyStatus(false)
    setLoginMessage(msg)
    setLoginCode(code)
  }, [])

  const handleCloseAlertModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
    setIsKeyStatus(true)
  }, [])

  const handleOpenSleepModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenSleep(true)
    setIsKeyStatus(false)
  }, [])

  const handleCloseSleepModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenSleep(false)
    setIsKeyStatus(true)
  }, [])

  const handleOpenNewPwdModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenNewPwd(true)
    setIsKeyStatus(false)
  }, [])

  const handleOpenFindPwdModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenFindPwd(true)
  }, [])

  const handleCloseFindPwdModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenFindPwd(false)
  }, [])

  // 로그인 성공 -> 비밀번호 변경이 필요(true)면 비밀번호 변경 팝업 오픈 / 필요x(false)면 페이지 새로고침
  const handleLoginSuccess = (isNeedPasswordChange, userseq) => {
    // 로그인 성공 > gnb refresh
    setRefresh(true)

    if (isNeedPasswordChange && (pwdCookie === '' || pwdCookie === undefined)) {
      handleOpenNewPwdModal()

      return false
    }

    if (handleCloseModal) {
      handleCloseModal()
    }
    if (signType === 'uPlus') {
      const data = {
        userSeq: userseq
      }
      // subscription post
      postSubscription(data)
      return
    }

    // 프로모션에서 비로그인 상태로 수강신청 시 (/login?promotion_order=... URL형태로 이동된 케이스)
    // pram 값에 저장된 URL 주소를 decodeURIComponent 후 URL 이동.
    if (pram.indexOf('promotion_order=') > -1) {
      window.location.href = decodeURIComponent(pram.split('promotion_order=')[1])
      return
    }

    // 프로모션 페이지에서 이동한 경우
    if (
      [
        '/promotion-landing/yafitLite.html',
        '/promotion-landing/yafitPremium.html',
        '/promotion-landing/yafitFree.html'
      ].indexOf(promotionPathName) > -1
    ) {
      window.location.href = promotionPathName
      return
    }

    // yanadoo.co.kr이 아닌 외부 URL에서 로그인 접근 시.
    // 파라메타 redirect 값에 encodeURIComponent 처리된 URL을 담아서 이동.
    // redirect 파라메타값이 존재하는 경우 해당 URL을 decodeURIComponent 처리 후 URL 이동 처리.
    if (pram.indexOf('redirect=') > -1) {
      window.location.href = decodeURIComponent(pram.split('redirect=')[1])
      return
    }

    // 야핏 운동성공률 예측 결과 이동시
    if (promotionPathName.indexOf('/yafitTalkResult') > -1) {
      window.location.href = promotionPathName
      return
    }

    // 베스트 어워즈인 경우
    if (['/promotion-landing/bestAwards.html'].indexOf(promotionPathName) > -1) {
      AuthService.getUser((userInfo) => {
        if (userInfo.entrance?.entranceStatus === 'ENTRANCE') {
          // 입학회원인 경우
          const PROMOTION_PACKAGE_CODE = 'BEST_AWARDS'
          window.location.href =
            '/mypage/order/cart/promotion/' +
            PROMOTION_PACKAGE_CODE +
            '?presentProductSeq=&errorReturnUrl=' +
            encodeURIComponent(promotionPathName)
        } else {
          window.location.href = promotionPathName + '?returnYn=Y'
        }
      })
      return false
    }

    // bigin sessionStorage에 회원정보 삽입 함수
    const biginUserInfo = () => {
      api
        .get(config.AUTH_API_HOST + '/auth/v2/user')
        .then((response) => {
          const userInfo = response.data.data
          let userDetail = {}

          if (userInfo) {
            userDetail = {
              id: userInfo.userId,
              name: userInfo.username,
              email: userInfo.email,
              cellPhone: userInfo.cellPhone
            }

            sessionStorage.setItem('user_detail', JSON.stringify(userDetail))

            if (redirectUrl && authCheck) {
              window.location.href = redirectUrl
            } else if (reloadType) {
              setCookie('orderLoginSuccess', 'Y')
              window.location.reload()
            } else if (getCookie('historyUrl') !== '') {
              // historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
              // 로그인 성공 시 실행되는 조건에서 가장 후순위 조건인 야나두 홈으로 이동되는 조건 바로전 조건으로 적용.
              if (window.location.href !== getCookie('historyUrl')) {
                window.location.href = getCookie('historyUrl')
              }
            } else {
              window.location.href = '/'
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
    if (!isPlatform) {
      biginUserInfo()
    }
    if (isPlatform) {
      navigate('/webview/loginSuccess', { state: checked })
    }
  }

  // 비회원 수기결제 회원가입이 필요한지 체크
  const handleManualPayment = (userToken) => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/anonymous-user/me')
      .then((response) => {
        if (response.data.data.anonymous) {
          if (handleCloseModal) {
            handleCloseModal()
          }

          navigate('/member/nonmember', {
            state: { userId: inputValue.id, token: userToken }
          })

          return false
        }

        if (handleCloseModal) {
          handleCloseModal()
        }
        window.location.href = '/'
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleLogin = () => {
    if (handleSignUpSocial) {
      handleSignUpSocial(inputValue.id, inputValue.password)
      return false
    }

    AuthService.signIn(inputValue.id, inputValue.password, checked, {
      SUCCESS: handleLoginSuccess,
      FAILURE: handleOpenModalAlert,
      SLEEP: handleOpenSleepModal,
      PAYMENT: handleManualPayment
    })
  }

  const handleCheckEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  useEffect(() => {
    if (inputValue.id && inputValue.password) {
      setIsComplete(true)
    } else {
      setIsComplete(false)
    }
  }, [inputValue.id, inputValue.password])

  useEffect(() => {
    // 비밀번호 5회 이상 틀렸을 경우
    if (loginCode === 4013 && !modalopenAlert) {
      handleOpenFindPwdModal()
      setLoginCode(null)
    }
  }, [loginCode, modalopenAlert])

  useEffect(() => {
    setPwdCookie(getCookie('isNeedPasswordChange'))
  }, [getCookie('isNeedPasswordChange')])

  useEffect(() => {
    if (signType) {
      sessionStorage.setItem('signType', signType)
    }
  }, [signType])

  return (
    <>
      <form name="loginFrm" action="#" onKeyPress={isKeyStatus ? handleCheckEnter : undefined}>
        <div className="login-box">
          <div className="login-input">
            <label>
              <i className="blind">아이디</i>
            </label>
            <input
              type="text"
              placeholder="아이디를 입력해주세요."
              name="id"
              value={inputValue.id}
              onChange={handleInputValue}
            />
          </div>
          <div className="login-input">
            <label>
              <i className="blind">비밀번호</i>
            </label>
            <input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              name="password"
              value={inputValue.password}
              onChange={handleInputValue}
            />
          </div>
          <div className="btn-flex-form">
            <button
              type="button"
              className={'btn btn-login' + (isComplete ? ' active' : ' disabled')}
              disabled={isComplete ? false : true}
              onClick={handleLogin}
            >
              <span>야나두 계정으로 로그인</span>
            </button>
          </div>
        </div>
      </form>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            btnConfirm
            msg={loginMessage && loginMessage}
            handleCloseModal={handleCloseAlertModal}
          />
        </ModalPortal>
      ) : null}
      {subScriptionModal}

      {modalopenSleep ? (
        <ModalPortal>
          <ModalDormantAccounts
            btnClose
            userInfo={inputValue}
            handleCloseModal={handleCloseSleepModal}
          />
        </ModalPortal>
      ) : null}
      {modalopenNewPwd ? (
        <ModalPortal>
          <ModalNewPwd title={'비밀번호 변경 안내'} btnClose />
        </ModalPortal>
      ) : null}
      {modalopenFindPwd ? (
        <ModalPortal>
          <ModalFindPwd
            title={'비밀번호 찾기'}
            btnClose
            handleCloseModal={handleCloseFindPwdModal}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
