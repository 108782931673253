import React from 'react'
import ReactMarkdown from 'react-markdown'

import { AINADOO_CONFIG } from '../config'
import s from './styles/Chat.module.scss'

export default function MessageAi({ message, pageType }) {
  return (
    <div className={s.aiMessage}>
      <div className={s.msgThumb}>
        <img
          src={`${AINADOO_CONFIG.IMG_HOST}${
            pageType === 'srr' ? 'img_ainadoo_profile_srr' : 'img_ainadoo_profile'
          }.png`}
          alt="AI 스르르"
        />
      </div>
      <dl>
        <dt className={s.msgName}>{pageType === 'srr' ? 'AI 스르르' : 'AI 나두'}</dt>
        <dd className={s.message}>
          <ReactMarkdown>{message}</ReactMarkdown>
        </dd>
      </dl>
    </div>
  )
}
