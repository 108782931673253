import { NativeSahreSNS } from 'webview/bridge'

// shareType : kakaotalk(카카오톡), kakaostory(카카오스토리), band(네이버밴드)
// shareUrl : 공유할 URL
// shareText : 공유 문구
// shareImage : og image
export default function Kakao({ meta, className }) {
  return (
    <button
      className={className}
      onClick={() =>
        NativeSahreSNS(
          'kakaotalk',
          'https://www.yanadoo.co.kr/AInativespeakertalk',
          meta.title,
          meta.og
        )
      }
    >
      카톡 공유하기
    </button>
  )
}
