import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import moment from 'moment'
import Satisfaction from 'pages/product/Satisfaction'

import api from 'common/api'

import '../../assets/styles/reviewlist.scss'

import styles from './ReviewList.module.scss'
import BtnMore from '../../components/BtnMore'
import ModalReviewDetail from '../../components/modal/ModalReviewDetail'
import ModalPortal from '../../ModalPortal'

export default function ReviewList({ classData, packageSeq }) {
  const [reviewData, setReviewData] = useState([])

  const [currentIdx, setCurrentIdx] = useState([])
  const [pageData, setPageData] = useState([])

  const [modalopen, setModalopen] = useState(false)

  const [images, setImages] = useState(null)

  const handleOpenModal = useCallback(
    (images) => {
      document.body.classList.add('modal-open')
      setImages(images)
      setModalopen(!modalopen)
    },
    [modalopen]
  )

  const handleCloseModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  // 후기 API 호출
  const getReviewList = (idx, sizeNum, reset = false) => {
    api
      .get(`/v2/store/sale/package/${packageSeq}/review/list?index=${idx}&size=${sizeNum}`)
      .then((response) => {
        if (reset) {
          setReviewData([...response.data.data.content])
        } else {
          setReviewData([...reviewData, ...response.data.data.content])
        }
        setPageData(response.data.data)
        setCurrentIdx(idx)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getMoreReviews = (idx, sizeNum) => {
    getReviewList(idx, sizeNum)
  }
  useEffect(() => {
    if (packageSeq) {
      getReviewList(1, 3, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageSeq])

  return (
    <>
      <section className="review-list-col">
        <h3>수강생 후기({classData.reviewCount && classData.reviewCount})</h3>
        <div className="point-box">
          <strong>
            평점&nbsp;
            <em>{classData.reviewSatisfaction && classData.reviewSatisfaction}</em>
          </strong>
          <Satisfaction
            type={'inline-block'}
            width={12.5}
            height={2.5}
            per={classData.reviewSatisfaction}
          />
        </div>
        <div className="review-list">
          <ul>
            {reviewData &&
              reviewData.map((review, idx) => {
                return (
                  <li key={idx}>
                    <ReviewItems item={review} onOpenModal={handleOpenModal} />
                  </li>
                )
              })}
          </ul>
          {pageData.numberOfElements < pageData.totalElements &&
          currentIdx < pageData.totalPages ? (
            <BtnMore
              type={'PAGINATION'}
              dataFunction={getMoreReviews}
              currentIdx={currentIdx}
              sizeNum={3}
              packageSeq={packageSeq}
              btnName={'수강생 후기 더보기'}
            />
          ) : null}
        </div>
      </section>
      {modalopen ? (
        <ModalPortal>
          <ModalReviewDetail handleCloseModal={handleCloseModal} images={images} />
        </ModalPortal>
      ) : null}
    </>
  )
}

const ReviewItems = ({ item, onOpenModal }) => {
  const [reviewDetail, setReviewDetail] = useState(false)

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    return isDesktop ? children : null
  }

  return (
    <>
      <dl>
        <dt>
          <span
            className="profile-img"
            style={{
              backgroundImage: `url(${item.profileUrl})`
            }}
          >
            프로필
          </span>
          <strong>
            {item.registUserName}
            <br />
            <span>{moment(item.registDate).format('YY.MM.DD')}</span>
          </strong>
          <div className="review-info">
            <Desktop>
              {item.answerYn === 'Y' ? (
                <span className="reply-state active">답변 완료</span>
              ) : (
                <span className="reply-state">답변 대기</span>
              )}
              <Satisfaction type={'inline-block'} width={8} height={1.6} per={item.satisfaction} />
            </Desktop>
            <Mobile>
              <Satisfaction
                type={'inline-block'}
                width={12.5}
                height={2.5}
                per={item.satisfaction}
              />
              <p>
                {item.answerYn === 'Y' ? (
                  <span className="reply-state active">답변 완료</span>
                ) : (
                  <span className="reply-state">답변 대기</span>
                )}
              </p>
            </Mobile>
            <Desktop>
              <p>{item.title}</p>
            </Desktop>
          </div>
        </dt>
        <dd className="review-box">
          <div className={reviewDetail ? 'active' : ''}>
            <p
              dangerouslySetInnerHTML={{
                __html: item.contents
              }}
            ></p>
            {!reviewDetail &&
              (item.contents.length >= (!Mobile ? 250 : 270) || item.answerYn === 'Y') && (
                <button type="button" onClick={() => setReviewDetail(!reviewDetail)}>
                  <span>... 더보기</span>
                </button>
              )}
          </div>
          {item.answerYn === 'Y' && reviewDetail && <AnswerItems item={item} />}
        </dd>
      </dl>
      <ReviewThumbs item={item} onOpenModal={onOpenModal} />
    </>
  )
}

const AnswerItems = ({ item }) => {
  return (
    <>
      <dl className="answer-box">
        <dt>
          <span className="profile-img">프로필</span>
          <strong>
            {item.answer.answerUserName}
            <br />
            <span>{moment(item.answer.answerDate).format('YY.MM.DD')}</span>
          </strong>
        </dt>
        <dd>
          <p
            dangerouslySetInnerHTML={{
              __html: item.answer.answerContent
            }}
          ></p>
        </dd>
      </dl>
    </>
  )
}

const ReviewThumbs = ({ item, onOpenModal }) => {
  return (
    <>
      {item.attachments && (
        <button
          className={styles.viewBtn}
          type="button"
          onClick={() => {
            onOpenModal(item.attachments)
          }}
        >
          {item.attachments?.length > 0 &&
            item.attachments.map((files, idx) => {
              return (
                <span
                  className={styles.itemThumb}
                  key={idx}
                  style={{
                    background: `url(${files.thumbnailPath}) no-repeat center`
                  }}
                />
              )
            })}
        </button>
      )}
    </>
  )
}
