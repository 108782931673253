import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useSubscription from 'hooks/useSubscription'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import { getCookie } from 'common/Cookie'
import OAuth from 'common/OAuth'

import ModalMemberType from '../modal/ModalMemberType'
import NoContent from '../NoContent'

export default function NaverAuth() {
  const navigate = useNavigate()
  const error = new URL(window.location.href).searchParams.get('error')
  const [accessUserId, setAccessUserId] = useState(null)
  const [encryptUserId, setEncryptUserId] = useState(null)
  const [modalopenSocialType, setModalopenSocialType] = useState(false)
  const [loading, setLoading] = useState(true)

  const signType = sessionStorage.getItem('signType')
  const { subScriptionModal, postSubscription } = useSubscription()

  const handleOpenModalMemberType = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenSocialType(true)
  }, [])

  const handleCloseModalMemberType = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenSocialType(false)

    // historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
    if (getCookie('historyUrl') !== '') {
      if (window.location.href !== getCookie('historyUrl'))
        window.location.href = getCookie('historyUrl')
    } else {
      navigate('/')
    }
  }, [])

  const getEncrypUserId = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/sign-in/encrypt-sns-token?snsToken=' + accessUserId)
      .then((response) => {
        setEncryptUserId(response.data.data.encryptSnsToken)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 소셜 토큰 암호화 o
  const handleLoginNaver = async (isFrame) => {
    try {
      const response = await api.post(
        `${config.AUTH_API_HOST}/auth/v2/sign-in/social/get-token?deviceType=0&snsToken=${encryptUserId}&snsType=1`
      )

      if (response.data.meta.code !== 200) {
        handleOpenModalMemberType()

        return false
      }
      if (signType === 'uPlus') {
        const data = { userSeq: response.data.data.user.userSeq }

        // subscription post
        await postSubscription(data)

        AuthService.setUserInfo(response.data.data)
        return
      }

      AuthService.setUserInfo(response.data.data)
      if (isFrame !== null) {
        AuthService.biginUserInfo(isFrame)
      } else {
        AuthService.biginUserInfo()
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (accessUserId) {
      getEncrypUserId()
    }
  }, [accessUserId])

  useEffect(() => {
    const isFrame = window.opener !== null ? 'opener' : null
    if (encryptUserId) {
      handleLoginNaver(isFrame)
    }
  }, [encryptUserId])

  useEffect(() => {
    OAuth.injectScript(
      'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js',
      () => {
        setLoading(false)
      }
    )

    if (!loading) {
      const naverLogin = new window.naver.LoginWithNaverId({
        clientId: OAuth.NAVER_CLIENT_ID,
        callbackUrl: OAuth.NAVER_REDIRECT_URI,
        isPopup: false,
        callbackHandle: true
      })
      naverLogin.init()

      naverLogin.getLoginStatus((status) => {
        if (status) {
          setAccessUserId(naverLogin.user.id)
        }
        //  else {
        //   alert('비로그인');
        // }
      })
    }

    if (error) {
      navigate('/login')
    }
  }, [loading])

  return (
    <>
      <div className="social-login naver">
        <NoContent titleMsg={''} />
      </div>
      {modalopenSocialType ? (
        <ModalPortal>
          <ModalMemberType
            title={'회원가입'}
            btnClose
            handleCloseModal={handleCloseModalMemberType}
            snsType={1}
            accessToken={accessUserId}
            encryptToken={encryptUserId}
          />
        </ModalPortal>
      ) : null}
      {subScriptionModal}
    </>
  )
}
