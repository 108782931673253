import React, { useEffect, useRef } from 'react'

import useChatFeedListStore from 'store/useChatFeedListStore'

import ChatFeedContainer from './ChatFeedContainer'
import s from './styles/Chat.module.scss'

export default function ChatContainer({ isAiResponse, userSeq, pageType }) {
  const { feedList } = useChatFeedListStore()
  const containerRef = useRef(null)

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [feedList])

  return (
    <section className={s.chatContainer} ref={containerRef}>
      <div className={s.contentInner}>
        <ChatFeedContainer isAiResponse={isAiResponse} userSeq={userSeq} pageType={pageType} />
      </div>
    </section>
  )
}
