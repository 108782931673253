import { useCallback, useEffect, useState } from 'react'

import api from 'common/api'
import useChatFeedListStore from 'store/useChatFeedListStore'

import { AI_TRAVEL_CONFIG } from '../config'

export default function useAiMessageFeed({ chatWindow, classSeq, lectureSeq, userSeq, missions }) {
  const { setFeedList, resetFeedList } = useChatFeedListStore()
  const [isAiResponse, setIsAiResponse] = useState(false)
  const [isChecked, setIsChecked] = useState({})

  /**
   * feed = {type: String, message: String}
   * type = 'AI'|'USER'
   */
  const addToFeed = useCallback(
    (feed) => {
      const trimmedMessage = feed.message?.trim()
      if (trimmedMessage === '') return

      if (feed.type === 'USER') {
        setIsAiResponse(true)
      }

      setFeedList({ type: feed.type, message: trimmedMessage })
    },
    [setFeedList]
  )

  const fetchMessage = useCallback(
    async (message, type = 'AI') => {
      const trimmedMessage = message.trim()
      if (trimmedMessage === '') return

      const payload = {
        chatWindow,
        classSeq,
        lectureSeq,
        userSeq,
        input: trimmedMessage
      }

      try {
        const response = await api.post(AI_TRAVEL_CONFIG.TRAVEL_CHAT_HOST, payload)
        const data = response.data
        const isResponse = !!data

        if (isResponse) {
          // has response
          console.log('isResponse: true')
          addToFeed({ type, message: data.assistant })
        } else if (!isResponse) {
          console.error('isResponse: false >>> This request has no response data available')
          if (message === 'Role playing start.') {
            // has no response
            fetchMessage(message)
          } else {
            addToFeed({ type, message: 'Sorry, could you say that one more time?' })
          }
        }

        if (data.missionNumber > -1) {
          setIsChecked((prev) => ({ ...prev, [data.missionNumber]: true }))
        }
      } catch (e) {
        console.error(`fetch message error (${type}): `, e)
      } finally {
        setIsAiResponse(false)
      }
    },
    [chatWindow, classSeq, lectureSeq, userSeq, addToFeed]
  )

  const postPromptMessage = useCallback(
    async ({ message }) => {
      await fetchMessage(message)
    },
    [fetchMessage]
  )

  const initPromptMessage = useCallback(() => {
    setIsAiResponse(true)
    setTimeout(() => {
      postPromptMessage({ message: 'Role playing start.' })
    }, 1000)
  }, [postPromptMessage])

  useEffect(() => {
    if (missions && missions.length > 0) {
      const initialCheckedState = missions.reduce((acc, mission) => {
        acc[mission.number] = false
        return acc
      }, {})
      setIsChecked(initialCheckedState)
    }
  }, [missions])

  return { addToFeed, initPromptMessage, postPromptMessage, isAiResponse, isChecked, resetFeedList }
}
