import React, { useEffect, useRef } from 'react'

import OAuth from 'common/OAuth'

function ShareItem({ data, banner, postShare, snsShareTitle, pathname }) {
  const copyUrlRef = useRef()

  // URL 복사하기
  const copyUrl = (e) => {
    if (!document.queryCommandSupported('copy')) {
      return alert('복사 기능이 지원되지 않는 브라우저입니다.')
    }

    copyUrlRef.current.select()
    document.execCommand('copy')
    e.target.focus()

    alert('URL 복사가 완료되었습니다!')

    if (postShare) {
      if (data.scholarshipSeq) {
        postShare(data.scholarshipRefundHistorySeq, 'SCHOLARSHIP_REFUND_HISTORY')
      } else {
        postShare(data.seq ? data.seq : data.learningReviewSeq, 'REVIEW')
      }
    }
  }

  // 카카오 공유하기
  const shareKakao = () => {
    if (data) {
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: snsShareTitle ? snsShareTitle : data.eventName,
          description: data.subTitle ? data.subTitle : '',
          imageUrl: banner ? banner : '',
          imageWidth: 600,
          imageHeight: 300,
          link: {
            mobileWebUrl: pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href,
            androidExecParams: pathname
              ? 'https://www.yanadoo.co.kr' + pathname
              : window.location.href
          }
        },
        installTalk: true,
        buttons: [
          {
            title: '웹으로 이동',
            link: {
              mobileWebUrl: pathname
                ? 'https://www.yanadoo.co.kr' + pathname
                : window.location.href,
              webUrl: pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href
            }
          }
        ]
      })

      if (postShare) {
        if (data.scholarshipSeq) {
          postShare(data.scholarshipRefundHistorySeq, 'SCHOLARSHIP_REFUND_HISTORY')
        } else {
          postShare(data.seq ? data.seq : data.learningReviewSeq, 'REVIEW')
        }
      }
    }
  }

  const shareNaverBand = () => {
    if (data) {
      let url =
        'http://www.band.us/plugin/share?body=' +
        encodeURIComponent(snsShareTitle ? snsShareTitle : data.eventName + '\n') +
        (pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href)

      window.open(url, 'shareBand', 'width=400, height=500, resizable=yes')

      if (postShare) {
        if (data.scholarshipSeq) {
          postShare(data.scholarshipRefundHistorySeq, 'SCHOLARSHIP_REFUND_HISTORY')
        } else {
          postShare(data.seq ? data.seq : data.learningReviewSeq, 'REVIEW')
        }
      }
    }
  }

  useEffect(() => {
    OAuth.injectScript('https://developers.kakao.com/sdk/js/kakao.js', () => {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(OAuth.KAKAO_JS_KEY)
      }
    })
  }, [])

  return (
    <ul className="share-item">
      <li className="kakaotalk">
        <button onClick={shareKakao}>
          <i className="blind">kakaotalk</i>
        </button>
      </li>
      <li className="band">
        <button onClick={shareNaverBand}>
          <i className="blind">band</i>
        </button>
      </li>
      <li className="url">
        <button onClick={copyUrl}>
          <i className="blind">
            copy
            <input
              ref={copyUrlRef}
              value={pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href}
              readOnly
            />
          </i>
        </button>
      </li>
    </ul>
  )
}

export default ShareItem
