import { useEffect, useState } from 'react'

import '../../assets/styles/modal.scss'
import '../../assets/styles/coupon.scss'
import api from 'common/api'
import CouponRegister from 'components/coupon/CouponRegister'

import Coupon from '../coupon/Coupon'
import NoContent from '../NoContent'

const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_coupon.png'

const ModalCouponDownload = ({
  btnClose,
  handleCloseModal,
  couponDownloadData,
  getCouponDownloadData,
  priceInfoSeq
}) => {
  const [couponCodeList, setCouponCodeList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [downloadPossible, setDownloadPossible] = useState(false)

  // 전체 쿠폰 다운로드 API
  const downLoadCoupon = () => {
    api
      .post(`/v2/coupon/download/list?size=100${couponCodeList}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          setIsLoading(!isLoading)
        } else {
          alert('전체 다운로드가 완료되었습니다!')
          window.location.reload()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 쿠폰 다운로드할 때 쿠폰 코드 리스트 변경해주는 함수
  const getCouponCode = (data) => {
    let couponDownloadCount = 0
    setCouponCodeList(data.map((coupon) => `&couponCodeList=${coupon.couponCode}`).join(''))
    couponDownloadData.forEach((item) => {
      if (item.getCouponYn && item.getCouponYn) couponDownloadCount++
    })
    if (couponDownloadData.length - couponDownloadCount > 0) setDownloadPossible(true)
  }

  const couponListReflesh = () => {
    priceInfoSeq ? getCouponDownloadData(priceInfoSeq) : getCouponDownloadData()
  }

  useEffect(() => {
    getCouponCode(couponDownloadData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponCodeList, isLoading, couponDownloadData, downloadPossible])

  useEffect(() => {
    if (isLoading) {
      priceInfoSeq ? getCouponDownloadData(priceInfoSeq) : getCouponDownloadData()
      getCouponCode(couponDownloadData)
      setIsLoading(!isLoading)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>쿠폰</strong>
          </div>
          <div className="modal-content coupon type-fixed-button">
            <CouponRegister couponAllListData={couponDownloadData} refleshFn={couponListReflesh} />
            <ul className="coupon-lists">
              {couponDownloadData.length !== 0 ? (
                couponDownloadData.map((couponData, idx) => {
                  return (
                    <Coupon
                      key={idx}
                      type={'down'}
                      couponData={couponData}
                      getCouponDownloadData={getCouponDownloadData}
                      priceInfoSeq={priceInfoSeq}
                    />
                  )
                })
              ) : (
                <NoContent
                  icoImg={icoImg}
                  titleMsg={'다운로드 가능한 쿠폰이 없습니다.'}
                  style={{ padding: '0 !important' }}
                />
              )}
            </ul>
            <div className="btn-flex-form">
              <button
                className={
                  'btn' +
                  (couponDownloadData.length !== 0 && downloadPossible ? ' active' : ' disabled')
                }
                onClick={
                  couponDownloadData.length !== 0 && downloadPossible
                    ? () => {
                        downLoadCoupon()
                      }
                    : undefined
                }
              >
                <span>쿠폰 전체 다운로드</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalCouponDownload
