import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'
import AlertModal from 'components/modal/AlertModal'
import ModalMyInquiry from 'components/modal/ModalMyInquiry'

import styles from './DetailInquiry.module.scss'
import Lnb from '../../../components/Lnb'

export default function DetailInquiry() {
  const navigate = useNavigate()
  const { inquirySeq } = useParams()
  const [inquiryData, setInquiryData] = useState([])
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [modalopenInquiry, setModalopenInquiry] = useState(false)

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleOpenModalInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenInquiry(true)
  }, [])

  const handleCloseModalInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenInquiry(false)
  }, [])

  const getInquiryData = () => {
    api
      .get(`/v2/inquiry/${inquirySeq}`)
      .then((response) => {
        setInquiryData(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  const deleteInquiryData = () => {
    api
      .delete(`/v2/inquiry/${inquirySeq}`)
      .then((response) => {
        alert('삭제가 완료되었습니다.')
        navigate('/mypage/qna/inquiry')
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getInquiryData()
    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader
              title="1:1 문의"
              onClick={() =>
                document.referrer === '' ? navigate('/mypage/qna/inquiry') : navigate(-1)
              }
            />
            <Lnb />
            <section className={styles.listDetailSection}>
              <div className={styles.titleBox}>
                <strong>1:1문의</strong>
              </div>
              <div className={`${styles.detailTitleBox} ${styles.board} ${styles.inquiry}`}>
                <div className={styles.infoArea}>
                  <div className={styles.class}>
                    <strong className={styles.subTit}></strong>
                    <p className={`${styles.mainTit} ellipsis`}>{inquiryData.categoryName}</p>
                  </div>
                  <div className={styles.replyModify}>
                    <div className={styles.reply}>
                      <strong
                        className={`${styles.check} ${
                          inquiryData.answerYn === 'Y' ? styles.completed : ''
                        }`}
                      >
                        {inquiryData.answerYn === 'Y' ? '답변완료' : '답변대기'}
                      </strong>
                      <span className={styles.date}>{formatDate(inquiryData.registDate)}</span>
                    </div>
                    <div className={styles.modify}>
                      {inquiryData.answerYn === 'N' && (
                        <div className={`${styles.btnGroup} btn-group`}>
                          <button
                            className={`${styles.btnModify} btn-modify`}
                            onClick={handleOpenModalInquiry}
                          >
                            <span>수정</span>
                          </button>
                          <button
                            className={`${styles.btnDelete} btn-delete`}
                            onClick={handleOpenModalAlert}
                          >
                            <span>삭제</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.detailContBox}>
                <div className={styles.detailContQuestionBox}>
                  <p>
                    {inquiryData.contents}
                    {inquiryData.inquiryFilePath && (
                      <img src={inquiryData.inquiryFilePath} alt="" />
                    )}
                  </p>
                  {inquiryData.image && <img src={inquiryData.image} alt="" />}
                </div>
                {inquiryData.answerYn === 'Y' ? (
                  <div className={styles.detailContAnswerBox}>
                    <div className={styles.thumbArea}>
                      <span className={styles.thumb}>
                        <i className="blind">썸네일</i>
                        {inquiryData.answer.answerThumbnailPath && (
                          <img src={inquiryData.answer.answerThumbnailPath} alt="" />
                        )}
                      </span>
                      <div className={`${styles.desc} desc`}>
                        <strong>{inquiryData.answer.answerUserName}</strong>
                        <p>{formatDate(inquiryData.answer.answerDate)}</p>
                      </div>
                    </div>
                    <pre
                      className="answer-txt"
                      dangerouslySetInnerHTML={{
                        __html: inquiryData.answer.answerContent
                      }}
                    />
                    {inquiryData.answer.answerFilePath && (
                      <img src={inquiryData.answer.answerFilePath} alt="" />
                    )}
                  </div>
                ) : null}
              </div>
              <div className="list-btn-col">
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/mypage/qna/inquiry'
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'작성하신 문의를 삭제하시겠습니까? 삭제시 문의내역은 복구할 수 없습니다.'}
            handleCloseModal={handleCloseModalAlert}
            handleConfirmClose={deleteInquiryData}
          />
        </ModalPortal>
      ) : null}
      {modalopenInquiry ? (
        <ModalPortal>
          <ModalMyInquiry
            btnClose
            inquirySeq={inquirySeq}
            handleCloseModal={handleCloseModalInquiry}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
