import React from 'react'
import { Link } from 'react-router-dom'

export default function TabMain({ activeTab }) {
  return (
    <section className="myclass-tab-area">
      <ul>
        <li className={activeTab === 'MYCLASS' ? 'active' : null}>
          <Link to={'/myclass/1'} className="tab-item">
            수강중인 클래스
          </Link>
        </li>
        <li className={activeTab === 'MYHISTORY' ? 'active' : null}>
          <Link to={'/myclass/history'} className="tab-item">
            전체 수강 내역
          </Link>
        </li>
      </ul>
    </section>
  )
}
