import React, { useEffect, useState } from 'react'

import '../../assets/styles/modal.scss'
import styles from './ModalApplyCoupon.module.scss'

export default function ModalApplyCoupon({
  title,
  couponName,
  couponPackage,
  btnClose,
  handleCloseModal,
  couponUrl,
  isUrlButton
}) {
  // 쿠폰 적용 상품 리스트
  const [couponPackageNameList, setCouponPackageNameList] = useState([])
  const [couponUrlList, setCouponUrlList] = useState('')

  useEffect(() => {
    setCouponPackageNameList(
      couponPackage &&
        couponPackage.map((item) => {
          return item.packageName
        })
    )
    setCouponUrlList(couponUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="modal-wrap apply-coupon">
      <div className="dimmed" onClick={handleCloseModal}></div>
      <div className="modal-inner">
        {btnClose && (
          <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
            <i className="blind">닫기</i>
          </button>
        )}
        <div className="modal-header">
          <strong>{title}</strong>
        </div>
        <div className="modal-content">
          <div className={styles.couponBox}>
            {couponName && <strong>{couponName}</strong>}
            {couponPackageNameList && (
              <div>
                {couponPackageNameList.map((packageName, idx) => {
                  return <p key={idx}>{packageName}</p>
                })}
              </div>
            )}
            {couponUrlList && isUrlButton ? (
              <button
                className={styles.urlButton}
                onClick={() => window.open(`${couponUrl}`, '_blank')}
              >
                상품 보러가기
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
