import React from 'react'

import ChatForm from './ChatForm'
import s from './styles/Chat.module.scss'

export default function ChatFooter({ addToFeed, postPromptMessage, isAiResponse }) {
  return (
    <section className={s.chatFooter}>
      <div className={s.footerInner}>
        <ChatForm
          addToFeed={addToFeed}
          postPromptMessage={postPromptMessage}
          isAiResponse={isAiResponse}
        />
      </div>
    </section>
  )
}
