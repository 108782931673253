import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import api from 'common/api'
import '../../assets/styles/notice.scss'
import MobileHeader from 'components/common/MobileHeader'

export default function DetailOfficial() {
  const navigate = useNavigate()
  const { currentSeq } = useParams()
  const [noticeData, setOfficialData] = useState([])

  const onChangeDash = (date) => {
    return date.replace(/-/g, '.')
  }

  const getOfficialData = () => {
    api
      .get(`/v2/ir-notice/${currentSeq}`)
      .then((response) => {
        setOfficialData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getOfficialData()
  }, [currentSeq])

  return (
    <>
      <div className="notice-col official">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader
              title="공고사항"
              onClick={() =>
                document.referrer === '' ? navigate('/service/official/1') : navigate(-1)
              }
            />
            <section className="list-detail-col">
              <div className="title-col">
                <strong>공고사항</strong>
              </div>
              <div className="detail-title-area">
                <div className="tit-flex-form">
                  <strong className="sub-tit black">{noticeData.title}</strong>
                  <div className="info">
                    <span className="date">
                      {noticeData.registDate &&
                        onChangeDash(noticeData.registDate.substring(0, 10))}
                    </span>
                  </div>
                </div>
              </div>
              <div className="detail-content">
                {noticeData.serviceNoticeAttachFiles &&
                  noticeData.serviceNoticeAttachFiles.map((item, idx) => {
                    return (
                      <>
                        <div key={idx} className="detail-download">
                          <a
                            key={item.seq}
                            href={item.attachFilePath}
                            download
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>{item.attachFileName}</span>
                          </a>
                        </div>
                      </>
                    )
                  })}

                <div className="detail-question">
                  <pre dangerouslySetInnerHTML={{ __html: noticeData.contents }}></pre>
                </div>
              </div>

              <div className="list-btn-col">
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/service/official/1'
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
