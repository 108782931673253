import { useCallback, useEffect, useState } from 'react'

import bestImage from '../images/ico_best.png'
import excellentImage from '../images/ico_excellent.png'
import poorImage from '../images/ico_poor.png'

/**
 * 카테고리 스탯을 3개 항목(어휘/문법, 뉘앙스, 듣기/말하기)으로 구분하여
 * 누적 정답 수, 문제 수, 스코어, 뱃지 이미지 등 등급 정보 객체를 반환
 */
export default function useSetCategoryGrade(categoryStats) {
  const { vocabulary, grammar, nuance, listening, speaking } = categoryStats
  const [grade, setGrade] = useState([])
  const [assessmentText, setAssessmentText] = useState('')

  const handleSetGrade = useCallback(() => {
    const calcCategoryStats = (stats) => ({
      correct: stats.reduce((acc, curr) => acc + curr.correct, 0),
      amount: stats.reduce((acc, curr) => acc + curr.amount, 0),
      score: stats.reduce((acc, curr) => acc + curr.score, 0)
    })

    const vocaGrammarStats = calcCategoryStats([vocabulary, grammar])
    const nuanceStats = calcCategoryStats([nuance])
    const speakingListeningStats = calcCategoryStats([speaking, listening])

    const setVocaGrammarGrade = (stat) => {
      const gradeObj = {
        category: '어휘/문법',
        correct: stat.correct,
        amount: stat.amount
      }

      return stat.score >= 29
        ? { ...gradeObj, badge: 'excellent', src: excellentImage } //
        : stat.score >= 10
        ? { ...gradeObj, badge: 'best', src: bestImage }
        : { ...gradeObj, badge: 'poor', src: poorImage }
    }

    const setNuanceGrade = (stat) => {
      const gradeObj = {
        category: '뉘앙스',
        correct: stat.correct,
        amount: stat.amount
      }
      return stat.score >= 16
        ? { ...gradeObj, badge: 'excellent', src: excellentImage } //
        : stat.score >= 7
        ? { ...gradeObj, badge: 'best', src: bestImage }
        : { ...gradeObj, badge: 'poor', src: poorImage }
    }

    const setListeningSpeakingGrade = (stat) => {
      const gradeObj = {
        category: '듣기/말하기',
        correct: stat.correct,
        amount: stat.amount
      }

      return stat.score >= 44
        ? { ...gradeObj, badge: 'excellent', src: excellentImage } //
        : stat.score >= 13
        ? { ...gradeObj, badge: 'best', src: bestImage }
        : { ...gradeObj, badge: 'poor', src: poorImage }
    }

    setGrade([
      setVocaGrammarGrade(vocaGrammarStats), //
      setNuanceGrade(nuanceStats),
      setListeningSpeakingGrade(speakingListeningStats)
    ])
  }, [vocabulary, grammar, nuance, listening, speaking])

  useEffect(() => {
    handleSetGrade()
  }, [handleSetGrade])

  // 진단 섹션 레벨별 문구
  useEffect(() => {
    if (grade.length === 0) return

    const calcAssessmentText = (grade) => {
      const [vocaGrammarBadge, nuanceBadge, listeningSpeakingBadge] = grade.map(
        (item) => item.badge
      )
      let text = <></>

      // 어휘/문법만 Poor 혹은 Best (나머지는 Excellent)
      if (
        (vocaGrammarBadge === 'poor' || vocaGrammarBadge === 'best') &&
        nuanceBadge === 'excellent' &&
        listeningSpeakingBadge === 'excellent'
      ) {
        text = (
          <>
            흥미로운 주제의 어휘와 자주 틀리는 <br />
            문법을 숙지하여 다시 말하는 연습이 필요합니다.
          </>
        )
      }

      // 뉘앙스만 Poor 혹은 Best (나머지는 Excellent)
      if (
        vocaGrammarBadge === 'excellent' &&
        (nuanceBadge === 'poor' || nuanceBadge === 'best') &&
        listeningSpeakingBadge === 'excellent'
      ) {
        text = (
          <>
            원어민이 어떤 상황에서 어떤 뉘앙스를 <br />
            쓰는지 파악하고 꾸준히 말하는 연습이 필요합니다.
          </>
        )
      }

      // 듣기/말하기만 Poor 혹은 Best (나머지는 Excellent)
      if (
        vocaGrammarBadge === 'excellent' &&
        nuanceBadge === 'excellent' &&
        (listeningSpeakingBadge === 'poor' || listeningSpeakingBadge === 'best')
      ) {
        text = (
          <>
            원어민의 음성 속도를 조절하여 <br />
            반복적으로 듣고 영어 문장으로 <br />
            생각하고 말해보는 연습이 필요합니다.
          </>
        )
      }

      // 어휘/문법 and 뉘앙스가 모두 Poor 혹은 Best (듣기/말하기는 Excellent)
      if (
        (vocaGrammarBadge === 'poor' || vocaGrammarBadge === 'best') &&
        (nuanceBadge === 'poor' || nuanceBadge === 'best') &&
        listeningSpeakingBadge === 'excellent'
      ) {
        text = (
          <>
            흥미로운 주제의 어휘와 자주 틀리는 <br />
            문법을 숙지하여 다시 말하는 연습이 필요합니다.
          </>
        )
      }

      // 어휘/문법 and 듣기/말하기가 모두 Poor 혹은 Best (뉘앙스는 Excellent)
      if (
        (vocaGrammarBadge === 'poor' || vocaGrammarBadge === 'best') &&
        nuanceBadge === 'excellent' &&
        (listeningSpeakingBadge === 'poor' || listeningSpeakingBadge === 'best')
      ) {
        text = (
          <>
            일상 단어와 문장 구조를 익힌 후 <br />
            원어민의 음성을 반복하여 듣고 말하는 <br />
            연습이 필요합니다.
          </>
        )
      }

      // 뉘앙스 and 듣기/말하기가 모두 Poor 혹은 Best (어휘/문법은 Excellent)
      if (
        vocaGrammarBadge === 'excellent' &&
        (nuanceBadge === 'poor' || nuanceBadge === 'best') &&
        (listeningSpeakingBadge === 'poor' || listeningSpeakingBadge === 'best')
      ) {
        text = (
          <>
            원어민의 뉘앙스를 파악하고 <br />
            영어로 표현하는 연습이 필요합니다.
          </>
        )
      }

      // 전체가 모두 Poor
      if (
        vocaGrammarBadge === 'poor' &&
        nuanceBadge === 'poor' &&
        listeningSpeakingBadge === 'poor'
      ) {
        text = (
          <>
            자주 사용하는 표현들의 패턴을 파악하고, <br />
            하나의 표현으로 다양한 문장을 만들어보는 <br />
            훈련이 필요합니다.
          </>
        )
      }

      // 전체가 모두 Best
      if (
        vocaGrammarBadge === 'best' &&
        nuanceBadge === 'best' &&
        listeningSpeakingBadge === 'best'
      ) {
        text = (
          <>
            원어민이 자주 쓰는 주요 표현을 <br />
            직접 써보고, 소리 내어 말하는 연습이 필요합니다.
          </>
        )
      }

      // 전체가 모두 Excellent
      if (
        vocaGrammarBadge === 'excellent' &&
        nuanceBadge === 'excellent' &&
        listeningSpeakingBadge === 'excellent'
      ) {
        text = (
          <>
            다양한 영어 콘텐츠를 찾아보고, <br />
            실제 원어민이 사용하는 영어 표현을 익혀보는 게 좋아요.
          </>
        )
      }

      // setAssessmentText(text)
      setAssessmentText(text)
    }
    calcAssessmentText(grade)
  }, [grade])

  return { grade, assessmentText }
}
