import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import api from 'common/api'
import * as config from 'common/config'
import 'swiper/swiper.scss'
import 'assets/styles/Home.scss'
import 'assets/styles/index.scss'

import ItemStore from './components/ItemStore.jsx'

import styles from './index.module.scss'
import MetaInfo from '../../components/common/MetaInfo.jsx'
import MallError from '../mall/Error.jsx'

export default function Custom() {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const { pageUrl } = useParams()
  const [isError, setError] = useState(false)
  const [isCustomData, setCustomData] = useState(null)

  const customGet = async (code) => {
    try {
      await api
        .get(`/v2/custom-page/` + code)
        .then((response) => {
          if (response.data.data.useYn === 'N') {
            setError(true)
          } else {
            const data = response.data.data
            setCustomData(data)
          }
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
          setError(true)
        })
    } catch (e) {
      console.log(e)
      setError(true)
    }
  }

  useEffect(() => {
    customGet(pageUrl.toLowerCase())
  }, [pageUrl])

  return (
    <>
      {isError && isError ? (
        <MallError comment={`연결할 수 없는 페이지 입니다`} />
      ) : (
        <>
          <MetaInfo
            props={{
              title: '야나두 전체 강의',
              description: '영어의 기초부터 능숙한 대화까지, 전체 강의에서 선택해보세요!',
              site_name: '야나두 영어로 시작하면 더 이상 어렵지 않아요',
              imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/og/img_og_custom.jpg'
            }}
          />
          {isCustomData ? (
            <>
              <div className={styles.bannerBox}>
                {isCustomData.banners && isCustomData.banners.length > 1 ? (
                  <Swiper slidesPerView={1} pagination={true} modules={[Pagination]}>
                    {isCustomData.banners.map((bannerSlide, bannerIndex) => {
                      return (
                        <SwiperSlide key={bannerIndex}>
                          <a
                            href={bannerSlide.bannerUrl ? `${bannerSlide.bannerUrl}` : null}
                            target={bannerSlide.bannerUrl ? `${bannerSlide.linkTarget}` : null}
                            style={{ backgroundColor: `${bannerSlide.backgroundColor}` }}
                          >
                            {deviceCheck ? (
                              <img src={bannerSlide.mobileImagePath} alt={bannerSlide.bannerName} />
                            ) : (
                              <img src={bannerSlide.pcImagePath} alt={bannerSlide.bannerName} />
                            )}
                          </a>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                ) : (
                  <>
                    {isCustomData.banners &&
                      isCustomData.banners.map((bannerSlide, bannerIndex) => {
                        return (
                          <a
                            href={bannerSlide.bannerUrl ? `${bannerSlide.bannerUrl}` : null}
                            target={bannerSlide.bannerUrl ? `${bannerSlide.linkTarget}` : null}
                            key={bannerIndex}
                            style={{ backgroundColor: `${bannerSlide.backgroundColor}` }}
                          >
                            {deviceCheck ? (
                              <img src={bannerSlide.mobileImagePath} alt={bannerSlide.bannerName} />
                            ) : (
                              <img src={bannerSlide.pcImagePath} alt={bannerSlide.bannerName} />
                            )}
                          </a>
                        )
                      })}
                  </>
                )}
              </div>
              <section>
                {isCustomData.products &&
                  isCustomData.products.map((item, itemIndex) => {
                    return (
                      <div className={styles.productBox} key={itemIndex}>
                        <div className={styles.innerCol}>
                          <ItemStore
                            title={item.categoryName}
                            subTitle={item.categoryText}
                            storeData={item.list}
                          />
                        </div>
                      </div>
                    )
                  })}
              </section>
            </>
          ) : null}
        </>
      )}
    </>
  )
}
