import { useCallback, useState, useRef, useEffect } from 'react'

import api from 'common/api'

import styles from './CouponRegister.module.scss'
import ModalPortal from '../../ModalPortal'
import AlertModal from '../modal/AlertModal'

const CouponRegister = ({ couponAllListData, refleshFn }) => {
  const [couponCodeList, setCouponCodeList] = useState([])
  const [flag, setFlag] = useState(false)
  const [modalopen, setModalopen] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const couponRef = useRef()

  useEffect(() => {
    // 등록 된 쿠폰 리스트
    setCouponCodeList(
      couponAllListData.map((item, idx) => {
        return item.couponCode
      })
    )
  }, [couponAllListData])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
    couponRef.current.value = ''
  }, [])

  // 쿠폰번호 validation check.
  const changeRegisterNumber = (e) => {
    let value = e.target.value
    let regCheck = /^[A-Za-z0-9\-_]{1,20}$/

    // setCouponCodeLength(value.length)
    setCouponCode(value)

    if (regCheck.test(value) && value.length > 0) {
      setFlag(true)
    } else {
      setFlag(false)
    }
  }

  // 쿠폰 등록.
  const postCouponCode = (e) => {
    e.preventDefault()

    if (couponCode === '' || !flag) {
      alert('쿠폰 번호를 확인해주세요.')
      couponRef.current.focus()
      return false
    }

    if (couponCodeList.includes(couponCode)) {
      couponRef.current.focus()
      return false
    }

    const data = {
      couponCode: couponCode
    }

    api
      .post(`/v2/coupon-publish?couponCode=${data.couponCode}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
        } else {
          alert('쿠폰 등록이 완료되었습니다!')
          setCouponCode('')
          refleshFn()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <div className={styles.couponDownloadBox}>
        <form name="registerForm" action="#">
          <input
            type="text"
            maxLength="20"
            placeholder="쿠폰 코드를 입력해주세요."
            onChange={changeRegisterNumber}
            ref={couponRef}
            value={couponCode}
          />
          <button
            type="submit"
            className={flag === true ? styles.active : ''}
            onClick={postCouponCode}
          >
            쿠폰 등록
          </button>
        </form>
      </div>
      {modalopen ? (
        <ModalPortal>
          <AlertModal
            btnGroup
            msg={'쿠폰이 등록이 완료되었습니다.'}
            handleCloseModal={handleCloseModal2}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}

export default CouponRegister
