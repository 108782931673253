import ModalLogin from 'components/modal/ModalLogin'

export default function Signin() {
  const handleModal = (type) => {
    window.parent.postMessage({ type }, '*')
  }

  return (
    <ModalLogin title={'login'} stepType={'MAIN'} handleCloseModal={handleModal} btnClose iframe />
  )
}
