import React from 'react'

import styles from './InfoTextBox.module.scss'

export default function BoardListItem({ text }) {
  return (
    <section className={styles.infoFullBox}>
      <div className={styles.smInnerBox}>
        <p>{text}</p>
      </div>
    </section>
  )
}
