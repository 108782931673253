import React, { useState, useEffect, useRef, useCallback } from 'react'

import AuthService from 'services/authService'

import api from 'common/api'
import { timeCheck } from 'common/common'
import * as config from 'common/config'
import CommunityInfo from 'components/CommunityInfo'

import ModalModify from '../components/modal/ModalModify'
import ModalPortal from '../ModalPortal'
import styles from './CommentView.module.scss'

export default function CommentView({ getReviewData, data, isLoading }) {
  const [userInfo, setUserInfo] = useState(null)
  const [relationSeq, setRelationSeq] = useState(null) // 댓글이 등록될 상세 시퀀스 넘버 바뀌지 않음
  const [replySeq, setReplySeq] = useState(0) // 대댓글이 등록될 댓글의 시퀀스
  const commentRef = useRef()
  const [chkItemContent, setChkItemContent] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [userComment, setUserComment] = useState(false)
  const [userName, setUserName] = useState(null)
  const [modifyText, setModifyText] = useState(null)

  const [commentData, setCommentData] = useState(null)

  const [modalCommentSeq, setModalCommentSeq] = useState(null)
  const [modalCommentContent, setModalCommentContent] = useState(null)

  const [commentRegistTime, setCommentRegistTime] = useState(null)

  const handleChangeChk = (event) => {
    setChkItemContent(event.target.value)
  }

  const handleOpenModal = useCallback((seq, chat) => {
    document.body.classList.add('modal-open')
    setModalOpen(true)
    setModalCommentSeq(seq)
    setModalCommentContent(chat)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalOpen(false)
  }, [])

  const handleOpenComment = (commentSeq, name) => {
    setModalCommentContent(null)
    setChkItemContent('')
    setReplySeq(commentSeq === relationSeq ? 0 : commentSeq)

    if (commentSeq !== relationSeq && name) {
      setUserComment(true)
      setModifyText(null)
      setUserName(name)
    }
    commentRef.current.focus()
  }

  const handleCloseComment = () => {
    setUserComment(false)
    setReplySeq(0)
    setChkItemContent('')
    setModalCommentSeq(null)
    setModalCommentContent(null)
  }

  const getCommentData = (seq) => {
    ;(data.reviewType
      ? api.get(`/v2/comment/review-comment/${seq}`)
      : api.get(`/v2/comment/scholarship-comment/${seq}`)
    )
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentData(response.data.data)
          setCommentRegistTime(response.data.meta.dateTime.replace('-', '/').replace('-', '/'))
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 유저정보 가져오기
  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(`${config.AUTH_API_HOST}/auth/v2/user`)
        .then((response) => {
          setUserInfo(response.data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const isEmpty = (val) => {
    return (
      val === undefined ||
      val === null ||
      val === '' ||
      val.trim() === '' ||
      (val !== null && typeof val === 'object' && !Object.keys(val).length)
    )
  }

  // 댓글등록하기 눌렀을 때 함수
  const handleComment = (e, relationSeq, replySeq) => {
    if (modalCommentContent) {
      putComment(e, modalCommentSeq)
    } else {
      postComment(e, relationSeq, replySeq)
    }
  }

  // 댓글 등록
  const postComment = (e, relationSeq, parentSeq) => {
    e.preventDefault()

    // 로그인 여부 체크
    if (!AuthService.getUserInfo()) {
      alert('로그인이 필요합니다.')
      return false
    }

    if (isEmpty(chkItemContent)) {
      alert('댓글을 입력해주세요.')
      commentRef.current.focus()
      return false
    } else {
      let dataBody
      if (data.reviewType) {
        dataBody = {
          contents: chkItemContent,
          parentSeq: parentSeq,
          relationSeq: relationSeq,
          relationType: 'REVIEW'
        }
      } else {
        dataBody = {
          commentDisplayType: 'ALL',
          contents: chkItemContent,
          parentSeq: parentSeq,
          scholarshipRefundHistorySeq: relationSeq
        }
      }
      ;(data.reviewType
        ? api.post(`/v2/comment`, dataBody)
        : api.post(`/v2/scholarship-refund-history/comment`, dataBody)
      )
        .then((response) => {
          if (response.data.meta.code === 200) {
            setChkItemContent('')
            setUserComment(false)
            setReplySeq(0)
            getCommentData(relationSeq)
            if (data.scholarshipSeq) {
              getReviewData(data.scholarshipSeq, relationSeq)
            } else {
              getReviewData(relationSeq)
            }
          } else {
            alert(response.data.meta.message)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  // 댓글 삭제
  const deleteComment = (e, commentseq) => {
    e.preventDefault()

    let deleteData

    if (!data.reviewType) {
      deleteData = {
        commentSeq: commentseq,
        scholarshipRefundHistorySeq: relationSeq
      }
    }

    ;(data.reviewType
      ? api.delete(`/v2/comment/${commentseq}`)
      : api.delete(`/v2/scholarship-refund-history/comment`, { data: deleteData })
    )

      .then((response) => {
        if (response.data.meta.code === 200) {
          alert('삭제되었습니다.')
          handleCloseModal()
          setReplySeq(0)
          setModalCommentContent(null)
          setUserComment(false)
          setChkItemContent('')
          getCommentData(relationSeq)
          if (data.scholarshipSeq) {
            getReviewData(data.scholarshipSeq, relationSeq)
          } else {
            getReviewData(relationSeq)
          }
        } else {
          alert(response.data.meta.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleCommentModify = () => {
    handleCloseModal()
    commentRef.current.focus()
    setChkItemContent(modalCommentContent)
    setUserComment(true)
    setUserName(false)
    setModifyText(true)
  }

  // 댓글 수정
  const putComment = (e, commentSeq) => {
    e.preventDefault()

    if (modalCommentContent === chkItemContent) {
      alert('내용이 동일합니다. 수정해주세요!')
      return false
    }

    if (isEmpty(chkItemContent)) {
      alert('댓글을 입력해주세요.')
      commentRef.current.focus()
      return false
    }

    let commentData
    if (data.reviewType) {
      commentData = {
        commentSeq: commentSeq,
        contents: chkItemContent,
        duration: 0
      }
    } else {
      commentData = {
        commentSeq: commentSeq,
        contents: chkItemContent,
        scholarshipRefundHistorySeq: relationSeq
      }
    }

    ;(data.reviewType
      ? api.put(`/v2/comment`, commentData)
      : api.put(`/v2/scholarship-refund-history/comment`, commentData)
    )
      .then((response) => {
        if (response.data.meta.code === 200) {
          setChkItemContent('')
          setUserComment(false)
          setReplySeq(0)
          setModalCommentSeq(null)
          setModalCommentContent(null)
          getCommentData(relationSeq)
          if (data.scholarshipSeq) {
            getReviewData(data.scholarshipSeq, relationSeq)
          } else {
            getReviewData(relationSeq)
          }
        } else {
          alert(response.data.meta.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onToggleLike = (commentSeq) => {
    if (isLoading) {
      api
        .post(`v2/reaction/like?relationSeq=${commentSeq}&relationType=COMMENT`)
        .then((response) => {
          if (response.data.meta.code === 200) {
            getCommentData(relationSeq)
            if (data.scholarshipSeq) {
              getReviewData(data.scholarshipSeq, relationSeq)
            } else {
              getReviewData(relationSeq)
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    if (isLoading && data) {
      if (data.learningReviewSeq) {
        setRelationSeq(data.learningReviewSeq)
      } else {
        setRelationSeq(data.scholarshipRefundHistorySeq)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const SingleComment = ({ children, ...rest }) => {
    if (
      rest.isDeleted &&
      rest.comments &&
      !rest.comments.filter((item) => item.isDeleted === false).length
    ) {
      return false
    } else {
      return <ChatTemp {...rest} />
    }
  }

  const ReplyComment = ({ children, ...rest }) => {
    return <ChatTemp type={'reply'} {...rest} />
  }

  const ChatTemp = ({
    type,
    commentSeq,
    parentSeq,
    profile,
    name,
    chat,
    registDate,
    registUserSeq,
    isDeleted,
    isLiked,
    likeCount
  }) => {
    return (
      <div className={styles.chatCol}>
        <div className={`${styles.profileBox} ${type ? styles.reply : ''}`}>
          <div
            style={{
              backgroundImage: `url(${profile})`
            }}
          ></div>
        </div>
        <div className={styles.chatBox}>
          <div className={styles.chatTextBox}>
            <strong>{name}</strong>
            {chat}
          </div>
          <ChatInfoTemp
            registDate={registDate}
            commentSeq={commentSeq}
            parentSeq={parentSeq}
            name={name}
            registUserSeq={registUserSeq}
            isDeleted={isDeleted}
            chat={chat}
            isLiked={isLiked}
            likeCount={likeCount}
          />
        </div>
      </div>
    )
  }

  const ChatInfoTemp = ({
    commentSeq,
    parentSeq,
    registDate,
    name,
    registUserSeq,
    isDeleted,
    chat,
    isLiked,
    likeCount
  }) => {
    return (
      <div className={styles.chatInfoBox}>
        <div>
          {!isDeleted && (
            <button
              type="button"
              onClick={() => {
                onToggleLike(commentSeq)
              }}
            >
              {isLiked ? <strong>좋아요</strong> : '좋아요'}{' '}
              {likeCount === 0 ? '' : isLiked ? <strong>{likeCount}</strong> : likeCount}{' '}
            </button>
          )}
          {parentSeq === 0 && !isDeleted && (
            <button
              type="button"
              onClick={() => {
                handleOpenComment(commentSeq, name)
              }}
            >
              답글달기
            </button>
          )}

          <span>
            {timeCheck(commentRegistTime, registDate.replace('-', '/').replace('-', '/'))}
          </span>
        </div>
        {userInfo && registUserSeq === userInfo.userSeq && !isDeleted && (
          <button
            type="button"
            onClick={() => {
              handleOpenModal(commentSeq, chat)
            }}
          >
            <span></span>
          </button>
        )}
      </div>
    )
  }

  useEffect(() => {
    getUserInfo()

    if (isLoading && data.learningReviewSeq) {
      getCommentData(data.learningReviewSeq)
    }

    if (isLoading && data.scholarshipRefundHistorySeq) {
      getCommentData(data.scholarshipRefundHistorySeq)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      <CommunityInfo
        type={'REAL'}
        handleOpenComment={() => {
          handleOpenComment(relationSeq)
        }}
        data={data}
        isLoading={isLoading}
      ></CommunityInfo>
      <div className={styles.commentList}>
        {commentData &&
          isLoading &&
          commentData.map((comment, idx) => {
            return (
              <div key={idx}>
                <SingleComment
                  key={comment.commentSeq}
                  profile={comment.registUserProfileImageUrl}
                  name={comment.registUserName}
                  chat={comment.contents}
                  commentSeq={comment.commentSeq}
                  parentSeq={comment.parentSeq}
                  registDate={comment.registDate}
                  registUserSeq={comment.registUserSeq}
                  isDeleted={comment.isDeleted}
                  isLiked={comment.isLiked}
                  likeCount={comment.likeCount}
                  comments={comment.comments}
                />
                {comment.comments &&
                  comment.comments
                    .filter((reReply) => reReply.isDeleted === false)
                    .map((reReply) => {
                      return (
                        <ReplyComment
                          key={reReply.commentSeq}
                          commentSeq={reReply.commentSeq}
                          parentSeq={reReply.parentSeq}
                          profile={reReply.registUserProfileImageUrl}
                          name={reReply.registUserName}
                          chat={reReply.contents}
                          registDate={reReply.registDate}
                          registUserSeq={reReply.registUserSeq}
                          isDeleted={reReply.isDeleted}
                          isLiked={reReply.isLiked}
                          likeCount={reReply.likeCount}
                        />
                      )
                    })}
              </div>
            )
          })}
      </div>
      <div className={styles.commentWrite}>
        <form>
          <div>
            {commentData && userComment && (
              <div>
                {userName && <strong>{userName}</strong>}
                {modifyText && <span>댓글 수정중</span>}
                <button type="button" onClick={handleCloseComment}>
                  취소
                </button>
              </div>
            )}
          </div>
          <div>
            <div>
              <input
                className={chkItemContent ? styles.active : ''}
                value={chkItemContent}
                placeholder={'댓글을 작성해주세요.'}
                onChange={handleChangeChk}
                ref={commentRef}
              />
              <button
                onClick={(e) => {
                  handleComment(e, relationSeq, replySeq)
                }}
              >
                submit
              </button>
            </div>
          </div>
        </form>
      </div>

      {modalOpen ? (
        <ModalPortal>
          <ModalModify
            btnClose
            handleCloseModal={handleCloseModal}
            handleCommentModify={handleCommentModify}
            handleCommentDelete={deleteComment}
            modalCommentSeq={modalCommentSeq}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
