import styles from './Loading.module.scss'

export default function Loading() {
  return (
    <div className={styles.loadingBox}>
      <div className={styles.lineOne}></div>
      <div className={styles.lineTwo}></div>
      <div className={styles.lineThree}></div>
    </div>
  )
}
