import { useState, useCallback } from 'react'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import ModalGoods from 'components/modal/ModalGoods'
import useModalLogin from 'store/useModalLogin'

import 'assets/styles/modal.scss'

const entranceGoods =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_entrance_goods.png'

const ModalCourse = ({ btnClose, handleCloseModal, goodsDirectOrder, showErrorMessage }) => {
  const [modalGoods, setModalGoods] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  const handleOpenModalLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }, [])

  const handleOpenModalGoods = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalGoods(true)
  }, [])

  // const handleCloseModalGoods = useCallback((e) => {
  //   document.body.classList.remove('modal-open')
  //   setModalGoods(false)
  // }, [])

  return (
    <>
      <div className="modal-wrap course">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close white" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-content type-fixed-button">
            <div className="btn-flex-form" style={{ height: '80%' }}>
              <div className="course-txt">
                <strong>
                  YANADOO UNIVERSITY <br />
                  <span>‘입학회원’</span> 전용 상품입니다
                </strong>
                <p>
                  한달, 커피 단 두 잔 값으로 <br />
                  입학회원만의 프리미엄 혜택 평생 유지!
                </p>
                <p>
                  <span>오직 입학 회원만 누리는 혜택</span>
                </p>
                <img src={entranceGoods} alt="" />
              </div>
              <button
                className="btn active"
                onClick={!AuthService.getUserInfo() ? handleOpenModalLogin : handleOpenModalGoods}
              >
                <span>입학금 월 8,250원과 함께 결제</span>
              </button>
              <span className="month-price-txt">* 총 99,000원 / 12개월 (무) 할부 시</span>
            </div>
          </div>
        </div>
      </div>

      {modalGoods ? (
        <ModalPortal>
          <ModalGoods
            btnClose
            goodsDirectOrder={goodsDirectOrder}
            handleCloseModal={handleCloseModal}
            showErrorMessage={showErrorMessage}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}

export default ModalCourse
