import React from 'react'
import '../../assets/styles/notice.scss'

export default function DetailPolicyJoin() {
  return (
    <>
      <div className="refund-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <section className="list-detail-col">
              <div className="title-col">
                <strong>개인정보 수집 및 이용동의</strong>
              </div>
              <div className="detail-title-area">
                <div className="tit-flex-form">
                  <strong className="sub-tit black" style={{ marginTop: '0' }}>
                    개인정보 수집 및 이용동의
                  </strong>
                  <div className="info">
                    <span className="date" />
                  </div>
                </div>
              </div>
              <div className="policy-content">
                <p>
                  야나두는 회원가입, 서비스 제공, 장학금제도에 따른 개인 실명과 계좌정보, 기타상담
                  등을 위해 개인정보를 수집하고 있으며, 그리고 보다 다양한 서비스 제공을 위하여
                  아래와 같이 회원의 개인정보를 수집, 이용합니다.
                </p>
                <table>
                  <colgroup>
                    <col width="30%" />
                    <col width="30%" />
                    <col width="40%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>이용목적</th>
                      <th>수집항목</th>
                      <th>보유기간</th>
                    </tr>
                    <tr>
                      <td>
                        회원제 서비스 이용에 따른 본인 확인, 불량회원의 부정 이용 방지와 비인가 사용
                        방지, 불만처리 등 민원처리, 공지 및 상담
                      </td>
                      <td>
                        (필수)아이디, 비밀번호,이름,성별,생년월일,휴대전화번호,이메일,암호화된
                        이용자 확인값(CI), 중복가입확인정보(DI)
                        (선택)서비스관련설문(유입경로),전화상담 여부,추천인 아이디,
                        프로필정보(닉네임, 프로필 사진)
                      </td>
                      <td rowspan="2" class="line-bottom">
                        <em>
                          고객님의 개인정보는 서비스 제공 기간동안 보유 및 이용하며, 회원 탈퇴 시
                          지체없이 파기합니다. 단, 관계법령에 의해 보존할 경우 그 의무기간동안
                          개인정보를 안전하게 보관합니다.
                        </em>
                      </td>
                    </tr>
                    <tr>
                      <td>소비자보호를 위한 법적의무준수, 서비스통계 분석 등</td>
                      <td>IP Address, 쿠키, 방문일자, 서비스 이용기록</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  개인정보 수집 및 이용동의에 대하여 동의를 거부할 권리가 있으며, 거부할 경우 서비스
                  이용이 제한됩니다. <br />
                  단, 선택항목의 경우에는 거부 시 회원가입 및 서비스 이용에 문제가 없습니다.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
