import React, { useState, useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import styles from './ImgView.module.scss'
import ModalReviewDetail from '../components/modal/ModalReviewDetail'
import ModalPortal from '../ModalPortal'

export default function ImgView({ data, isLoading }) {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [modalopen, setModalopen] = useState(false)
  const [imageData, setImageData] = useState([])

  const [images, setImages] = useState(null)

  const handleOpenModal = useCallback(
    (images) => {
      document.body.classList.add('modal-open')
      setImages(images)
      setModalopen(!modalopen)
    },
    [modalopen]
  )

  const handleCloseModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  useEffect(() => {
    if (isLoading && data) {
      setImageData(data.attachments ? data.attachments : data.scholarshipRefundHistoryImageList)
    }
  }, [isLoading, data])

  return (
    <>
      {imageData && imageData.length > 0 && (
        <div
          className={`${styles.imgBox} ${
            styles[`item${imageData.length > 5 ? 5 : imageData.length}`]
          }`}
        >
          {imageData.map((item, idx) => {
            return (
              idx < 5 && (
                <div
                  key={idx}
                  style={{
                    backgroundImage: `url(${
                      item.attachmentPath
                        ? item.attachmentPath
                        : item.imageUrl
                        ? item.imageUrl
                        : "'" + item.url + "'"
                    })`
                  }}
                  onClick={() => {
                    handleOpenModal(imageData)
                  }}
                ></div>
              )
            )
          })}
        </div>
      )}
      {modalopen ? (
        <ModalPortal>
          <ModalReviewDetail
            deviceCheck={deviceCheck}
            handleCloseModal={handleCloseModal}
            images={images}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
