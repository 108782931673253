import React from 'react'

import ModalWrap from 'components/promotion/modal/ModalWrap'

import s from './ModalTerms.module.css'

export default function ModalPrivacy({ close }) {
  return (
    <ModalWrap btn onClose={() => close('privacy')}>
      <div className={s.privacy}>
        <hgroup>
          <h3>개인정보 수집 및 이용동의</h3>
        </hgroup>
        <div>
          <p>
            (주)야나두는 회원이 주문하신 상품 배송을 위하여 아래와 같이 회원의 개인정보를
            수집·이용합니다.
          </p>
          <dl>
            <dt>이용목적</dt>
            <dd>상품 배송</dd>
            <dt>수집항목</dt>
            <dd>이름, 휴대전화번호, 배송지</dd>
            <dt>
              보유기간 및<br />
              이용기간
            </dt>
            <dd>5년 ※ 전자상거래 등에서의 소비자보호에 관한 법률</dd>
          </dl>
          <p>
            개인정보 수집 및 이용동의에 대하여 동의를 거부할 권리가 있으며, 거부할 경우 상품 결제 및
            배송이 불가합니다.
          </p>
        </div>
      </div>
    </ModalWrap>
  )
}
