import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import usePlatform from 'hooks/usePlatform'

export default function ShareCopy({ pathname, className }) {
  const location = useLocation()
  const { isPlatform } = usePlatform(location.pathname)
  const [url, setUrl] = useState(
    pathname ? `https://www.yanadoo.co.kr${pathname}` : window.location.href
  )

  const copy = useCallback(async () => {
    if (!navigator?.clipboard) {
      console.warn('복사 기능이 지원되지 않는 브라우저입니다.')
      return false
    }

    try {
      await navigator.clipboard.writeText(url)
      alert('공유하실 URL 복사가 완료되었습니다.')
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      return false
    }
  }, [url])

  useEffect(() => {
    if (isPlatform) {
      setUrl('https://www.yanadoo.co.kr/AInativespeakertalk')
    }
  }, [isPlatform])

  return (
    <>
      <button className={className} onClick={copy}>
        링크 복사하기
      </button>
      <input style={{ display: 'none' }} value={url} readOnly />
    </>
  )
}
