import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import 'assets/styles/pagination.scss'

export default function Pagination({
  type,
  handleCurrentIdx,
  totalPages,
  limitPage,
  maxNum,
  minNum,
  pathName,
  currentSeq,
  pageIdx,
  jumpingNextBtn, // limitPage 숫자 기준으로 페이지 이동되는 버튼
  jumpingPrevBtn // limitPage 숫자 기준으로 페이지 이동되는 버튼
}) {
  const pageSeq = Number(currentSeq) // 현재 페이지의 파라미터
  const [currentPageIdx, setCurrentPageIdx] = useState(currentSeq === undefined ? 1 : pageSeq) // 현재 활성화된 페이지 번호
  const [maxPageNumber, setMaxPageNumber] = useState(maxNum) // 최대로 보여지는 숫자
  const [minPageNumber, setMinPageNumber] = useState(minNum) // 최소로 보여지는 숫자

  const pages = []
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i)
  }

  const renderPageNum = pages.map((number) => {
    if (number < maxPageNumber + 1 && number > minPageNumber) {
      return (
        <li key={number} className={(pageSeq || currentPageIdx) === number ? 'active' : null}>
          {type === 'SEQ' ? (
            <Link
              to={{
                pathname: `${pathName}/${number}`
              }}
              id={number}
            >
              {number}
            </Link>
          ) : 'IDX' ? (
            <button id={number} onClick={(e) => handlePageChange(e)}>
              {number}
            </button>
          ) : null}
        </li>
      )
    } else {
      return null
    }
  })

  function handlePageChange(e) {
    setCurrentPageIdx(Number(e.target.id))
  }

  const handleNextBtn = (num) => {
    if (type === 'IDX') {
      setCurrentPageIdx(currentPageIdx + num)
    }

    if ((pageSeq + num || currentPageIdx + num) > maxPageNumber) {
      setMaxPageNumber(maxPageNumber + limitPage)
      setMinPageNumber(minPageNumber + limitPage)
    }
  }

  const handlePrevBtn = (num) => {
    if (type === 'IDX') {
      setCurrentPageIdx(currentPageIdx - num)
    }

    if ((pageSeq - num || currentPageIdx - num) === minPageNumber) {
      setMaxPageNumber(maxPageNumber - limitPage)
      setMinPageNumber(minPageNumber - limitPage)
    }
  }

  useEffect(() => {
    // 현재 파라미터 값 = 최소로 보여지는 숫자
    if ((pageSeq || currentPageIdx) <= minPageNumber) {
      setMaxPageNumber(maxPageNumber - limitPage)
      setMinPageNumber(minPageNumber - limitPage)
    }
    // 현재 파라미터 값 > 최대로 보여지는 숫자
    if ((pageSeq || currentPageIdx) > maxPageNumber) {
      setMaxPageNumber(maxPageNumber + limitPage)
      setMinPageNumber(minPageNumber + limitPage)
    }
  }, [pageSeq, currentPageIdx, minPageNumber, maxPageNumber, limitPage])

  useEffect(() => {
    if (handleCurrentIdx) {
      handleCurrentIdx(currentPageIdx)
    }
  }, [currentPageIdx])

  useEffect(() => {
    if (pageIdx === 1) {
      setCurrentPageIdx(1)
      setMaxPageNumber(maxNum)
      setMinPageNumber(minNum)
    }
  }, [pageIdx])

  useEffect(() => {
    if (pageSeq === 1) {
      setCurrentPageIdx(1)
      setMaxPageNumber(maxNum)
      setMinPageNumber(minNum)
    }
  }, [pageSeq])

  return (
    <>
      <div className="list-more-box">
        <ul
          className={
            `pagination` +
            (jumpingNextBtn && jumpingPrevBtn && jumpingNextBtn && jumpingPrevBtn
              ? ` side-btn-full`
              : ``)
          }
        >
          {jumpingPrevBtn && pages.length > limitPage && (
            <li className="prev jump">
              {type === 'SEQ' ? (
                <Link
                  to={{
                    pathname: `${pathName}/${
                      pageSeq === pages[pages.length + limitPage]
                        ? pages[pages.length + limitPage]
                        : pageSeq - limitPage
                    }`
                  }}
                  className={pageSeq <= limitPage || pages.length <= limitPage ? 'disabled' : null}
                  onClick={() => {
                    handlePrevBtn(limitPage)
                  }}
                >
                  <i className="blind">prev</i>
                </Link>
              ) : 'IDX' ? (
                <button
                  onClick={() => {
                    handlePrevBtn(limitPage)
                  }}
                  className={currentPageIdx <= limitPage ? 'disabled' : null}
                >
                  <i className="blind">prev</i>
                </button>
              ) : null}
            </li>
          )}
          <li className="prev">
            {type === 'SEQ' ? (
              <Link
                to={{
                  pathname: `${pathName}/${pageSeq === 1 ? 1 : pageSeq - 1}`
                }}
                className={pageSeq === 1 ? 'disabled' : null}
                onClick={() => {
                  handlePrevBtn(1)
                }}
              >
                <i className="blind">prev</i>
              </Link>
            ) : 'IDX' ? (
              <button
                onClick={() => {
                  handlePrevBtn(1)
                }}
                className={currentPageIdx === 1 ? 'disabled' : null}
              >
                <i className="blind">prev</i>
              </button>
            ) : null}
          </li>

          {renderPageNum}

          <li className="next">
            {type === 'SEQ' ? (
              <Link
                to={{
                  pathname: `${pathName}/${
                    pageSeq === pages[pages.length - 1] ? pages[pages.length - 1] : pageSeq + 1
                  }`
                }}
                className={pageSeq === pages[pages.length - 1] ? 'disabled' : null}
                onClick={() => {
                  handleNextBtn(1)
                }}
              >
                <i className="blind">next</i>
              </Link>
            ) : 'IDX' ? (
              <button
                onClick={() => {
                  handleNextBtn(1)
                }}
                className={currentPageIdx === pages[pages.length - 1] ? 'disabled' : null}
              >
                <i className="blind">next</i>
              </button>
            ) : null}
          </li>

          {jumpingNextBtn && pages.length > limitPage && (
            <li className="next jump">
              {type === 'SEQ' ? (
                <Link
                  to={{
                    pathname: `${pathName}/${
                      pageSeq === pages[pages.length - limitPage]
                        ? pages[pages.length - limitPage]
                        : pageSeq + limitPage
                    }`
                  }}
                  className={
                    pageSeq >= pages[pages.length - limitPage] || pages.length < limitPage
                      ? 'disabled'
                      : null
                  }
                  onClick={() => {
                    handleNextBtn(limitPage)
                  }}
                >
                  <i className="blind">next</i>
                </Link>
              ) : 'IDX' ? (
                <button
                  onClick={() => {
                    handleNextBtn(limitPage)
                  }}
                  className={
                    currentPageIdx >= pages[pages.length - limitPage] || pages.length < limitPage
                      ? 'disabled'
                      : null
                  }
                >
                  <i className="blind">next</i>
                </button>
              ) : null}
            </li>
          )}
        </ul>
      </div>
    </>
  )
}
