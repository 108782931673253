import React, { useRef, useState } from 'react'

import cx from 'classnames'

import SendSvgIcon from './SendSvgIcon'
import s from './styles/Chat.module.scss'

export default function ChatForm({ addToFeed, postPromptMessage, isAiResponse }) {
  const inputRef = useRef(null)
  const [currentMessage, setCurrentMessage] = useState('')

  const handleOnChange = (e) => {
    setCurrentMessage(e.target.value) // 현재 메세지
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addToFeed({ type: 'USER', message: currentMessage }) // feed 추가
    postPromptMessage({ message: currentMessage }) // api에 message 전달
    setCurrentMessage('')
  }

  return (
    <form onSubmit={handleSubmit} className={s.chatForm}>
      <input
        className={s.chatInput}
        type="text"
        value={currentMessage}
        onChange={handleOnChange}
        maxLength={200}
        placeholder={isAiResponse ? 'AI가 답변을 작성하는 중입니다.' : '나의 답변 차례입니다.'}
        disabled={isAiResponse}
        ref={inputRef}
      />
      <button className={s.btnSubmit} type="submit" disabled={currentMessage.length === 0}>
        <SendSvgIcon
          pathClassName={cx({
            [s.btnActive]: currentMessage.length > 0,
            [s.btnDisable]: currentMessage.length === 0
          })}
        />
      </button>
    </form>
  )
}
