import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import HeaderHamburger from 'components/header/components/sideMenu/HeaderHamburger'
import HeaderLoginButton from 'components/header/components/sideMenu/HeaderLoginButton'

import styles from './index.module.scss'
import api from '../../common/api'
import NavCompany from '../../components/NavCompany'

export default function GnbPreview() {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  // const [isActive, setIsActive] = useState(false)
  // const [isOver, setIsOver] = useState(false)
  const isActive = false
  const isOver = false
  const [isHamburger, setHamburger] = useState(false)
  const [isEntry, setEntry] = useState(true) // 어떤 GNB를 보여줄지 체크하기 위한 상태값. (메인 GNB 컴포넌트 호출 : true, 상세 GNB 컴포넌트 호출 : false)
  const [gnbItems, setGnbItems] = useState([]) // '이벤트' 메뉴를 제외한 GNB 데이터 상태값.
  const [gnbEventItems, setGnbEventItems] = useState(null) // '이벤트' 메뉴의 GNB 데이터 상태값.
  const [currentIdx, setCurrentIdx] = useState(null) // 상세 GNB를 호출 할때, 몇번째 GNB를 호출할지 체크하는 상태값.
  const [depthCheck, setDepthCheck] = useState(null)

  const currentPath = useLocation().pathname
  const onClickGnbMenu = (idx) => {
    setCurrentIdx(idx)
  }

  const getGnbPreviewList = () => {
    api
      .get(`/v2/gnb-menu/preview-list`)
      .then((response) => {
        const gnbItem = response.data.data.filter((item) => item.menuName !== '이벤트')
        const eventItem = response.data.data.filter((item) => item.menuName !== '이벤트')
        setGnbItems(gnbItem)
        setGnbEventItems(eventItem)
        gnbActiveCheck(gnbItem)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 현재 URL을 체크하고, 세부메뉴 URL과 비교. 존재한다면, 해당 의 메뉴를 노출시키는 함수입니다.
  // 현재 URL과 세부메뉴 URL이 매칭되지 않는다면, 메인 GNB를 노출합니다.
  const gnbActiveCheck = (gnbItemsArr) => {
    let depthCheckBoolen = true
    gnbItemsArr.forEach((item, idx) => {
      item.childGnbMenus.forEach((subItem, subIdx) => {
        subItem.childGnbMenus.forEach((detailItem, detailIdx) => {
          if (window.location.href.indexOf(detailItem.link) !== -1) {
            setCurrentIdx(idx)
            setEntry(false)
            setDepthCheck({ depth1: idx, depth2: subIdx, depth3: detailIdx })
            depthCheckBoolen = false
            return false
          }
        })
      })
    })
    if (depthCheckBoolen) setDepthCheck({ depth1: 0, depth2: 0, depth3: 0 })
    // 야나두 메인 페이지라면, 메인 GNB를 노출합니다.
    if (window.location.href === '/') setEntry(true)
  }

  const handleScroll = () => {
    const ScrollChk = window.scrollY || document.documentElement.scrollTop
    const setScroll = document.querySelector('body').classList
    const footerOft = document.querySelector('footer').offsetTop
    const diff = footerOft - ScrollChk
    const $lnb = document.querySelector('#lnb .lnb-col')
    const $container = document.querySelector('.container')
    const agent = navigator.userAgent.toLowerCase()

    !ScrollChk <= 0 ? setScroll.add('fixed') : setScroll.remove('fixed')

    if (
      (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) ||
      agent.indexOf('msie') !== -1
    ) {
      // ie일 경우
      $container.classList.add('lnb-fixed')
      $lnb && diff <= 800 ? $lnb.classList.add('remove') : $lnb.classList.remove('remove')
    } else {
      // ie가 아닐 경우
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    getGnbPreviewList()
    setHamburger(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])

  return (
    <>
      <header
        className={
          styles.headerCol +
          (deviceCheck && isActive ? 'active-mobile ' : '') +
          (!deviceCheck && isOver ? 'active-pc' : '')
        }
      >
        {isEntry ? (
          <div className={styles.entryNav}>
            <div className={styles.innerCol}>
              <h1 className={styles.mainTitle}>
                <Link to="/">
                  <i className="blind">야나두</i>
                </Link>
              </h1>
              <ul className={styles.gnbList}>
                {gnbItems &&
                  gnbItems.map((item, idx) => (
                    <li key={idx} className={currentIdx === idx ? 'active ' : ''}>
                      <a
                        href={item.link}
                        onClick={() =>
                          onClickGnbMenu(
                            idx,
                            item.gaEventName !== undefined ? item.gaEventName : ''
                          )
                        }
                      >
                        <strong
                          className={
                            item.gnbMenuSeq === 49
                              ? styles.eng
                              : item.gnbMenuSeq === 105
                              ? styles.yu
                              : item.gnbMenuSeq === 71
                              ? styles.yafit
                              : ''
                          }
                        >
                          {item.menuName}
                        </strong>
                        <span>{item.description}</span>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ) : (
          <NavCompany
            setEntry={setEntry}
            currentIdx={currentIdx}
            gnbItems={gnbItems}
            onClickGnbMenu={onClickGnbMenu}
            depthCheck={depthCheck}
            currentPath={currentPath}
          />
        )}
        <div className={styles.sideMenu + (isEntry ? '' : ' ' + styles.active)}>
          <button className={styles.btnEvent}>이벤트</button>
          <ul>
            {gnbEventItems &&
              gnbEventItems[0].childGnbMenus.map((item, idx) => (
                <li key={idx} className={currentIdx === idx ? styles.active : ''}>
                  {item.link.includes('http') ? (
                    <a
                      href={item.link}
                      target={`_${item.linkTarget.toLowerCase()}`}
                      onClick={() => window.dataLayer.push}
                    >
                      {item.menuName}
                    </a>
                  ) : (
                    <Link to={item.link} target={`_${item.linkTarget.toLowerCase()}`}>
                      {item.menuName}
                    </Link>
                  )}
                </li>
              ))}
          </ul>
          <HeaderLoginButton />
          <HeaderHamburger
            isHamburger={isHamburger}
            setHamburger={setHamburger}
            gnbItems={gnbItems}
            depthCheck={depthCheck}
          />
        </div>
      </header>
      <section className={styles.gnbControlBox}>
        <p>
          GNB 미리보기 페이지 입니다.
          <br />
          아래에서 메뉴를 선택해 주세요.
        </p>
        <ul>
          <li>
            <button type="button" onClick={() => setEntry(true)}>
              GNB 메인
            </button>
          </li>
          {gnbItems &&
            gnbItems.map((item, idx) => (
              <li key={idx} className={currentIdx === idx ? 'active ' : ''}>
                <button
                  type="button"
                  onClick={() => {
                    onClickGnbMenu(idx)
                    setEntry(false)
                  }}
                >
                  {item.menuName}
                </button>
              </li>
            ))}
        </ul>
      </section>
    </>
  )
}
