import React from 'react'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
//style
import '../../assets/styles/modal.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination])

const ModalDetailCard = ({
  deviceCheck,
  classData,
  bgImage,
  imgWidth,
  imgHeight,
  handleCloseModal
}) => {
  return (
    <>
      {deviceCheck ? (
        <div className="mobile-modal">
          <div className="inner">
            {classData.packageImages && classData.packageImages.length > 1 ? (
              <Swiper
                className="swiper-container"
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                pagination={{ type: 'fraction' }}
              >
                {classData.packageImages.map((imgUrl, idx) => {
                  return (
                    <SwiperSlide key={idx} className="swiper-slide">
                      <span
                        className="slide-item"
                        style={{
                          backgroundImage: `url(${imgUrl})`
                        }}
                      ></span>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            ) : (
              <span
                className="slide-item"
                style={{
                  backgroundImage: `url(${bgImage})`
                }}
              ></span>
            )}
            <button className="btn-modal-close white" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          </div>
        </div>
      ) : (
        <div className="modal-wrap detail">
          <div className="dimmed" onClick={handleCloseModal}></div>
          <div className="modal-inner">
            <div
              className="modal-image-content"
              style={{
                backgroundImage: `url(${bgImage})`,
                width: `${imgWidth}px`,
                height: `${imgHeight}px`
              }}
            >
              <button className="btn-modal-close white" onClick={handleCloseModal}>
                <i className="blind">닫기</i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalDetailCard
