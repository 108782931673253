const BgBannerSrr =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_board_banner_srr.png'
const ImgBannerFree =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_board_banner_free_356x356.png'
const ImgBannerSrr =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_board_banner_srr_356x356.png'
const ImgBannerStudy =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_board_banner_study_356x356.png'

export const communityValues = {
  boardInfo: {
    FREE: {
      title: `수강생 Talk`, // * 게시판 제목 (상단 배너 노출이름)
      contents: `야나두의 학습 미션을 인증하고 \n도전 각오를 자유롭게 나눠보세요.`, // * 상단 배너 텍스트
      bgFullbox: `#F7F7FC`, // * 상단 배너 background style.
      bgInnerBox: `url(${ImgBannerFree}) no-repeat 100% 50% / 17.8rem auto`, // * 상단 배너 inner background style.
      infoText: `수강생 Talk 글 작성은 야나두 APP에서 가능합니다.`, // * 상단 info 문구.
      noContentText: `아직 작성된 게시물이 없습니다.`, // * 컨텐츠가 없을때, 표기 문구.
      apiContentsList: `/v2/community-tab/community/list/post`, // * 해당 게시글 목록 api 키값.
      apiContentsCount: `/v2/community-tab/community/list/post-count`, // * 해당 게시글 총 개수 api 키값.
      apiSearchSelectList: `/v2/community-tab/community/list/post/search-type` // 하단 셀렉트 박스 호출 api url.
    },
    STUDY: {
      title: `학습 Q&A`,
      contents: `학습 관련 궁금한 점은 무엇이든 물어보세요! \n야나두가 해결해드려요.`,
      bgFullbox: `#F7F7FC`,
      bgInnerBox: `url(${ImgBannerStudy}) no-repeat 100% 50% / 17.8rem auto`,
      infoText: `학습 Q&A 작성은 야나두 APP에서 가능합니다.`,
      noContentText: `아직 작성된 질문이 없습니다.`,
      apiContentsList: `/v2/community-tab/lecture-question/list`,
      apiContentsCount: `/v2/community-tab/lecture-question/count`,
      apiSearchSelectList: `/v2/community-tab/lecture-question/list/search-type`
    },
    SRR: {
      title: `스르르 학습지 Talk`,
      contents: `하루 한 장으로 영어가 스르르~\n스르르 학습지의 영어 소통 공간입니다.`,
      bgFullbox: `url(${BgBannerSrr}) repeat-y 50% 50% / 100% auto`,
      bgInnerBox: `url(${ImgBannerSrr}) no-repeat 100% 50% / 17.8rem auto`,
      infoText: `글 작성은 야나두 APP에서 가능 (학습지 구매자에 한함)`,
      noContentText: `아직 작성된 게시물이 없습니다.`,
      apiContentsList: `/v2/community-tab/community/list/post`,
      apiContentsCount: `/v2/community-tab/community/list/post-count`
    }
  }
}
