import React from 'react'
import Lottie from 'react-lottie-player'

import cx from 'classnames'
import ModalPortal from 'ModalPortal'

import s from './ModalAiTravel.module.scss'
import LottieData from '../FeedbackLoading.json'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function ModalFeedbackLoading() {
  return (
    <ModalPortal>
      <div className={cx(s.modalAiTravel, s.feedbackLoading)}>
        <div className={s.loadingContent}>
          <figure className={s.loadingImg}>
            <Lottie {...defaultOptions} animationData={LottieData} />
          </figure>
          <dl className={s.loadingTxt}>
            <dt>피드백 작성중...</dt>
            <dd>
              AI가 피드백 내용을 정리하고 있어요.
              <br />
              잠시만 기다려 주세요!
            </dd>
          </dl>
        </div>
      </div>
    </ModalPortal>
  )
}
