import React, { useEffect, useRef } from 'react'
import Lottie from 'react-lottie-player'

import cx from 'classnames'
import ModalPortal from 'ModalPortal'

import s from './ModalTransAudio.module.scss'
import LottieData from './TransCircle'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function ModalTransAudio({ character, handleModal, handleMode }) {
  const timerRef = useRef(null)
  const { info, images } = character
  const { fullname, firstname } = info
  const { circle } = images

  const handleBackMode = () => {
    handleMode('chat')
    handleModal(false)
  }

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleModal(false)
    }, 3000)

    return () => clearTimeout(timerRef.current)
  }, [])

  console.log(firstname.toLowerCase())

  return (
    <ModalPortal>
      <article className={s.modalAudioMode}>
        <div className={cx(s.modeContainer, s[`${firstname.toLowerCase()}`])}>
          <h3 className={s.modeTitle}>음성모드로 전환하고 있어요.</h3>
          <div className={s.aiProfile}>
            <figure className={s.profileImg}>
              <Lottie {...defaultOptions} animationData={LottieData} />
              <img src={circle} alt={fullname} />
            </figure>
            <h6 className={s.profileName}>{fullname}</h6>
          </div>
          <dl className={s.noti}>
            <dt>채팅모드로 대화중이었나요?</dt>
            <dd>
              음성모드를 이용해도 대화 내역이 사라지지 않고, <br />
              이어서 대화할 수 있어요.
            </dd>
          </dl>
          <button className={s.btnChangeMode} onClick={handleBackMode}>
            채팅모드로 돌아가기
          </button>
        </div>
      </article>
    </ModalPortal>
  )
}
