import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'

import styles from './Learning.module.scss'
import TabQna from './TabQna'
import Lnb from '../../../components/Lnb'
import NoContent from '../../../components/NoContent'
import Pagination from '../../../components/Pagination'

import '../../../assets/styles/main.scss'
const icoImg =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/imagesassets/images/ico_no_qna.png'

export default function Learning() {
  const navigate = useNavigate()
  const [pageIdx, setPageIdx] = useState(1)
  const [learningData, setLearningData] = useState([])
  const [totalPages, setTotalPages] = useState(null)
  const [dataKeyword, setDataKeyword] = useState({
    period: 3,
    reply: 'ALL'
  })

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  const getLearningData = (period, replyStatus) => {
    ;(replyStatus === 'ALL' && period === 'ALL'
      ? api.get(`/v2/lecture-question/user/list?index=${pageIdx}&size=10`)
      : period === 'ALL' && replyStatus !== 'ALL'
      ? api.get(
          `/v2/lecture-question/user/list?index=${pageIdx}&size=10&keys=replyStatus&types=equal&values=${replyStatus}`
        )
      : period !== 'ALL' && replyStatus === 'ALL'
      ? api.get(
          `/v2/lecture-question/user/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}`
        )
      : api.get(
          `/v2/lecture-question/user/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}&keys=replyStatus&types=equal&values=${replyStatus}`
        )
    )
      .then((response) => {
        setLearningData(response.data.data.content)
        setTotalPages(response.data.data.totalPages)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  const handlePeriodCheck = (e) => {
    const targetValue = e.target.value
    setPageIdx(1)
    setDataKeyword({ ...dataKeyword, period: targetValue })

    getLearningData(targetValue, dataKeyword.reply)
  }

  const handleReplyStatusCheck = (e) => {
    const targetValue = e.target.value
    setPageIdx(1)
    setDataKeyword({ ...dataKeyword, reply: targetValue })

    getLearningData(dataKeyword.period, targetValue)
    setPageIdx(1)
  }

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  useEffect(() => {
    getLearningData(dataKeyword.period, dataKeyword.reply)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIdx])

  return (
    <div className="qna-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader title="학습 문의" onClick={() => navigate('/mypage/1')} />
          <Lnb />
          <section className={styles.qnaListSection}>
            <TabQna activeTab={'LEARNING'} />
            <div className={styles.searchBox}>
              <ul className={styles.searchTxtList}>
                <li className={styles.searchTxtItem}>
                  답변이 등록된 후에는 문의를 수정 또는 삭제하실 수 없습니다.
                </li>
                <li className={styles.searchTxtItem}>
                  야나두APP 및 PC에서 문의 등록 및 수정 가능합니다.
                </li>
              </ul>
              <div className={styles.searchSelectBox}>
                <select
                  name="inquiryPeriod"
                  id="inquiryPeriod"
                  value={dataKeyword.period}
                  onChange={handlePeriodCheck}
                >
                  <option value="ALL">전체</option>
                  <option value="3">3개월</option>
                  <option value="6">6개월</option>
                  <option value="12">12개월</option>
                </select>
                <select
                  name="replyStatus"
                  id="replyStatus"
                  value={dataKeyword.reply}
                  onChange={handleReplyStatusCheck}
                >
                  <option value="ALL">전체</option>
                  <option value="Y">답변 완료</option>
                  <option value="N">답변 대기</option>
                </select>
              </div>
            </div>
            <Desktop>
              <table
                className={styles.tableBox}
                style={{ borderBottom: learningData.length > 0 ? '0.1rem solid #b7b7c8' : 'none' }}
              >
                <colgroup>
                  <col width="24%" />
                  <col width="" />
                  <col width="20%" />
                  <col width="12%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>분류</th>
                    <th>문의 내용</th>
                    <th>작성일</th>
                    <th>답변여부</th>
                  </tr>
                </thead>
                <tbody>
                  {learningData.length > 0 ? (
                    learningData.map((learningItem, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <p className="ellipsis">{learningItem.classTitle}</p>
                          </td>
                          <td>
                            <Link to={`/mypage/qna/learning/detail/${learningItem.questionSeq}`}>
                              <strong className="ellipsis">{learningItem.contents}</strong>
                            </Link>
                          </td>
                          <td>
                            <p>{formatDate(learningItem.registDate)}</p>
                          </td>
                          <td>
                            <p
                              className={
                                styles.check +
                                (learningItem.replyStatus === 'Y' ? ' ' + styles.completed : '')
                              }
                            >
                              {learningItem.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                            </p>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">
                        <NoContent icoImg={icoImg} titleMsg={'등록된 문의가 없습니다.'} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Desktop>
            <Mobile>
              <div className="table-form-mobile">
                {learningData.length > 0 ? (
                  <ul className="table-lists">
                    {learningData.map((learningItem, index) => {
                      return (
                        <li key={index}>
                          <Link to={`/mypage/qna/learning/detail/${learningItem.questionSeq}`}>
                            <span className="ellipsis">{learningItem.classTitle}</span>
                            <strong className="ellipsis line-clamp-2">
                              {learningItem.contents}
                            </strong>
                            <div className="info">
                              <span
                                className={
                                  'check' + (learningItem.replyStatus === 'Y' ? ' completed' : '')
                                }
                              >
                                {learningItem.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                              </span>
                              <span className="date">{formatDate(learningItem.registDate)}</span>
                            </div>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  <NoContent icoImg={icoImg} titleMsg={'등록된 문의가 없습니다.'} />
                )}
              </div>
            </Mobile>

            {learningData.length > 0 && (
              <Pagination
                type={'IDX'}
                handleCurrentIdx={handleCurrentIdx}
                totalPages={totalPages}
                limitPage={5}
                maxNum={5}
                minNum={0}
                pageIdx={pageIdx}
              />
            )}
          </section>
        </div>
      </div>
    </div>
  )
}
