import { useEffect, useState } from 'react'

import api from 'common/api'
import * as config from 'common/config'

export default function useGetEventList(currentSeq, type) {
  const [listData, setListData] = useState([])
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    const getEventList = () => {
      api
        .get(`/v2/event/list?index=${currentSeq}&size=10&eventStatus=${type}`)
        .then((response) => {
          setListData(response.data.data.content)
          setTotalPages(response.data.data.totalPages)
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
        })
    }

    if (currentSeq) {
      getEventList()
    }
  }, [currentSeq, type])

  return [listData, totalPages]
}
