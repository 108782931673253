import React from 'react'
import { Link } from 'react-router-dom'

import styles from './AttachmentLink.module.scss'

export default function AttachmentLink({ data }) {
  return (
    <>
      {data && (
        <Link className={styles.attachLink} to={data.url} target="_blank" rel="noreferrer">
          {data?.meta?.image && (
            <span
              style={{
                backgroundImage: `url(${data.meta.image})`
              }}
            ></span>
          )}
          <div>
            {data.meta ? (
              <>
                <strong>{data.meta.title}</strong>
                <p>{data.meta.description}</p>
              </>
            ) : (
              <>
                <strong>{data.url}</strong>
                <p>{data.url}</p>
              </>
            )}
          </div>
        </Link>
      )}
    </>
  )
}
