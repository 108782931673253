import React, { useState, useEffect, useCallback } from 'react'
import { isMobile, isIOS } from 'react-device-detect'
import { Link, useLocation } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import Banner from 'components/Banner'
import AlertModal from 'components/modal/AlertModal'
import ModalAppDownload from 'components/modal/ModalAppDownload'

import 'assets/styles/paymentComplete.scss'

let entrancePackageSeq = -1

const appImg =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_app_logo_240x240.png'
const normalBanner = [
  {
    imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/banner/combnr_pc_yafit_2301.png',
    imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/banner/combnr_mo_yafit_2301.png',
    imageInfo: '세상의 모든 사이클이 야핏으로 연결된다. 야핏특가 최대 50% 할인',
    link: 'https://yafit.yanadoo.co.kr/yanadoo/web/package/AppTicket.html',
    bgColor: '#ebebeb'
  }
]

export default function PaymentComplete() {
  // const navigate = useNavigate()
  const { state } = useLocation()
  const orderNo = state && state.orderNo
  const oProduct = state && state.oProduct

  // const [deviceType, setDeviceType] = useState('')
  const [modalopen, setModalopen] = useState(false)
  const [paymentData, setPaymentData] = useState([])
  const [paymentDataDetail, setPaymentDataDetail] = useState([])
  const [modalopenAlert, setModalopenAlert] = useState(false)

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleMoveEntrance = useCallback((e) => {
    window.location.href = '/'
    handleCloseModalAlert()
  }, [])

  const handleAppOpenModal = () => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = 'https://apps.apple.com/kr/app/id1539582420'
      } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=kr.co.yanadoo.app2'
      }
    } else {
      alert('모바일에서 이용해 주세요.')
    }
  }

  const getOrderDetail = () => {
    api
      .get(`/v2/order/detail?orderNo=${orderNo}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
        } else {
          const paymentData = response.data.data.orderDetailVOList[0]
          // const trackParameter = {
          //   name: paymentData?.orderName,
          //   price: paymentData?.totalPaymentPrice,
          //   quantity: paymentData?.cnt
          // }
          // trackKarrotEvent('Purchase', trackParameter)
          setPaymentData(paymentData)
          removePostMallStorage()
        }
      })
      .catch((e) => {
        console.log(e)
      })

    api
      .get(`/payment/v2/order/${orderNo}`)
      .then((response) => {
        setPaymentDataDetail(response.data.data.orderInfo.order_master_list)
        entrancePackageSeq = response.data.data.entrancePackageSeq || -1

        if (window['checkURL'] !== window.location.href) {
          window['checkURL'] = window.location.href
          window['dataChkPaymentComplete'](response.data.data)
          window['adpacker-purchase'](oProduct)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const entrancePackage = () => {
    for (let entranceChk of paymentDataDetail) {
      entranceChk.packageSeq === entrancePackageSeq && handleOpenModalAlert()
    }
  }

  // 몰인몰 로컬스토리지 삭제
  const removePostMallStorage = () => {
    window.localStorage.removeItem('mallCategory')
    window.localStorage.removeItem('mallDetail')
    window.localStorage.removeItem('packageSeq')
    window.localStorage.removeItem('mallCode')
  }

  useEffect(() => {
    getOrderDetail()
    return () => {
      // 페이지 벗어날때 모달 클래스 삭제
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    entrancePackage()
  }, [paymentDataDetail])

  return (
    <>
      <div className="order-col">
        <div className="inner-col">
          <section className="payment-complete-box">
            <div className="app-download-mobile">
              <dl>
                <dt>
                  <img src={appImg} alt="앱 다운로드" />
                </dt>
                <dd>
                  <p>
                    세상의 모든 클래스를 모아놨다!
                    <span>앱으로 학습 시작하기</span>
                  </p>
                  <button type="button" onClick={handleAppOpenModal}>
                    <span>다운로드</span>
                  </button>
                </dd>
              </dl>
            </div>
            <div className="deposit-box">
              <span className="ico-check"></span>
              <p>주문이 정상적으로 완료되었습니다.</p>
              {paymentData &&
                paymentData?.paymentTypeCode === 'REMITTANCE' &&
                paymentData?.paymentStatusCode === 'PAY_CONFIRM' && (
                  <>
                    <div className="info">
                      <dl>
                        <dt>입금기한</dt>
                        <dd>
                          <strong>{paymentData?.depositDate}</strong>
                        </dd>
                      </dl>
                      <dl>
                        <dt>입금계좌</dt>
                        <dd>{paymentData?.vactNum}</dd>
                      </dl>
                      <p>
                        <span>위 기한까지 입금이 완료되면 수강이 가능합니다.</span>
                      </p>
                    </div>
                  </>
                )}
            </div>
            <div className="order-box">
              <h3>주문완료</h3>
              <button type="button" className="app-download" onClick={handleOpenModal}>
                <span>
                  주문을 완료 하셨다면? <strong>야나두 앱</strong>을 다운로드 받아보세요!
                </span>
              </button>
              <div className="info">
                <dl>
                  <dt>주문 일자</dt>
                  <dd>{paymentData?.orderDate}</dd>
                </dl>
                <dl>
                  <dt>주문 번호</dt>
                  <dd>{paymentData?.orderNo}</dd>
                </dl>
              </div>
            </div>
            <div className="btn-flex-form">
              <Link to="/store/list">쇼핑 계속하기</Link>
              <Link
                to={{
                  pathname: `/mypage/order/detail/${orderNo}`
                }}
              >
                주문 상세 조회
              </Link>
            </div>
            {normalBanner.length > 0 ? (
              <Banner loop autoPlay slideView={1} type={'normal'} data={normalBanner} />
            ) : null}
          </section>
        </div>
      </div>
      {/* 모바일은 팝업 노출 x 처리 필요 */}
      {modalopen ? (
        <ModalPortal>
          <ModalAppDownload btnClose handleCloseModal={handleCloseModal} />
        </ModalPortal>
      ) : null}
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'입학회원이 되신 걸 축하 드립니다. 입학 상품 안내로 이동하시겠습니까?'}
            handleCloseModal={handleCloseModalAlert}
            handleConfirmClose={handleMoveEntrance}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
