import { Suspense } from 'react'
import { Outlet, useLocation } from 'react-router'

import useGetEventPopup from 'hooks/useGetEventPopup'
import useInitialApp from 'hooks/useInitialApp'

import ChannelTalk from 'components/channelTalk'
import Loading from 'components/common/Loading'
import Footer from 'components/Footer'
import Header from 'components/header'
import { headerValues } from 'components/header/config'
import PromotionLandingKeyVisual from 'components/promotion/keyVisualBanner/index.jsx'
import ModalPromotionEvent from 'components/promotion/modal/ModalPromotionEvent'

import routerValues from './config'
import styles from './router.module.scss'

export function Order() {
  return (
    <div>
      <h2 className="h2">Order</h2>
      <Outlet />
    </div>
  )
}

export function Mypage() {
  return (
    <div>
      <h2 className="h2">Mypage</h2>
      {/* <Lnb /> */}
      <Outlet />
    </div>
  )
}

export function Myclass() {
  return (
    <div>
      <h2 className="h2">Myclass</h2>
      {/* <Lnb /> */}
      <Outlet />
    </div>
  )
}

export function Service() {
  return (
    <div>
      <h2 className="h2" aria-hidden="true" style={{ display: 'none' }}>
        Service
      </h2>
      {/* <Lnb /> */}
      <Outlet />
    </div>
  )
}

export function Event() {
  return (
    <div>
      <h2 className="h2">Event</h2>
      <Outlet />
    </div>
  )
}

export function Member() {
  return (
    <div>
      <h2 className="h2">Member</h2>
      <Outlet />
    </div>
  )
}

export function Community() {
  return (
    <div>
      <h2 className="h2">Community</h2>
      <Outlet />
    </div>
  )
}

export function ClassStore() {
  const location = useLocation()
  const popupInfo = useGetEventPopup(location.pathname)

  return (
    <>
      <div>
        <h2 className="h2">Yanadoo Class</h2>
        <Outlet />
      </div>
      {location.pathname !== '/promotion-landing/newBenefit' && popupInfo && (
        <ModalPromotionEvent popupInfo={popupInfo} />
      )}
    </>
  )
}

export function PromotionLanding() {
  const location = useLocation()
  const popupInfo = useGetEventPopup(location.pathname)
  const isH2Hide = routerValues.h2HidePaths.some((path) => location.pathname.includes(path))

  return (
    <>
      <div>
        {!isH2Hide && <h2 className="h2">Yanadoo English</h2>}
        <Outlet />
      </div>
      {location.pathname !== '/promotion-landing/newBenefit' && popupInfo && (
        <ModalPromotionEvent popupInfo={popupInfo} />
      )}
    </>
  )
}

export function DefaultLayout() {
  const location = useLocation()
  const isHeaderHide = headerValues.headerHidePaths.some((path) => location.pathname.includes(path))

  // 프로모션 랜딩페이지 여부 확인
  const isPromotionLanding = location.pathname.includes('/promotion-landing/')

  useInitialApp()

  return (
    <>
      {!isHeaderHide && <Header />}
      <Suspense fallback={<Loading />}>
        <div className={styles.container}>
          {/* 프로모션 랜딩일 경우 공통 키비주얼 배너 */}
          {isPromotionLanding && <PromotionLandingKeyVisual />}
          <Outlet />
        </div>
      </Suspense>
      <Footer />
      <ChannelTalk />
    </>
  )
}
