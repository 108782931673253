import React, { useState, useCallback } from 'react'

import api from 'common/api'

import ModalProductSelection from '../../components/modal/ModalProductSelection'
import ModalPortal from '../../ModalPortal'

export default function ButtonReview({ productLists, packageSeq, thumbnailPath }) {
  const [modalopen, setModalopen] = useState(false)

  const handleOpenModal = useCallback(() => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  // 상품 후기 등록
  const postProductReview = (packageSeq, productSeq, textareaValue, satisfaction) => {
    const data = {
      contents: textareaValue,
      packageSeq: packageSeq,
      productSeq: productSeq,
      satisfaction: satisfaction
    }

    api
      .post('/v2/review/product-review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('상품 후기 등록이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <>
      <button
        className="button stroke"
        onClick={() => {
          handleOpenModal(productLists, packageSeq, thumbnailPath)
        }}
      >
        <span>후기작성</span>
      </button>

      {modalopen ? (
        <ModalPortal>
          <ModalProductSelection
            title={'상품 선택'}
            productLists={productLists}
            productPackageSeq={packageSeq}
            postProductReview={postProductReview}
            btnClose
            handleCloseModal={handleCloseModal}
            thumbnailPath={thumbnailPath}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
