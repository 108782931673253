import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

import * as config from 'common/config'

import SkeletonMallList from './SkeletonMallList'
import api from '../../common/api'
import ItemStore from '../../components/Store/List/ItemStore'

export default function MallList() {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const { mallSeq } = useParams()
  const [isLoading, setLoarding] = useState(true)
  const [isError, setError] = useState(false)
  const [isMallDetailData, setMallDetailData] = useState(null)
  const mallDataGet = async (code) => {
    try {
      await api
        .get(`/v2/mall/` + code + `/detail`)
        .then((response) => {
          const data = response.data.data
          setMallDetailData(data)
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
        })
      setError(false)
    } catch (e) {
      console.log(e)
      setError(true)
    } finally {
      setLoarding(false)
    }
  }

  useEffect(() => {
    mallDataGet(mallSeq.toLowerCase()) // mall code를 소문자로 전환하여, 함수 호출.
  }, [mallSeq])

  return (
    <>
      {isLoading && isLoading ? (
        <>
          <SkeletonMallList />
        </>
      ) : isError && isError ? (
        <>
          <div>
            <p>연결할 수 없는 페이지 입니다.</p>
          </div>
        </>
      ) : (
        <>
          {!!isMallDetailData ? (
            deviceCheck ? (
              <img src={isMallDetailData.bannerImageMO} alt="" />
            ) : (
              <section
                style={{
                  width: '100%',
                  height: '42rem',
                  backgroundImage: `url('` + isMallDetailData.bannerImagePC + `')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'auto 100%',
                  backgroundPosition: '50% 0'
                }}
              ></section>
            )
          ) : null}
          <section className="class-list-col">
            <div className="inner-col">
              {isMallDetailData.categoryList &&
                isMallDetailData.categoryList.map((item, idx) => {
                  return (
                    <ItemStore
                      key={idx}
                      title={item.title}
                      storeData={item.list}
                      storeType={`mall`}
                      mallCode={mallSeq}
                    />
                  )
                })}
            </div>
          </section>
        </>
      )}
    </>
  )
}
