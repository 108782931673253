import React, { useState } from 'react'

import api from 'common/api'
import * as config from 'common/config'
import { setCookie } from 'common/Cookie'

import Password from '../Password'
import '../../../src/assets/styles/modal.scss'
import '../../../src/assets/styles/modalJoin.scss'

export default function ModalNewPwd({ title, btnClose }) {
  const [pwdComplete, setPwdComplete] = useState(false)
  const [newPassword, setNewPassword] = useState(null)
  const [currentPassword, setCurrentPassword] = useState(null)
  const [pwdCheckResult, setPwdCheckResult] = useState(null)

  const handleNewPwdComplete = (status, newPwd, currentPwd) => {
    setPwdComplete(status)
    setNewPassword(newPwd)
    setCurrentPassword(currentPwd)
  }

  const handleNextChange = () => {
    setCookie('isNeedPasswordChange', 'N', 2)
    window.location.reload()
  }

  const postEditPassword = () => {
    const data = {
      currentPassword: currentPassword,
      newPassword: newPassword
    }

    api
      .put(config.AUTH_API_HOST + '/auth/v2/user/verify-and-edit-password', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          setPwdCheckResult(false)
        } else {
          setPwdCheckResult(true)
          alert('비밀번호 변경이 완료되었습니다.')
          window.location.reload()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <div className="modal-wrap modal-form password">
        <div className="dimmed" onClick={() => window.location.reload()}></div>
        <div className="modal-inner">
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close"
              onClick={() => window.location.reload()}
            >
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content password">
            <div className="title">
              <p>
                더 안전한 서비스를 위해
                <strong>비밀번호를 재설정해주세요.</strong>
              </p>
              <p>
                회원분들의 개인정보를 안전하게
                <br />
                지키기 위해 주기적으로 비밀번호 변경 안내를
                <br />
                드리고 있습니다.
              </p>
            </div>
            <form name="newPwdFrm" action="#" className="frm">
              <div className="join-form">
                <Password
                  currentPwd
                  handleNewPwdComplete={handleNewPwdComplete}
                  pwdCheckResult={pwdCheckResult}
                />
              </div>
            </form>
            <div className="btn-flex-form group">
              <button
                type="button"
                className={'btn' + (pwdComplete ? ' active' : ' disabled')}
                disabled={pwdComplete ? false : true}
                onClick={postEditPassword}
              >
                <span>변경하기</span>
              </button>
              <button type="button" onClick={handleNextChange}>
                <span>다음에 변경하기</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
