import React from 'react'
import Skeleton from 'react-loading-skeleton'

import styles from './ThumbNail.module.scss'

export default function ThumbNail({ backgroundColor, backgroundImage, size }) {
  return (
    <div className={styles.thumbBox}>
      {backgroundImage ? (
        <i
          style={{
            width: size,
            height: size,
            backgroundColor: backgroundColor,
            backgroundImage: `url(${backgroundImage})`
          }}
        ></i>
      ) : (
        <Skeleton circle={true} width={size} height={size} />
      )}
    </div>
  )
}
