import React, { useState, useCallback } from 'react'

import '../../assets/styles/modal.scss'
import api from 'common/api'

import ModalReviewWrite from '../../components/modal/ModalReviewWrite'
import ModalPortal from '../../ModalPortal'
import NoContent from '../NoContent'

const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_coupon.png'

const ModalProductSelection = ({
  title,
  btnClose,
  handleCloseModal,
  productLists,
  productPackageSeq,
  postProductReview,
  thumbnailPath
}) => {
  const [modalopen, setModalopen] = useState(false)
  // input value 값
  const [inputValue, setInputValue] = useState({
    productName: '',
    productSeq: '',
    packageSeq: productPackageSeq,
    thumbnailPath: thumbnailPath
  })

  // input 상태값 관리 함수
  const handleInputValue = (e) => {
    let { name, value } = e.target

    if (value && name) {
      setInputValue({
        ...inputValue,
        productSeq: value,
        productName: productLists.filter((item) => item.productSeq === Number(value))[0].productName
      })
      // console.log(inputValue);
    }
  }

  // 상품 후기 조회
  const getProductReview = (
    setTextareaValue,
    setTextareaLength,
    setProductLearningReviewSeq,
    handleGetPoint
  ) => {
    api
      .get(`/v2/review/product-review/${productPackageSeq}/${inputValue.productSeq}`)
      .then((response) => {
        // console.log('getProductReview', response);
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents)
          setTextareaLength(response.data.data.contents.length)
          setProductLearningReviewSeq(response.data.data.learningReviewSeq)
          handleGetPoint(response.data.data.satisfaction)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 상품 후기 수정
  const editProductReview = (learningReviewSeq, productTextareaValue, satisfaction) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: productTextareaValue,
      satisfaction: satisfaction
    }

    console.log('editProductReview', data)

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('상품 후기 수정이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content type-fixed-button white">
            <ul className="product-lists">
              {productLists.length > 0 ? (
                productLists.map((product, idx) => {
                  return (
                    <li key={idx}>
                      <label htmlFor={`productRadioButton${idx}`}>
                        <p className="ellipsis">{product.productName}</p>
                        <input
                          type="radio"
                          id={`productRadioButton${idx}`}
                          name="product"
                          onChange={handleInputValue}
                          value={product.productSeq}
                        />
                        <span className="chk"></span>
                      </label>
                    </li>
                  )
                })
              ) : (
                <NoContent icoImg={icoImg} titleMsg={'선택 가능한 상품이 없습니다.'} />
              )}
            </ul>
            <div className="btn-flex-form">
              <button
                className={'btn' + (productLists.length > 0 ? ' active' : ' disabled')}
                onClick={handleOpenModal}
              >
                <span>다음</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {modalopen ? (
        <ModalPortal>
          <ModalReviewWrite
            title={'상품 후기 작성'}
            btnClose
            postProductReview={postProductReview}
            getProductReview={getProductReview}
            editProductReview={editProductReview}
            inputValue={inputValue}
            handleCloseModalReviewWrite={handleCloseModal2}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}

export default ModalProductSelection
