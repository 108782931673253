import React from 'react'

import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './ModalReviewDetail.module.scss'

import 'assets/styles/modal.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination, Navigation])

export default function ModalReviewDetail({ handleCloseModal, images }) {
  return (
    <section className={styles.modalSection}>
      {images?.type ? (
        <>
          <span
            className={styles.slideItem}
            style={{
              backgroundImage: `url(${images.url})`
            }}
          ></span>
          <button className={styles.btnClose} onClick={handleCloseModal}>
            <span>닫기</span>
          </button>
        </>
      ) : images?.length > 1 ? (
        <>
          <Swiper
            className="swiper-container"
            spaceBetween={20}
            slidesPerView={1}
            navigation
            loop={true}
            pagination={{ type: 'fraction' }}
          >
            {images.map((item, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <span
                    className={styles.slideItem}
                    style={{
                      backgroundImage: `url(${
                        item.attachmentPath
                          ? item.attachmentPath
                          : item.imageUrl
                          ? item.imageUrl
                          : "'" + item.url + "'"
                      })`
                    }}
                  ></span>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <button className={styles.buttonClose} onClick={handleCloseModal}>
            <span>닫기</span>
          </button>
        </>
      ) : (
        <>
          <span
            className={styles.slideItem}
            style={{
              backgroundImage: `url(${
                images[0].attachmentPath
                  ? images[0].attachmentPath
                  : images[0].imageUrl
                  ? images[0].imageUrl
                  : "'" + images[0].url + "'"
              })`
            }}
          ></span>
          <button className={styles.buttonClose} onClick={handleCloseModal}>
            <span>닫기</span>
          </button>
        </>
      )}
    </section>
  )
}
