import { Link } from 'react-router-dom'

import DetailTitle from '../DetailTitle'
import ItemApply from '../ItemApply'
import TabMain from '../TabMain'
import useEventDetail from './hooks/useEventDetail'

export default function EventDetail() {
  const { eventStatus, banner, eventData, eventContent, currentSeq } = useEventDetail()

  return (
    <>
      <TabMain activeTab={eventStatus} />
      {eventData && (
        <>
          <DetailTitle eventData={eventData} banner={banner} />
          <ItemApply eventData={eventData} eventSeq={currentSeq} eventContent={eventContent} />
        </>
      )}
      <section className="list-btn-col">
        <div className="inner-col">
          {eventStatus === 'PROGRESS' ? (
            <Link to="/event/list/1" className="btn btn-go-list">
              목록보기
            </Link>
          ) : (
            <Link to="/event/end/1" className="btn btn-go-list">
              목록보기
            </Link>
          )}
        </div>
      </section>
    </>
  )
}
