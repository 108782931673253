import { Fragment } from 'react'

import { v4 as uuid } from 'uuid'

import s from '../aiTalk.module.css'

export default function StepItem({ data }) {
  const { badge, title, detail, sub, icon } = data
  return (
    <div className={s.stepItem}>
      <hgroup style={{ backgroundImage: `url(${icon})` }}>
        <i>{badge}</i>
        <h4>
          {title.split('\n').map((item, index) => {
            return (
              <Fragment key={uuid()}>
                {index !== 0 && <br />}
                {item}
              </Fragment>
            )
          })}
        </h4>
      </hgroup>
      <div className={s.bodyGroup}>
        <p>
          {detail.split('\n').map((item, index) => {
            return (
              <Fragment key={uuid()}>
                {index !== 0 && <br />}
                {item}
              </Fragment>
            )
          })}
        </p>
        <span>{sub}</span>
      </div>
    </div>
  )
}
