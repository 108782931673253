import { YANADOO_AI_HOST } from 'common/config'

const API_HOST = {
  chat: `${YANADOO_AI_HOST}/qna/chat`,
  enter: `${YANADOO_AI_HOST}/qna/id`,
  survey: `${YANADOO_AI_HOST}/qna/survey`
}

const IMG_HOST = 'https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/'

const DAILY_TIP = {
  1: [
    {
      number: 1,
      content: [
        '명사란 이름을 나타내는 품사이며, 영어는 명사를 셀 수 있는 것과 셀 수 없는 것으로 구분합니다.',
        '원어민은 수에 민감한 문화의 영향으로 셀 수 있는 명사에 반드시 수 표기를 합니다.',
        'pen이 아닌 a pen 또는 pens라고 발음하기 때문에 새로운 단어로 들릴 수 있으니 반드시 덩어리로 훈련해주세요.'
      ]
    },
    {
      number: 2,
      content: [
        '한국어(농경문화)와 영어(사냥문화)의 어순 차이는 문화차이에서 비롯합니다.',
        '사냥문화는 사냥감을 잡았는지 행위의 결과가 중요하기 때문에 [주어+동사]의 어순으로 결론을 먼저 이야기하고 뒤에 부가 설명을 합니다.'
      ]
    },
    {
      number: 3,
      content: [
        '영어의 동사는 크게 일반동사 / be동사로 나뉘며, 일반동사는 동작 또는 행위를 나타내는 동사입니다.',
        '한국어는 주어에 따른 동사 변화가 없지만, 영어는 주어의 개수에 따라 동사의 소리가 변합니다.',
        '주어가 한 명(개)인지 여러 명(개)인지 상상하면서 동사가 변하는 소리에 집중해서 연습해주세요.'
      ]
    },
    {
      number: 4,
      content: [
        '영어는 결론이 중요하기에 부정의 소리도 앞에 나옵니다.',
        '주어가 한 명(개)인지 여러 명(개)인지 개수에 따라 부정의 (don’t / doesn’t) 소리가 변합니다.',
        '어떠한 동작을 부정하는 것으로 ~하지 않는다 / 안 한다로 해석됩니다.'
      ]
    },
    {
      number: 5,
      content: [
        '영어는 의문을 가장 먼저 던집니다.',
        '주어가 한 명(개)인지 여러 명(개)인지 개수에 따라 Do / Does로 구분해 질문합니다.',
        '어떠한 동작에 대한 의문을 갖는 것으로 ~해?로 해석됩니다.'
      ]
    },
    {
      number: 6,
      content: [
        '원어민은 어떠한 현상을 바라볼 때 움직임이 있는지, 아니면 어떠한 상태인지로 나누어 생각합니다.',
        '움직임이 있는 행위는 일반동사, 상태는 be동사로 구분하여 말합니다.',
        'be동사는 ~한(인) 상태다의 개념으로 상태를 나타내는 단어와 함께 사용합니다.'
      ]
    },
    {
      number: 7,
      content: [
        '일반동사와 마찬가지로 주어의 개수에 따라 be가 am, are, is로 바뀝니다.',
        '주어가 한 명(개)인지 여러 명(개)인지 상상하면서 동사가 변하는 소리에 집중해서 연습해주세요.'
      ]
    },
    {
      number: 8,
      content: [
        '주어 개수에 따라 부정의 소리(am not, are not, is not)가 변합니다.',
        '어떠한 상태를 부정하는 것으로 ~가 아니다, ~한 상태가 아니다로 해석됩니다.'
      ]
    },
    {
      number: 9,
      content: [
        '일반동사 의문문과 달리, 상태를 나타낼 땐 be동사가 먼저 나오면서 의문문이 됩니다.',
        '어떠한 상태에 대한 의문을 갖는 것으로 ~한 상태야?로 해석됩니다.'
      ]
    },
    {
      number: 10,
      content: [
        '영어는 어순 차이뿐 아니라 행위와 상태의 문장 표기 방법이 다르고, 수에 민감하기 때문에 기본 문장이 익숙해질 때까지 훈련하는 것이 중요합니다. 문장이 쉽다고 그냥 넘어가면 안됩니다.',
        '헷갈리지 않고 바로 입에서 툭 나올 수 있도록 일반동사와 be동사를 섞어서 누적 복습하는 것을 추천합니다.'
      ]
    },
    {
      number: 11,
      content: [
        '영어에는 can, could, should, will, would, may, might, must와 같은 8가지의 필수 느낌동사 (조동사)가 있습니다. 느낌동사를 사용하면 내가 전하고자 하는 의도와 뉘앙스를 표현할 수 있습니다. (추측, 허락, 경고, 의무, 능력, 요청, 의지 등)',
        '느낌동사는 단독으로 쓸 수 없으며, 일반동사나 be동사 앞에 쓰입니다.',
        '주어의 개수와 상관없이 뒤에는 반드시 동사 원형이 옵니다.'
      ]
    },
    {
      number: 12,
      content: [
        '[can+일반동사]는 대체로 ~할 수 있다 (능력)이나 ~해도 된다 (허락)의 의미입니다.',
        '[can+be동사]는 대체로 ~일 수도 있다 (추측)의 뉘앙스로 해석됩니다.'
      ]
    },
    {
      number: 13,
      content: [
        'could는 50%의 가능성을 나타내는 추측 표현으로 ~일지도 모른다, ~일 수도 있다는 의미입니다.',
        '의문문일 때는 윗사람이나 손님에게 공손하게 말해야 할 경우 사용합니다.'
      ]
    },
    {
      number: 14,
      content: [
        'can은 능력적인 부분을 물어볼 때 사용하고, will은 계획을 묻는 표현입니다.',
        '주어가 3인칭(그, 그녀, 그들, 그것)일 때 will은 ~가 할 거야, ~가 할까?로 뉘앙스가 바뀝니다.',
        'Will의 부정문은 will + not이 되고, 축약형으로 표현하면 won’t가 됩니다.'
      ]
    },
    {
      number: 15,
      content: [
        'would는 주로 be동사와 함께 쓰이며, 80% 정도의 강한 추측을 나타냅니다.',
        'would는 ~나라면 하겠다라는 뉘앙스로, 어떤 계획이 없더라도 의향을 나타낼 때 사용합니다.',
        '느낌동사 중 would가 가장 공손한 표현입니다.'
      ]
    },
    {
      number: 16,
      content: [
        'should는 추측보다 의무의 뜻으로 더 많이 쓰입니다.',
        '꼭 해야 하는 의무감보다는 권유의 느낌입니다.',
        'should는 70% 정도의 확신을 보이는 뉘앙스입니다.'
      ]
    },
    {
      number: 17,
      content: [
        'may, might는 ~일지도 모른다는 의미로, 추측 느낌동사들 중 가장 희박한 가능성을 표현합니다.',
        'may 의문문은 잘 사용하지 않고, could와 비슷한 느낌의 공손함을 표현합니다.',
        'can과 마찬가지로 ~해도 된다는 허락, 양보의 의미도 쓰입니다.'
      ]
    },
    {
      number: 18,
      content: [
        'must는 ~할 필요가 있다는 뉘앙스로 꼭 해야 할 때만 사용되고, 강한 의무감을 보이는 표현입니다.',
        'must 의문문은 잘 사용하지 않기 때문에 have to를 활용해 Do you have to ~? / Does she have to ~?로 말해야 합니다.',
        'must는 99%의 매우 높은 확신을 보이며, ~일리 없다처럼 강한 부정의 의미로 표현하고 싶다면 can’t를 사용합니다.'
      ]
    },
    {
      number: 19,
      content: [
        '필수 느낌동사는 크게 추측, 의무, 허락/요청, 의지와 같이 네 개의 의미로 나눠볼 수 있습니다.'
      ]
    },
    {
      number: 20,
      content: [
        'had better는 아랫사람이나 동년배에게 ~하는 게 좋을 것이다라고 경고나 협박의 뉘앙스를 줄 때 사용합니다.',
        'would like to는 want to의 정중한 표현입니다.',
        '손님 또는 윗사람에게 서비스를 제공할 때도 쓰이고, 상대방이 내가 원하는 바를 들어줄지 확신할 수 없을 때도 사용합니다.'
      ]
    },
    {
      number: 21,
      content: [
        'would rather는 차라리 ~하는 게 낫다는 의미입니다.',
        '비교 대상이 있을 때는 than (~보다)과 함께 사용 가능합니다. (생략 가능)',
        "단, 문맥에 따라 다양한 의미로 번역할 수 있습니다. ex. I'd rather go. = 나 갈 거야/나 갈래/나 가는 게 낫겠어."
      ]
    },
    {
      number: 22,
      content: [
        'have to는 ~해야 한다는 뉘앙스로 사용합니다. (must보다는 약한 의무)',
        '부정문 don’t have to는 ~할 필요가 없다는 뜻입니다.',
        'have to는 주어가 3인칭 단수일 경우 has to로 표현합니다.'
      ]
    },
    {
      number: 23,
      content: [
        'will(미래)과 be able to(능력)를 함께 사용하면 ~할 수 있을 것이다는 의미가 됩니다.',
        'can, could는 일반적인 능력을 나타낸다면, be able to는 특정 상황에서 ~할 수 있다는 것을 표현할 수 있습니다.'
      ]
    },
    {
      number: 24,
      content: [
        'need to는 ~할 필요가 있다 그리고 ~해야 한다는 의미도 포함합니다.',
        '의무를 의미하는 다른 느낌동사들과 비교하면 need to는 비교적 부드러운 표현입니다.',
        '강제성이나 의무감보다는 가벼운 의무 정도의 뉘앙스를 표현할 때 사용합니다.'
      ]
    },
    {
      number: 25,
      content: [
        'be supposed to는 ~해야 한다, ~하기로 되어있다는 의미입니다.',
        '규칙, 다짐, 약속, 상식, 관습, 법과 같은 기준에 따른 의무나 책임을 나타내는 표현입니다.',
        '부정문 be not supposed to는 해서는 안 되는 일을 했을 때 사용합니다.'
      ]
    },
    {
      number: 26,
      content: [
        '느낌동사 + have pp를 활용해 과거에 대한 후회, 아쉬움 또는 추측을 표현할 수 있습니다.',
        '여기서 중요한 것은 have 단어 뒤에 pp 형태의 빠른 전환이므로 많은 훈련이 필요합니다.'
      ]
    },
    {
      number: 27,
      content: [
        'could have pp는 할 수도 있었지만 결국 못했다는 아쉬움의 뉘앙스를 의미합니다.',
        '부정문 could not have pp는 못 할 수도 있었지만 해서 다행이다의 뉘앙스를 의미합니다.',
        '여기서 could는 can의 과거형으로, ~할 수 있었는데, ~할 수 있었을 텐데로 해석합니다.'
      ]
    },
    {
      number: 28,
      content: [
        'would have pp는 어떤 사실을 알았다면 ~했었을 텐데, ~하려고 했는데, ~했었을 거다는 아쉬움의 뉘앙스 표현입니다.',
        'could have pp는 ~할 수도 있었지만 못했다의 의미로 능력을 강조한 뉘앙스라면, would have pp는 ~하려고 했지만 결국 안했다의 의미로 의지를 강조할 때 사용합니다.',
        '부정문 would not have pp는 어떤 사실을 알았다면 ~하지 않았을 텐데로 해석됩니다.'
      ]
    },
    {
      number: 29,
      content: [
        'should have pp는 의무적인 뉘앙스로 결국 안 한 것에 대한 아쉬움을 표현합니다.',
        '(과거에) ~했어야 했는데 안 했네로 해석되며, 이미 한 일에 대해 후회나 의무를 저버렸거나 아쉬움이 클 때 사용됩니다.'
      ]
    },
    {
      number: 30,
      content: [
        'must have pp는 다른 [느낌동사 + have pp]와는 달리 아쉬움보다는 강한 추측의 뉘앙스입니다.',
        '과거 시제의 느낌으로 ~했던 게 분명하다로 해석할 수 있습니다.',
        '반대로 ~했을 리가 없다라고 과거에 대한 단정적인 추측을 나타낼 때는 부정문 cannot have pp로 표현할 수 있습니다.'
      ]
    },
    {
      number: 31,
      content: [
        'used to는 과거에는 그랬지만 지금은 더 이상 ~하지 않는다라는 의미입니다.',
        '과거의 규칙적인 습관을 말하고 싶을 때 말할 수 있습니다.',
        '부정문은 did not use to로 표현할 수 있으며, 해석은 ~하지 않곤 했다, ~하지 않았었다가 자연스럽습니다.'
      ]
    },
    {
      number: 32,
      content: [
        '영어 동사는 크게 be동사와 일반동사로 나눌 수 있습니다.',
        '일반동사를 다시 나누어 보면 느낌동사 / 기본동사 / 지각동사 / 사역동사로 구분할 수 있습니다.',
        'get, have, take와 같이 원어민들이 일상생활에서 자주 쓰는 쉬운 단어들을 기본동사라고 합니다.'
      ]
    },
    {
      number: 33,
      content: [
        'get의 기본적인 의미는 사다, 얻다, 받다 입니다.',
        'get의 뜻에는 주어의 상태가 ~로 변하다라는 뜻도 있습니다.',
        '이외에도 ~하게 되다라고 해석할 수 있습니다.'
      ]
    },
    {
      number: 34,
      content: [
        'have의 기본적인 의미는 ~을 가지고 있다입니다.',
        'have의 현재형은 have와 has, 과거형과 과거분사형은 had입니다.',
        'have 뒤에 오는 목적어에 따라 해석이 다양해집니다.'
      ]
    },
    {
      number: 35,
      content: [
        'take의 가장 기본적인 뜻은 데려가다, 가져가다 (옮기다)입니다.',
        'take 단어 뒤에 10 minutes, 2 hours, a day와 같이 시간을 나타내는 표현이 오면 (~시간만큼) 걸리다는 의미로 해석합니다.'
      ]
    },
    {
      number: 36,
      content: [
        '지각동사는 보고(see, watch) 듣고(hear, listen to) 냄새 맡고(smell) 느끼는(feel) 감각을 나타내는 동사입니다.',
        'see는 우연히 보게 된 경우에 쓰이고, watch는 움직이는 것을 지켜보는 경우에 쓰입니다.',
        'hear는 우연히 들린 경우에 쓰이고, listen to는 들으려고 노력하는 경우에 쓰입니다.',
        '지각동사 뒤에는 [목적어 + 동사원형] 또는 [목적어 + v-ing] 형태가 옵니다.'
      ]
    },
    {
      number: 37,
      content: [
        '사역동사는 ~하도록 시키다로 해석하며, make, have, let이 대표적인 사역동사입니다.',
        'make는 강제성이 담긴 뉘앙스로 목적어(사람)에게 억지로 시키는 뉘앙스가 있습니다.',
        'have는 강제성 없이 설득, 권유 등으로 목적어(사람)가 하도록 유도하는 것입니다.',
        'let은 목적어(사람)가 무언가를 하도록 허락을 구하는 뉘앙스입니다.'
      ]
    },
    {
      number: 38,
      content: [
        '사역동사 get은 have와 비슷하게 목적어(사람)을 설득, 권유하거나 분위기를 조성하여 어떤 행동을 하도록 유도하는 것입니다.',
        '사역동사 have와는 다르게 사역동사로 쓰이는 get 뒤에는 목적어 + to V 형태가 와야 합니다. (주어 + get + 목적어 + to V)'
      ]
    },
    {
      number: 39,
      content: [
        '한국어와 다르게 영어에는 시제가 12개나 될 정도로 다양한 시제가 있습니다.',
        '꽤 많고 복잡하다고 느낄 수 있지만 사용 빈도가 높은 단순 시제부터 한 단계씩 익히는 게 효과적입니다.'
      ]
    },
    {
      number: 40,
      content: [
        '일반동사의 과거형은 현재형(기본형태) 단어 뒤에 ?d, -ed를 붙여서 말합니다.',
        '-d, -ed를 붙이지 않고 말하는 과거형은 불규칙 과거형이라고 하며, 추가로 익혀야 합니다.',
        'be동사의 과거형은 주어에 따라 was/were로 바뀝니다.'
      ]
    },
    {
      number: 41,
      content: [
        '현재시제라고 해서 현재만을 나타내지 않고, 과거/현재/미래를 모두 포함하는 시제입니다.',
        '늘, 항상이라는 개념이며, 우리가 늘 하는 습관 개념의 뉘앙스로 생각하면 쉽습니다.',
        '주어가 3인칭 단수(he/she/it)일 때, 동사 뒤에 붙는 -s, -es 발음에 신경 쓰면서 연습합니다.'
      ]
    },
    {
      number: 42,
      content: [
        '미래시제 will은 ~할 거야처럼 당장 하기로 마음먹은 내용을 강조할 때 사용합니다.',
        '전부터 결정한 계획을 강조하려면 ~할 계획이야의 be going to를 활용할 수 있습니다.'
      ]
    },
    {
      number: 43,
      content: [
        '과거진행은 (과거에) ~하고 있었다는 의미로, 과거 어느 시점에 진행되고 있던 행동을 표현합니다.',
        '주어에 따라 be동사의 형태가 was나 were로 바뀌므로 주어의 단수 복수에 따른 훈련이 필요합니다.',
        '과거진행형의 형태는 was/were(be 동사) + 동사원형 ing입니다.'
      ]
    },
    {
      number: 44,
      content: [
        '현재진행은 현재 시점에서 어떤 동작이 진행되고 있는 상황을 나타내며, ~하고 있다, ~하고 있는 중이다는 의미로 해석합니다.',
        '주어의 단수 복수에 따라 be동사의 형태가 am/are/is로 바뀝니다.',
        '현재진행형의 형태는 be동사(am/are/is) + 동사원형 ing 입니다.'
      ]
    },
    {
      number: 45,
      content: [
        '미래 시점에 어떤 동작이 진행되고 있는 경우, 미래진행 시제라 합니다.',
        '미래시제 will + 동사원형과 달리 미래진행 시제는 will + be(예외 없음) + 동사원형 ing 형태로 이루어집니다.'
      ]
    },
    {
      number: 46,
      content: [
        '과거완료 시제는 과거보다 더 먼저 일어난 사건을 표현하며, ~했어로 해석합니다.',
        '단순 과거시제를 사용했을 때보다 옛날에 어느 정도 장기간 계속되었던 일에 대해서 시간적 의미를 더 정확하게 표현할 수 있습니다.',
        '과거완료 시제의 형태는 had + pp로 표현할 수 있으며, 과거분사의 형태에 유의합시다.'
      ]
    },
    {
      number: 47,
      content: [
        '현재완료 시제의 형태는 has/have + pp로 표현합니다.',
        '경험: [has/have + pp] + before(전에) 와 함께 사용하면 ~해본 적 있어로 해석해요.',
        '계속: [has/have + pp] + for(동안) 과 함께 사용하면 ~해왔어로 해석해요.'
      ]
    },
    {
      number: 48,
      content: [
        '현재완료 has/have + pp는 경험/계속뿐만 아니라 완료/결과를 표현할 수 있습니다.',
        '과거 어떠한 일의 결과가 현재까지 영향을 미칠 때 사용합니다',
        '현재완료 시제를 완료/결과로 표현할 경우, ~했어로 해석합니다.'
      ]
    },
    {
      number: 49,
      content: [
        '미래완료 시제는 [will have + pp] 형태로 표현합니다.',
        '미래의 어느 한 시점을 기준으로 이전의 행동이나 상태가 미래 시점에 영향을 미쳤을 때 사용하며, 이때 해석은 ~해놓을 거야로 해석합니다.',
        '미래완료 시제를 [will have + pp] + (숫자) times처럼 표현하는 경우 ~번째일 것이다로 해석합니다.'
      ]
    },
    {
      number: 50,
      content: [
        '현재완료진행은 ~하고 있다의 의미를 강조하고 싶을 때 사용합니다.',
        '현재완료 시제인 have/has pp에서 have/has been + ~ing 형태로 바꾸면 현재완료진행 시제가 됩니다.',
        '이때 해석은 계속 ~해오고 있어의 의미가 됩니다.'
      ]
    },
    {
      number: 51,
      content: [
        '동사 뒤에 ing를 붙이거나 앞에 to를 붙여서 동사의 형태를 변형시킬 수 있습니다.',
        '동사를 변형시키는 이유는 한국말의 ~은, ~을, ~위해, ~할 등의 다양한 의미를 표현할 수 있기 때문입니다.',
        '또한 동사 변형을 통해 뒤에 수식하는 말을 붙여 길게 늘여 표현할 수 있습니다.'
      ]
    },
    {
      number: 52,
      content: [
        '동사+ing는 문장에서 주어 역할을 할 수 있습니다.',
        '동사+ing가 주어 역할을 할 경우 위치는 동사 앞에 오고, 해석은 은, 는, 이, 가 중 하나로 할 수 있습니다.',
        '동명사 주어 동사+ing는 단수로 여기기 때문에 뒤에 동사로 be 동사가 올 경우에는 is가 쓰입니다.'
      ]
    },
    {
      number: 53,
      content: [
        'to+동사는 주어 역할을 할 수 있으며, 이 경우 은, 는, 이, 가 중 하나로 해석 가능합니다.',
        '동사+ing가 주어로 오는 경우와 다르게 to+동사를 주어로 사용할 경우 문장 앞, 뒤 모두 위치할 수 있습니다.',
        'to+동사가 주어로 쓰여 문장 뒤에 오는 경우, 문장 주어 자리에는 It을 써줍니다.'
      ]
    },
    {
      number: 54,
      content: [
        '동사+ing는 문장 바로 뒤에서 목적어 역할을 할 수 있습니다. 이때, 동사+ing의 해석은 을, 를로 할 수 있습니다.',
        '어떤 동사들은 동사 뒤에 오는 목적어로 반드시 동사+ing 형태를 써야 합니다. (ex. keep, mind, suggest, enjoy, miss, finish, avoid, resist, practice, quit, imagine, consider, discuss)',
        '일부 동사들은 목적어로 동사+ing 형태와 to+동사 형태를 모두 쓸 수 있습니다. (ex. like, love, start)'
      ]
    },
    {
      number: 55,
      content: [
        'to+동사는 문장의 동사 바로 뒤에서 목적어 역할을 할 수 있습니다.',
        'to+동사가 목적어 역할을 하는 경우, 을, 를로 해석합니다.',
        '어떤 동사들은 동사 뒤에 오는 목적어로 반드시 to+동사 형태를 써야 합니다. 이 동사들의 공통점은 주로 미래에 할 일을 이야기할 때 쓴다는 것입니다. (ex. want, plan, refuse, hope, expect, swear, promise, decide, fail, agree, wish, learn, need, choose, pretend)'
      ]
    },
    {
      number: 56,
      content: [
        'to+동사는 명사 바로 뒤에 위치해서 명사를 꾸며줄 수 있습니다. 이때 to+동사는 ~할, ~ㄹ로 해석합니다.',
        'to+동사가 명사를 꾸며주는 역할을 할 때, 뒤에 전치사를 함께 사용해야 하는 경우가 있습니다.'
      ]
    },
    {
      number: 57,
      content: [
        'to+동사는 동사를 꾸며주는 부사 역할을 할 수도 있습니다.',
        '이때 to+동사는 문장 안에서 목적의 의미를 나타내며, ~하려고, ~하기 위해서라고 해석합니다.',
        '문장 안에서 부사 역할로 쓰이는 to+동사의 부정은 앞에 not만 붙여 not to+동사로 하며, 해석은 ~하지 않으려고로 하면 자연스럽습니다.'
      ]
    },
    {
      number: 58,
      content: [
        '동사+ing는 문장의 주어, 목적어 역할을 할 수 있습니다.',
        'to+동사 또한 문장의 주어, 목적어로 쓰일 수 있으며, 명사의 의미를 꾸며주는 형용사, 동사의 의미를 더해주는 부사의 역할을 하기도 합니다.',
        '어떤 동사가 쓰였는지에 따라 뒤에 오는 목적어의 형태도 달라집니다. ex. I want to talk. (O) / I want talking. (X) I enjoy talking. (O) / I enjoy to talk. (X)'
      ]
    },
    {
      number: 59,
      content: [
        '어떤 단어 뒤에 위치해서 그 의미를 꾸며주는 것을 후치수식이라고 합니다.',
        '영어에서 명사를 긴 덩어리 뭉치로 꾸며주려면 그 명사 뒤에서 꾸며줘야 합니다.',
        '후치수식을 이용하면 접속사 and를 쓰지 않고도 문장을 길게 늘여 말할 수 있게 됩니다.'
      ]
    },
    {
      number: 60,
      content: [
        '한국에 있는 남자처럼 사람을 꾸며줄 때는 who를 사용해서 의미를 더 해주며, 사람 바로 뒤에 who를 써서 표현합니다.',
        '사람+who 뒤에 be동사를 쓰면 사람의 상태, 직업, 장소, 동작의 진행에 대해서 설명할 수 있습니다.',
        '설명하는 사람이 한 명일 경우, who 뒤 be동사는 is를 쓰고, 여럿이면 are을 씁니다.'
      ]
    },
    {
      number: 61,
      content: [
        '사람을 꾸며주거나 설명할 때는 사람 단어 뒤에 who를 써서 표현할 수 있습니다.',
        '어떤 사람이 습관처럼 늘 하는 행동이나 동작하고 있다고 설명하고 싶을 때는 사람+who 뒤에 현재 시제 형태의 일반동사로 말할 수 있습니다.'
      ]
    },
    {
      number: 62,
      content: [
        '사물을 설명하고 싶을 때는 사물 단어 뒤에 which를 써서 표현할 수 있습니다.',
        '어떤 사물 단어의 상태, 명칭, 장소, 진행 상황을 표현하고 싶을 때는 사물+which 뒤에 be동사를 써서 말할 수 있습니다.'
      ]
    },
    {
      number: 63,
      content: [
        '사물+which 뒤에 일반동사를 써서 사물 단어를 꾸며 말할 수 있습니다.',
        '일반동사의 현재시제를 사용하면 언제나, 늘, 항상의 뉘앙스입니다.'
      ]
    },
    {
      number: 64,
      content: [
        '사람을 꾸밀 때는 그 단어 뒤에 who, 사물을 꾸밀 때는 사물 단어 뒤에 which를 사용합니다.',
        '어떠한 상태, 직업, 장소, 동작의 진행을 나타내고 싶을 때는 be동사를 쓰고, 습관처럼 늘 하는 행동을 말하고 싶을 때는 일반동사를 사용합니다.'
      ]
    },
    {
      number: 65,
      content: [
        '사람이 어떤 행동을 하고 있다고 말하고 싶을 때는 사람 단어 뒤에 who+be동사+ing를 써서 표현할 수 있습니다.',
        '이때 who+be동사+ing 단어 중에서 who+be동사를 생략하고 사람+ing로 간단하게 말할 수도 있습니다.',
        '사람+ing 패턴을 사용하면 그 사람이 하고 있는 동작을 묘사할 수 있습니다. (ex. a man who is reading a book → a man reading a book / 책을 읽고 있는 남자)'
      ]
    },
    {
      number: 66,
      content: [
        '사람+ing 표현과 마찬가지로, 사람+who+be동사+pp 형태에서 who+be동사를 생략하고 사람+pp로 간단하게 사람을 꾸며 말할 수 있습니다. (ex. a man who is loved by people → a man loved by people)',
        'ing가 능동의 뉘앙스라면, pp는 수동적인 뉘앙스입니다.'
      ]
    },
    {
      number: 67,
      content: [
        '사람+pp와 마찬가지로 사물+pp를 사물 단어 뒤에 쓰면 그 사물을 꾸며줄 수 있습니다.',
        '사물+pp는 사물이 어떤 상태에 처한 수동적인 상태의 뉘앙스를 나타냅니다. ex. the car which is fixed today → the car fixed today (오늘 수리된 자동차)'
      ]
    },
    {
      number: 68,
      content: [
        '사람과 사물 모두 who+be동사 또는 which+be동사를 생략하여 간단히 후치수식 할 수 있습니다.',
        '사람 → 능동일 때 -ing, 수동일 때 pp를 사용',
        '사물 → 사물이 어떤 행동을 할 수 있는 상황은 극히 드물기에, 주로 수동으로 표현함으로 pp 사용'
      ]
    },
    {
      number: 69,
      content: [
        'where+주어+동사 패턴을 활용하면 어떤 장소를 설명할 수 있습니다.',
        '장소를 뜻하는 단어와 where 중 하나를 생략해서 간단히 표현할 수 있습니다. ex. the place where I study = the place I study = where I study'
      ]
    },
    {
      number: 70,
      content: [
        '시간을 설명할 때는 when을 활용하여 when+주어+동사 패턴을 써서 말할 수 있습니다.',
        '이때, 시간을 뜻하는 단어와 when 중 하나를 생략할 수 있습니다. ex. the day when I work = the day I work = when I work'
      ]
    },
    {
      number: 71,
      content: [
        '내가 피자를 좋아하는 이유는과 같이 이유를 설명하고 싶을 때는 why+주어+동사 패턴으로 말할 수 있습니다.',
        'where, when에서와 동일하게 the reason과 why 중 하나를 생략해서 말할 수 있습니다. ex. the reason why I teach = the reason I teach = why I teach'
      ]
    },
    {
      number: 72,
      content: [
        'how+주어+동사 패턴을 쓰면 그가 요리하는 방식은~과 같은 표현을 할 수 있습니다.',
        '(주의) where, when, why와는 달리 how는 수식을 받는 명사 the way와 동시에 사용할 수 없습니다. ex. The way I teach (O) = How I teach (O), The way how I teach (X)'
      ]
    },
    {
      number: 73,
      content: [
        'that은 사람, 사물, 장소, 시간, 이유, 방법과 같은 단어를 모두 꾸며서 말할 수 있는 단어입니다.',
        '명사+that+주어+동사 패턴으로 다양한 명사를 꾸며 길게 표현할 수 있습니다.'
      ]
    },
    {
      number: 74,
      content: [
        '문장의 부수적인 요소인 시간/장소/이유/방법을 꾸밀 때는 완전한 문장으로 꾸미게 됩니다.',
        '시간/장소/이유 → the day/the place/the reason 또는 when/where/why 중 하나 생략 가능합니다.',
        '방법 → the way 또는 how 중 반드시 하나만 사용합니다.'
      ]
    },
    {
      number: 75,
      content: [
        '다양하고 구체적인 질문을 하려면 의문문 앞에 의문사를 붙이면 됩니다.',
        'who(누가), what(무엇을), where(어디서), when(언제), why(왜), how(어떻게)라는 여섯 개의 의문사로 시작하는 의문문을 의문사 의문문이라고 합니다.',
        '간접 의문문은 문장 안에 의문사+주어+동사 어순을 활용해서 질문을 부드럽게 돌려서 할 수 있습니다.'
      ]
    },
    {
      number: 76,
      content: [
        '의문사 의문문은 who, when, where, why, what, how라는 의문사로 시작하는 의문문입니다.',
        'be동사와 일반동사, 느낌동사까지 활용하여 다양하게 말할 수 있습니다.'
      ]
    },
    {
      number: 77,
      content: [
        '간접 의문문을 활용하면 의문사 의문문보다 더 부드럽게 돌려서 질문할 수 있습니다. (ex. 그녀가 누구야? → 그녀가 누구인지 알아?)',
        '간접 의문문에서는 의문문 패턴 뒤에 [의문사 + 주어 + 동사]의 순서로 씁니다.'
      ]
    },
    {
      number: 78,
      content: [
        '간접 의문문을 통해 상대방의 생각을 물어볼 수도 있습니다. (ex. 그녀가 누구야? → 그녀가 누구라고 생각해?)',
        '앞서 배운 Do you know+의문사와 달리 의문사+Do you think에서는 의문사가 패턴 앞에 옵니다.'
      ]
    },
    {
      number: 79,
      content: [
        "'의문사 의문문은 [동사+주어]의 어순으로 쓰지만, 간접 의문문에서는 [주어+동사] 어순으로 바뀝니다.",
        '의문사 의문문: Who is she? (그녀는 누구야?)',
        '간접 의문문: Do you know who she is? (그녀가 누군지 알아?)',
        '간접 의문문 패턴 1, 2의 경우, 둘의 공통점은 [주어+동사] 어순이고, 둘의 차이점은 의문사의 위치입니다.',
        '간접 의문문 패턴 1: Do you know who he is? (그가 누군지 알아?)',
        '간접 의문문 패턴 2: Who do you think he is? (그가 누구라고 생각해?)'
      ]
    },
    {
      number: 80,
      content: [
        "'그녀는 너보다 더 어려'와 같이 비교급으로 표현하고 싶다면 형용사에 -er 또는 more를 붙여 표현할 수 있습니다.",
        '모음을 기준으로 2음절 이하일 경우 형용사 + -er 형태로, 3음절 이상일 경우 more + 형용사 형태로 비교급을 만듭니다.'
      ]
    },
    {
      number: 81,
      content: [
        "'이 집은 내 집만큼 깨끗해'와 같이 비교 대상의 동등함을 나타내고 싶을 때, as + 형용사/부사 + as로 표현합니다.",
        'as와 as 사이에 오는 형용사, 부사는 비교급이 아닌 기본형으로 써야하는 점을 꼭 기억합시다. ex. as strong as (O) / as stronger as (X)'
      ]
    },
    {
      number: 82,
      content: [
        "'가장 키가 큰'과 같이 최상급으로 표현하고 싶을 때에는 형용사에 the+est 또는 the most를 붙여서 말할 수 있습니다.",
        '모음 기준 2음절 이하일 경우에는 the 형용사 + est 형태로 최상급을 만듭니다.',
        '모음 기준 3음절 이상일 경우에는 the most 형용사 형태로 최상급을 만듭니다.'
      ]
    },
    {
      number: 83,
      content: [
        '형용사에 -er 또는 more를 붙이면 비교급 형태로 표현할 수 있습니다.',
        '형용사에 the+-est 또는 the most를 붙여 최상급을 만들어 말할 수 있습니다.',
        '(예외) 일부 단어들은 비교급, 최상급을 만들 때 형태가 불규칙하게 변합니다.',
        'as ~ as 패턴을 이용하여 비슷한 수준의 대상끼리 비교해서 표현할 수 있습니다.'
      ]
    },
    {
      number: 84,
      content: [
        "원어민들은 상황이나 감정을 표현할 때 It's ~ 로 말을 시작하는 경우가 많습니다.",
        '이때 It은 특별한 뜻이 없는 형식상 주어로 생각하면 됩니다.',
        'It+is+형용사 뒤에 의미가 담긴 to+동사 또는 that+주어+동사를 써서 상황이나 감정을 보충 설명할 수 있습니다.'
      ]
    },
    {
      number: 85,
      content: [
        "'그건 수리 됐어'와 같이 수동태 문장으로 말하고 싶을 때는 It is+pp 패턴으로 표현할 수 있습니다.",
        '이때 주어는 It 외에도 다른 단어로 다양하게 활용할 수 있습니다.',
        '동사의 pp 형태(과거분사)란 어떤 행동을 당할 때나 감정을 느끼는 수동적인 상태를 의미합니다.'
      ]
    },
    {
      number: 86,
      content: [
        "주어+be동사+ing/pp 문장에서 ing는 주어가 감정을 유발하는 의미를 갖고, pp가 쓰인 경우는 외부 상황에 의해 주어가 감정을 느끼는 의미를 갖습니다. ex. 'He is boring.'은 사람 자체가 고지식하고 지루한 사람이라는 의미",
        "'He is bored.'는 어떤 상황 때문에 지루함을 느낀다는 의미"
      ]
    },
    {
      number: 87,
      content: [
        "It's 뒤에 to+동사 또는 that+주어+동사를 써서 상황이나 감정을 보충 설명할 수 있습니다.",
        "It's 뒤에 ing 또는 pp 형태를 붙여 다양한 상황이나 기분을 나타낼 수 있습니다."
      ]
    },
    {
      number: 88,
      content: [
        "'내가 슈퍼맨이라면 날아갈 텐데...'와 같이 현재 상황에 반대되는 가정이나 상상을 할 때 가정법으로 표현합니다.",
        '가정법은 If+주어+과거 동사, 주어+could/would+동사원형 패턴으로 만듭니다.',
        '*이 경우 동사의 현재형이 아닌 과거형을 써서 말합니다.',
        '● If절에서 동사로 be동사가 쓰일 때는, 주어에 관계없이 was가 아닌 반드시 were를 씁니다.',
        'If+주어+현재 동사는 실현 가능한 상황에서, If+주어+과거 동사는 실현 가능성이 희박한 상황에서 씁니다.'
      ]
    },
    {
      number: 89,
      content: [
        "'그건 정말 비싸구나!'와 같이 감탄을 표현하고 싶을 때에는 How+형용사+(주어+동사)! 패턴을 사용합니다.",
        'What+a/an+(형용사)+명사+(주어+동사)! 패턴으로도 ‘정말 ~한 ~구나!’라는 의미의 감탄문을 만들 수 있습니다.',
        'How 감탄문에서는 형용사가 필수적으로 쓰이고, What 감탄문에서는 명사가 필수로 쓰입니다.'
      ]
    },
    {
      number: 90,
      content: [
        '전치사를 활용하면 시간, 장소, 방향, 수단, 출처, 대상, 기간 등에 대한 부가 정보를 전달할 수 있습니다.',
        '전치사 뒤에는 명사가 나오며, 이 둘은 항상 덩어리로 함께 쓰입니다.',
        '전치사마다 대표적인 이미지를 갖고 있으므로 각 전치사의 이미지를 파악하는 것이 중요합니다.'
      ]
    },
    {
      number: 91,
      content: [
        '전치사 at은 점의 개념으로 어떤 지점을 콕 찍어 시간과 장소 등을 표현하는 뉘앙스입니다.',
        '시간(시점) : 시각이나 나이 등 시간의 흐름 속에서 특정 시점을 나타내는 느낌입니다.',
        '장소(지점) : 주소가 있거나 (지도를 본다고 생각했을 때) 손으로 가리킬 수 있는 지점을 나타냅니다.'
      ]
    },
    {
      number: 92,
      content: [
        '전치사 on은 접촉의 개념으로 무언가의 위, 옆, 경사면에 딱 붙어 있는 뉘앙스 입니다.',
        '시간 : 날짜, 요일, 생일, 기념일에 사용합니다. 달력 위에 딱 붙어 있는 날짜를 생각하면 쉽습니다.',
        '장소 : 어떤 위치나 장소에 붙어 있는 경우에 사용합니다. 반드시 위에 닿아 있을 필요는 없고, 옆면/빗면 모두 가능합니다',
        '도구, 수단 : 도구에 의존하는 것을 붙어있는 개념으로 표현합니다.'
      ]
    },
    {
      number: 93,
      content: [
        '전치사 in은 공간 개념입니다. 시간과 장소에서 상대적으로 더 넓은 의미로 사용됩니다.',
        '시간 : 월, 계절, 연도 등에 사용됩니다. at(시각), on(날짜)과 비교했을 때 긴 시간의 경과를 나타냅니다.',
        '시간의 경과 : 경과 바로 직후를 뜻합니다. 반대 개념으로 within(안에)이 있습니다.',
        '장소 : at(지점), on(표면)과 비교했을 때 넓은 의미로 국가나 도시에 사용합니다.'
      ]
    },
    {
      number: 94,
      content: [
        '전치사 at/on/in은 모두 시간/공간 개념으로 사용할 수 있습니다.',
        '시간 : at ? on ? in 뒤로 갈수록 시간 개념이 상대적으로 길어집니다.',
        '공간 : at ? on ? in 뒤로 갈수록 공간 개념이 상대적으로 넓어집니다.'
      ]
    },
    {
      number: 95,
      content: [
        '전치사 by는 서로 영향을 미치는 관계를 떠올리면 됩니다.',
        '시간(완료 기한) : 어떤 행위가 그 시점까지 미치는 영향을 나타냅니다.',
        '장소(옆에) : A와 B 사이에 영향을 미치는 거리를 나타냅니다.',
        '행위자/교통수단 : 수단, 행위자, 에너지 등의 영향을 받을 때 사용됩니다.'
      ]
    },
    {
      number: 96,
      content: ['전치사 from은 시간과 장소의 출발점을 나타내며, 출처의 뉘앙스를 줍니다.']
    },
    {
      number: 97,
      content: [
        '전치사 for은 대상을 가리킬 때 사용합니다. 반드시 ~를 위해서으로 해석할 필요는 없지만 기본적으로 대상을 위함, 떠받듦의 뉘앙스를 가지고 있습니다.',
        '전치사 for의 대상은 사람, 사물, 추상 개념을 모두 포함합니다.',
        '어떤 대상을 위해 시간을 할애할 때도 사용합니다.'
      ]
    },
    {
      number: 98,
      content: [
        '전치사 to는 향하고 있는 방향 또는 그곳까지의 도달을 연상하면 됩니다.',
        '도달하는 목적지는 사람, 장소, 범위, 분량, 수치 등 다양한 것들을 포함합니다.',
        'up과 함께 사용해 특정 범위, 정도, 수량까지 도달한다는 의미를 강조할 수 있습니다.'
      ]
    },
    {
      number: 99,
      content: [
        '전치사 out of는 안쪽에서 바깥쪽으로 나오는 느낌입니다. 그렇기 때문에 설정된 범위 밖으로 벗어나고 이탈한 느낌을 주기도 합니다.',
        'out of는 무언가로부터 나왔기 때문에 출처나 원인을 표현하기도 합니다.'
      ]
    },
    {
      number: 100,
      content: [
        '전치사를 통해 시간과 장소 등에 관해 매끄럽게 표현이 가능해요.',
        '전치사를 통해 방향, 시간, 수단, 위치, 출처, 대상, 기간 등 부가 정보를 추가할 수 있어요.',
        '전치사를 통해 문장을 길게 늘려 말할 수 있어요. (ex. I looked at him from my car for 3 hours in Seoul.)'
      ]
    }
  ],
  2: [
    {
      content: [
        '영어와 한국어의 차이는 바로 문화적인 요소에서 나옵니다.',
        '동앙에서는 전체적인 배경 중심으로 생각해 전체적인 그림을 먼저 언급하는 반면, 원어민들온 개체를 중요시하는 개별 중심 사고를 가지고 있어 같은 사진을 보더라도 문장을 시작하는 방법부터 디릅니다.'
      ]
    },
    {
      content: [
        '한국 사람들은 관계 위주로 생각하기에 전체 상황을 서술하는 동사와 부사가 발달되어 있는 반면, 원어민들은 특정 개체를 중심으로 생각하기에 명사를 활용한 표현이 발달했습니다.',
        '이러한 차이로 인해 한국어에 없는 영어만의 독특한 특징이 있습니다.'
      ]
    },
    {
      content: [
        '원어민들은 개별 종심 사고로 인해 대상을 먼저 말하고 그 뒤에 행동이나 상태를 덧붙여 말합니다.',
        '동작을 실행하는 행위자가 바로 주어가 되며,무엇을 강조하고 싶은지에 따라 사람/사물 주어를 결정할 수 있습니다.'
      ]
    },
    {
      content: [
        '한국어 ‘밥은 먹었니?’라는 말올 그대로 영어로 바꿔 말하연 안 됩니다.',
        '숨어있는 주어(밥을 먹는 대상)를 찾아 말해야 합니다.',
        '먼저 보이는 단어에 속지 말고 올바른 주어, 주인공을 찾아보세요.'
      ]
    },
    {
      content: [
        '한국어로 어떤 특징이나 생김새를 묘사할 때 ‘무엇이 어떠하다’라고 단순하게 표현하지만, 원어민들온 ‘누가’ 어떤 특징을 ‘가지고있다,소유하고있다’라고 생각하여 소유의 동사 have를 사용하여 말합니다.'
      ]
    },
    {
      content: [
        '어떠한 상황을 묘사할 때 ‘상황’ 자체를 중요하게 끌고 가는 한국어와 달리 영어는 상황에 처한 ‘사람’을 강조하여 말합니다.',
        '‘누가’ 그런 상황에 처한 것인지, 주어를 정확하게 알려줘야 원어민과 문제없이 소통할 수 있습니다.',
        '주어 뒤에 have/have got/be in을 사용하여 자연스럽게 말해보세요.'
      ]
    },
    {
      content: [
        '상황 묘사와 마찬가지로 영어는 의견올 제시할 때도 ‘누구’의 의견인지를 명확하게 말합니다.',
        '사람 주어와 함께 원어민이 자주 사용하는 like/don’t like, love/hate, I think를 활용하여 의견을 말해보세요.'
      ]
    },
    {
      content: [
        '원어민은 개별 중심의 사고의 영향으로 사람과 사물을 동등하게 바라보기에 사람이나 생물체가 아닌 사물 또한 무언가를 소유할 수 있다고 생각합니다.',
        '사물도 사람처럼 소유 가능하다는 것을 이해하고 사물 주어를 활용하는데 익숙해져야 합니다'
      ]
    },
    {
      content: [
        '영어에서는 사물을 사람과 동둥하게 바라보기 때문에, 사물이 사람처럼 행동할 수 있습니다.',
        '그래서 원어민은 사물이 말하거나(say), 좋아하거나(like), 싫어하거나(hate), 데려간다는(take) 표현을 자주 사용합니다.'
      ]
    },
    {
      content: [
        '영어에서는 사물과 사람올 동등하게 바라보기에 사물이 사람처럼 감정을 교류하고 영향을 줄 수 있다고 생각합니다.',
        '사물이 누군가를 걱정하게 하거나(worry), 놀라게 하거나(surprise), 즐거움을 주는(amuse)등 사물이 주는 감정/영향을 표현하는데 익숙해져야 합니다.'
      ]
    },
    {
      content: [
        '영어로 이유나 원인올 말할 때 한국어 하듯이 because부터 말하면 문장이 길어집니다.',
        '원어민들온 핵심 요점(결과와 그 이유)를 더 명확하게 전달하고자 이유/원인을 주어로 잡고 말하는 것을 선호합니다.'
      ]
    },
    {
      content: [
        '영어로 이유/원인을 말할 때 because를 사용하면 문장이 길어집니다. 무엇 때문에 그 결과가 나왔는지에 집중해서 이유/원인을 주어로 말했던 것처럼, ‘불가능의 원인’ 역시 주어로 말할 수 있습니다.'
      ]
    },
    {
      content: [
        '원어민들이 조건을 말할 때 if나 when을 쓰기도 하지만, 조건 자체를 주어로 말하면 좀 더 명확하게 ‘어떠한 조건’으로 인해 ‘어떤 상태’가 되는지를 전달할 수 있습니다.'
      ]
    },
    {
      content: [
        '원어민들은 동작도 어떤 행동을 할 수 있다는 관점에서 바라보기 때문에 동작올 주어로 말하는 경우가 많습니다.',
        '원어민들이 자주 사용하는 동사 help/sound/cost를 함께 알아두면 유용하게 쓸 수 있습니다.'
      ]
    },
    {
      content: [
        '영어는 한국어와 달리 맥락이 주어지더라도 주어를 생략할 수 없기에, it/this/that과 같은 대명사를 활용해서 반복되는 주제를 대체하여 표현합니다.',
        '상황에 따라 대명사의 활용법과 해석은 달라질 수 있습니다.'
      ]
    },
    {
      content: [
        '영어에서는 주어를 생략할 수 없습니다. 따라서 사람/사물/대명사 등 올바른 주어를 활용하여 상황을 명확하게 전달해야 합니다.',
        '원어민의 사고에 대한 이해를 기반으로 지금까지 배운 주어 설정 훈련을 해보세요.'
      ]
    },
    {
      content: [
        '원어민들은 사람과 사물에게 사용하는 표현올 구분하지 않는 사고방식올 가졌습니다.',
        '영어에서는 run(달리다),send(보내다),need(필요하다)와 같이 사람에게 쓸 법한 표현들을 사물 주어에도 적용 가능합니다.'
      ]
    },
    {
      content: [
        '영어에서는 사람 주어와 사물 주어 간의 경계가 희미하다는 것을 배웠습니다.',
        '이번에는 사물에게 쓸 법한 표현을 사람에게도 쓰는 경우에 대해 알아봅시다.'
      ]
    },
    {
      content: [
        '말하고자 하는 의도에 따라 주어를 설정할 줄 알아야 합니다.',
        '‘누가’했는지를 강조하고 싶다면 사람 주어를, 누가 했든‘결과’를 강조하고 싶다면 사물 주어를 사용해 문장을 완성해야 합니다.'
      ]
    },
    {
      content: [
        '영어에서는 사람과 사물 간 쓰이는 표현들의 경계가 모호합니다.',
        '또한 영어에서 무엇을 강조하고 싶은지에 따라 사람 주어, 혹은 사물 주어로 설정할 줄 알아야 합니다.'
      ]
    },
    {
      content: [
        '영어는 개별 중심 사고의 영향으로 개별적인 개체에 집중합니다.',
        '그래서 원어민처럼 개체를 중심으로 다양한 표현을 구사하려면,명사를 한정해주는 한정사에 익숙해져야 합니다.'
      ]
    },
    {
      content: [
        '원어민에게 관사란 명사라는 존재에 다른 색의 옷을 입혀주는 것과 같습니다.',
        '명사에 어떤 관사를 입히냐에 따라 의미/뉘앙스가 달라지기 때문에 관사 a/an/the를 햇갈리지 않고 상황에 맞게 사용할 수 있어야 합니다.'
      ]
    },
    {
      content: [
        '원어민들은 범위가 정해져 있지 않아 명사를 셀 수 없을 때 관사를 사용하지 않습니다.',
        '관사 유무에 따라 뜻이 잘못 전달될 수 있기 때문에 범위 설정을 잘해야 합니다.'
      ]
    },
    {
      content: [
        '원어민들은 긍정의 뉘앙스인지 부정의 뉘앙스인지를 수량 형용사를 통해 표현하며, 명사를 셀 수 있는 때와 셀수 없는 때의 짝꿍이 정해져 있습니다.'
      ]
    },
    {
      content: [
        '원어민들은 개별 중심 사고를 가지고 있기 때문에, 명사의 소유권을 콕 짚어 말합니다.',
        '의사전달에 오류나 오해가 안 생기도록 소유자가 누구인지 확실히 표현해야 합니다'
      ]
    },
    {
      content: [
        '원어민들은 주어를 중점으로 두고, 주어와 명사 간의 거리를 따집니다.',
        '주어가 지칭하는 사람이나 사물과의 거리/수에 따라 명사의 범위를 한정 짓는 표현이 다릅니다.'
      ]
    },
    {
      content: [
        '영어는 명사 중심의 언어라 원어민들은 주로[형용사+ 영사]조합으로 표현합니다.',
        '원어민들은 명사를 한정 짓는 형용사 외에도 명사의 내적/외적인 요소를 다양하게 꾸며주는 상태 형용사를 활용하기 때문에 꾸미는 순서를 지켜 말해야 합니다.'
      ]
    },
    {
      content: [
        '원어민들은 다양한 한정사들과 상태 형용사를 활용해 명사를 한정 지어 꾸며줍니다.',
        '원어민처럼 개별 중심의 사고를 기반으로 명확하게 소통하고 싶으면  명사를 올바르게 한정하여 말해야 합니다.'
      ]
    },
    {
      content: [
        '영어는 명사 중심의 언어로,명사에 중점을 둡니다.',
        '그래서 주어의 특징이나 행동올 표현할 때도 명사를 한정 지어주는 동사를 활용하는데,원어민처럼 말하기 위해선 한국식[부사+동사]형태를 원어민식[형용사+명사]형태로 바꿔 말하는 동사 명사화에 익숙해져야 합니다.'
      ]
    },
    {
      content: [
        '원어민들은 주어가 ‘어떠한 사람’인지 특징을 묘사할 때, be동사를 활용합니다.',
        '원어민스러운 표현을 구사하려면,[be동사+ 동사 명사화]에 익숙해져야 합니다.'
      ]
    },
    {
      content: [
        '원어민들은 주어가 한 행동을 콕 짚어 말할 때 동사보다는 명사로 표현합니다.',
        '원어민처럼 말하고 싶으면 [have/make/take + 동사 명사화]에 익숙해져야 합니다.'
      ]
    },
    {
      content: [
        '원어민들은 기본동사 have의 ‘소유의 이미지’를 확장해 다양한 상황에 적용합니다.',
        '단순히 사물을 소유할 때뿐만 아니라, 주어가‘가진’ 경험/결과물/상횡을 강조할 때[have + 동사 명사화]형태로 말합니다.'
      ]
    },
    {
      content: [
        '기본동사 make는 ‘시간과 공을 들여서 만든’이라는 노력이 포함된 단어입니다.',
        '이 이미지를 활용해 원어민들은 단순히 사물/음식 등을 만들 때뿐만 아니라, 주어가 ‘만든’변화/선택/노력 등을 나타낼 때도 [make + 동사 명사화] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 기본동사 take의 ‘의지를 갖고 무언가를 나의 것으로 취한다’는 이미지를 활용해 주어가 가지고 간 이 무언가를 한정합니다.',
        '주어가‘취한’휴식/행동/관심 등을 표현할 때 [take + 동사 명사화] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 주어의 특징/행동을 강조하고 싶을 때 명사로 표현합니다.',
        '그렇기 때문에 우리가 원어민처럼 명사 중심으로 말하려면 동사 명사화하여 [형용사 + 명사] 형태로 말해야 합니다,',
        '? 주어의 특징을 나타낼 때 be동사를 활용해 ‘나는 ~한 사람이야.’',
        '? 주어의 행동을 나타낼 때 기본동사 have/make/take를 활용해 ‘나는 ~를 했어.’'
      ]
    },
    {
      content: [
        '원어민들은 기본동사 활용만으로도 여러 가지 표현을 할 수 있습니다.',
        '그래서 기본동사가 지닌 이미지와 함께 명사구 [형용사 + 명사]를 사용하여 더 명확하고 다양한 의미를 전달하는 것에 익숙해져야 합니다.'
      ]
    },
    {
      content: [
        '한국인들은 기본동사 do를 일반적으로‘무언가를 했다’는 간단한 의미로 사용하지만, 원어민들온 ‘어떤 행동을 실행하는 이미지’를 활용해 주어의 행동을 강하게 표현할 때 [do + 명사구] 형태로 자주 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 기본동사 show의 ‘보여주는 이미지’를 확장해 물질적인 것 외에 내적인 요소를 표현할 때도 사용합니다.',
        '주어가 겉으로 ‘보여준’성장/발전/관심 등 추상적인 것을 표현힐 때[show + 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 ‘가져오다/데려오다’의 뜻올 지닌 기본동사 bring을 ‘무언가를 나와 함께 들고 이동하는 이미지’로 접근합니다.',
        '그래서 물질적인 것 외에도 주어가‘가져온’ 경험/수치심 등 추상적인 것을 표현할 때 [bring + 명사구] 형태를 사용합니다.'
      ]
    },
    {
      content: [
        '원어민틀은 기본동사 keep의 ‘붙잡아서 관찰하고 주의를 기울이는 이미지’를 활용해 다양한 의미로 확장해 표현합니다.',
        '주어가 외부로부터 지켜서‘유지한’태도/상태/기록 등을 표현할 때 [keep + 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '기본동사 put은 단순히 내려놓는 것이 아니라‘무언가를 어딘가로 옮겨 놓음으로써 변화시키는 이미지’를 지니고 있습니다.',
        '이 이미지를 확장해 원어민들은 주어가 ‘놓은’ 표시/상태/강조 등 추상적인 개념을 표현할 때 [put+ 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 기본동사 run을 ‘달리다’라는 뜻으로 한정 지어 생각하지 않고 ‘스스로 흘러가는, 거침없이 나아가는 이미지’로 접근해 다양한 상황에 적용합니다.',
        '주어가 빠르게‘실행한’행동을 강조할 때 [run+ 명사구] 형태로 표현합니다.'
      ]
    },
    {
      content: [
        '원어민들은 ‘잡다’의 뜻올 지닌 기본동사 hold를 ‘무언가를 꽉 잡고 있는 이미지’로 접근합니다.',
        '그래서 이 꽉 잡은 상태를 지속하는 이미지를 활용해 주어가 ‘잡은’ 회의/성격/속성 등을 표현할 때 [hold+ 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 기본동사와 동사 명사화된 명사구 [형용사 + 명사]를 사용하여 다양한 의미를 전달합니다. 원어민처럼 명사 중심으로 나의 의도를 콕 짚어 명확하게 전달하고 싶으면 [기본동사 + 명사구] 사용법을 이해하고 연습해야 합니다.'
      ]
    },
    {
      content: [
        '기본동사 come to는 단순히 오다/가다가 아니라 ‘어떤 목적지나 결론에 다가오는, 가까워지는 이미지’를 지니고 있습니다.',
        '원어민들은 이 이미지를 확장해 주어가 ‘도달한’해결책/결정/결말 등을 표현할 때 [come to + 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '한국인들은 기본동사 meet올 ‘누군가를 만난다’는 의미로만 사용하지만, 원어민들은 ‘한 지점에서 만나 마주하는 이미지’를 활용해 주어가‘마주한’목표/요건/일정 등을 표현힐 때 [meet + 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 기본동사 get을 아주 많은 상황에서 다양하게 사용합니다.',
        '기본동사 get은 ‘어떤 상태로 변화해서 얻는 이미지’를 갖고 있으며 원어민들온 주어가 ‘얻은’승인/점수/기대 등을 표현할 때 [get+ 명사구] 형태로 자주 말합니다'
      ]
    },
    {
      content: [
        '한국인들은 기본동사 give를 ‘무언가를 준다’의 의미로 사용하지만, 원어민들은 무언가틀 ‘밖으로 내보내서 주는 이미지’를 활용해 주어가 ‘준’설명/조언/눈치 등을 표현할 때 [give+ 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 ‘보내다’의 뜻을 지닌 기본동사 send를 ‘무언가를 이동시켜서 보내는 이미지’로 접근합니다.',
        '그래서 물질적인 것 외에 주어가 ‘보낸’ 충격/후회/경고 등 추상적인 것을 표현할 때 [send + 명사구] 형태로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 단순히 동사의 뜻으로만 문장을 풀지 않고, 동사가 갖고 있는 이미지/뉘앙스를 활용해 주어가 정확히 무엇을 했는지를 명사로 한정해 표현합니다.',
        '상황 맥락부터 파악해서 그 맥락에 맞는 뉘앙스의 기본동사를 명사구와 함께 사용하면 더욱 자연스러운 영어를 할 수 있습니다.'
      ]
    },
    {
      content: [
        '한국어는 어휘가 많아 동작을 한 단어로 표현할 수 있지만,영어는 한국어와 다르게 동작을 풀어서 말합니다.',
        '동사에 어떤 단어를 조합하느냐에 따라 다양한 의미가 만들어지며 원어민 처럼 쉽고 간단한 표현이 가능해집니다.'
      ]
    },
    {
      content: [
        '한국인들은 up을 ‘위로’라고 방향올 떠올리지만, 원어민들은 up의 ‘아래에서 위로 올라가는 이미지’에서 확장해서 사용합니다.',
        '시작점에서 점점 차오르는(완료), 위로 점점 올라오며 무엇이 떠오르는(등장) 경우에도 [동사 + up]으로 말합니다.'
      ]
    },
    {
      content: [
        '원어민들은 단어의 조합으로 새로운 뜻을 만드는 유연한 사고를 가졌습니다.',
        '그래서 down의‘동작이 위에서 아래로 진행되는 이미지’에서 확장해서 기분/행동/앙이 줄어드는 것을 표현할 때도 [동사 + down]으로 말합니다.'
      ]
    },
    {
      content: [
        'around 하면 바로 떠오르는 이미지는 ‘둥글게’ 또는 ‘빙 돌아서’입니다. 원어민들은 ‘원을 따라 빙 둘러싸는 모습’에서 확장하여 주변/근저, 그리고 여기저기를 표현할 때 [동사 + around]로 말합니다.'
      ]
    },
    {
      content: [
        'after는 주로 어떤 것 다음/이후에 진행될 때 많이 사용합니다.',
        '원어민들은 after를‘어떤 것의 뒤를 따라가거나 진행되는 이미지’에서 확장해서 사람의 행동을 따라하거나, 특징을 닮았을 때도 [동사 + after]로 말합니다.'
      ]
    },
    {
      content: [
        'back은 뒤로 가는 의미뿐 아니라 동작이 뒤로 진행되는 이미지를 가지고 있습니다.',
        '그래서 원어민들은 무언가를 다시 하거나 기억을 떠올리는 상황에서도 [동사 + back]을 사용합니다.'
      ]
    },
    {
      content: [
        'away는 ‘어떤 대상으로부터 멀어지는 이미지’를 가지고 있습니다.',
        '그래서 원어민들은 멀리 있는 것 외에도 다른 곳으로 이동하거나, 어떤 대상을 피하거나, 어딘가로부터 사라지는 상황을 표현하기 위해[동사 + away]를 사용합니다.'
      ]
    },
    {
      content: [
        'into에서 연상되는 의미는 ‘안으로’일 것입니다.',
        '하지만 원어민들은 사전적 의미에서 벗어나서 ‘무언가의 안으로 들어가는 이미지’를 떠올리며 어떤 상황에 처했을 때, 어떤 것에 빠져있을 때 등 [동사 + into] 조합으로 다양한 표현을 합니다.'
      ]
    },
    {
      content: [
        '원어민들은 through의 ‘어떤 대상을 뚫고 지나가는’ 이미지를 활용하여 어떠한 장소를 가로질러 갈 때, 어떠한 상황을 겪었을 때, 끝까지 뚫고 마쳤을 때 [동사 + through]를 자주 사용합니다.'
      ]
    },
    {
      content: [
        '영어를 자연스럽게 하기 위해서는 단어가 가진 이미지를 생각하고 [동사 + 부사/전치사] 조합의 다양한 구동사를 사용하는 것에 익숙해져야 합니다.',
        '지금까지 배운 방향의 의미에서 확장된 전치사의 이미지를 떠올리며 원어민처럼 자연스럽게 구동사를 만들어 보세요.'
      ]
    },
    {
      content: [
        '일반적으로 생각하는 in의 뜻은 안에라고 생각하지만, in은‘어떤 범위/영역 안에 들어가 있는’이미지를 가지고있어, 원어민들은 그 뜻을 확장시켜 어디로 들어갈 때, 무언가를 제출할 때 등을 표현할 때 [동사 + in]을 사용합니다.'
      ]
    },
    {
      content: [
        'on의 기본 이미지는 ‘어떤 범위에 붙어있는’것입니다.',
        '그렁기 때문에, 원어민들은 붙어있거나(입다/타다), 붙어있는 상태를 유지하거나, 어딘가에 붙어 의지하는 등의 다양한 표현을 할 때 주로 [동사 + on] 조합으로 사용하여 표현합니다.'
      ]
    },
    {
      content: [
        '‘두 대상이 같은 장소에서 서로에게 계속 영향을 주고받는’이미지를 가지고 [동사 + with]를 사용하면 원어민처럼 with의 의미를 확장해서 대상과의 관계/어울림을 표현할 수 있습니다.'
      ]
    },
    {
      content: [
        '‘-을 따라’라는 뜻으로 along을 주로 기억했겠지만,‘특정 범위를 따라서 가는’ 이미지를 가지고 along의 의미를 확장시켜야 합니다.',
        '원어민들은 [동사 + along] 조합을 사용하여 특정 방향으로 나아갈 때, 함께 할 때, 또는 진행됨을 표현할 때 사용합니다'
      ]
    },
    {
      content: [
        '전치사의 이미지를 가지고 [동사 + 전치사/부사] 조합으로 풀어서 말하는 것에 익숙해지면, 간단한 구동사 단어 조합만으로도 원어민처럼 다양하고 자연스러운 표현을 할 수 있습니다.',
        '이번엔 범위를 다루는 전치사를 활용하여 말해보세요.'
      ]
    },
    {
      content: [
        '한국인들은 전치사 for를 주로 ‘-를 위해서’로 알고 있습니다.',
        '하지만 원어민은 for의 ‘대상에게 집중되는 이미지’를 떠올리며 목적을 이야기할 때 뿐 아니라 이유를 이야기할 때도[동사 + for]를 사용합니다.'
      ]
    },
    {
      content: [
        'by는 ‘어떤 대상의 옆에서 동작이 진행되는 이미지’입니다.',
        '한국인들은 ‘-에 의해서’로 많이 알고 있지만, 원어민들온 [동사+ by] 조합으로 ‘옆에서,잠깐,접촉’ 등의 다양한 의미를 표현할 때도 사용합니다.'
      ]
    },
    {
      content: [
        'together는 ‘어떤 대상들이 같이 있는 이미지’입니다.',
        '한국인들은 주로 누구와 무언가를 ‘같이 할 때’만 사용하지만, 원어민들은 [동사 + together] 조합으로 물건을 모아서 만들 때, 조립할 때도 사용합니다.'
      ]
    },
    {
      content: [
        '한국어의 동사를 풀어서 생각하고 단어의 뜻을 확장하는 연습을 통해 원어민처럼 다양한 표현을 구사할 수 있습니다.',
        '대상의 이미지를 떠오르게 하는 전치사 for/by/together를 동사와 조합하여 말해보세요.'
      ]
    },
    {
      content: [
        'off는 on의 반대 이미지를 가지고 있습니다.',
        '‘붙어 있는 곳에서 떨어지는 이미지’에서 확장되어 서서히 떠나는(출발/시작), 접작된 곳에서 떨어지거나 떼지는 상태(제거/분리)를 표현할 때 [동사 + off]를 사용합니다.'
      ]
    },
    {
      content: [
        'out은 주로 ‘밖에’ 또는 ‘나가다’라는 의미로 사용합니다.',
        '하지만 단어 out이 가진 이미지인 ‘동작이 밖으로 진행될 때’를 확장하여 무언가를 제외하거나, 무언가가 사라질 때도 [동사 + out]을 사용합니다.'
      ]
    },
    {
      content: [
        'over는 ‘위에/위로’의 의미로 사용됩니다.',
        '하지만 단어 over가 가진 이미지인 ‘포물선을 그리며 무언가를 넘어가는것 ’을 확장하여 무언가를 넘어 진행되거나 이동할 때, 혹은 무언가가 반복될 때도[동사 + over]를 사용합니다.'
      ]
    },
    {
      content: [
        '한국어와 다르게 영어는 한 단어로 표현이 안 되는 단어들이 많습니다.',
        '그래서 원어민들은 어떤 상태로 변하거나 유지되는 의미를 나타낼 때 주로 변화를 의미하는 동사 come/go/stay를 형용사와 함께 사용하여 풀어서 말합니다.'
      ]
    },
    {
      content: [
        '동사와 전치사가 가진 단어의 이미지를 확장해서 사용해야 원어민처럼 다양한 표현을 할 수 있습니다.',
        '또한 동사 come/go/stay에 형용사를 붙여 상태의 변화/유지를 표현할 수 있습니다.',
        '풀어 말하는 훈련을 통해 원어민 영어에 익숙해져 봅시다.'
      ]
    },
    {
      content: [
        '한국어는 동사를 여러 개 사용하여 말할 수 있지만, 영어는 부수적인 정보를 [전치사 + 명사] 형태로 주로 표현합니다.',
        '그래서 원어민처럼 자연스럽게 말하기 위해서는 원어민들이 자주 사용하는 전치사 with/by/for/at을 잘 활용해야 합니다.'
      ]
    },
    {
      content: [
        '전지사 with은 밀착의 의미가 있습니다.',
        '그래서 원어민들은 ‘무언가를 입고/메고/들고/데리고/가지고’를 표현할 때 [with + 명사]를 사용합니다.'
      ]
    },
    {
      content: [
        '전치사 by는 수단의 의미가 있습니다.',
        '원어민들은 다양한 형태의 이동/교통수단을 표현할 때, 어떤 것으로 인해 이루어낼 때, 또는 무언가를 통해서 전달할 때 [by+ 명사] 조합으로 표현합니다.'
      ]
    },
    {
      content: [
        '전치사 for는 목적의 의미가 있습니다.',
        '그래서 원어민들은 ‘어떠한 것을 하려고/가려고/주려고’ 어떤 행동을 할 때 [for + 명사] 조합으로 표현합니다.'
      ]
    },
    {
      content: [
        '원어민들은 감정의 원인을 설명할 때 주로 전치사 at을 사용합니다.',
        '예상 밖으로 놀랐을 때 [be surprised], 정말 놀랐을 때 [be amazed], 그러고 즐거워할 때[be amused], 이 3가지의 표현을 [at + 명사]와 같이 자주 사용합니다'
      ]
    },
    {
      content: [
        '한국어와 다르게 영어는 한 문장에 동사를 여러 개를 사용하는 것보다, [전치사 + 명사] 조합으로 간략하게 풀어서 말하는 것을 선호 합니다.',
        '그래서 원어민들은 동사가 여러 개 들어가는 말을 할 때는 with/by/for/at을 명사와 같이 사용해서 표현합니다.'
      ]
    }
  ],
  3: [
    {
      content: [
        '영어는 깊게 숨 쉬며 말하는 복식 호흡을 활용합니다.',
        '영어는 강세와 억양을 나타내기 위해 발음과 호흡을 조절합니다.',
        '호흡에는 뱉는 호흡과 뱉지 않는 호흡이 있습니다.'
      ]
    },
    {
      content: [
        '우리말은 모든 글자에 모음이 오지만, 영어는 단어마다 모음의 개수가 다릅니다.',
        '우리말은 각 음절마다 강세가 비슷하지만, 영어는 강세가 오는 모음이 따로 있습니다.',
        '강세가 오는 모음은 힘을 주어 길게 발음하고, 나머지 모음은 힘을 빼고 짧게 발음합니다.'
      ]
    },
    {
      content: [
        '4음절 이상의 단어 중에서 주 강세와 보조 강세가 있는 단어들을 연습해 봅시다. 주 강세는 가장 강하게, 보조 강세는 그다음으로 강하게, 나머지 모음은 약하게 발음합니다.',
        '-ity, -tion, -ian, -ic, -ious 등으로 끝나는 긴 단어는 바로 앞의 모음에 강세가 옵니다.',
        '같은 어근에서 나온 단어는 품사에 따라 강세도 달라집니다.'
      ]
    },
    {
      content: [
        '영어 단어 중에 같은 철자인데 명사와 동사로 쓰이는 단어들이 있습니다.',
        '명사로 쓰이면 강세가 앞에, 동사로 쓰이면 강세가 뒤에 옵니다.',
        '엉뚱한 모음에 강세를 주면 의미가 전혀 다른 단어로 들릴 수 있으므로 주의해 주세요.'
      ]
    },
    {
      content: [
        '명사와 명사, 형용사와 명사가 결합된 복합 명사의 경우 강세가 앞에 옵니다.',
        'takeoff, makeup과 같은 구동사의 명사형은 강세가 앞에 옵니다.'
      ]
    },
    {
      content: [
        '영어 단어에 강세가 있듯이 영어 문장에도 강세가 있습니다.',
        '명사와 동사처럼 문장의 뜻에 중요한 역할을 하는 내용어는 강세를 주어 강하고 길게 발음합니다.',
        '내용어 중에서 문장의 맨 마지막에 오는 내용어는 조금 더 강하게 발음합니다.'
      ]
    },
    {
      content: [
        '관사, be동사, 전치사 등 문장의 뜻에 크게 영향을 미치지 않는 기능어는 문장에서 약하고 짧게 발음합니다.',
        '기능어는 약하고 짧게 발음하기 때문에 앞뒤 단어와 연음될 때 전혀 다른 소리처럼 들리기도 합니다.'
      ]
    },
    {
      content: [
        'be동사, 조동사에 부정어 not이 붙어 축약되면 기능어라도 강하게 발음합니다.',
        'be동사, 조동사, 전치사가 문장의 맨 끝에 오면 강하게 발음합니다.',
        '관사, 대명사, 전치사와 같은 기능어도 문맥상 필요한 경우 강하게 발음될 수 있습니다.'
      ]
    },
    {
      content: [
        '억양이란 음의 높낮이를 말하며, 끝을 올리는 억양, 내리는 억양, 약간 올리는 억양이 있습니다.',
        '마침표로 끝나는 평서문과 what, how 등 의문사가 있는 의문문은 끝을 내려줍니다.',
        '조동사, do 동사, be 동사로 시작되는 의문문과 부가 의문문은 끝을 올려줍니다.',
        '단어나 어구의 연결, 계속되는 생각을 나타낼 때는 끝을 약간 올려줍니다.',
        '특정한 의도나 감정에 따라 억양이 달라질 수 있습니다.'
      ]
    },
    {
      content: [
        '내용어는 강하게, 기능어는 약하게 발음하면서 억양까지 살려주면 영어 문장에 리듬이 생깁니다.',
        '다양한 문장을 따라 말하며 영어 문장 특유의 박자와 리듬에 익숙해지세요.',
        '특정한 의도나 감정에 따라 억양이 달라질 수 있습니다.'
      ]
    },
    {
      content: [
        '영어는 26개의 알파벳으로 표기하며, 이 중 자음이 21개, 모음이 5개입니다.',
        '26개의 알파벳은 자음 소리, 모음 소리, 반모음 소리를 다양하게 만들어 냅니다.',
        '영어의 자음과 모음 소리를 우리말로 표기할 수 있지만, 정확히 같은 소리는 아니며, [f, v, θ, ð] 등과 같이 우리말에 없는 소리는 비슷한 소리로 표기합니다.'
      ]
    },
    {
      content: [
        '[æ]와 [e] 소리는 우리말의 ‘애’와 ‘에’로 표기될 수 있지만, 정확히 같은 소리는 아닙니다.',
        '[æ]는 ‘애’라고 말하면서 턱을 아래로 벌리고, [e]는 ‘에’라고 말하면서 입꼬리에 힘을 주면서 발음합니다.'
      ]
    },
    {
      content: [
        '[iː]와 [ɪ] 소리는 우리말로 긴 ‘이’와 짧은 ‘이’로 표기될 수 있지만 정확히 같은 소리는 아니며,영어에서 이 두 소리는 전혀 다른 소리입니다.',
        '[iː]는 입꼬리를 바깥쪽으로 벌리고 두 박자 동안 길게 끌며 ‘이’라고 발음합니다.',
        '[ɪ]는 짧고 약하게 ‘이’와 ‘에’ 중간 소리 정도로 발음합니다.'
      ]
    },
    {
      content: [
        '[ɔː]와 [oʊ]는 각각 우리말로 ‘어’와 ‘오우’로 표기될 수 있지만, 정확히 같은 소리는 아닙니다.',
        '[ɔː]는 ‘어’보다 턱을 아래로 내리고 혀의 뒤쪽에 힘을 주면서 길게 발음합니다.',
        '[oʊ]는 ‘오우’에서 ‘오’를 크고 길게, ‘우’를 짧고 약하게 발음합니다.'
      ]
    },
    {
      content: [
        '[uː]와 [ʊ] 소리는 우리말로 긴 ‘우’와 짧은 ‘우’로 표기될 수 있지만 정확히 같은 소리는 아니며,영어에서 이 두 소리는 전혀 다른 소리입니다.',
        '[uː]는 입술을 둥글게 모으고 혀 뒤쪽에 힘을 주면서 길게 ‘우’로 발음합니다.',
        '[?]는 입술과 혀에 힘을 빼고 ‘우’와 ‘어’ 중간 느낌으로 발음합니다.'
      ]
    },
    {
      content: [
        '[ɑː]와 [ʌ] 소리는 우리말로 ‘아’와 ‘어’로 표기할 수 있지만 정확히 같은 발음은 아닙니다.',
        '[ɑː]는 턱을 충분히 내리고 입안 공간을 확보한 상태에서 ‘아’라고 발음합니다.',
        '[ʌ]는 턱을 내리지 않은 상태에서 ‘어’라고 발음합니다.'
      ]
    },
    {
      content: [
        '[aːr]과 [əːr] 소리는 미국 영어의 특징적인 소리로 [aː]와 [?ː]에 [r] 소리가 더해진 것입니다.',
        '[aːr]는 턱을 충분히 내리고 혀 양날개가 입천장에 붙은 상태로 ‘알’이라고 발음합니다.',
        '[əːr]는 턱을 내리지 않은 상태에서 혀 양날개를 입천장에 붙인 채로 ‘어’에 r발음을 더합니다.'
      ]
    },
    {
      content: [
        '[j], [w]의 소리는 모음과 비슷하지만 모음처럼 음절이 될 수는 없는 소리입니다.',
        '[j]는 안쪽 혀를 입천장에 가깝게, 혀 끝을 아래 쪽에 위치시킨 후 ‘이으’라고 발음합니다.',
        '[w]는 입술을 동그랗게 모은 후, ‘우’라고 하다가 ‘워’로 발음합니다.'
      ]
    },
    {
      content: [
        '영어에서 강세가 오는 모음은 강하고 길게 발음하는데, 이 강세 모음 앞뒤에 오는 모음은 소리가 약화되어 [ə]로 발음합니다. 예를 들어, about, taken, pencil, today, unable에서 모음 a, e, i, o, u는 모두 [ə]로 발음합니다.',
        '[ə]는 턱을 내리지 않고 ‘으’와 ‘어’ 중간 정도의 느낌으로 빠르고 약하게 발음합니다.'
      ]
    },
    {
      content: [
        '[eɪ], [aɪ], [ɔɪ], [aʊ]는 우리말 ‘에이’, ‘아이’, ‘오이’, ‘아우’로 표기되지만, 2음절인 우리말과 달리 1음절로 발음합니다.',
        '‘에이’, ‘아이’, ‘오이’, ‘아우’에서 앞소리에 힘을 주고 끝의 ‘이’와 ‘우’는 힘을 빼고 짧게 발음하기 때문에 우리말 하듯이 ‘아’와 ‘이’에 동일한 강세를 주지 않도록 주의해야 합니다.'
      ]
    },
    {
      content: [
        '[t]와 [d]의 가장 기본적인 소리로, 혀끝을 앞니 뒤의 위쪽 잇몸에 대고 ‘트’와 ‘드’를 발음합니다.',
        '두 발음 모두 강하게 터트리듯 발음하는 소리로 [t]는 무성음이고 [d]는 유성음입니다.',
        '‘동사+ed(과거형)’는 ed 앞에 오는 소리에 따라 [t], [d], [id]로 발음됩니다.'
      ]
    },
    {
      content: [
        't와 d가 모음 사이에 올 때, r 뒤에 올 때, l 앞에 올 때 굴러가는 [t] 소리로 발음될 수 있습니다.',
        '굴러가는 [t] 소리는 혀끝을 입천장 볼록한 곳과 윗니 사이에 살짝 댔다가 혀를 목구멍 쪽 방향으로 움직이며 입천장을 빠르게 핥듯이 발음합니다.'
      ]
    },
    {
      content: [
        '단어의 끝에 오는 t는 우리말 ‘ㅌ’이나 ‘ㄷ’처럼 발음합니다.',
        '예를 들어, cat은 ‘캐트’가 아니라 ‘캩’처럼 받침 [t] 소리로 발음합니다.',
        't나 tt 다음에 강세가 없는 모음과 n이 뒤따르면 이때 t는 콧바람 소리가 납니다.',
        '콧바람 [t] 소리는 [n] 소리와 비슷합니다. 혀끝을 윗니 뒤 잇몸에서 떼지 않은 상태에서 [n]을 코로 삼키는 듯이 발음합니다.'
      ]
    },
    {
      content: [
        'nt 앞에 강세가 있는 모음이 올 때 [t] 소리는 자주 생략됩니다. 예를 들어, Internet은 ‘인터넷’이 아니라 ‘이너넷’처럼 발음합니다.',
        'point, friend처럼 단어가 nt나 nd로 끝나면 끝의 [t]와 [d] 소리는 자주 생략됩니다.',
        '-ly 앞의 [t] 소리도 자주 생략됩니다. 예를 들어, softly는 ‘소플리’처럼 발음합니다.'
      ]
    },
    {
      content: [
        's 다음에 오는 t는 우리말 ‘뜨’처럼 된소리로 발음됩니다. 예를 들어, stop은 ‘스탑’이 아니라 ‘스따압’과 같이 발음합니다.',
        'tree, dream처럼 t, d 다음에 r이 나오면 [r] 소리를 내기 위해 입을 모으게 되는데, 이 과정에서 [tr]는 ‘츄루’, [dr]는 ‘쥬루’처럼 발음됩니다. tree는 ‘츄리’, dream은 ‘쥬림’처럼 발음합니다.'
      ]
    },
    {
      content: [
        '[θ] 소리는 혀끝을 윗니 앞으로 살짝 나오게 했다가 뒤로 집어넣으면서 공기를 내뱉는 소리입니다. 우리말 ‘ㅆ’와 비슷한 소리가 납니다.',
        '[ð] 소리는 [θ] 소리를 내면서 성대를 울리는 소리입니다. 우리말 ‘ㄷ’와 비슷한 소리가 납니다.',
        '[θ]와 [ð]는 우리말에 없는 소리이므로 충분히 연습해 주세요.'
      ]
    },
    {
      content: [
        '[l]은 혀끝을 앞니 뒤의 윗잇몸에 대고 내는 소리로 ‘ㄹ’과 같은 소리가 납니다.',
        'l은 정석 [l]과 길게 가져가는 [l] 소리가 있으며, 아예 발음되지 않는 경우도 있습니다.',
        '길게 가져가는 [l]은 단어의 끝이나 자음 바로 앞에 오며 ‘으얼’처럼 발음합니다.',
        '[r]은 입술을 둥글게 ‘우’ 모양으로 만들고 혀끝을 뒤로 만 상태에서 ‘어얼’로 발음합니다.'
      ]
    },
    {
      content: [
        '[p]와 [b]는 윗입술과 아랫입술을 붙이고 있다가 힘차게 터뜨리는 소리로 ‘프’와 ‘브’라고 발음합니다.',
        '[f]와 [v]는 아랫입술을 윗니에 닿게 하고 바람이 새듯이 ‘f으’와 ‘v으’라고 발음합니다.',
        '[p]와 [f]는 성대를 울리지 않고 나는 소리이고, [b]와 [v]는 성대를 울려서 나는 소리입니다.'
      ]
    },
    {
      content: [
        '[s]는 우리말 ‘ㅆ’와 같은 소리이며, 성대를 울리지 않고 나는 소리입니다.',
        '[z]는 [s]와 같은 방법으로 발음하지만 성대가 울리고 떨림이 있는 소리입니다.',
        '복수형 ‘명사+(e)s’는 앞에 오는 소리에 따라 [s], [z], [iz]로 발음됩니다.'
      ]
    },
    {
      content: [
        '[ʃ]는 입술을 둥글게 앞으로 모으고 도시의 ‘시’ 발음과 같이 발음합니다.',
        '[ʒ]는 [ʃ]와 동일한 방법으로 소리 내되 성대를 울리고 떨림이 있게 발음합니다.',
        '[tʃ]는 입술을 둥글게 앞으로 모으고 우리말의 ‘ㅊ’와 같은 소리로 발음합니다.',
        '[dʒ]는 [tʃ]와 동일한 방법으로 소리 내되 성대를 울리고 떨림이 있게 발음합니다.'
      ]
    },
    {
      content: [
        '한 단어의 끝 자음과 다음 단어의 첫 모음은 연결해서 발음합니다.예를 들어, find out은 ‘파인다웃’처럼 발음합니다.',
        '[t], [d] 소리는 연음할 때 모음 사이에 있는 경우, 굴러가는 소리로 발음합니다.예를 들어, get out은 ‘게라웃’처럼 발음합니다.',
        '관사 an은 앞 단어의 자음, 다음 단어의 모음과 연결해서 발음합니다.'
      ]
    },
    {
      content: [
        '두 단어가 같은 자음으로 연결되면 자음을 한 번만 발음합니다.예를 들어, bus stop은 ‘버스땁’으로 발음합니다.',
        'p, t, k, b, d, g로 끝나는 단어 뒤에 자음이 오면 p, t, k, b, d, g를 받침처럼 발음합니다.예를 들어, great day는 ‘그뤠잍데이’와 같이 발음합니다.'
      ]
    },
    {
      content: [
        '두 단어가 모음으로 연결될 때, 두 모음 사이가 끊기지 않도록 반모음 [j], [w] 소리가 짧고 약하게 삽입될 수있습니다. 예를 들어, I ate은 ‘I yate(아이예잍)’, go out은 ‘go wout(고우와웃)’처럼 발음됩니다.',
        '모음 [eɪ], [ɪ], [aɪ], [ɔɪ] 뒤에는 [j] 소리가 삽입되고, [oʊ], [ʊ], [aʊ] 뒤에는 [w] 소리가 삽입됩니다.'
      ]
    },
    {
      content: [
        't, d, s, z로 끝나는 단어 뒤에 반모음 [j]가 오면 [tʃ], [ʒ], [ʃ], [dʒ]로 발음됩니다.예를 들어, meet you는 ‘미츄’, miss you는 ‘미슈’로 발음합니다.',
        '자음 소리로 끝나는 단어 뒤에 반모음 [w]가 오면 두 단어를 한 단어처럼 발음합니다.예를 들어, where was는 ‘웰워즈’처럼 발음합니다.'
      ]
    },
    {
      content: [
        '[t, d], [p, b], [f, v] 등과 같이 무성음/유성음 차이만 있는 자음이 나란히 오면 대부분 뒤에 오는자음만 발음합니다. 예를 들어, last day는 ‘래스데이’처럼 발음합니다.',
        '[p, b]와 [m], [s, z]와 [ʃ ], [tʃ ]와 [ʃ ]처럼 입술 모양/혀의 위치가 비슷하게 발음되는자음이 나란히 오면 대부분 뒤에 오는 자음만 발음합니다. 예를 들어, is she는 ‘이쉬’처럼 발음합니다.'
      ]
    },
    {
      content: [
        '대명사 he, 조동사 have 등 기능어에 있는 [h]는 연음되면서 생략되기도 합니다.예를 들어, is he는 ‘이즈이’, she has는 ‘쉬애즈’처럼 발음합니다.',
        '정관사 the, 대명사 they, 접속사 that 등 기능어에 있는 [ð]는 연음되면 [d]처럼 발음되기도 합니다.예를 들어, watch the film에서 the[ðə]는 [də]처럼 발음합니다.'
      ]
    },
    {
      content: [
        '기능어인 전치사와 접속사는 문장에서 약하게 발음되는데, 연음될 때 소리가 더욱 약화됩니다.예를 들어, of는 ‘업’, and는 ‘은’과 같이 발음합니다.',
        '기능어인 be동사와 조동사는 강세를 받는 의문사와 문장 맨 앞의 대명사 뒤에서 약하게 발음됩니다.예를 들어, can은 ‘큰’, has는 ‘애즈/어즈’와 같이 발음합니다'
      ]
    },
    {
      content: [
        'have to, going to 등에 나오는 부정사 to는 연음될 때 축약되면서 발음도 달라집니다.예를 들어, have to는 ‘해브ㅌ’, going to는 ‘거너’, want to는 ‘워너’처럼 발음합니다.',
        'will, have, has와 같은 조동사는 ’ll,’ve,’s와 같이 축약됩니다.',
        '‘조동사+have’도 축약되면 발음이 약화되거나 달라집니다.예를 들어, could have는 축약되면 could’ve가 되며, ‘쿠드ㅂ’와 같이 발음합니다.'
      ]
    },
    {
      content: [
        '원어민들은 일상체에서 자주 쓰는 어구들을 축약해서 말하는 경우가 많습니다.',
        'do, of, have가 다른 어구들과 결합해 축약되면서 발음이 달라지는데,do는 ‘ㄷ’나 ‘ㅈ’, of는 ‘어’, have는 조동사와 함께 쓰여 ‘ㅂ’처럼 발음합니다.',
        '기타 자주 쓰이는 축약 어구들도 알아두면 도움이 됩니다.'
      ]
    },
    {
      content: [
        '지금까지 연음 규칙과 연음 과정에서 나타나는 생략, 그리고 축약에 대해 배웠습니다.',
        '앞에서 배운 내용을 떠올리면서 다양한 문장으로 연음을 연습해 보세요.'
      ]
    },
    {
      content: [
        '섀도잉(shadowing)은 원어민의 말을 ‘그림자(shadow)’처럼 따라 말하는 것으로, 초급 학습자들은 의미 덩어리로 섀도잉 연습을 하는 것이 효과적입니다.',
        '원어민이 말을 할 때 구와 구, 절과 절, 문장과 문장 단위로 끊어서 말을 하는데, 이 각각을 의미 덩어리라고 합니다.'
      ]
    },
    {
      content: [
        '보통 원어민은 말을 할 때 I need your help나 You look happy처럼 [주어+동사+목적어/보어]를 하나의 의미 덩어리로 말합니다.',
        '주어, 목적어, 보어가 길어지면 각각 하나의 의미 덩어리로도 끊어 말하는데, 이 안에서도 의미 덩어리를 더 세분화할 수 있습니다.'
      ]
    },
    {
      content: [
        '끊어 말할 때는, 문장에 디테일을 더해주는 수식어 덩어리가 ‘핵심 내용 덩어리’와 구분되도록 말해야 합니다.',
        '수식어 덩어리에는 명사를 디테일하게 꾸며주는 형용사구(절) 의미 덩어리, 시간/목적/조건/정도의 개념을 더하는 부사구(절) 의미 덩어리, 2가지가 있습니다.'
      ]
    },
    {
      content: [
        '상대방의 시선이나 집중을 유도할 때 자주 사용되는 말들을 표현 중심 의미 덩어리로 끊어 줍니다.',
        '말하는 중간에 추가적인 정보를 주는 삽입구도 하나의 의미 덩어리로 끊어 줍니다.'
      ]
    },
    {
      content: [
        '짧은 대화와 담화를 의미 덩어리로 섀도잉하는 연습을 해 봅시다.',
        '섀도잉을 할 때는 원어민의 목소리, 억양, 강세, 리듬을 최대한 똑같이 따라 해 보세요.'
      ]
    },
    {
      content: [
        '달콤한 주말을 보내고 온 월요일 아침은 집중도 잘 안 되고 피곤해요. 월요병은 영어로 Monday blues라고 해요. 오늘 아침은 조엘이 특별히 더 힘들어 하는 것 같은데, 어떤 일인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '최애 영화는 영어로 favorite film이라고 표현할 수 있어요. 애슐리랑 조엘이 서로의 최애 영화를 소개하고 있는데, 어떤 영화인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '혹시 나쁜 습관이 있거나 가졌었던 경험이 있나요? 나쁜 습관은 한 번 생기면 고치기가 정말 어려운데요. 애슐리의 나쁜 습관은 무엇인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '하루 루틴은 일어나서 잠들기까지 매일 반복하는 일을 말하는데요. 하루 루틴을 정해두면 하루를 좀 더 효율적으로 보낼 수 있어요. 그럼, 조엘의 하루 루틴은 무엇인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '한 주를 마무리하고 주말을 앞둔 금요일 저녁에는 뭔가 근사한 약속이라도 잡고 싶어요. 업무를 마친 애슐리에게 조엘이 연락을 했는데요. 무슨 일인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리가 남자 친구 테드와 헤어진 후 조엘과 만나 얘기를 나누고 있어요.남녀가 사귀다 헤어지는 것을 영어로 break up이라고 해요.애슐리가 헤어진 이유가 무엇인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리가 여행지에서 식사를 주문하고 있어요.요즘 다이어트 중이라고 들었는데, 어떤 음식을 주문했는지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '여름휴가 계획을 세우셨나요? 조엘이 여름휴가 계획을 얘기하고 있는데요. 독특하고 멋진 장소 같아요.자, 조엘의 여름휴가 계획을 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리와 조엘이 어젯밤 본 축구 경기에 대해 얘기를 나누고 있는데요.한국 팀도 참가한 경기였던 것 같아요. 최종 경기 결과는 어땠는지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '현대인들에게는 일만큼이나 여가 생활의 중요성도 강조되면서 이색적인 활동들도 많아지고 있어요. 애슐리는 어떤 여가 활동을 하고 있는지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        'SNS 활동을 하고 계신가요? SNS는 Social Network Service의 약자로, 원어민들은 social media라고 해요. 조엘이 SNS 관련해서 걱정이 있다고 하는데요, 어떤 건지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '몸이 안 좋거나 기분이 다운될 때 생각나는 음식을 ‘소울 푸드’라고 하는데요, 영어로는 comfort food라고 해요. 누구나 한두 개쯤은 있는 거 같아요.',
        ' 애슐리의 comfort food는 무엇인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리가 썸을 타고 있는 상대에 대해 조엘과 얘기를 나누고 있어요. 상대가 누구인지, 연인으로 발전할 가능성이 있는지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리와 조엘이 서로의 bucket list에 대해 이야기를 나누고 있어요. 이 둘의 버킷 리스트는 무엇인지, 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '조엘이 심각한 얼굴로 애슐리와 대화를 나누고 있는데요. 여자친구와 무슨 문제가 있는 것 같아요. 무슨 일 때문인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리랑 조엘이 직장 동료 존의 퇴사 소식에 대해 얘기를 나누고 있어요. 조엘은 인사과 신디에게서 소식을 들었다고 하는데요, 둘의 대화를 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '조엘이 시무룩한 얼굴로 자리에 앉아 있어요. 시무룩한 얼굴을 영어로 long face라고 하는데요. 조엘에게 무슨 일이 있었는지, 조엘과 애슐리의 대화를 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리가 면접이 잡혔다네요, 그것도 영어로요! 들떠있는 애슐리에게 조엘이 제안을 하나 하는데요. 어떤 내용인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리와 조엘이 오랜만에 만났어요. 최근 조엘은 취업 준비로 바빴다고 하는데요. 취업 준비가 쉽지 않은 것 같아요. 둘의 대화를 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '지나치게 꼼꼼한 상사 때문에 속이 상한 애슐리를 동료 조엘이 위로하고 있어요. 꼼꼼한 상사를 영어로 micromanager라고 해요. 애슐리의 상사가 과연 어느 정도로 꼼꼼한지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리와 조엘이 MBTI 결과에 대해 이야기하고 있어요. MBTI 검사는 심리 검사의 한 종류로 16가지 성격 유형 중 하나로 결과가 나와요. MBTI 검사에 대한 애슐리와 조엘의 생각을 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '조엘이 회사에서 후회되는 일이 있는 것 같아요. 작년에 있었던 큰 프로젝트와 관련이 있는 것 같은데, 어떤 내용인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '조엘은 거절을 잘 못하는 성격인데, 그것 때문에 힘든 일이 있었나 봐요. 애슐리가 옆에서 좋은 조언을 해주고 있는 거 같은데요. 어떤 내용인지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '코트 단추가 잠기지 않아 충격을 받은 애슐리! 최근 다이어트에 성공했다고 하는데요. 그녀만의 비결이 무엇인지, 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리와 조엘이 종이책과 전자책의 장단점에 대해 대화를 나누고 있어요. 전자책은 영어로 e-book, 종이책은 physical book이라고 해요. 두 사람은 어느 것을 선호하는지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '사진사 조엘이 이력서용 사진 보정에 대한 자기 생각을 이야기하고 있어요. 조엘이 어떤 생각을 가지고 있는지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리와 조엘이 서로의 이상형에 대해 이야기하고 있어요. 이상형은 영어로 ideal type이라고 해요. 두 사람은 어떤 스타일의 이성을 좋아하는지 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리랑 조엘이 결혼에 대한 생각을 나누고 있어요. 애슐리는 결혼하지 않는 쪽, 조엘은 결혼하는 쪽의 입장을 택한 것 같아요. 그 이유를 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리의 취미는 그림 감상이에요. 그래서 주말마다 미술 전시회를 찾아다니는데, 오늘은 애슐리가 자신의 최애 그림을 소개한다고 하네요. 어떤 그림인지, 좋아하는 이유도 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리가 조엘에게 가장 기억에 남는 여행지에 대해 물어보고 있어요. 멋진 해변이 있고 흥미로운 투어를 할 수 있는 곳인 것 같은데요, 과연 어디인지 조엘에게 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '첫인상은 영어로 first impression이라고 해요. 처음 만나는 자리에서 첫인상은 중요할 수밖에 없는데요, 첫인상에 대한 애슐리와 조엘의 생각을 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        'SNS나 데이팅 앱을 이용한 범죄가 늘고 있는데요. 이런 범죄를 romance scam이라고 해요. 애슐리가 로맨스 스캠에 대해 자세히 알려준다는데 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '여러분은 존경하는 사람이 있나요? 조엘이 가장 존경하는 사람은 친누나 앨리스라고 하는데요, 그 이유를 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '1인 가구가 늘어나면서 반려 동물을 키우는 사람들이 늘어나고 있어요. 오늘은 애슐리가 자신이 길렀던 반려견 바크에 대해 이야기하고 있는데요, 한 번 들어볼까요?'
      ]
    },
    {
      content: [
        '애슐리가 조엘에게 metaverse 콘서트에 다녀온 후기를 들려주고 있어요. 조엘은 아직 메타버스 콘서트에 대해 잘 모르는 것 같은데요, 두 사람의 대화를 한 번 들어볼까요?'
      ]
    }
  ]
}

const ICON = {
  star: (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9964 6.93662L17.0843 5.48472C16.8971 5.42933 16.7331 5.30994 16.6226 5.14836L13.7279 0.913211C13.3369 0.341234 12.6909 0 11.9996 0C11.3084 0 10.6621 0.341234 10.2714 0.913211L7.37638 5.14836C7.26589 5.30994 7.10188 5.42933 6.91468 5.48472L2.00288 6.93662C1.33967 7.13263 0.816151 7.64319 0.602619 8.30241C0.389088 8.96164 0.513028 9.68342 0.934938 10.2327L4.05748 14.302C4.17655 14.4573 4.23924 14.6504 4.2338 14.8461L4.09297 19.9787C4.07723 20.551 4.28675 21.0908 4.68319 21.4992C5.07733 21.9056 5.6249 22.1386 6.18535 22.1386C6.42436 22.1386 6.66136 22.0973 6.88978 22.0158L11.7146 20.2956C11.8064 20.2628 11.9026 20.2462 11.9999 20.2462C12.0973 20.2462 12.1934 20.2628 12.2853 20.2956L17.1101 22.0158C17.3388 22.0973 17.5758 22.1386 17.8145 22.1386C18.375 22.1386 18.9222 21.9056 19.3167 21.4992C19.7128 21.0908 19.9226 20.5507 19.9069 19.9787L19.7661 14.8461C19.7606 14.6504 19.8233 14.457 19.9424 14.302L23.0652 10.2327C23.4868 9.68342 23.6111 8.96164 23.3972 8.30241C23.1837 7.64319 22.6596 7.13234 21.9964 6.93662Z"
        fill="#FBCE41"
      />
      <path
        d="M8.93278 11.8516C8.93278 12.9574 8.03309 13.8571 6.92733 13.8571C5.82156 13.8571 4.92188 12.9574 4.92188 11.8516C4.92188 10.7459 5.82156 9.84619 6.92733 9.84619C8.03309 9.84619 8.93278 10.7459 8.93278 11.8516Z"
        fill="#F6A42B"
      />
      <path
        d="M19.0773 11.8516C19.0773 12.9574 18.1776 13.8571 17.0719 13.8571C15.9661 13.8571 15.0664 12.9574 15.0664 11.8516C15.0664 10.7459 15.9661 9.84619 17.0719 9.84619C18.1776 9.84619 19.0773 10.7459 19.0773 11.8516Z"
        fill="#F6A42B"
      />
      <path
        d="M9.95626 10.4028C9.66944 10.4028 9.39826 10.2463 9.25746 9.97517C9.10622 9.6727 8.82462 9.64141 8.71511 9.64141C8.60559 9.64141 8.32398 9.6727 8.17274 9.97517C7.97457 10.3611 7.50523 10.5123 7.11932 10.3194C6.73341 10.1212 6.58217 9.65184 6.77512 9.26593C7.14539 8.53582 7.89113 8.0769 8.70989 8.0769C9.52864 8.0769 10.2744 8.53061 10.6499 9.26593C10.848 9.65184 10.6916 10.1212 10.3057 10.3194C10.1909 10.3767 10.071 10.4028 9.95106 10.4028H9.95626Z"
        fill="#66205E"
      />
      <path
        d="M16.5266 10.4026C16.2398 10.4026 15.9686 10.2461 15.8278 9.97492C15.6765 9.67245 15.3949 9.64116 15.2854 9.64116C15.1759 9.64116 14.8943 9.67245 14.7431 9.97492C14.5449 10.3608 14.0755 10.5121 13.6896 10.3191C13.3037 10.1209 13.1525 9.65159 13.3454 9.26568C13.7157 8.53558 14.4615 8.07666 15.2802 8.07666C16.099 8.07666 16.8447 8.53037 17.2202 9.26568C17.4184 9.65159 17.2619 10.1209 16.876 10.3191C16.7613 10.3765 16.6413 10.4026 16.5214 10.4026H16.5266Z"
        fill="#66205E"
      />
      <path
        d="M15.5 13C15.1111 14.25 13.933 15.5 12 15.5C10.067 15.5 8.88889 14.25 8.5 13"
        stroke="#66205E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  ),
  check: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M2 8.5L5.5 12.5L14 3.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const AINADOO_CONFIG = { API_HOST, IMG_HOST, DAILY_TIP, ICON }
