import { useState } from 'react'
import { useNavigate } from 'react-router'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import AlertModal from 'components/modal/AlertModal'

/**
 * ! 페이지 이동 및 params, query 등을 알기 위해서 사용하는 window.location에 대해서 사용을 통일 해야 할것으로 보입니다.
 * ! react-router-dom 사용 통일 필요
 * TODO: storage 사용에 대해서도 로직을 한곳에 모을 수 있는지 고민해 보면 좋을거같아요
 */
export default function useSubscription() {
  const navigate = useNavigate()
  const [msg, setMsg] = useState('')
  const [show, setShow] = useState(false)

  /**
   * post API
   * @param {*} data
   */
  const postSubscription = async (data) => {
    try {
      const response = await api.post('/v2/external/u-plus/subscription/activation', data)

      if (response.data.meta.code === 200) {
        setMsg('구독이 완료되었습니다. 야나두 클래스 App을 다운로드 받아 학습을 시작해 보세요.')
      } else if (response.data.meta.code === 2001) {
        setMsg('이미 연동된 회원입니다. 야나두 클래스 App을 다운로드 받아 학습을 시작해 보세요.')
      } else {
        setMsg(
          '연동을 실패했습니다. 다시 연동을 진행해 주세요. 이후 동일한 증상 발생 시 고객센터에 문의 부탁드립니다.'
        )
      }

      setShow(true)

      // console.log('response message', response.data.meta.message, response.data.meta.code);
    } catch (e) {
      console.log(e)
    } finally {
      sessionStorage.removeItem('signType')
    }
  }

  /**
   * Modal coinfirm & close
   * @param {*} e
   */
  const handleConfirmClose = (e) => {
    setShow(false)
    navigate('/mypage/1')
  }

  return {
    subScriptionModal: show ? (
      <ModalPortal>
        <AlertModal btnConfirm msg={msg} handleCloseModal={handleConfirmClose} />
      </ModalPortal>
    ) : null,
    postSubscription
  }
}
