import React from 'react'

import s from './styles/QnaTip.module.scss'

export default function QnaTip({ handleInquiry }) {
  return (
    <>
      <div className={s.qnaTip}>
        <p className={s.qnaTipText}>
          오류나 학습자료 문의는{' '}
          <button className={s.btnInquiry} onClick={handleInquiry}>
            1:1문의
          </button>
          를 통해 질문해주세요.
        </p>
      </div>
    </>
  )
}
