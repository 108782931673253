import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'
import AlertModal from 'components/modal/AlertModal'
import ModalGoodsInquiry from 'components/modal/ModalGoodsInquiry'

import styles from './DetailProduct.module.scss'
import Lnb from '../../../components/Lnb'

export default function DetailProduct() {
  const navigate = useNavigate()
  const { questionSeq } = useParams()
  const [questionData, setQuestionData] = useState([])
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [modalopenInquiry, setModalopenInquiry] = useState(false)

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleOpenModalInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenInquiry(true)
  }, [])

  const handleCloseModalInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenInquiry(false)
  }, [])

  const getQuestionData = () => {
    api
      .get(`/v2/package-question/${questionSeq}`)
      .then((response) => {
        setQuestionData(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 문의 내용 수정
  const editProductInquiry = (questionSeq, textareaValue) => {
    const data = {
      questionSeq: questionSeq,
      contents: textareaValue
    }

    api
      .put('/v2/package-question', data)
      .then((response) => {
        alert('상품 문의가 수정되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 문의 내용 삭제
  const deleteProductData = () => {
    api
      .delete(`/v2/package-question/${questionSeq}`)
      .then((response) => {
        alert('삭제가 완료되었습니다.')
        navigate('/mypage/qna/product')
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getQuestionData()

    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader
              title="상품 문의"
              onClick={() =>
                document.referrer === '' ? navigate('/mypage/qna/product') : navigate(-1)
              }
            />
            <Lnb />
            <section className={styles.listDetailView}>
              <div className={styles.titleCol}>
                <strong>상품문의</strong>
              </div>
              <div className={`${styles.detailTitleArea} ${styles.board}`}>
                <div className={styles.thumbArea}>
                  <span
                    style={
                      questionData.packageImage && {
                        backgroundImage: `url(${questionData.packageImage})`
                      }
                    }
                  >
                    <i className="blind">상품 썸네일</i>
                  </span>
                </div>
                <div className={styles.infoArea}>
                  <div className={styles.class}>
                    <p className={styles.subTit}>
                      <span>{questionData.packageCategoryGroupName}</span>
                      &middot;
                      <span>{questionData.packageCategoryName}</span>
                    </p>
                    <p className={`${styles.mainTit} ellipsis`}>{questionData.packageTitle}</p>
                  </div>
                  <div className={styles.replyModify}>
                    <div className={styles.reply}>
                      <strong
                        className={
                          styles.check +
                          (questionData.replyStatus === 'Y' ? ' ' + styles.completed : '')
                        }
                      >
                        {questionData.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                      </strong>
                      <span className={styles.date}>{formatDate(questionData.registDate)}</span>
                    </div>
                    <div className={styles.modify}>
                      {questionData.replyStatus === 'N' && (
                        <div className={`${styles.btnGroup} btn-group`}>
                          <button
                            className={`${styles.btnModify} btn-modify`}
                            onClick={handleOpenModalInquiry}
                          >
                            <span>수정</span>
                          </button>
                          <button
                            className={`${styles.btnDelete} btn-delete`}
                            onClick={handleOpenModalAlert}
                          >
                            <span>삭제</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.detailContent}>
                <div className={styles.detailQuestion}>
                  <p>{questionData.contents}</p>
                  {questionData.image && <img src={questionData.image} alt="" />}
                </div>
                {questionData.replyStatus === 'Y' ? (
                  <div className={styles.detailAnswer}>
                    <div className={styles.thumbArea}>
                      <span className={styles.thumb}>
                        <i className="blind">썸네일</i>
                        {questionData.answer.answerThumbnailPath && (
                          <img src={questionData.answer.answerThumbnailPath} alt="" />
                        )}
                      </span>
                      <div className={`${styles.desc} desc`}>
                        <strong>{questionData.answer.answerUserName}</strong>
                        <p>{formatDate(questionData.answer.answerDate)}</p>
                      </div>
                    </div>
                    <pre
                      className="answer-txt"
                      dangerouslySetInnerHTML={{
                        __html: questionData.answer.answerContent
                      }}
                    />
                    {questionData.image && <img src={questionData.image} alt="" />}
                  </div>
                ) : null}
              </div>

              <div className="list-btn-col">
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/mypage/qna/product'
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'작성하신 문의를 삭제하시겠습니까? 삭제시 문의내역은 복구할 수 없습니다.'}
            handleCloseModal={handleCloseModalAlert}
            handleConfirmClose={deleteProductData}
          />
        </ModalPortal>
      ) : null}
      {modalopenInquiry ? (
        <ModalPortal>
          <ModalGoodsInquiry
            classData={questionData}
            putQuestion={editProductInquiry}
            questionSeq={questionSeq}
            btnClose
            handleCloseModal={handleCloseModalInquiry}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
