import { useEffect, useRef, useState } from 'react'

import classNames from 'classnames/bind'

import useLevelTestAdvancedStore from 'store/levelTestAdvanced/useLevelTestAdvancedStore'

import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

import styles from './QuestionSection.module.css'
import useQuestion from './useQuestion'

const cx = classNames.bind(styles)

// 인덱스
const Index = ({ questionIndex }) => {
  return <p className={styles.questionIndex}>Q{questionIndex}.</p>
}

// 문항
const QuestionText = ({ question }) => {
  return <p className={styles.questionText}>{question}</p>
}

// default:font-size: 22px
const Typography = ({ variant, children }) => {
  return <p className={cx(variant || 'typography')}>{children}</p>
}

// h2:font-size: 28px
const TypographyH2 = ({ children }) => {
  return <Typography variant="h2">{children}</Typography>
}
// h1:font-size: 32px
const TypographyH1 = ({ children }) => {
  return <Typography variant="h1">{children}</Typography>
}
// 텍스트 사이 간격
const TypographyGap = ({ children }) => {
  return <div className={styles.typographyGap}>{children}</div>
}

const CircleIndexTypography = ({ index, children }) => {
  return (
    <TypographyH2>
      <span className={styles.circleIndex}>{index}</span>
      {children}
    </TypographyH2>
  )
}

const Video = ({ src }) => {
  return (
    <video controls className={styles.video}>
      <source src={src} type="video/mp4" />
    </video>
  )
}
const Audio = ({ src }) => {
  const audioRef = useRef(null)
  const [isplay, setIsPlay] = useState(false)

  const handleClickPlay = () => {
    setIsPlay((prev) => !prev)
    if (isplay) {
      audioRef?.current?.pause()
    } else {
      audioRef?.current?.play()
    }
  }

  return (
    <div className={styles.imageWrapper}>
      <img src={`${LTA_HOST_CDN}img-test-sound.png`} alt="sound" />
      <button className={styles.soundPlayButton} onClick={handleClickPlay}>
        <img src={`${LTA_HOST_CDN}icon-test-sound-${isplay ? 'pause' : 'play'}.png`} alt="play" />
      </button>
      <audio controls ref={audioRef} className={styles.audio}>
        <source src={src} type="audio/mpeg" />
      </audio>
    </div>
  )
}

/**
 *  정답 선택 버튼
 */
const Button = ({ isSelected, onClick, children }) => {
  return (
    <button onClick={onClick} className={cx('button', isSelected && 'buttonActive')}>
      {children}
    </button>
  )
}

/**
 *  기본 정답형
 *  버튼
 */
const ButtonGroup = ({ list, answer, selectedAnswer, score, onClick }) => {
  const handleClick = (button) => {
    if (selectedAnswer === button) {
      onClick({
        answer: null,
        isCorrect: false,
        score: 0,
        category: ''
      })
    } else {
      onClick({ answer: button, isCorrect: answer === button, score })
    }
  }

  return (
    <div className={styles.buttonRow}>
      {list.map((button) => (
        <Button
          key={button}
          isSelected={selectedAnswer === button}
          onClick={() => handleClick(button)}
        >
          {button}
        </Button>
      ))}
    </div>
  )
}
/**
 *  문장완성형 문항
 *  버튼
 */
const SentenceCompleteButtonGroup = ({ list, answer, selected, score, onComplete }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(new Set())

  const handleClick = (button) => {
    const newSelectedAnswer = new Set(selectedAnswer)
    newSelectedAnswer.has(button) ? newSelectedAnswer.delete(button) : newSelectedAnswer.add(button)
    setSelectedAnswer(newSelectedAnswer)

    if (newSelectedAnswer.size === list.length) {
      const completed = Array.from(newSelectedAnswer).join(' ')
      onComplete({
        answer: Array.from(newSelectedAnswer),
        isCorrect: completed === answer,
        score
      })
    }
    if (newSelectedAnswer.size === list.length - 1) {
      onComplete({
        answer: null,
        isCorrect: false,
        score: 0
      })
    }
  }

  useEffect(() => {
    if (selected && selectedAnswer.size === 0) setSelectedAnswer(new Set(selected))
  }, [selected, selectedAnswer.size])

  return (
    <>
      <p className={styles.answerInput}>{Array.from(selectedAnswer).join(' ')}</p>
      <div className={styles.buttonGrid}>
        {list.map((button) => {
          const isSelected = selectedAnswer.has(button)
          return (
            <Button key={button} isSelected={isSelected} onClick={() => handleClick(button)}>
              {button}
              {isSelected && (
                <img
                  className={styles.deleteIcon}
                  src={`${LTA_HOST_CDN}icon-test-answer-delete.png`}
                  alt="delete icon"
                />
              )}
            </Button>
          )
        })}
      </div>
    </>
  )
}

// 문항에 따른 버튼
function QuestionButton() {
  const context = useQuestion()
  const { questionIndex, category, buttonType, buttonGroup } = context
  const onChangeAnswer = useLevelTestAdvancedStore((state) => state.onChangeAnswer)
  const selectedAnswer = useLevelTestAdvancedStore((state) => state.test)[questionIndex - 1]?.answer

  const handleClickAnswer = (value) => {
    onChangeAnswer(questionIndex - 1, { ...value, category })
  }

  return buttonType === 'complete' ? (
    <SentenceCompleteButtonGroup
      selected={selectedAnswer}
      {...buttonGroup}
      onComplete={handleClickAnswer}
    />
  ) : (
    <ButtonGroup selectedAnswer={selectedAnswer} {...buttonGroup} onClick={handleClickAnswer} />
  )
}

Typography.H2 = TypographyH2
Typography.H1 = TypographyH1

const QItem = {
  Index,
  QuestionText,
  Button,
  Typography,
  TypographyGap,
  CircleIndexTypography,
  Video,
  Audio,
  QuestionButton
}

export default QItem
