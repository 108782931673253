import { useState } from 'react'
import Lottie from 'react-lottie-player'

import AiInformation from 'pages/aiTalk/components/AiInformation'
import LottieData from 'pages/aiTalk/lottieData.json'

import s from './Chat.module.css'
import ErrorMessage from './ErrorMessage'
import ModalProfile from './ModalProfile'
import TypingMessage from './TypingMessage'
import UserMessage from './UserMessage'

const defaultOptions = {
  loop: true,
  play: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function ChatFeed({ feed, aiInfo, isLoading, userSeq, isSupported }) {
  const [profile, setProfile] = useState(false)

  const handleProfileModal = () => {
    setProfile(!profile)
  }

  return (
    <>
      <div className={s.chatFeed}>
        <AiInformation data={aiInfo} isSupported={isSupported} />
        <ul className={s.feedList}>
          {feed.map((item, index) => {
            return (
              <li className={`${s.feedItem} ${s[item.type]}`} key={index}>
                {item.type === 'Ai' ? (
                  <TypingMessage
                    text={item.msg}
                    mode={item.mode}
                    aiInfo={aiInfo}
                    userSeq={userSeq}
                    handleProfileModal={handleProfileModal}
                    controls
                  />
                ) : item.type === 'Error' ? (
                  <ErrorMessage msg={item.msg} />
                ) : (
                  <UserMessage userSeq={userSeq} msg={item.msg} />
                )}
              </li>
            )
          })}
          {isLoading || feed.length <= 0 ? (
            <li className={s.feedItem}>
              <div className={s.aiMessage}>
                <div className={s.msgThumb}>
                  <img src={aiInfo.images.circle} alt={aiInfo.info.fullname} />
                </div>
                <dl>
                  <dt className={s.msgName}>{aiInfo.info.firstname}</dt>
                  <dd className={s.message} style={{ minWidth: 'auto' }}>
                    <div className={s.lottieBox}>
                      <Lottie {...defaultOptions} animationData={LottieData} />
                    </div>
                  </dd>
                </dl>
              </div>
            </li>
          ) : null}
        </ul>
      </div>
      {profile && <ModalProfile character={aiInfo} handleProfileModal={handleProfileModal} />}
    </>
  )
}
