import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import cx from 'classnames'
import AuthService from 'services/authService'

import { setCookie } from 'common/Cookie'
import ModalFindId from 'components/modal/ModalFindId'
import ModalFindPwd from 'components/modal/ModalFindPwd'
import ModalJoin from 'components/modal/ModalJoin'

import Login from '../components/Login'
import SocialLogin from '../components/SocialLogin'
import ModalPortal from '../ModalPortal'

export default function LoginPage({ isPlatform }) {
  const historyPathName = document.referrer
  const [isChecked, setIsChecked] = useState(false)
  const [modalopenJoin, setModalopenJoin] = useState(false)
  const [modalopenFindId, setModalopenFindId] = useState(false)
  const [modalopenFindPwd, setModalopenFindPwd] = useState(false)
  const [joinPath, setJoinPath] = useState(null)
  const [searchParams] = useSearchParams()
  const signType = searchParams.get('signType')

  const navigate = useNavigate()
  const userInfo = AuthService.getUserInfo() === null ? false : true

  const handleOpenModalJoin = () => {
    document.body.classList.add('modal-open')
    setModalopenJoin(true)
  }

  const handleCloseModalJoin = () => {
    document.body.classList.add('modal-open')
    setModalopenJoin(false)
  }

  const handleOpenModalFindId = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenFindId(true)
  }, [])

  const handleCloseModalFindId = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenFindId(false)
  }, [])

  const handleOpenModalFindPwd = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenFindPwd(true)
  }, [])

  const handleCloseModalFindPwd = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenFindPwd(false)
  }, [])

  const handleCheckedItem = () => {
    setIsChecked(!isChecked)
  }

  const checkWhereJoin = () => {
    if (historyPathName.includes('/yafitTalk')) {
      setJoinPath('YAFIT_FIND_EFFECT') // 74
    } else if (historyPathName.includes('/yafit')) {
      setJoinPath('YAFIT') // 81
    } else if (historyPathName.includes('/bestAwards')) {
      setJoinPath('ENGLISH_PROMOTION') // 91
    } else {
      setJoinPath('YANADOO_WEB') // 90
    }
  }

  const initWebviewLogin = () => {
    AuthService.removeUserInfo()
  }

  useEffect(() => {
    checkWhereJoin()
  }, [historyPathName])

  useEffect(() => {
    if (isPlatform) {
      initWebviewLogin()
    }
  }, [isPlatform])

  useEffect(() => {
    if (isPlatform) {
      window['wingBannerDisplay']()
      setCookie('historyUrl', window.location.href)

      if (userInfo) {
        navigate('/webview/loginSuccess')
      }
    }
  }, [isPlatform, userInfo])

  return (
    <>
      <section className={cx('login-wrap', { frameless: isPlatform })}>
        <div className="inner">
          <Login checked={isChecked} signType={signType} isPlatform={isPlatform} />
          <div className="option-box">
            <label htmlFor="smsAgree" className="checkbox-flex-form lg-chk">
              <input
                type="checkbox"
                id="smsAgree"
                checked={isChecked ? true : false}
                onChange={handleCheckedItem}
              />
              <span className="chk"></span>
              로그인 상태 유지
            </label>
            <ul className="find-account-box">
              <li>
                <button onClick={handleOpenModalFindId}>아이디 찾기</button>
              </li>
              <li>
                <button onClick={handleOpenModalFindPwd}>비밀번호 찾기</button>
              </li>
            </ul>
          </div>
          {!isPlatform && (
            <>
              <SocialLogin joinPath={joinPath} />
              <div className="btn-flex-form">
                <span>아직 야나두 아이디가 없으신가요?</span>
                <button type="button" className="btn-join" onClick={handleOpenModalJoin}>
                  <span>회원가입</span>
                </button>
              </div>
            </>
          )}
        </div>
      </section>
      {modalopenJoin ? (
        <ModalPortal>
          <ModalJoin
            btnClose
            type={'DEFAULT'}
            handleCloseModal={handleCloseModalJoin}
            handleCloseModalJoin={handleCloseModalJoin}
            handleOpenModalFindId={handleOpenModalFindId}
            whereJoinType={joinPath}
          />
        </ModalPortal>
      ) : null}
      {modalopenFindId ? (
        <ModalPortal>
          <ModalFindId title={'ID 찾기'} btnClose handleCloseModal={handleCloseModalFindId} />
        </ModalPortal>
      ) : null}
      {modalopenFindPwd ? (
        <ModalPortal>
          <ModalFindPwd
            title={'비밀번호 찾기'}
            btnClose
            handleCloseModal={handleCloseModalFindPwd}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
