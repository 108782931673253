import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

import ChannelService from 'ChannelService'

import { hideChannelTalkPaths, CHANNEL_TALK_KEY, CHANNEL_TALK_IMAGE_URL } from './config'

export default function ChannelTalk() {
  const location = useLocation()

  const checkShouldHide = hideChannelTalkPaths.some((path) => location.pathname.includes(path))

  const elementVisibility = (selector, isVisible) => {
    const element = document.querySelector(selector)
    if (element) {
      element.style.setProperty('display', isVisible ? 'none' : 'block', 'important')
    }
  }

  useEffect(() => {
    elementVisibility('#ch-plugin', checkShouldHide)
    elementVisibility('#ch-custom-button', checkShouldHide)
  }, [location.pathname])

  useEffect(() => {
    const ChannelIO = new ChannelService()
    const pluginKey = CHANNEL_TALK_KEY
    const options = isMobile
      ? {
          pluginKey,
          customLauncherSelector: '#ch-custom-button',
          hideChannelButtonOnBoot: true
        }
      : {
          pluginKey
        }

    ChannelIO.boot(options, () => {
      elementVisibility('#ch-plugin', checkShouldHide)
    })
  }, [])

  return (
    <>
      {isMobile && (
        <button
          id="ch-custom-button"
          style={{
            position: 'fixed',
            right: '0',
            bottom: '110px',
            width: '55px',
            height: '55px',
            borderRadius: '50%',
            border: '1px solid #eee',
            zIndex: 9999,
            backgroundImage: `url(${CHANNEL_TALK_IMAGE_URL})`,
            backgroundSize: 'cover',
            backgroundColor: '#fff'
          }}
        >
          <p
            style={{
              position: 'absolute',
              top: '-9999px',
              left: '-9999px'
            }}
          >
            야나두 상담 버튼
          </p>
          <div
            style={{
              position: 'absolute',
              top: '2px',
              right: '4px',
              width: '8px',
              height: '8px',
              backgroundColor: 'rgb(233, 78, 88)',
              borderRadius: '50%',
              transform: 'translate(50%, -50%)'
            }}
          ></div>
        </button>
      )}
    </>
  )
}
