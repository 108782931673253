import React from 'react'
import { Link } from 'react-router-dom'

import styles from './TabCoupon.module.scss'
export default function TabCoupon({ activeTab }) {
  return (
    <ul className={styles.tabMenuList}>
      <li className={`${styles.tabMenuItem} ${activeTab === 'COUPON' ? styles.active : ''}`}>
        <Link to="/mypage/coupon">쿠폰</Link>
      </li>
      <li className={`${styles.tabMenuItem} ${activeTab === 'VOUCHER' ? styles.active : ''}`}>
        <Link to="/mypage/voucher">수강권</Link>
      </li>
    </ul>
  )
}
