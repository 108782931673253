import React, { useRef } from 'react'

import classNames from 'classnames'
import useAlertBeforeUnload from 'hooks/useAlertBeforeUnload'
import useConsultation from 'hooks/useConsultation'
import MallError from 'pages/mall/Error'
import ScrollToTop from 'ScrollToTop'

import ALERT_MESSAGE from 'common/alertMessage'
import Loading from 'components/common/Loading'
import LevelTestCounsel from 'components/promotion/dbCollectionForm/components/LevelTestCounsel'
import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import Assessment from './components/Assessment'
import Average from './components/Average'
import BottomBanner from './components/BottomBanner'
import Grading from './components/Grading'
import LevelChart from './components/LevelChart'
import MyLevel from './components/MyLevel'

import useDownloadResult from './hooks/useDownloadResult'
import useSetDataToStore from './hooks/useSetDataToStore'
import styles from './LevelTestAdvancedResult.module.scss'

export default function LevelTestAdvancedResult() {
  useAlertBeforeUnload('페이지를 떠나시면 결과를 확인할 수 없습니다.\n 페이지를 떠나시겠습니까?')

  const { onPostUserConsultation } = useConsultation()

  // 데이터 GET 커스텀 훅
  const { level, name } = useLevelTestAdvancedResultStore()
  const { isLoading, isError } = useSetDataToStore()

  // 화면 캡쳐 커스텀 훅
  const captureAreaRef = useRef(null)
  const { handleDownloadResult } = useDownloadResult(captureAreaRef)

  // 빠른 상담 예약 데이터
  const levelTestAdvancedFormData = JSON.parse(localStorage.getItem('levelTestAdvancedFormData'))

  // 빠른 상담 신청 POST
  const postFreeConsultation = (privacyAgreeYn, smsAgreeYn, counselTime) => {
    const formData = {
      category: levelTestAdvancedFormData.category,
      utm: levelTestAdvancedFormData.utm,
      counselTime,
      smsAgreeYn: smsAgreeYn ? 'Y' : 'N',
      privacyAgreeYn: privacyAgreeYn ? 'Y' : 'N',
      content: levelTestAdvancedFormData.content
    }

    onPostUserConsultation(formData, (response) => {
      if (response.data.data) {
        alert(ALERT_MESSAGE.CONSULTATION_COMPLETE)
      } else {
        alert(ALERT_MESSAGE.CONSULTATION_DUPLICATED)
      }
    })
  }

  // 데이터 로딩 중
  if (isLoading)
    return (
      <main className={styles.main}>
        <ScrollToTop />
        <Loading />
      </main>
    )

  // 에러 발생 시
  if (isError)
    return (
      <main className={styles.main}>
        <MallError
          comment={
            <>
              예상치 못한 문제가 발생했습니다. <br />
              잠시 후 다시 시도해 주세요.
            </>
          }
          fontColor={'#fff'}
        />
      </main>
    )

  return (
    <main ref={captureAreaRef} className={classNames(styles.main, styles[level])}>
      <div className={styles.inner}>
        <MyLevel />
        <div className={styles.contents}>
          <Average />
          <LevelChart />
          <Grading userName={name} onSubmitConsultation={postFreeConsultation} />
          <Assessment onImageDownload={handleDownloadResult} />
          <LevelTestCounsel
            type="advanced"
            userName={name}
            onSubmitConsultation={postFreeConsultation}
          />
          <BottomBanner />
        </div>
      </div>
    </main>
  )
}
