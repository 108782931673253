import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'

import TabClassListENG from './TabClassListENG'
import TabMain from './TabMain'
import Lnb from '../../components/Lnb'
import NoContent from '../../components/NoContent'
const icoImg =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_mobile_420x420.png'

export default function MyClassDetailENG() {
  const { studySeq } = useParams()

  const [lectureData, setLectureData] = useState(null)
  const [myClassData, setMyClassData] = useState(null)
  const activeClassData =
    myClassData && myClassData.filter((list) => list.studyGroupSeq === Number(studySeq))[0]

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1025 })

    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  // 2.0 클래스 리스트 -> 디테일 페이지 및 셀렉트박스 데이터 필요
  const getClassData = () => {
    api
      .get('/v1/myClass/web')
      .then((response) => {
        setMyClassData(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 2.0 커리큘럼 리스트
  const getCurriculumList = () => {
    api
      .get('/v1/feed/CURRICULUM?keys=studyGroupSeq&types=equal&values=' + studySeq)
      .then((response) => {
        setLectureData(response.data.data.content)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getCurriculumList()
    getClassData()
  }, [studySeq])

  useEffect(() => {
    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    activeClassData && (
      <>
        <div className="myclass-col">
          <div className="inner-col">
            <div className="container mypage-sub">
              <Lnb />
              <section className="content">
                <TabMain activeTab={'MYCLASS'} />
                <section className="class-detail-area">
                  <Desktop>
                    <div className="class-info">
                      <dl>
                        <dt>
                          <p>{activeClassData.className}</p>
                        </dt>
                        <dd>
                          <p>
                            <em>
                              {activeClassData.classTypeName}
                              강의
                            </em>
                            <em className="total-class">총 {activeClassData.lectureCount}강</em>
                          </p>
                          {activeClassData.subscriptionType !== 'U_PLUS' ? (
                            <p>
                              수강기간 <span>{formatDate(activeClassData.beginDate)}</span>~
                              <span>{formatDate(activeClassData.classCloseDate)}</span>
                            </p>
                          ) : null}
                        </dd>
                      </dl>
                    </div>
                    <div className="class-check">
                      <div className="class-check__icon"></div>
                      <p>
                        <strong>PC 수강 전 꼭 확인하세요!</strong>
                        모든 미션 및 학습문의는 앱에서만 제공되며 PC와 모바일 앱 간의 이어보기는
                        연동이 되지 않습니다.
                      </p>
                    </div>
                    <div className="myclass-tab-area-depth2">
                      <ul>
                        <li className="active">
                          <a href="#;" className="tab-item">
                            강의 리스트
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="myclass-content-box myclass-detail">
                      <div className="tab-content tab-course-list ynd2 active">
                        <TabClassListENG
                          myClassData={myClassData}
                          lectureData={lectureData}
                          activeClassData={activeClassData}
                          studySeq={studySeq}
                        />
                      </div>
                    </div>
                  </Desktop>
                  <Mobile>
                    <div className="class-empty myclass">
                      <NoContent
                        icoImg={icoImg}
                        titleMsg={'PC버전과 야나두앱에서만 이용할 수 있는 서비스입니다.'}
                      />
                    </div>
                  </Mobile>
                </section>
              </section>
            </div>
          </div>
        </div>
      </>
    )
  )
}
