import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { getCookie, setCookie } from 'common/Cookie'
import Loading from 'components/common/Loading'

import styles from './ModalPromotionEvent.module.scss'
import ModalWrap from './ModalWrap'

export default function ModalPromotionEvent({ popupInfo }) {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDate = (date) => {
    const day = date === '오늘 하루 열지 않기' ? 1 : date === '7일간 열지 않기' ? 7 : 0
    if (day !== 0) {
      setCookie(`popupClose${popupInfo.seq}`, 'N', day)
    }
    handleClose()
  }

  const LinkContent = ({ link, children }) =>
    link ? (
      <Link to={link} onClick={handleClose}>
        {children}
      </Link>
    ) : (
      <button onClick={handleClose}>{children}</button>
    )

  const containerRef = useRef(null)
  const handleImageLoad = useCallback(() => {
    const img = new Image()
    img.src = popupInfo.image
    img.onload = () => {
      containerRef.current.style.aspectRatio = `${img.width} / ${img.height}`
      setIsImageLoaded(true)
    }
  }, [popupInfo.image])

  useEffect(() => {
    if (popupInfo !== null && popupInfo !== undefined && popupInfo.displayYn === 'Y') {
      setOpen(getCookie(`popupClose${popupInfo.seq}`) !== 'N')
    }
    handleImageLoad()
    return () => setOpen(false)
  }, [popupInfo, handleImageLoad])

  return (
    <>
      {open ? (
        <ModalWrap
          onClose={handleClose}
          dateClose={
            popupInfo.closeType === '옵션 없음'
              ? null
              : { onClick: () => handleCloseDate(popupInfo.closeType), title: popupInfo.closeType }
          }
          btn
        >
          <div className={styles.container} ref={containerRef}>
            <LinkContent link={popupInfo.link ? popupInfo.link : null}>
              {isImageLoaded ? <img src={popupInfo.image} alt={popupInfo.title} /> : <Loading />}
            </LinkContent>
          </div>
        </ModalWrap>
      ) : null}
    </>
  )
}
