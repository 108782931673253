import React, { useState, useEffect } from 'react'

import api from 'common/api'

import styles from './CommentReviewItem.module.scss'
import ThumbNail from './ThumbNail'
import { timeCheck } from '../../common/common'

export default function CommentReviewItem({
  handleOpenReply, // 답글 달기 함수
  handleOpenModal, // 팝업 여는 함수
  backgroundImage,
  nickName,
  contents,
  likeCount,
  registDate,
  existChildComment,
  getCommentData, // 댓글 조회 API
  deviceCheck,
  commentRegistTime,
  isLoading,
  data,
  userInfo,
  replyFlag,
  currentSeq
}) {
  const [replyData, setReplyData] = useState(null)
  // 대댓글 조회 API
  const getReplyData = (seq) => {
    api
      .get(
        `/v2/community-tab/srr-learning-review/comment/child-comment?pageIndex=1&pageSize=100&parentSeq=${seq}`
      )
      .then((response) => {
        if (response.data.meta.code === 200) {
          setReplyData(response.data.data.childComments)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onToggleLike = (seq, relationType) => {
    if (isLoading) {
      api
        .post(`/v2/reaction/like?relationSeq=${seq}&relationType=${relationType}`)
        .then((response) => {
          if (response.data.meta.code === 200) {
            getCommentData(currentSeq)
            getReplyData(data.commentSeq)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    if (isLoading && existChildComment) {
      getReplyData(data.commentSeq)
      getCommentData(currentSeq)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, existChildComment])

  useEffect(() => {
    if (replyFlag && existChildComment) {
      getReplyData(data.commentSeq)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyFlag, data])

  return (
    <>
      <div className={styles.commentItem}>
        <ThumbNail backgroundImage={backgroundImage} size={deviceCheck ? '6.8rem' : '4.6rem'} />
        <div className={styles.descBox}>
          <div className={styles.contentsBox}>
            <strong>{nickName}</strong>
            {contents}
          </div>
          <div className={styles.iconBox}>
            <div>
              {data.status === 'NORMAL' && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      onToggleLike(data.commentSeq, 'COMMENT')
                    }}
                  >
                    {data.isLiked ? <strong>좋아요</strong> : <span>좋아요</span>}
                    {data.likeCount === 0 ? (
                      ''
                    ) : data.isLiked ? (
                      <strong>{likeCount}</strong>
                    ) : (
                      likeCount
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleOpenReply(data.commentSeq, data.registUser.nickName)
                    }}
                  >
                    <span>답글달기</span>
                  </button>
                </>
              )}

              <span>{registDate}</span>
            </div>
            {userInfo &&
              data.registUser.userSeq === userInfo.userSeq &&
              data.status === 'NORMAL' && (
                <button
                  type="button"
                  onClick={() => {
                    handleOpenModal(data.commentSeq, data.contents)
                  }}
                >
                  <span>
                    <i className="blind">팝업열기</i>
                  </span>
                </button>
              )}
          </div>
        </div>
      </div>
      {existChildComment &&
        replyData &&
        replyData
          .filter((reReply) => reReply.status !== 'DELETE_OF_WRITER')
          .map((reReply, idx) => {
            return (
              <div className={`${styles.commentItem} ${styles.reply}`} key={idx}>
                <ThumbNail
                  backgroundImage={reReply.registUser.profileImageUrl}
                  size={deviceCheck ? '5.2rem' : '3.6rem'}
                />
                <div className={styles.descBox}>
                  <div className={styles.contentsBox}>
                    <strong>{reReply.registUser.nickName}</strong>
                    {reReply.contents}
                  </div>
                  <div className={styles.iconBox}>
                    <div>
                      {reReply.status === 'NORMAL' && (
                        <button
                          type="button"
                          onClick={() => {
                            onToggleLike(reReply.commentSeq, 'COMMENT')
                          }}
                        >
                          {reReply.isLiked ? <strong>좋아요</strong> : <span>좋아요</span>}
                          {reReply.likeCount === 0 ? (
                            ''
                          ) : reReply.isLiked ? (
                            <strong>{reReply.likeCount}</strong>
                          ) : (
                            likeCount
                          )}
                        </button>
                      )}
                      <span>
                        {timeCheck(
                          commentRegistTime,
                          reReply.registDate.replace('-', '/').replace('-', '/')
                        )}
                      </span>
                    </div>
                    {userInfo &&
                      reReply.registUser.userSeq === userInfo.userSeq &&
                      reReply.status === 'NORMAL' && (
                        <button
                          type="button"
                          onClick={() => {
                            handleOpenModal(reReply.commentSeq, reReply.contents)
                          }}
                        >
                          <span>
                            <i className="blind">팝업열기</i>
                          </span>
                        </button>
                      )}
                  </div>
                </div>
              </div>
            )
          })}
    </>
  )
}
