import React from 'react'

import s from './styles/FeedbackTextArea.module.scss'

export default function FeedbackTextArea({ value, length, handleCheckTextLength }) {
  return (
    <div className={s.textBox}>
      <div className={s.innerText}>
        <textarea
          placeholder="의견을 남겨주시면 서비스 개선에 활용돼요."
          maxLength={100}
          value={value}
          onChange={handleCheckTextLength}
        />
        <p className={s.textLength}>
          <span>{length}</span>/100
        </p>
      </div>
    </div>
  )
}
