import React, { useState } from 'react'

import classNames from 'classnames'
import ModalPortal from 'ModalPortal'
import { getDateString } from 'pages/levelTestAdvanced/useLevelTestAdvanced'

import { ONLY_NUMBER_REGEXR } from 'common/regExr'
import ModalShare from 'components/modal/ModalShare'
import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import shareIcon from '.././images/ico_share.svg'
import { currentLevelValues } from '../config'
import styles from '../LevelTestAdvancedResult.module.scss'

export default function MyLevel() {
  const { goal, name, level } = useLevelTestAdvancedResultStore()

  // GIF 이미지 로딩 개선
  const [gifLoaded, setGifLoaded] = useState(false)

  // 공유하기 Modal
  const [modalStatus, setModalStatus] = useState(false)

  const handleShareOpen = () => {
    setModalStatus(true)
  }
  const handleShareClose = () => {
    setModalStatus(false)
  }

  return (
    <section className={styles.goals}>
      <div className={styles.top}>
        <p className={styles.date}>{getDateString()}</p>
        <button type="button" className={styles.share} onClick={handleShareOpen}>
          <img src={shareIcon} alt="공유하기" />
        </button>
      </div>

      <article className={styles.user}>
        <strong>{name}님은</strong>
        {goal} <br />
        목표로 하는
        {level && (
          <figure className={classNames(styles.level, styles[level])}>
            {/* 화면 캡쳐 시 gif 이미지가 첫 번째 프레임만 캡쳐되는 현상을 막기 위한 캡쳐 용 정적 이미지  */}
            {gifLoaded && (
              <img className={styles.jpg} src={currentLevelValues[level].jpgSrc} alt={level} />
            )}
            <img
              className={styles.gif}
              src={currentLevelValues[level].gifSrc}
              alt={level}
              onLoad={() => {
                setGifLoaded(true)
              }}
            />
            <div className={styles.levelText}>
              <p>{`Lv.${level.replace(ONLY_NUMBER_REGEXR, '')}`}</p>
              <h3>{currentLevelValues[level].text}</h3>
            </div>
          </figure>
        )}
      </article>

      {modalStatus && (
        <ModalPortal>
          <ModalShare
            title={'심화 레벨테스트를'} //
            data={{
              subTitle: '정확한 영어 실력이 궁금하다면? 야나두 영어 심화 레벨테스트'
            }}
            btnClose
            snsShareTitle={'야나두 영어 심화 레벨테스트'}
            banner={''}
            pathname={'/levelTestAdvanced/landing'}
            handleCloseModal={handleShareClose}
          />
        </ModalPortal>
      )}
    </section>
  )
}
