import { useEffect, useRef, useState } from 'react'

import CurriculumList from 'pages/product/CurriculumList'
import Notice from 'pages/product/Notice'
import QuestionList from 'pages/product/QuestionList'
import ReviewList from 'pages/product/ReviewList'
import TeacherInfo from 'pages/product/TeacherInfo'

import useHeaderHeightStore from 'store/useHeaderHeightStore'

import DetailInfo from './DetailInfo'
import Tab from './Tab'
import styles from '../StoreDetail.module.scss'

export default function ContentsSection({
  isSticky,
  isLoading,
  userInfo,
  classData,
  onChangeIsSticky
}) {
  const [selectedTab, setSelectedTab] = useState(null)

  const headerHeight = useHeaderHeightStore((state) => state.headerHeight)

  const tabRef = useRef()
  const detailWrapRef = useRef(null)
  const detailRef = useRef()
  const curriculumPointRef = useRef()
  const reviewPointRef = useRef()
  const questionPointRef = useRef()

  const scrollCheck = () => {
    const offset = headerHeight + 150
    if (questionPointRef.current && questionPointRef.current.getBoundingClientRect().top < offset) {
      setSelectedTab('question')
    } else if (
      curriculumPointRef.current &&
      curriculumPointRef.current.getBoundingClientRect().top < offset
    ) {
      setSelectedTab('curriculum')
    } else if (
      reviewPointRef.current &&
      reviewPointRef.current.getBoundingClientRect().top < offset
    ) {
      setSelectedTab('review-list')
    } else if (detailRef.current && detailRef.current.getBoundingClientRect().top < offset + 100) {
      setSelectedTab('product')
      onChangeIsSticky(true)
    } else {
      setSelectedTab(null)
      onChangeIsSticky(false)
    }
  }
  useEffect(() => {
    if (!isLoading) {
      window.addEventListener('scroll', scrollCheck)
    }
    return () => {
      window.removeEventListener('scroll', scrollCheck)
    }
  }, [isLoading])

  return (
    <>
      <Tab
        isSticky={isSticky}
        headerHeight={headerHeight}
        tabRef={tabRef}
        selectedTab={selectedTab}
        packageTypeCode={classData?.packageTypeCode}
      />
      <section className={styles.contentsContainer}>
        <div className={styles.contentsInner}>
          <DetailInfo infoRef={detailRef} detailWrapRef={detailWrapRef} classData={classData} />

          <TeacherInfo classData={classData} />
          <div ref={reviewPointRef}>
            <ReviewList packageSeq={classData?.packageSeq} classData={classData} />
          </div>
          {/* 실물상품, 앱 이용권인 경우 비노출 */}
          {classData.packageTypeCode === 'REAL' || classData.packageTypeCode === 'APP' ? null : (
            <div ref={curriculumPointRef}>
              <CurriculumList packageSeq={classData?.packageSeq} />
            </div>
          )}
          <div ref={questionPointRef}>
            <QuestionList
              packageSeq={classData?.packageSeq}
              classData={classData}
              userInfo={userInfo}
            />
          </div>
          <Notice />
        </div>
      </section>{' '}
    </>
  )
}
