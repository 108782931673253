import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import { convertHtmlCode } from 'utils/convertHtmlCode'

import api from 'common/api'
import '../../assets/styles/notice.scss'
import MobileHeader from 'components/common/MobileHeader'

export default function DetailNotice() {
  const navigate = useNavigate()
  const { currentSeq } = useParams()
  const [noticeData, setNoticeData] = useState([])

  const getNoticeData = useCallback(() => {
    api
      .get(`/v2/notice/${currentSeq}`)
      .then((response) => {
        const dataArr = { ...response.data.data, title: convertHtmlCode(response.data.data.title) }
        setNoticeData(dataArr)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [currentSeq])

  useEffect(() => {
    getNoticeData()
  }, [currentSeq, getNoticeData])

  return (
    <div className="notice-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader
            title="공지사항"
            onClick={() =>
              document.referrer === '' ? navigate('/service/notice/1') : navigate(-1)
            }
          />
          <section className="list-detail-col">
            <div className="title-col">
              <strong>공지사항</strong>
            </div>
            <div className="detail-title-area">
              <div className="tit-flex-form">
                <strong className="sub-tit black">{noticeData.title}</strong>
                <div className="info">
                  <span className="date">{noticeData.registDate}</span>
                </div>
              </div>
            </div>
            {noticeData.serviceNoticeAttachFiles &&
              noticeData.serviceNoticeAttachFiles.length > 0 && (
                <div className="detail-attached-files">
                  <span>첨부파일</span>
                  <ul>
                    {noticeData.serviceNoticeAttachFiles.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.attachFilePath}>{item.attachFileName}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            <div className="detail-content">
              <div className="detail-question">
                <pre
                  dangerouslySetInnerHTML={{ __html: noticeData.contents }}
                  style={{ lineHeight: 1.4 }}
                ></pre>
              </div>
            </div>

            <div className="list-btn-col">
              <div className="inner-col">
                <Link
                  to={{
                    pathname: '/service/notice/1'
                  }}
                  className="btn btn-go-list"
                >
                  목록보기
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
