import React from 'react'
import Skeleton from 'react-loading-skeleton'

import styles from './SkeletonReview.module.scss'

import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonReview = ({ deviceCheck }) => {
  return (
    <div className={styles.skeletonBox}>
      <div>
        <div className={styles.leftBox}>
          <Skeleton
            circle={true}
            width={deviceCheck ? '8.2rem' : '5.4rem'}
            height={deviceCheck ? '8.2rem' : '5.4rem'}
            inline={true}
          />
          <div>
            <Skeleton width={'8rem'} />
            <p>
              <Skeleton width={'8rem'} />
            </p>
          </div>
        </div>
        <div className={styles.rightBox}>
          <Skeleton width={'8rem'} />
          {!deviceCheck && (
            <p>
              <Skeleton width={'8rem'} />
            </p>
          )}
        </div>
      </div>
      {deviceCheck && (
        <p>
          <Skeleton />
        </p>
      )}
      <p>
        <Skeleton />
      </p>
    </div>
  )
}

export default SkeletonReview
