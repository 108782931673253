import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'

import styles from './SkeletonMallList.module.scss'

import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonMallList = () => {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [isDeviceCheck, setDeviceCheck] = useState(false)
  useEffect(() => {
    setDeviceCheck(deviceCheck)
  }, [deviceCheck])
  return (
    <>
      <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
      <div className={styles.skeletonList}>
        <h1>
          <Skeleton />
        </h1>
        <ul>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
        </ul>
      </div>
    </>
  )
}

export default SkeletonMallList
