import React, { useRef, useEffect } from 'react'

import { scrollMove } from 'common/common'

import styles from './BoardSearch.module.scss'
import YndButton from '../common/YndButton'

export default function BoardSearch({ searchTypeList, onChangeFilter, searchWord }) {
  const refInput = useRef()
  const refSelect = useRef()
  const onClickSearch = (e) => {
    e.preventDefault()
    onChangeFilter({
      pIndex: 1,
      sType: refSelect.current.value,
      sWord: encodeURIComponent(refInput.current.value)
    })
    scrollMove(0, 0)
  }

  useEffect(() => {
    refInput.current.value = searchWord ? decodeURIComponent(searchWord) : ''
  }, [searchWord])

  return (
    <>
      <form className={styles.searchSection} onSubmit={onClickSearch}>
        {searchTypeList && searchTypeList.length > 0 ? (
          <select ref={refSelect}>
            {searchTypeList.map((item, idx) => {
              return (
                <option key={idx} value={item.searchType}>
                  {item.description}
                </option>
              )
            })}
          </select>
        ) : null}
        <input type="text" ref={refInput} placeholder="검색어를 입력해주세요." />
        <YndButton name="검색" size="md" btnTheme="dark" onClick={onClickSearch} />
      </form>
    </>
  )
}
