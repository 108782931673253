import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import api from 'common/api'
import LikedButton from 'components/LikedButton'
import LikedCount from 'components/LikedCount'

import styles from './CommunityInfo.module.scss'
import ModalPortal from '../ModalPortal'
import ModalShare from './modal/ModalShare'

export default function CommunityInfo({
  type, // 리얼수강후기, 스르르 학습지 후기 분기처리
  link, // 목록 페이지에서 '좋아요, 댓글달기' 영역 클릭 시 이동 될 url
  isLoading,
  data,
  handleOpenComment
}) {
  const location = useLocation()
  const pathname = location.pathname
  const [shareItemData, setShareItemData] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)

  const [likeNum, setLikeNum] = useState(0)
  const [liked, setLiked] = useState(false)

  const moveUrlLists = {
    // 스르르 학습지 후기
    REVIEW: {
      moveUrl: '/community/review/detail/'
    },

    // 리얼 수강 후기
    REAL: {
      moveUrl: '/courseReview/detail/'
    }
  }[type]

  const postShare = (seq, relationType) => {
    api
      .post(
        `v2/reaction/increase?reactionType=SHARE&relationSeq=${seq}&relationType=${relationType}`
      )
      .then((response) => {
        if (response.data?.meta.code === 200) {
          console.log('공유 성공')
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onToggleLike = () => {
    const seq = data?.scholarshipRefundHistorySeq
      ? data?.scholarshipRefundHistorySeq
      : data?.learningReviewSeq
      ? data?.learningReviewSeq
      : data?.seq
    const relationType =
      data?.type === 'LEARNING' || data?.reviewType === 'LEARNING' || type === 'REVIEW'
        ? 'REVIEW'
        : 'SCHOLARSHIP_REFUND_HISTORY'

    if (isLoading) {
      api
        .post(`v2/reaction/like?relationSeq=${seq}&relationType=${relationType}`)
        .then((response) => {
          if (response.data?.meta.code === 200) {
            setLikeNum(response.data?.data?.likeCount)
            setLiked(response.data?.data?.isActive === 'Y' ? 'active' : false)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    if (isLoading) {
      setLikeNum(data?.likeCount)
      setLiked(data?.liked || data?.isLiked ? 'liked' : false)
    }
  }, [isLoading, data])

  const handleOpenShareModal = useCallback((e, data) => {
    document.body.classList.add('modal-open')
    setShareModalOpen(true)
    setShareItemData(data)
  }, [])

  const handleCloseShareModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setShareModalOpen(false)
  }, [])

  return (
    <>
      <div className={styles.buttonListBox}>
        <div>
          {link ? (
            <Link to={link}>
              <LikedCount count={likeNum} type={'좋아요'}>
                {likeNum || 0}
              </LikedCount>
              <LikedCount count={data?.commentCount || 0} type={'댓글'}></LikedCount>
            </Link>
          ) : (
            <>
              <LikedCount count={likeNum || 0} type={'좋아요'}></LikedCount>
              <LikedCount count={data?.commentCount || 0} type={'댓글'}></LikedCount>
            </>
          )}
        </div>
        <div>
          <button
            className={styles.shareButton}
            onClick={(e) => {
              handleOpenShareModal(e, data)
            }}
          >
            <span>공유하기</span>
          </button>
          <LikedButton
            onToggleLike={onToggleLike}
            liked={liked}
            isLoading={isLoading}
            data={data}
          ></LikedButton>
          {link ? (
            <Link to={link}>댓글달기</Link>
          ) : (
            <button className={styles.commentButton} onClick={handleOpenComment}>
              댓글달기
            </button>
          )}
        </div>
      </div>
      {shareModalOpen ? (
        <ModalPortal>
          <ModalShare
            title={'수강생들의 리뷰를'}
            snsShareTitle={
              type === 'REVIEW' ? '학습지 후기' : data?.title ? data?.title : '장학금 리뷰'
            }
            btnClose
            data={shareItemData}
            postShare={postShare}
            banner={data?.thumbnailPath}
            handleCloseModal={handleCloseShareModal}
            pathname={
              // 스르르 학습지 후기면
              type === 'REVIEW'
                ? moveUrlLists.moveUrl + data?.learningReviewSeq
                : !data?.type
                ? pathname
                : // 장학금 후기 시퀀스가 있다면
                data?.scholarshipRefundHistorySeq
                ? moveUrlLists.moveUrl + data?.seq + '/' + data?.scholarshipRefundHistorySeq // 장학금 후기
                : // 일반 리얼후기 시퀀스가 있다면
                data?.seq
                ? moveUrlLists.moveUrl + data?.seq + '/' + 0 // 일반 리얼수강후기
                : moveUrlLists.moveUrl + data?.learningReviewSeq // 스르르 학습지 후기
            }
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
