import { Link } from 'react-router-dom'

import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import ResponsiveImage from 'components/ResponsiveImage'

import styles from './MainSlideSection.module.scss'
import NumberMotion from './NumberMotion'

import 'swiper/swiper.scss'
SwiperCore.use([Autoplay])

export default function MainSlideSection({ bannerList }) {
  return (
    <section className={styles.sliderSection}>
      {bannerList?.length > 0 && (
        <Swiper
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
        >
          {bannerList?.map((item, index) => (
            <SwiperSlide style={{ backgroundColor: item.bgColor }} key={index}>
              <Link
                to={item.link}
                target={item.linkType === 'NEW' ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <ResponsiveImage
                  mo={item.bannerFileMobile}
                  pc={item.bannerFilePc}
                  maxWidth={1024}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <NumberMotion />
    </section>
  )
}
