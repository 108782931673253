import HomeMypage from 'pages/mypage/Home'
import DetailInquiry from 'pages/mypage/qna/DetailInquiry'
import DetailLearning from 'pages/mypage/qna/DetailLearning'
import DetailProductQna from 'pages/mypage/qna/DetailProduct'
import DetailQna from 'pages/mypage/qna/DetailQna'
import DetailReview from 'pages/mypage/qna/DetailReview'
import Inquiry from 'pages/mypage/qna/Inquiry'
import Learning from 'pages/mypage/qna/Learning'
import ProductQna from 'pages/mypage/qna/Product'
import Review from 'pages/mypage/qna/Review'
import { Mypage } from 'router/OutletStore'

import PrivateRoute from 'common/PrivateRoute'
import ItemCoupon from 'components/coupon/ItemCoupon'
import ItemVoucher from 'components/coupon/ItemVoucher'

const MypageRoutes = [
  {
    path: 'mypage/*',
    element: (
      <PrivateRoute>
        <Mypage />
      </PrivateRoute>
    ),
    children: [
      { path: ':id', element: <HomeMypage /> },
      { path: 'coupon', element: <ItemCoupon /> },
      { path: 'voucher', element: <ItemVoucher /> },
      { path: 'qna/detail', element: <DetailQna /> },
      { path: 'qna/detailReview', element: <DetailReview /> },
      { path: 'qna/product', element: <ProductQna /> },
      { path: 'qna/product/detail/:questionSeq', element: <DetailProductQna /> },
      { path: 'qna/inquiry', element: <Inquiry /> },
      { path: 'qna/inquiry/detail/:inquirySeq', element: <DetailInquiry /> },
      { path: 'qna/learning', element: <Learning /> },
      { path: 'qna/learning/detail/:questionSeq', element: <DetailLearning /> },
      { path: 'qna/review', element: <Review /> },
      { path: 'qna/review/detail/:learningReviewSeq', element: <DetailReview /> }
    ]
  }
]

export default MypageRoutes
