import React, { useState, useCallback } from 'react'

import api from 'common/api'

import AlertModal from '../../components/modal/AlertModal'
import ModalPortal from '../../ModalPortal'

export default function ButtonReceipt({ invoiceNum, orderNum }) {
  const [modalopen, setModalopen] = useState(false)

  const handleOpenModal = useCallback((invoiceNum, orderNum) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleConfirmClose = (invoiceNum, orderNum) => {
    handleCloseModal()

    if (invoiceNum && orderNum) {
      putDeliveryComplete(invoiceNum, orderNum)
    }
  }

  const handleCloseModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  // 상품수령 API
  const putDeliveryComplete = (invoiceNum, orderNum) => {
    api
      .put(`/v2/order/delivery-complete?invoiceNum=${invoiceNum}&orderNo=${orderNum}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          return false
        }

        alert('상품수령 처리가 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <button className="button stroke" onClick={() => handleOpenModal(invoiceNum, orderNum)}>
        <span>상품수령 확인</span>
      </button>

      {modalopen ? (
        <ModalPortal>
          <AlertModal
            btnGroup
            msg={'상품수령 확인 처리를 하시겠습니까?'}
            handleCloseModal={handleCloseModal}
            handleConfirmClose={() => {
              handleConfirmClose(invoiceNum, orderNum)
            }}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
