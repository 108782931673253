import React, { useRef, useState } from 'react'

import cx from 'classnames'

import useFeedbackStore from 'store/useFeedbackStore'

import s from './Chat.module.scss'
import SendSvgIcon from '../SendSvgIcon'

export default function ChatForm({ addToFeed, postPromptMessage, isAiResponse }) {
  const inputRef = useRef(null)
  const [currentMessage, setCurrentMessage] = useState('')
  const { isFeedback } = useFeedbackStore()

  const handleOnChange = (e) => {
    const message = e.target.value
    setCurrentMessage(message.trimStart()) // 현재 메세지
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addToFeed({ type: 'USER', message: currentMessage.trim() }) // feed 추가
    postPromptMessage({ message: currentMessage.trim() }) // api에 message 전달
    setCurrentMessage('')
  }

  return (
    <form onSubmit={handleSubmit} className={s.chatForm}>
      <input
        className={s.chatInput}
        type="text"
        value={currentMessage}
        onChange={handleOnChange}
        maxLength={200}
        placeholder={
          isAiResponse
            ? 'AI가 답변을 작성하는 중입니다.'
            : isFeedback
            ? 'AI와의 대화가 종료되었습니다.'
            : '나의 답변 차례입니다.'
        }
        disabled={isAiResponse || isFeedback}
        ref={inputRef}
      />
      <button className={s.btnSubmit} type="submit">
        <SendSvgIcon
          pathClassName={cx({
            [s.btnActive]: currentMessage.length > 0,
            [s.btnDisable]: currentMessage.length === 0
          })}
        />
      </button>
    </form>
  )
}
