import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import api from 'common/api'
import ClassItem from 'components/classItem'
import Loading from 'components/common/Loading'

import ResultNone from './components/ResultNone'
import SortOrder from './components/SortOrder'

import styles from './searchResult.module.css'

export default function SearchResult() {
  const location = useLocation()

  const [searchText, setSearchText] = useState('')
  const [sortOrder, setSortOrder] = useState('RECENT')
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)

  const onChangeSortOrder = (type) => {
    setSortOrder(type)
    getSearchResult(searchText, type)
  }

  /**
   * 검색결과 목록 조회
   */
  const getSearchResult = (searchText, sortOrder) => {
    try {
      setLoading(true)
      const url = `/v2/store/sale/package/search-list?searchText=${searchText}&sortOrder=${sortOrder}`
      api.get(url).then(({ data }) => {
        setList(data.data)
        setLoading(false)
      })
    } catch (error) {
      console.warn(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    const initialSearch = () => {
      const searchParams = new URLSearchParams(location.search)
      const text = searchParams.get('searchText')

      setSearchText(text)
      setSortOrder('POPULAR')
      getSearchResult(text, 'POPULAR')
    }

    if (location.search) {
      initialSearch()
    }
  }, [location.search])

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <span className={styles.titleHighlight}>{searchText}</span>에 대한 검색결과
      </p>
      <SortOrder listLength={list.length} sortOrder={sortOrder} onChange={onChangeSortOrder} />

      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : list.length > 0 ? (
        <ul className={styles.classList}>
          {list.map((item) => (
            <ClassItem key={item.packageSeq} item={item} />
          ))}
        </ul>
      ) : (
        <ResultNone />
      )}
    </div>
  )
}
