import AiFaq from 'pages/aiFaq'
import AiNadoo from 'pages/aiNadoo'
import Signin from 'pages/iframe/Signin'
import LevelTestAdvancedAnswer from 'pages/levelTestAdvanced/answer/index'
import LevelTestAdvancedExam from 'pages/levelTestAdvanced/exam'
import LevelTestAdvancedResult from 'pages/levelTestAdvanced/result/index'
import LoginPage from 'pages/LoginPage'
import CustomerService from 'pages/service'
import AppAInativespeakerChat from 'webview/ai/chat'
import AppAInativespeakertalk from 'webview/ai/intro'
import WebviewCustomer from 'webview/service'
import Customer from 'webview/service/components/Customer'
import CustomerFaq from 'webview/service/components/CustomerFaq'
import CustomerSearchResult from 'webview/service/components/CustomerSearchResult'
import LoginSuccess from 'webview/signin/LoginSuccess'
import AiTravelEnglish from 'webview/travel'
import AiTravelChat from 'webview/travel/AiTravelChat'

import PrivateRoute from 'common/PrivateRoute'
import AppleAuth from 'components/oauth/AppleAuth'
import FacebookAuth from 'components/oauth/FacebookAuth'
import KakaoAuth from 'components/oauth/KakaoAuth'
import NaverAuth from 'components/oauth/NaverAuth'

const FramelessRoutes = [
  {
    path: '/iframe/*',
    children: [
      {
        path: 'signin',
        element: <Signin />
      }
    ]
  },
  {
    path: '/webview/*',
    children: [
      {
        path: 'ai/intro',
        element: <AppAInativespeakertalk />
      },
      {
        path: 'ai/chat',
        element: <AppAInativespeakerChat />
      },
      {
        path: 'ai/srr/qna',
        element: <AiNadoo pageType={'srr'} />
      },
      {
        path: 'ai/qna',
        element: <AiNadoo pageType={'ainadoo'} />
      },
      {
        path: 'travel',
        element: <AiTravelEnglish />
      },
      {
        path: 'travel/chat',
        element: <AiTravelChat />
      },
      {
        path: 'ai/qna',
        element: <CustomerService />
      },
      {
        path: 'login',
        element: <LoginPage isPlatform />
      },
      {
        path: 'loginSuccess',
        element: <LoginSuccess />
      }
    ]
  },
  // 레벨테스트
  {
    path: 'levelTestAdvanced/*',
    children: [
      {
        path: 'exam',
        element: (
          <PrivateRoute>
            <LevelTestAdvancedExam />
          </PrivateRoute>
        )
      },
      {
        path: 'result',
        element: (
          <PrivateRoute>
            <LevelTestAdvancedResult />
          </PrivateRoute>
        )
      },
      {
        path: 'answer',
        element: (
          <PrivateRoute>
            <LevelTestAdvancedAnswer />
          </PrivateRoute>
        )
      }
    ]
  },
  // webview service (웹뷰 고객센터)
  {
    path: '/webview/service/*',
    element: <WebviewCustomer />,
    children: [
      {
        path: 'center',
        element: <Customer />
      },
      {
        path: 'faq',
        element: <CustomerFaq />
      },
      {
        path: 'faq/chat',
        element: <AiFaq isPlatform />
      },
      {
        path: 'faq/search/:currentSeq',
        element: <CustomerSearchResult />
      }
    ]
  },
  {
    path: '/webview/srr/*',
    children: [
      {
        path: 'faq/chat',
        element: <AiFaq service="SRRAPP" isPlatform />
      }
    ]
  },
  {
    path: '/oauth/*',
    children: [
      { path: 'kakao', element: <KakaoAuth /> },
      { path: 'naver', element: <NaverAuth /> },
      { path: 'facebook', element: <FacebookAuth /> },
      { path: 'apple', element: <AppleAuth /> }
    ]
  }
]

export default FramelessRoutes
