import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import useAlertBeforeUnload from 'hooks/useAlertBeforeUnload'
import { v4 as uuid } from 'uuid'

import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import Answer from './components/Answer'
import Top from './components/Top'

import styles from './LevelTestAdvancedAnswer.module.scss'
import { questionValues } from '../config'

export default function LevelTestAdvancedAnswer() {
  const { test } = useLevelTestAdvancedResultStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (!test) {
      window.alert('잘못된 접근입니다.')
      navigate('/levelTestAdvanced/landing')
      return
    }
  }, [test, navigate])

  useAlertBeforeUnload('페이지를 떠나시면 결과를 확인할 수 없습니다.\n 페이지를 떠나시겠습니까?')

  if (!test) return <main></main>

  return (
    <main className={styles.main}>
      <div className={styles.inner}>
        <Top />
        <section className={styles.section}>
          <h3>정답 확인</h3>

          {Object.values(questionValues).map((questionValue, idx) => (
            <Answer key={uuid()} idx={idx} questionValue={questionValue} userResult={test[idx]} />
          ))}
        </section>
      </div>
    </main>
  )
}
