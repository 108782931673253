import { useEffect, useState, useRef, useCallback } from 'react'

import AuthService from 'services/authService'

import api from 'common/api'

import BtnMore from '../../components/BtnMore'
import AlertModal from '../../components/modal/AlertModal'
import ModalGoodsInquiry from '../../components/modal/ModalGoodsInquiry'
import ModalPortal from '../../ModalPortal'
import '../../assets/styles/reviewlist.scss'
import '../../assets/styles/modal.scss'

export default function QuestionList({ classData, packageSeq, userInfo }) {
  const [question, setQuestion] = useState('')
  const questionRef = useRef()
  const [questionSeq, setQuestionSeq] = useState(null)

  const [currentIdx, setCurrentIdx] = useState(null)
  const [pageData, setPageData] = useState([])

  const [modalopen, setModalopen] = useState(false)
  const [modalopen2, setModalopen2] = useState(false)
  const [modalopen3, setModalopen3] = useState(false)

  const [questionData, setQuestionData] = useState([])

  const handleQuestionValue = (e) => {
    setQuestion(e.target.value)
  }

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  const handleOpenModal2 = useCallback((seq) => {
    document.body.classList.add('modal-open')
    setModalopen2(true)
    setQuestionSeq(seq)
  }, [])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen2(false)
  }, [])

  const handleOpenModal3 = useCallback((seq) => {
    document.body.classList.add('modal-open')
    setModalopen3(true)
    setQuestionSeq(seq)
  }, [])

  const handleCloseModal3 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen3(false)
  }, [])

  const handleConfirmClose = (status) => {
    handleCloseModal()

    if (status) {
      deleteQuestion(questionSeq)
    }
  }

  // 문의 내용 등록
  const postQuestion = (event) => {
    event.preventDefault()

    // 로그인 여부 체크
    if (!AuthService.getUserInfo()) {
      alert('로그인이 필요합니다.')
      return false
    }

    if (question === '' || question === null || question === undefined || !question.length) {
      alert('문의 내용을 입력해주세요.')
      questionRef.current.focus()
      return false
    } else {
      const data = {
        packageSeq: packageSeq,
        contents: question
      }

      api
        .post('/v2/package-question', data)
        .then((response) => {
          if (response.data.meta.code !== 200) {
            alert(response.data.meta.message)
          } else {
            // alert('등록이 완료되었습니다!');
            setQuestion('')
            // window.location.reload();
            handleOpenModal()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const handleReload = () => {
    window.location.reload()
  }

  // 문의 내용 수정
  const putQuestion = (questionSeq, contents) => {
    const data = {
      questionSeq: questionSeq,
      contents: contents
    }
    api
      .put('/v2/package-question', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          return false
        }

        alert('저장되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 문의 내용 삭제
  const deleteQuestion = (questionSeq) => {
    api
      .delete(`/v2/package-question/${questionSeq}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          return false
        }

        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 문의 API 호출
  const getQuestionList = (idx, sizeNum, reset = false) => {
    api
      .get(`/v2/store/sale/package/${packageSeq}/question/list?index=${idx}&size=${sizeNum}`)
      .then((response) => {
        if (reset) {
          setQuestionData([...response.data.data.content])
        } else {
          setQuestionData([...questionData, ...response.data.data.content])
        }

        setPageData(response.data.data)
        setCurrentIdx(idx)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (packageSeq) {
      getQuestionList(1, 3, true)
    }
  }, [packageSeq])

  return (
    <>
      <section className="review-list-col question-col">
        <form name="questionFrm" action="#;">
          <h3>
            문의하기
            <button type="submit" className="button" onClick={postQuestion}>
              등록하기
            </button>
          </h3>
          <textarea
            placeholder="문의할 내용을 작성해주세요."
            id="questionText"
            ref={questionRef}
            value={question}
            onChange={handleQuestionValue}
          ></textarea>
        </form>
        <div className="review-list">
          <ul>
            {questionData &&
              questionData.map((question) => {
                return (
                  <li key={question.questionSeq}>
                    <dl>
                      <dt>
                        {userInfo ? (
                          <span
                            className={`profile-img ${
                              question.registUserSeq === userInfo.userSeq ? 'self' : ''
                            }`}
                            style={{
                              backgroundImage: 'url(' + question.profileUrl + ')'
                            }}
                          >
                            프로필
                          </span>
                        ) : (
                          <span
                            className="profile-img"
                            style={{
                              backgroundImage: 'url(' + question.profileUrl + ')'
                            }}
                          >
                            프로필
                          </span>
                        )}
                        <strong>
                          {question.registUserName}
                          <br />
                          <span>{question.registDate}</span>
                        </strong>
                        {userInfo &&
                          (question.registUserSeq === userInfo.userSeq && userInfo.userSeq ? (
                            <div className="review-info">
                              {question.replyStatus === 'N' && (
                                <>
                                  <button
                                    onClick={() => {
                                      handleOpenModal2(question.questionSeq)
                                    }}
                                    type="button"
                                    className="btn-modify"
                                  >
                                    수정
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-delete"
                                    onClick={() => {
                                      handleOpenModal3(question.questionSeq)
                                    }}
                                  >
                                    삭제
                                  </button>
                                </>
                              )}
                            </div>
                          ) : null)}
                      </dt>
                      <dd className="review-box">
                        <div>
                          <p>{question.contents}</p>
                        </div>
                        {question.replyStatus === 'Y' && (
                          <dl className="answer-box">
                            <dt>
                              <span className="profile-img">프로필</span>
                              <strong>
                                {question.answer.answerUserName}
                                <br />
                                <span>{question.answer.answerDate}</span>
                              </strong>
                            </dt>
                            <dd>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: question.answer.answerContent
                                }}
                              ></p>
                            </dd>
                          </dl>
                        )}
                      </dd>
                    </dl>
                  </li>
                )
              })}
          </ul>
        </div>
        {pageData.numberOfElements < pageData.totalElements && currentIdx < pageData.totalPages ? (
          <BtnMore
            type={'PAGINATION'}
            dataFunction={getQuestionList}
            currentIdx={currentIdx}
            sizeNum={3}
            packageSeq={packageSeq}
          />
        ) : null}
      </section>
      {modalopen ? (
        <ModalPortal>
          <AlertModal
            msg={'등록이 완료되었습니다!'}
            handleCloseModal={handleCloseModal}
            handleReload={handleReload}
          />
        </ModalPortal>
      ) : null}

      {modalopen2 ? (
        <ModalPortal>
          <ModalGoodsInquiry
            classData={classData}
            questionList={questionData}
            putQuestion={putQuestion}
            questionSeq={questionSeq}
            btnClose
            handleCloseModal={handleCloseModal2}
          />
        </ModalPortal>
      ) : null}

      {modalopen3 ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'작성하신 문의를 삭제하시겠습니까? 삭제 시 문의 내역은 복구할 수 없습니다.'}
            handleCloseModal={handleCloseModal3}
            handleConfirmClose={() => {
              handleConfirmClose(questionSeq)
            }}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
