import s from './styles/FeedbackRating.module.scss'

const Star = ({ isFilled, handleClickRating }) => {
  return (
    <span onClick={handleClickRating}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 3.60156L31.1383 16.1198L45 19.1858L35.55 29.9885L36.9787 44.4016L24 38.5598L11.0213 44.4016L12.45 29.9885L3 19.1858L16.8617 16.1198L24 3.60156Z"
          fill={isFilled ? '#FFB900' : '#ECECF6'}
        />
      </svg>
    </span>
  )
}

export default function FeedbackRating({ rating, handleClickRating }) {
  const getRatingTitle = () => {
    switch (rating) {
      case 5:
        return '아주 좋아요'
      case 4:
        return '좋아요'
      case 3:
        return '보통이에요'
      case 2:
        return '조금 아쉬워요'
      case 1:
        return '아쉬워요'
      default:
        return '별점을 선택해주세요'
    }
  }

  return (
    <div className={s.ratingBox}>
      <p className={s.ratingTitle}>{getRatingTitle()}</p>
      <div className={s.starsBox}>
        {[...Array(5)].map((_, index) => (
          <Star
            key={index}
            isFilled={index < rating}
            handleClickRating={() => handleClickRating(index)}
          />
        ))}
      </div>
    </div>
  )
}
